var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-edit" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "info-edit-form" },
        [_c("fieldForm", { ref: "formRef", attrs: { fields: _vm.fields } })],
        1
      ),
      _c("div", { staticStyle: { height: "150px" } }),
      _c("fixedButton", {
        attrs: { text: "保存" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "top-tip" }, [
      _vm._v("先上传"),
      _c("span", [_vm._v(" 营业执照 ")]),
      _vm._v("和"),
      _c("span", [_vm._v(" 身份证 ")]),
      _vm._v("，系统将自动识别并回填信息"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
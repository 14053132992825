var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "patient-login" }, [
    _c("div", { staticClass: "login-bg" }),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "input-warp" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fromInfo.username,
              expression: "fromInfo.username",
            },
          ],
          attrs: { maxlength: "50", placeholder: "请输入账号" },
          domProps: { value: _vm.fromInfo.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.fromInfo, "username", $event.target.value)
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fromInfo.password,
              expression: "fromInfo.password",
            },
          ],
          attrs: {
            type: "password",
            maxlength: "50",
            placeholder: "请输入密码",
          },
          domProps: { value: _vm.fromInfo.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.fromInfo, "password", $event.target.value)
            },
          },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fromInfo.code,
              expression: "fromInfo.code",
            },
          ],
          attrs: { maxlength: "50", placeholder: "请输入验证码" },
          domProps: { value: _vm.fromInfo.code },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.fromInfo, "code", $event.target.value)
            },
          },
        }),
        _c("van-image", {
          staticClass: "code-image",
          attrs: { fit: "cover", src: _vm.codeUrl },
          on: { click: _vm.getCode },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "login-btn", on: { click: _vm.smsLogin } }, [
      _vm._v("登 录"),
    ]),
    false
      ? _c(
          "div",
          { staticClass: "login-tip" },
          [
            _c(
              "checkbox",
              {
                model: {
                  value: _vm.isAgree,
                  callback: function ($$v) {
                    _vm.isAgree = $$v
                  },
                  expression: "isAgree",
                },
              },
              [_vm._v("已阅读并同意《用户协议》和《隐私政策》")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("p", { staticClass: "header-login" }, [_vm._v("登录爱检康")]),
      _c("p", { staticClass: "header-title" }, [_vm._v("后台管理系统")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isWeChat && _vm.showDownload
        ? _c("div", { staticClass: "download", on: { click: _vm.downImg } }, [
            _vm._v("下载报告详情"),
          ])
        : _vm._e(),
      _c(
        "div",
        { ref: "pageRef", staticClass: "public-report-detail" },
        [
          _vm.infoList.length > 1
            ? _c(
                "tabs",
                {
                  attrs: { swipeable: "" },
                  model: {
                    value: _vm.active,
                    callback: function ($$v) {
                      _vm.active = $$v
                    },
                    expression: "active",
                  },
                },
                _vm._l(_vm.infoList, function (item, index) {
                  return _c(
                    "tab",
                    { key: index, attrs: { title: "报告 " + (index + 1) } },
                    [
                      _c("baseInfo", { attrs: { detail: item } }),
                      _c("infoTable", {
                        attrs: {
                          tableInfo: item.checkReportInspectionList,
                          showRange: item.showRange,
                        },
                      }),
                      _c("dataEcharts", {
                        attrs: {
                          chartsList: item.checkReportHistogramList,
                          chartsImage: item.chartsImage,
                        },
                      }),
                      _c("interpretation", {
                        attrs: { tableInfo: item.clinicalResultExplain },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.infoList.length === 1 && _vm.infoList[_vm.active].checkOrderVo
            ? [
                _c("baseInfo", { attrs: { detail: _vm.infoList[_vm.active] } }),
                _c("infoTable", {
                  attrs: {
                    tableInfo:
                      _vm.infoList[_vm.active].checkReportInspectionList,
                    showRange: _vm.infoList[_vm.active].showRange,
                  },
                }),
                [_vm.apiModes.DM, _vm.apiModes.JY, _vm.apiModes.TK].includes(
                  this.mode
                )
                  ? [
                      _c("dataEcharts", {
                        attrs: {
                          chartsList:
                            _vm.infoList[_vm.active].checkReportHistogramList,
                          chartsImage: _vm.infoList[_vm.active].chartsImage,
                        },
                      }),
                      _c("interpretation", {
                        attrs: {
                          tableInfo:
                            _vm.infoList[_vm.active].clinicalResultExplain,
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _c("div", { staticStyle: { height: "50px" } }),
        ],
        2
      ),
      _vm.showCodeAuth
        ? _c("span", { staticClass: "add-icon", on: { click: _vm.showCode } }, [
            _c("i", { staticClass: "iconfont icon-ewm" }),
          ])
        : _vm._e(),
      _c("divider", [_vm._v("没有更多了")]),
      _vm.qrcodeModel.show
        ? _c("qrcode", {
            attrs: { show: _vm.qrcodeModel.show, info: _vm.qrcodeModel.info },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.qrcodeModel, "show", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="patient-login">
    <div class="login-bg"></div>
    <div class="header">
      <p class="header-login">登录爱检康</p>
      <p class="header-title">后台管理系统</p>
    </div>
    <div class="input-warp">
      <input maxlength="50" v-model="fromInfo.username" placeholder="请输入账号" />
      <input type="password" maxlength="50" v-model="fromInfo.password" placeholder="请输入密码" />
      <input maxlength="50" v-model="fromInfo.code" placeholder="请输入验证码" />
      <van-image fit="cover" :src="codeUrl" class="code-image" @click="getCode" />
      <!-- <span class="sms-tip" @click="getCode"> {{ countdown > 0 ? `${countdown} 秒后重新获取` : '获取验证码' }} </span> -->
    </div>
    <div class="login-btn" @click="smsLogin">登 录</div>
    <!-- <div class="wx-btn" @click="wxLogin">微信一键登录</div> -->
    <div class="login-tip" v-if="false">
      <checkbox v-model="isAgree">已阅读并同意《用户协议》和《隐私政策》</checkbox>
    </div>
  </div>
</template>

<script>
import services from '@/service';
import { Checkbox, Image as VanImage } from 'vant';
import qs from 'qs';
import { getUserType, getCurToken } from '@/lib/utils';
import { storageEncrypt, storageDecrypt } from '@/lib/jsencrypt';

export default {
  components: {
    Checkbox,
    VanImage
  },
  data() {
    return {
      isAgree: true,
      fromInfo: {
        username: '',
        password: '',
        code: '',
        uuid: ''
      },
      codeUrl: '',
      countdown: 0
    };
  },
  computed: {
    userType() {
      return getUserType();
    }
  },
  methods: {
    async getCode() {
      const res = await services[this.userType].getCode();
      this.fromInfo.uuid = res.uuid;
      this.codeUrl = 'data:image/gif;base64,' + res.img;
    },
    async sendVerificationCode() {
      try {
        await services[this.userType].sendSms(this.fromInfo.username);
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        this.$toast(error.msg || '获取验证码失败');
      }
    },
    async smsLogin() {
      if (!this.fromInfo.username) return this.$toast('请输入账号');
      if (!this.fromInfo.password) return this.$toast('请输入密码');
      if (!this.fromInfo.code) return this.$toast('请输入验证码');
      try {
        const res = await services[this.userType].smsLogin(this.fromInfo);

        await Promise.all([
          this.$store.dispatch('set_token', res.data.access_token),
          this.$store.dispatch(`set_${this.userType}Info`),
          this.$store.dispatch('set_checkOrderState')
        ]);

        storageEncrypt(`${this.userType}_login_username`, this.fromInfo.username);
        storageEncrypt(`${this.userType}_login_password`, this.fromInfo.password);

        this.$router.replace({
          path: `/${this.userType}-home`
        });

        const { redirect_name: redirectName = '', redirect_query: redirectQuery = '' } = this.$route.query;

        if (redirectName) {
          const query = qs.parse(decodeURIComponent(redirectQuery));
          setTimeout(() => {
            this.$router.push({ name: redirectName, query });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        this.getCode();
        // this.$toast(error.msg);
      }
    },
    async checkIsAgree() {
      return new Promise((resolve, reject) => {
        if (this.isAgree) return resolve();
        this.$dialog
          .confirm({
            title: '提示',
            message: '请阅读并同意《用户协议》和《隐私政策》'
          })
          .then(() => {
            this.isAgree = true;
            resolve();
          })
          .catch(() => {
            reject({ msg: '请阅读并同意《用户协议》和《隐私政策》' });
          });
      });
    },
    async wxLogin() {
      await this.checkIsAgree();
      this.$router.push({
        path: `/${this.userType}-home`
      });
    }
  },
  async mounted() {
    const token = getCurToken();
    if (token) {
      await Promise.all([
        this.$store.dispatch('set_token', token),
        this.$store.dispatch(`set_${this.userType}Info`),
        this.$store.dispatch('set_checkOrderState')
      ]);
      this.$router.replace({
        path: `/${this.userType}-home`
      });
    }
    this.getCode();

    const username = storageDecrypt(`${this.userType}_login_username`) || '';
    const password = storageDecrypt(`${this.userType}_login_password`) || '';

    this.fromInfo = {
      username,
      password
    };
  }
};
</script>

<style lang="scss" scoped>
.patient-login {
  height: 100%;
  padding: $lr $tb;
  background: #fff;
  position: relative;
  .header {
    margin-top: 60px;
    z-index: 20;
    position: relative;
    color: #fff;
    .header-login {
      font-size: 20px;
    }
    p {
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }
  .input-warp {
    margin-top: 60px;
    position: relative;
    font-size: $size3;
    color: $black1;
    input {
      display: block;
      width: 100%;
      height: 44px;
      border-radius: $radius;
      background: lighten($appcolor, 55%);
      margin-bottom: $edge;
      padding: 0 $lr;
      box-sizing: border-box;
      border-bottom: 1px solid $gray3;
    }
    .code-image {
      height: 44px !important;
      // width: 100px;
      position: absolute;
      right: 0;
      bottom: 0px;
      text-align: right;
      font-size: $size6;
    }
  }

  .login-btn {
    @include flexCenter();
    width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: $btnbg;
    color: #fff;
    margin-bottom: $edge;
    margin-top: 50px;
  }
  .wx-btn {
    @include flexCenter();
    width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: #24d270;
    color: #fff;
  }
  .login-tip {
    position: absolute;
    bottom: 0;
    // width: 100%;
    z-index: 9999; /* 适当的 z-index 值 */
    width: 95%;
    display: flex;
    justify-content: center;
    bottom: calc(20px + #{env(safe-area-inset-bottom)});
    color: $gray2;
    font-size: $size4;
    text-align: center;
  }
}
</style>

<template>
  <div>
    <div class="add-doctor">
      <div class="device-decord-form">
        <div class="device-decord-tags" v-if="deviceNums.length">
          已添加：<span v-for="(item, index) in deviceNums" :key="item" class="dev-tag">
            {{ item }}
            <Icon name="cross" @click="delDeviceNums(index)" />
          </span>
        </div>
        <van-form ref="formRef">
          <field
            :value="formInfo.f1.text"
            name="产品"
            label="产品"
            placeholder="请选择产品"
            required
            readonly
            clickable
            @click="settingDevicesShow = true"
          />
          <popup v-if="!isEdit" v-model="settingDevicesShow" position="bottom">
            <picker
              :columns="settingDevicesList"
              @confirm="settingDevicesListConfirm"
              @cancel="settingDevicesShow = false"
              value-key="text"
            />
          </popup>
          <field
            v-model="formInfo.deviceNum"
            name="设备编号"
            label="设备编号"
            placeholder="请扫码添加设备编号"
            :rules="[{ validator: () => deviceNums.length > 0 }]"
            right-icon="scan"
            required
            readonly
            clickable
            @click="toScan"
          />
          <field name="联系人" label="联系人：" required>
            <template #input>
              <radio-group v-model="formInfo.receiveType" direction="horizontal" :disabled="isEdit">
                <radio name="sales">业务员</radio>
                <radio name="people">联系人</radio>
              </radio-group>
            </template>
          </field>

          <template v-if="formInfo.receiveType === 'sales'">
            <field
              :value="formInfo.f2.text"
              name="业务员"
              label="业务员"
              placeholder="请选择业务员"
              required
              readonly
              clickable
              @click="salesShow = true"
              :rules="[{ required: true }]"
            />
            <popup v-if="!isEdit" v-model="salesShow" position="bottom">
              <picker :columns="salesList" @confirm="salesListConfirm" @cancel="salesShow = false" value-key="text" />
            </popup>
          </template>
          <template v-if="formInfo.receiveType === 'people'">
            <field
              v-model="formInfo.receiveName"
              name="联系人姓名"
              label="联系人姓名"
              placeholder="请输入联系人姓名"
              type="text"
              :rules="[{ required: true }]"
              required
              :readonly="isEdit"
            />
          </template>
          <field
            v-model="formInfo.receiveMobile"
            name="电话号码"
            label="电话号码"
            placeholder="请输入电话号码"
            type="number"
            maxLength="11"
            :readonly="isEdit"
          />
          <field
            name="地区"
            label="地区"
            placeholder="请选择地区"
            readonly
            clickable
            :value="area"
            @click="showArea = true"
            :rules="[{ required: true }]"
            required
          />
          <popup v-if="!isEdit" v-model="showArea" position="bottom">
            <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
          </popup>
          <field
            v-model="formInfo.addressDetail"
            name="详细地址"
            label="详细地址"
            placeholder="请输入详细地址"
            :rules="[{ required: true }]"
            type="textarea"
            required
            :readonly="isEdit"
          />
          <field
            v-model="formInfo.remark"
            name="说明"
            label="说明"
            placeholder="请输入说明"
            type="textarea"
            :readonly="isEdit"
          />
          <field name="uploader" :label="item.label" v-for="(item, index) in imageList" :key="index">
            <template #input>
              <uploader
                :maxCount="maxCount"
                v-model="item.value"
                :after-read="from => afterRead(from, item)"
                :readonly="isEdit"
                :deletable="!isEdit"
              />
            </template>
          </field>
        </van-form>
      </div>
    </div>

    <div style="height: 200px" />
    <fixedButton text="保存" @emitEvent="submit" v-if="!isEdit" />
  </div>
</template>

<script>
import services from '@/service';
import { Icon, Form as VanForm, RadioGroup, Radio, Field, Uploader, Popup, Area } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';
import Picker from '@/components/picker';
import { scanQRCode, phoneReg } from '@/lib/utils';
// import tabbar from '../components/tabbar';
import { settingDevicesAll, salesAll } from '@/enumAdmin/index';

export default {
  components: { Icon, VanForm, RadioGroup, Radio, Field, Uploader, Popup, Area, Picker, fixedButton },
  data() {
    return {
      phoneReg,
      deviceNums: [],
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {
        f1: {},
        f2: {},
        receiveType: 'sales'
      },
      imageList: [
        {
          label: '拍照',
          key: 'recordUrl',
          value: []
        }
      ],
      maxCount: 1,
      settingDevices: {},
      settingDevicesList: [],
      settingDevicesShow: false,

      sales: {},
      salesList: [],
      salesShow: false
    };
  },
  async mounted() {
    const [settingDevices, sales] = await Promise.all([settingDevicesAll(), salesAll()]);
    this.settingDevices = settingDevices;
    this.settingDevicesList = settingDevices.enums.map(i => {
      return { ...i, text: i.goodsName };
    });
    this.formInfo.f1 = this.settingDevicesList[0];

    this.sales = sales;
    this.salesList = sales.enums.map(i => {
      return { ...i, text: i.name };
    });
    this.getInfo();
  },
  computed: {
    isEdit() {
      return !!this.$route.query.id;
    },
    curId() {
      return this.$route.query.id;
    }
  },
  methods: {
    settingDevicesListConfirm(e) {
      this.formInfo.f1 = e;
      this.settingDevicesShow = false;
    },
    salesListConfirm(e) {
      this.formInfo.f2 = e;
      this.salesShow = false;
    },
    async getInfo() {
      if (!this.isEdit) return;
      const res = await services.admin.deviceRecordInfo(this.curId);
      this.formInfo.deviceNum = res.data.deviceNum;
      this.formInfo.receiveName = res.data.receiveName;
      this.formInfo.receiveMobile = res.data.receiveMobile;
      this.formInfo.addressDetail = res.data.addressDetail;

      this.area = `${res.data.addressProvinceName}/${res.data.addressCityName}/${res.data.addressRegionName}`;

      if (res.data.recordUrl) {
        this.imageList[0].value = [{ url: res.data.recordUrl }];
      }

      if (res.data.goodsSettingId) {
        this.formInfo.f1 = this.settingDevices.enums.find(i => i.goodsSettingId === res.data.goodsSettingId);
      }
      if (res.data.salesId) {
        this.formInfo.f2 = this.sales.enums.find(i => i.salesId === res.data.salesId);
      }
      if (res.data.receiveName) {
        this.formInfo.receiveType = 'people';
      }
    },
    async toScan() {
      if (this.isEdit) return;
      const deviceNum = await scanQRCode();
      const deviceNums = [...new Set([...this.deviceNums, deviceNum])];
      this.deviceNums = deviceNums;
    },
    delDeviceNums(idx) {
      this.deviceNums.splice(idx, 1);
    },
    async afterRead(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      item.value = [res.data];
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    async submit() {
      await this.$refs.formRef.validate();

      const params = {
        ...this.formInfo,
        goodsSettingId: this.formInfo.f1.goodsSettingId,
        deviceNums: this.deviceNums
      };

      delete params.f1;
      delete params.f2;

      if (this.area) {
        const areaList = this.area.split('/');
        params.addressProvinceName = areaList[0];
        params.addressCityName = areaList[1];
        params.addressRegionName = areaList[2];
      }

      this.imageList.forEach(i => {
        if (i.value.length) {
          params[i.key] = i.value[0].url;
        }
      });

      if (params.receiveType === 'sales') {
        params.salesId = this.formInfo.f2.id;
        params.receiveName = '';
      }

      await services.admin.addDeviceRecord(params);
      this.$toast('保存成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-field__label {
    width: 100px !important;
  }
}
.add-doctor {
  padding: $tb $lr;
  .device-decord-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;

    .device-decord-tags {
      background: #fff;
      padding: $tb $lr 4px;
      border: 1px solid $bordercolor;
      .dev-tag {
        display: inline-block;
        padding: 4px 6px;
        background: $appcolor;
        color: #fff;
        border-radius: 4px;
        font-size: $size5;
        margin: 0 10px 10px 0;
      }
    }
  }
}
</style>

<template>
  <Popup v-model="visible" round @closed="closedHandle">
    <div class="opinion-warp">
      <p class="opinion-title">{{ title }}</p>
      <Field v-model="value" label="" :placeholder="placeholder" type="textarea" :maxLength="maxLength" :rows="rows" />
      <div class="btn" @click="submit">{{ submitText }}</div>
    </div>
  </Popup>
</template>

<script>
import { Popup, Field } from 'vant';
export default {
  components: { Popup, Field },
  data() {
    return {
      value: '',
      curValue: []
    };
  },
  props: {
    show: {
      type: Boolean,
      defautl: false
    },
    info: {
      type: Object,
      required: true,
      defautl: () => {
        return {};
      }
    },
    title: {
      type: String,
      default: '重命名'
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    submitText: {
      type: String,
      default: '提交'
    },
    maxLength: {
      type: Number,
      default: 30
    },
    rows: {
      type: Number,
      default: 2
    },
    defaultValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  },
  methods: {
    closedHandle() {
      this.visible = false;
    },
    submit() {
      if (!this.value.trim()) return this.$toast('请输入内容');
      this.$emit('submit', { ...this.info, value: this.value });
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.opinion-warp {
  padding: $tb $lr;
  width: 300px;
  .opinion-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .btn {
    margin-bottom: 0;
  }
}
</style>

<template>
  <tabbar :curIndex="curIndex" :tabbarList="tabbarList" />
</template>

<script>
import tabbar from '@/components/tabbar';

export default {
  components: { tabbar },
  props: {
    curIndex: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      tabbarList: [
        {
          name: '首页',
          icon: 'sy',
          link: '/patient-home'
        },
        {
          name: '我的',
          icon: 'wd',

          link: '/patient-personal'
        }
      ]
    };
  }
};
</script>

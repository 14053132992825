// 统一处理枚举类型
export default function(list, type) {
  let enums = [...list];
  if (type) {
    enums = enums.filter(i => i.types && i.types.includes(type));
  }
  const values = enums.map(item => item.value);
  const keys = enums.map(item => item.key);
  const colors = enums.map(item => item.color);
  return {
    values,
    keys,
    enums,
    colors,
    getKeyByValue(value) {
      return values.indexOf(value) > -1 ? keys[values.indexOf(value)] : '-';
    },
    getValueByKey(key) {
      return keys.indexOf(key) > -1 ? values[keys.indexOf(key)] : '-';
    },
    getColorByKey(key) {
      return keys.indexOf(key) > -1 ? colors[keys.indexOf(key)] : '-';
    }
  };
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clinic-detail" },
    [
      _c(
        "swipe",
        { attrs: { autoplay: 3000 } },
        _vm._l(_vm.images, function (item, index) {
          return _c(
            "swipe-item",
            { key: index },
            [
              _c("van-image", {
                staticClass: "slide-image",
                attrs: { fit: "cover", src: item },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "p",
        { staticClass: "clinic-position", on: { click: _vm.openLocation } },
        [
          _c("i", { staticClass: "iconfont icon-shdz" }),
          _vm._v(" " + _vm._s(_vm.clinicPosition)),
        ]
      ),
      _c("div", { staticClass: "device-info" }, [
        _c(
          "div",
          { staticClass: "info-list" },
          [
            _c("field", {
              attrs: {
                name: "手机号码",
                label: "手机号码：",
                type: "number",
                readonly: "",
                rules: [{ required: true, message: "请输入手机号码" }],
              },
              model: {
                value: _vm.formInfo.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "phone", $$v)
                },
                expression: "formInfo.phone",
              },
            }),
            _c("field", {
              attrs: {
                name: "机构名称",
                label: "机构名称：",
                readonly: "",
                rules: [{ required: true, message: "请输入机构名称" }],
              },
              model: {
                value: _vm.formInfo.clinicName,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "clinicName", $$v)
                },
                expression: "formInfo.clinicName",
              },
            }),
            _c("field", {
              attrs: {
                name: "法人名称",
                label: "法人名称：",
                readonly: "",
                rules: [{ required: true, message: "请输入法人名称" }],
              },
              model: {
                value: _vm.formInfo.corporateName,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "corporateName", $$v)
                },
                expression: "formInfo.corporateName",
              },
            }),
            _c("field", {
              attrs: {
                name: "法人身份证",
                label: "法人身份证：",
                readonly: "",
                type: "idcard",
                maxLength: "18",
                rules: [{ required: true, message: "请输入法人身份证" }],
              },
              model: {
                value: _vm.formInfo.corporateIdCard,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "corporateIdCard", $$v)
                },
                expression: "formInfo.corporateIdCard",
              },
            }),
            _c("field", {
              attrs: {
                name: "营业执照编号",
                label: "营业执照编号：",
                readonly: "",
                rules: [{ required: true, message: "请输入营业执照编号" }],
              },
              model: {
                value: _vm.formInfo.license,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "license", $$v)
                },
                expression: "formInfo.license",
              },
            }),
            _c("field", {
              attrs: {
                name: "所在区域",
                label: "所在区域：",
                placeholder: "请选择所在区域",
                readonly: "",
                clickable: "",
                value: _vm.area,
                rules: [{ required: true, message: "请选择所在区域" }],
              },
            }),
            _c("field", {
              attrs: {
                name: "详细地址",
                label: "详细地址：",
                readonly: "",
                rules: [{ required: true, message: "请输入详细地址" }],
              },
              model: {
                value: _vm.formInfo.address,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "address", $$v)
                },
                expression: "formInfo.address",
              },
            }),
            _c("field", {
              attrs: {
                name: "有效期至",
                label: "有效期至：",
                placeholder: "请选择有效日期",
                readonly: "",
                clickable: "",
                value: _vm.formInfo.validity,
                rules: [{ required: true, message: "请选择有效日期" }],
              },
              on: {
                click: function ($event) {
                  _vm.showCalendar = true
                },
              },
            }),
            _c("field", {
              attrs: {
                name: "机构简介",
                label: "机构简介：",
                readonly: "",
                type: "textarea",
                maxlength: "500",
              },
              model: {
                value: _vm.formInfo.clinicIntro,
                callback: function ($$v) {
                  _vm.$set(_vm.formInfo, "clinicIntro", $$v)
                },
                expression: "formInfo.clinicIntro",
              },
            }),
            _vm._l(_vm.imageList, function (item, index) {
              return _c("field", {
                key: index,
                attrs: { name: "uploader", label: item.label + "：" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("uploader", {
                            attrs: {
                              maxCount: _vm.maxCount,
                              "after-read": (from) => _vm.afterRead(from, item),
                              readonly: "",
                              deletable: false,
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("fixedBottom", [
        _c("div", { staticClass: "btn-warp" }, [
          _c("div", { staticClass: "btn", on: { click: _vm.toBind } }, [
            _vm._v("绑定设备"),
          ]),
          _c(
            "div",
            { staticClass: "btn btn2", on: { click: _vm.performance } },
            [_vm._v("业绩")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "pull-refresh",
    {
      on: { refresh: _vm.refreshList },
      model: {
        value: _vm.pullLoading,
        callback: function ($$v) {
          _vm.pullLoading = $$v
        },
        expression: "pullLoading",
      },
    },
    [
      _c(
        "list",
        {
          attrs: {
            finished: !_vm.hasNext,
            "finished-text": "没有更多了",
            "immediate-check": false,
          },
          on: { load: _vm.loadList },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _vm.listInfo.length == 0 && !_vm.loading
            ? _c("div", {
                staticStyle: { "text-align": "center", padding: "10vh 0" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "finance-list" },
            _vm._l(_vm.listInfo, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "finance-item",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "finance-content" }, [
                    _c("div", { staticClass: "finance-info" }, [
                      _c("h3", { staticClass: "finance-name" }, [
                        _vm._v(_vm._s(item.billName)),
                      ]),
                      _c("p", { staticClass: "finance-tip" }, [
                        _vm._v(_vm._s(item.billCode)),
                      ]),
                    ]),
                    _c("div", { staticClass: "finance-status" }, [
                      _c("p", { staticClass: "view-details" }, [
                        _vm._v("详情"),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
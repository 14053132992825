import services from '@/service';

export default {
  state: {
    clinicId: '',
    clinicInfo: {},
    doctorCurrent: {},
    paymentMode: '1'
  },
  getters: {
    clinicId: state => state.clinicId,
    clinicInfo: state => state.clinicInfo,
    doctorCurrent: state => state.doctorCurrent,
    paymentMode: state => state.paymentMode
  },
  actions: {
    set_clinicId({ commit }, value) {
      commit('set_clinicId', value);
    },
    async set_clinicInfo({ commit }) {
      const { data } = await services.clinic.getClinicInfo();
      commit('set_clinicInfo', data);
      commit('set_paymentMode', data.paymentMode);
    },
    async set_doctorCurrent({ commit }) {
      const { data } = await services.clinic.getDoctorCurrent();
      const { avatarData } = await services.clinic.getDoctorAvatar(data.id);
      data.avatar = avatarData;
      commit('set_doctorCurrent', data);
    },
    set_paymentMode({ commit }, value) {
      commit('set_paymentMode', value);
    }
  },
  mutations: {
    set_clinicId(state, value) {
      state.clinicId = value;
    },
    set_doctorCurrent(state, value) {
      state.doctorCurrent = value;
    },
    set_clinicInfo(state, value) {
      state.clinicInfo = value;
      state.clinicId = value.clinicId;
    },
    set_paymentMode(state, value) {
      state.paymentMode = value;
    }
  }
};

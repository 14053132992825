<template>
  <tabbar :curIndex="curIndex" :tabbarList="tabbarList" />
</template>

<script>
import tabbar from '@/components/tabbar';

export default {
  components: { tabbar },
  props: {
    curIndex: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      tabbarList: [
        {
          name: '功能模块',
          icon: 'gnmk',
          link: '/admin-home'
        },
        {
          name: '统计',
          icon: 'tj',
          link: '/admin-statistics'
        },
        {
          name: '业绩分析',
          icon: 'yjfx',
          link: '/admin-clinic-performance'
        },
        {
          name: '我的',
          icon: 'wd',
          link: '/admin-personal'
        }
      ]
    };
  }
};
</script>

<template>
  <picker
    show-toolbar
    :columns="compColumns"
    @cancel="$emit('cancel')"
    @confirm="(value, index) => $emit('confirm', value, index)"
    :value-key="valueKey"
  >
    <template #title>
      <field
        input-align="center"
        left-icon="search"
        class="search"
        v-model="searchValue"
        placeholder="输入关键词检索"
        clearable
      />
    </template>
  </picker>
</template>

<script>
import { Field, Picker } from 'vant';

export default {
  components: {
    Field,
    Picker
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      default: 'value-key'
    },
    search: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchValue: ''
    };
  },
  computed: {
    compColumns() {
      return this.columns.filter(i => i[this.valueKey].includes(this.searchValue.replace(/\s*/g, '')));
    }
  },
  methods: {
    reset() {
      this.searchValue = '';
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.search {
  width: 50%;
  border-bottom: 1px solid $bordercolor;
  border-radius: 4px;
  padding: 4px 6px;
  margin: 4px 0;
}
</style>

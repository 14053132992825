var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "add-doctor" }, [
        _c("div", { staticClass: "panel-product" }, [
          _c("p", [
            _c("span", { staticClass: "out" }, [
              _vm._v("设备实发数： "),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceOutCount))]),
              _vm._v(" 台 "),
            ]),
            _c("span", { staticClass: "apply" }, [
              _vm._v("（申请数：" + _vm._s(_vm.item.deviceApplyCount) + "）"),
            ]),
          ]),
          _c("p", [
            _c("span", { staticClass: "out" }, [
              _vm._v("耗材实发数： "),
              _c("span", [_vm._v(_vm._s(_vm.item.consumablesOutCount))]),
              _vm._v(" 份 "),
            ]),
            _c("span", { staticClass: "apply" }, [
              _vm._v(
                "（申请数：" + _vm._s(_vm.item.consumablesApplyCount) + "）"
              ),
            ]),
          ]),
          _c("div", { staticClass: "gray-warp" }, [
            _c("p", { staticClass: "gray-info" }, [
              _vm._v(_vm._s(_vm.item.createTime)),
            ]),
            _c("p", { staticClass: "gray-info" }, [
              _vm._v(_vm._s(_vm.item.goodsName)),
            ]),
            _c("p", { staticClass: "gray-info" }, [
              _vm._v(" 申请理由："),
              _c("span", [_vm._v(_vm._s(_vm.item.applyContent))]),
            ]),
            _c("p", { staticClass: "gray-info" }, [
              _vm._v("申请人：" + _vm._s(_vm.item.createBy)),
            ]),
            _c("p", { staticClass: "gray-info" }, [
              _vm._v(" 收货信息：" + _vm._s(_vm.showArea(_vm.item)) + " "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.copyValue(_vm.item),
                      expression: "copyValue(item)",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: () => _vm.$toast("复制成功"),
                      expression: "() => $toast('复制成功')",
                      arg: "success",
                    },
                  ],
                  staticClass: "copy",
                },
                [_vm._v(" 复制")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "device-decord-form" },
          [
            _c(
              "div",
              { staticClass: "device-decord-tags" },
              [
                _vm._v(" 已添加："),
                _vm._l(_vm.deviceNums, function (item, index) {
                  return _c(
                    "span",
                    { key: item, staticClass: "dev-tag" },
                    [
                      _vm._v(" " + _vm._s(item) + " "),
                      _c("Icon", {
                        attrs: { name: "cross" },
                        on: {
                          click: function ($event) {
                            return _vm.delDeviceNums(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c(
                  "span",
                  { staticClass: "dev-tag" },
                  [
                    _c("Icon", {
                      attrs: { name: "plus" },
                      on: {
                        click: function ($event) {
                          return _vm.fieldPrompShow({})
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "van-form",
              { ref: "formRef" },
              [
                _c("field", {
                  attrs: {
                    value: _vm.item.goodsName,
                    name: "产品",
                    label: "产品",
                    placeholder: "请选择产品",
                    required: "",
                    readonly: "",
                    clickable: "",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "设备编号",
                    label: "设备编号",
                    placeholder: "请添加设备编号",
                    "right-icon": "scan",
                    readonly: "",
                    clickable: "",
                  },
                  on: { click: _vm.toScan },
                  model: {
                    value: _vm.formInfo.deviceNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "deviceNum", $$v)
                    },
                    expression: "formInfo.deviceNum",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "设备发货数量",
                    label: "设备发货数量",
                    placeholder: "请输入设备发货数量",
                    type: "number",
                    readonly: _vm.isEdit,
                    required: "",
                    rules: [{ required: true, message: "请输入" }],
                  },
                  model: {
                    value: _vm.formInfo.deviceOutCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "deviceOutCount", $$v)
                    },
                    expression: "formInfo.deviceOutCount",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "耗材发货数量",
                    label: "耗材发货数量",
                    placeholder: "请输入耗材发货数量",
                    type: "number",
                    readonly: _vm.isEdit,
                    required: "",
                    rules: [{ required: true, message: "请输入" }],
                  },
                  model: {
                    value: _vm.formInfo.consumablesOutCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "consumablesOutCount", $$v)
                    },
                    expression: "formInfo.consumablesOutCount",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "说明",
                    label: "说明",
                    placeholder: "请输入说明",
                    type: "textarea",
                    readonly: _vm.isEdit,
                  },
                  model: {
                    value: _vm.formInfo.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "remark", $$v)
                    },
                    expression: "formInfo.remark",
                  },
                }),
                _vm._l(_vm.imageList, function (item, index) {
                  return _c("field", {
                    key: index,
                    attrs: { name: "uploader", label: item.label },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("uploader", {
                                attrs: {
                                  maxCount: _vm.maxCount,
                                  "after-read": (from) =>
                                    _vm.afterRead(from, item),
                                  readonly: _vm.isEdit,
                                  deletable: !_vm.isEdit,
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("fixedButton", {
        attrs: { text: "提交" },
        on: { emitEvent: _vm.submit },
      }),
      _vm.fieldPromptModel.show
        ? _c("fieldPrompt", {
            attrs: {
              title: "添加",
              show: _vm.fieldPromptModel.show,
              fieldForm: _vm.fieldPromptModel.fieldForm,
              info: _vm.fieldPromptModel.info,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.fieldPromptModel, "show", $event)
              },
              emit: _vm.fieldPrompEmit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
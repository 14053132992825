var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "order-confirm" }, [
        _c(
          "div",
          { staticClass: "address-item", on: { click: _vm.selectAddr } },
          [
            _c("div", { staticClass: "address-content" }, [
              _c("i", { staticClass: "iconfont icon-shdz" }),
              _c("div", { staticClass: "address-info" }, [
                _c("h3", { staticClass: "address-name" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.addressName))]),
                  _c("span", { staticClass: "address-phone" }, [
                    _vm._v(_vm._s(_vm.orderInfo.addressMobile)),
                  ]),
                ]),
                _vm.orderInfo.addressProvinceName
                  ? _c("p", { staticClass: "address-area" }, [
                      _vm._v(_vm._s(_vm.showArea(_vm.orderInfo))),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
        _vm.orderInfo.sum
          ? _c(
              "div",
              { staticClass: "product-list" },
              [
                _vm._l(_vm.orderInfo.orderDetailVoList, function (product) {
                  return _c(
                    "div",
                    { key: product.id, staticClass: "product-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "product-content" },
                        [
                          _c("van-image", {
                            staticClass: "product-image",
                            attrs: {
                              fit: "cover",
                              src: product.goodsIcon,
                              alt: product.name,
                            },
                          }),
                          _c("div", { staticClass: "product-details" }, [
                            _c("h3", { staticClass: "product-name" }, [
                              _vm._v(_vm._s(product.goodsName)),
                            ]),
                            _c("p", { staticClass: "product-description" }, [
                              _vm._v(_vm._s(product.goodsFunction)),
                            ]),
                            _c("div", { staticClass: "product-foot" }, [
                              _c("span", { staticClass: "product-price" }, [
                                _vm._v("￥" + _vm._s(product.salePrice)),
                              ]),
                              _c("span", { staticClass: "product-price" }, [
                                _vm._v(_vm._s(product.number)),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _c("field", {
                  attrs: {
                    value: _vm.orderInfo.remark || "无",
                    name: "订单备注",
                    label: "订单备注：",
                    type: "textarea",
                    readonly: "",
                  },
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "price-box" }, [
          _c("span", [_vm._v("合计")]),
          _c("span", { staticClass: "price" }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.payAmount)),
          ]),
        ]),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      ["0"].includes(_vm.orderInfo.status)
        ? _c("fixedButton", {
            staticClass: "wx",
            attrs: { text: "微信支付" },
            on: { emitEvent: _vm.wxPay },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
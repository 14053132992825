var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "order-confirm" }, [
        _c(
          "div",
          { staticClass: "address-item", on: { click: _vm.selectAddr } },
          [
            _c("div", { staticClass: "address-content" }, [
              _c("i", { staticClass: "iconfont icon-shdz" }),
              _c("div", { staticClass: "address-info" }, [
                _c("h3", { staticClass: "address-name" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.addressName))]),
                  _c("span", { staticClass: "address-phone" }, [
                    _vm._v(_vm._s(_vm.orderInfo.addressMobile)),
                  ]),
                ]),
                _vm.orderInfo.addressProvinceName
                  ? _c("p", { staticClass: "address-area" }, [
                      _vm._v(_vm._s(_vm.showArea(_vm.orderInfo))),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
        _vm.orderInfo.sum
          ? _c(
              "div",
              { staticClass: "product-list" },
              _vm._l(_vm.orderInfo.mallOrderDetailVoList, function (product) {
                return _c(
                  "div",
                  { key: product.id, staticClass: "product-item" },
                  [
                    _c("div", { staticClass: "product-content" }, [
                      _c(
                        "div",
                        { staticClass: "item-img" },
                        [
                          _c("van-image", {
                            attrs: { fit: "cover", src: product.goodsIcon },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "product-details" }, [
                        _c("h3", { staticClass: "product-name" }, [
                          _vm._v(_vm._s(product.goodsName)),
                        ]),
                        _c("p", { staticClass: "product-description" }, [
                          _vm._v(_vm._s(product.goodsFunction)),
                        ]),
                        _c("div", { staticClass: "product-foot" }, [
                          _c("span", { staticClass: "product-price" }, [
                            _vm._v("x" + _vm._s(product.number)),
                          ]),
                          _c("span", { staticClass: "product-price" }, [
                            _vm._v("￥" + _vm._s(product.salePrice)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "price-info" },
          [
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("实付款")]),
              _c("span", { staticClass: "price price-total" }, [
                _vm._v("￥" + _vm._s(_vm.orderInfo.payAmount)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("订单金额")]),
              _c("span", { staticClass: "info" }, [
                _vm._v("￥" + _vm._s(_vm.orderInfo.amount)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("配送金额")]),
              _c("span", { staticClass: "info" }, [
                _vm._v("￥" + _vm._s(_vm.orderInfo.deliveryAmount)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("会员积分抵扣")]),
              _c("span", { staticClass: "info" }, [
                _vm._v("- ￥" + _vm._s(_vm.orderInfo.realDeductionAmount)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("消费积分抵扣")]),
              _c("span", { staticClass: "info" }, [
                _vm._v("- ￥" + _vm._s(_vm.orderInfo.realConsumptionAmount)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("订单编号")]),
              _c("span", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.orderInfo.orderCode)),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("订单备注")]),
              _c("span", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.orderInfo.remark || "无")),
              ]),
            ]),
            _c("div", { staticClass: "price-box" }, [
              _c("span", [_vm._v("下单时间")]),
              _c("span", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.orderInfo.createTime)),
              ]),
            ]),
            !["0"].includes(_vm.orderInfo.status)
              ? [
                  _c("div", { staticClass: "price-box" }, [
                    _c("span", [_vm._v("支付时间")]),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.orderInfo.payTime)),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      ["0"].includes(_vm.orderInfo.status)
        ? _c("fixedButton", {
            staticClass: "wx",
            attrs: { text: "微信支付" },
            on: { emitEvent: _vm.wxPay },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="scroll-warp" ref="scroll" @scroll="loadMore">
    <sticky>
      <searchFilter :searchForm="searchForm" @emitSearch="emitSearch" immediately v-if="initEnum" />
    </sticky>
    <div>
      <pull-refresh v-model="pullLoading" @refresh="refreshList">
        <listBox v-for="(item, index) in listInfo" :key="index" :info="item" />
      </pull-refresh>
    </div>
    <divider v-if="listInfo.length && !hasNext">没有更多了</divider>

    <empty v-if="!listInfo.length" description="暂无数据" />
  </div>
</template>

<script>
import { PullRefresh, Empty, Sticky, Divider } from 'vant';
import services from '@/service';
import searchFilter from '@/components/searchFilter';
import { clinicListAll2 } from '@/enumAdmin/index';
import { scanQRCode } from '@/lib/utils';
import { mapGetters } from 'vuex';
import listBox from './components/listBox';

export default {
  name: 'adminClinic',
  components: { PullRefresh, searchFilter, Empty, Sticky, Divider, listBox },
  data() {
    return {
      total: 0,
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        clinicId: '',
        deviceNum: '',
        notTest: false,
        histogramAnomaly: false
      },
      searchParams: {},
      listInfo: [],
      clinicList: {},
      todayFinish: 0,
      scrollPosition: 0
    };
  },
  computed: {
    ...mapGetters(['checkOrderState']),
    notSelect() {
      return this.$route.query.clinicId;
    },
    searchForm() {
      return {
        // salesId: {
        //   label: '业务员',
        //   component: 'select',
        //   options: this.salesList.enums
        // },
        // partnerId: {
        //   label: '合伙人',
        //   component: 'select',
        //   options: this.partnerList.enums
        // },
        clinicId: {
          label: '诊所',
          component: 'select',
          options: this.clinicList.enums
        }
        // goodsSettingId: {
        //   label: '产品',
        //   component: 'select',
        //   options: this.settingList.enums
        // },
        // goodsType: {
        //   label: '产品类型',
        //   component: 'select',
        //   options: this.goodsTypeAll.enums
        // },
        // validFlag: {
        //   label: '库存有效期状态',
        //   component: 'select',
        //   options: this.validFlagType.enums
        // },
        // area: {
        //   label: '所在地区',
        //   component: 'area'
        // }
      };
    },
    initEnum() {
      return this.clinicList.enums;
    }
  },
  async mounted() {
    const [clinicList] = await Promise.all([clinicListAll2()]);
    this.clinicList = clinicList;

    // const [settingList, compWarehouse, clinicListEnum, salesList, partnerList] = await Promise.all([
    //   settingListAll(),
    //   compWarehouseAll(),
    //   clinicListAll(),
    //   salesAll(),
    //   partnerListAll()
    // ]);
    // this.settingList = settingList;
    // this.compWarehouse = compWarehouse;
    // this.clinicListEnum = clinicListEnum;
    // this.salesList = salesList;
    // this.partnerList = partnerList;
    // this.refreshList();
  },
  methods: {
    loadMore() {
      if (!this.hasNext) return;
      const container = this.$refs.scroll;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 1) {
        this.loadList();
      }
    },
    emitSearch(params) {
      this.searchParams = params;
      this.refreshList();
    },
    handleScroll(e) {
      console.log(e);
    },
    async toScan() {
      const deviceNum = await scanQRCode();
      this.queryParams.deviceNum = deviceNum;
      this.refreshList();
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      if (this.loading) return;
      this.loading = true;
      this.queryParams.pageNum++;

      if (this.queryParams.notTest) {
        this.queryParams.notContainTest = '1';
      } else {
        this.queryParams.notContainTest = '2';
      }

      if (this.queryParams.histogramAnomaly) {
        this.queryParams.histogramAnomalyFlag = '1';
      } else {
        this.queryParams.histogramAnomalyFlag = '2';
      }

      const res = await services.admin.listClinic({ ...this.queryParams, ...this.searchParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;

      this.total = res.total;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    unbind(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定解绑？'
        })
        .then(async () => {
          await services.admin.delClinicDevice(item.clinicDeviceRelId);
          this.$toast('操作成功');
          this.refreshList();
        });
    },
    replace(item) {
      this.$router.push({
        name: 'partnerBindDevice',
        query: {
          clinicId: item.clinicId,
          goodsSettingId: item.goodsSettingId,
          clinicDeviceRelId: item.clinicDeviceRelId
        }
      });
    },
    detail(item) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id: item.clinicDeviceRelId
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollPosition = this.$refs.scroll.scrollTop;
    next();
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.scroll.scrollTop = this.scrollPosition;
    });
  }
};
</script>

<style lang="scss" scoped>
.scroll-warp {
  overflow-y: auto;
  height: calc(100% - 46px);
  .other-warp {
    background: #fff;
    text-align: right;
    padding: $tb $lr;
    @include flex(row);
    justify-content: space-between;
    font-size: 14px;
    .other-text {
      margin-top: 10px;
      font-size: 12px;
      .num {
        font-size: $size3;
        color: $red1;
        font-weight: 600;
      }
    }
  }
}
.icon-sm {
  font-size: 24px;
}

.device-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr 0;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.device-content {
  display: flex;
  align-items: center;
}

.device-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.device-avatar {
  width: 100%;
  height: auto;
}

.device-info {
  flex: 1;
  line-height: 20px;
  margin-bottom: $tb;
}

.device-name {
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.clinic-name {
  color: $gray2;
  font-size: $size4;
}

.device-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.device-status-tag {
  position: absolute;
  top: 0;
  right: -30px;
  background-color: #007bff; /* 默认背景色 */
  color: #fff;
  padding: 4px 40px;
  font-size: 12px;
  transform: translateY(0%) rotate(45deg) translateX(10%);
}

.status-normal {
  background-color: $green; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}

.report-foot {
  border-top: 1px solid $bordercolor;
  font-size: $size6;
  color: $gray2;
  padding: $tb $lr;
  padding-right: 0;
  @include flexCenter(row);
  justify-content: space-between;
  .patient-name {
  }

  .foot-btn {
    margin-left: $lr;
    border-radius: 4px;
    padding: 4px 10px;
  }
  .unbind-btn {
    border: 1px solid $red1;
    color: $red1;
  }

  .replace-btn {
    border: 1px solid $blue1;
    color: $blue1;
  }

  .detail-btn {
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}
</style>

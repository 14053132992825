var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "patient-login" }, [
    _c("div", { staticClass: "login-bg" }),
    _c("div", { staticClass: "header" }, [
      _c("p", { staticClass: "header-title" }, [_vm._v("爱检医")]),
      _c("p", { staticClass: "header-login" }, [_vm._v(_vm._s(_vm.showType))]),
    ]),
    _vm.loginType === "sms"
      ? _c("div", { staticClass: "type-warp" }, [
          _c("div", { staticClass: "input-warp" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fromInfo.mobile,
                  expression: "fromInfo.mobile",
                },
              ],
              attrs: {
                type: "number",
                maxlength: "11",
                placeholder: "请输入手机号",
              },
              domProps: { value: _vm.fromInfo.mobile },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.fromInfo, "mobile", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fromInfo.smsCode,
                  expression: "fromInfo.smsCode",
                },
              ],
              attrs: {
                type: "number",
                maxlength: "6",
                placeholder: "请输入验证码",
              },
              domProps: { value: _vm.fromInfo.smsCode },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.fromInfo, "smsCode", $event.target.value)
                },
              },
            }),
            _c("span", { staticClass: "sms-tip", on: { click: _vm.getCode } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.countdown > 0
                      ? `${_vm.countdown} 秒后重新获取`
                      : "获取验证码"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "login-btn", on: { click: _vm.smsLogin } }, [
            _vm._v("登 录"),
          ]),
          _c("div", { staticClass: "login-other" }, [
            _c(
              "span",
              {
                staticClass: "withe-btn",
                on: {
                  click: function ($event) {
                    _vm.loginType = "password"
                  },
                },
              },
              [_vm._v("密码登录")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.loginType === "password"
      ? _c("div", { staticClass: "type-warp" }, [
          _c("div", { staticClass: "input-warp" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fromInfo.mobile,
                  expression: "fromInfo.mobile",
                },
              ],
              attrs: {
                type: "number",
                maxlength: "11",
                placeholder: "请输入手机号",
              },
              domProps: { value: _vm.fromInfo.mobile },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.fromInfo, "mobile", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fromInfo.password,
                  expression: "fromInfo.password",
                },
              ],
              attrs: {
                type: "password",
                maxlength: "20",
                placeholder: "请输入密码",
              },
              domProps: { value: _vm.fromInfo.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.fromInfo, "password", $event.target.value)
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "remember" },
            [
              _c(
                "checkbox",
                {
                  model: {
                    value: _vm.rememberPassword,
                    callback: function ($$v) {
                      _vm.rememberPassword = $$v
                    },
                    expression: "rememberPassword",
                  },
                },
                [_vm._v("记住密码")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login-btn", on: { click: _vm.passwordLogin } },
            [_vm._v("登 录")]
          ),
          _c("div", { staticClass: "login-other" }, [
            _c(
              "span",
              {
                staticClass: "withe-btn",
                on: {
                  click: function ($event) {
                    _vm.loginType = "sms"
                  },
                },
              },
              [_vm._v("验证码登录")]
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.loginType
      ? _c("div", { staticClass: "type-warp" }, [
          _c(
            "div",
            {
              staticClass: "login-btn",
              on: {
                click: function ($event) {
                  _vm.loginType = "sms"
                },
              },
            },
            [_vm._v("验证码登录")]
          ),
          _c(
            "div",
            {
              staticClass: "login-btn",
              on: {
                click: function ($event) {
                  _vm.loginType = "password"
                },
              },
            },
            [_vm._v("密码登录")]
          ),
        ])
      : _vm._e(),
    false
      ? _c(
          "div",
          { staticClass: "login-tip" },
          [
            _c(
              "checkbox",
              {
                model: {
                  value: _vm.isAgree,
                  callback: function ($$v) {
                    _vm.isAgree = $$v
                  },
                  expression: "isAgree",
                },
              },
              [_vm._v("已阅读并同意《用户协议》和《隐私政策》")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
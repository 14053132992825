<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="common-list">
          <div class="common-item" v-for="(item, index) in listInfo" :key="index">
            <div class="common-content">
              <div class="common-image">
                <van-image fit="cover" :src="item.avatar || avatar" class="common-avatar" />
                <!-- <span class="common-status-tag" :class="getStatusClass(common.status)">
                  {{ defaultFlagAll.getValueByKey(common.status) }}
                </span> -->
              </div>
              <div class="common-info">
                <h3 class="common-name">{{ item.salesName }}</h3>
                <p class="clinic-name">{{ item.stock }}</p>
              </div>
              <!-- <div class="common-status">
              <p class="view-details">查看详情<icon name="arrow" /></p>
              <p class="view-details">解绑<icon name="arrow" /></p>
            </div> -->
            </div>
            <div class="report-info report-foot">
              <span class="patient-name"></span>
              <span class="report-time">
                <span class="foot-btn replace-btn" @click.stop="toSalesDeviceList(item)">查看设备</span>
                <!-- <span class="foot-btn detail-btn" @click.stop="detail(item)">库存明细</span> -->
              </span>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>

    <fieldPrompt
      title="调仓出库"
      v-if="fieldPromptModel.show"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      @emit="fieldPromptEmit"
    />
  </div>
</template>

<script>
import { PullRefresh, List, Image as VanImage } from 'vant';
import services from '@/service';
import { defaultFlagAll, teamAllSalesAll } from '@/enum/index';
import { mapGetters } from 'vuex';
import fieldPrompt from '@/components/fieldPrompt';

export default {
  components: { PullRefresh, List, VanImage, fieldPrompt },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {}
      },
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      defaultFlagAll,
      avatar: this.$getImg('avatar'),
      stockSalesList: {}
    };
  },
  computed: {
    ...mapGetters(['isPartnerC']),
    showAction() {
      return !this.isPartnerC;
    },
    notSelect() {
      return this.$route.query.clinicId;
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }
    const [stockSalesList] = await Promise.all([teamAllSalesAll()]);
    this.stockSalesList = stockSalesList;

    this.refreshList();
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.partner.listDeviceStockSales({ ...this.queryParams });

      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toSalesDeviceList({ salesId }) {
      this.$router.push({
        name: 'partnerSalesDeviceList',
        query: {
          salesId
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    unbind(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定解绑？'
        })
        .then(async () => {
          await services.partner.delClinicDevice(item.clinicDeviceRelId);
          this.$toast('操作成功');
          this.refreshList();
        });
    },
    replace(item) {
      this.$router.push({
        name: 'partnerBindDevice',
        query: {
          clinicId: item.clinicId,
          goodsSettingId: item.goodsSettingId,
          clinicDeviceRelId: item.clinicDeviceRelId
        }
      });
    },
    detail(item) {
      this.$router.push({
        name: 'partnerSalesStockRecord',
        query: {
          salesStockId: item.salesStockId
        }
      });
    },
    async fieldPrompShow(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
      this.fieldPromptModel.fieldForm = {
        recordNum: {
          label: '出库数量'
        },
        targetSalesId: {
          label: '业务员',
          component: 'select',
          options: this.stockSalesList.enums
        }
      };
      // await services.partner.getOneClinicServiceOrderList();
    },
    async fieldPromptEmit(e) {
      if (!e.recordNum || e.recordNum > e.stock) return this.$notify('不可超过业务员当前库存');
      if (!e.salesId) return this.$notify('请选择业务员');
      const params = {
        salesId: e.salesId,
        salesMan: e.salesMan,
        goodsSettingId: e.goodsSettingId,
        compId: e.compId,
        compName: e.compName,
        recordNum: Number(e.recordNum),
        targetSalesId: e.targetSalesId,
        targetSalesMan: this.stockSalesList.getValueByKey(e.targetSalesId)
      };
      console.log(this.stockSalesList.getValueByKey(e.targetSalesId));
      console.log(params);

      await services.partner.transferSalesStock(params);
      this.fieldPromptModel.show = false;
      this.refreshList();
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-sm {
  font-size: 24px;
}

.common-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr 0;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.common-content {
  display: flex;
  align-items: center;
}

.common-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.common-avatar {
  width: 100%;
  height: auto;
}

.common-info {
  flex: 1;
  line-height: 20px;
  margin-bottom: $tb;
}

.common-name {
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.clinic-name {
  color: $gray2;
  font-size: $size5;
  margin-top: 10px;
}

.common-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.common-status-tag {
  position: absolute;
  top: 0;
  right: -30px;
  background-color: #007bff; /* 默认背景色 */
  color: #fff;
  padding: 4px 40px;
  font-size: 12px;
  transform: translateY(0%) rotate(45deg) translateX(10%);
}

.status-normal {
  background-color: $green; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}

.report-foot {
  border-top: 1px solid $bordercolor;
  font-size: $size6;
  color: $gray2;
  padding: $tb 0;
  @include flexCenter(row);
  justify-content: space-between;
  .patient-name {
  }

  .foot-btn {
    margin-left: $lr;
    border-radius: 4px;
    padding: 4px 10px;
  }
  .unbind-btn {
    border: 1px solid $red1;
    color: $red1;
  }

  .replace-btn {
    border: 1px solid $blue1;
    color: $blue1;
  }

  .detail-btn {
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}
</style>

<template>
  <div>
    <div class="clinic-info" @click="selectClinic">
      <van-image fit="cover" :src="clinicInfo.frontPhoto || avatar" class="avatar" />
      <span class="name">{{ clinicInfo.clinicName }}</span>
      <span class="read-more" v-if="canChange"> <icon name="arrow"/></span>
    </div>

    <Popup v-model="visible" round @closed="visible = false">
      <div class="opinion-warp">
        <p class="opinion-title">选择诊所</p>

        <div class="radio-warp">
          <radio-group v-model="clinicId">
            <radio :name="item.clinicId" v-for="(item, index) in clinicList" :key="index">
              {{ item.clinicName }}
            </radio>
          </radio-group>
        </div>

        <div class="btn" @click="submit">确认</div>
      </div>
    </Popup>
  </div>
</template>

<script>
import services from '@/service';
import { Image as VanImage, Icon, Popup, RadioGroup, Radio } from 'vant';
import { mapGetters } from 'vuex';

export default {
  components: { VanImage, Icon, Popup, RadioGroup, Radio },
  props: {
    initShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pullLoading: false,
      scoreInfo: {
        todaySalseAmount: 0,
        cmSalseAmount: 0,
        lmSalseAmount: 0
      },
      searchVal: '',
      listInfo: [],
      stockList: [],
      clinicList: [],
      clinicId: '',
      visible: false
    };
  },
  async mounted() {
    await this.getBindClinics();
  },
  computed: {
    ...mapGetters(['clinicInfo']),
    canChange() {
      return this.clinicList.length > 1;
    }
  },
  methods: {
    selectClinic() {
      if (!this.canChange) return;
      this.visible = true;
      this.clinicId = this.clinicInfo.clinicId;
    },
    async submit() {
      await services.clinic.chooseClinic(this.clinicId);
      await this.$store.dispatch(`set_clinicInfo`);
      this.$toast.success('切换成功');
      this.$emit('refresh');
      this.visible = false;
    },
    async getBindClinics() {
      const res = await services.clinic.getBindClinics();
      this.clinicList = res.data;
      if (this.initShow) {
        this.selectClinic();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-radio-group {
    > div {
      border: 1px solid $bordercolor;
      margin: $tb 0;
      padding: $tb $lr;
      border-radius: $radius;
      transition-duration: 0.2s;
      &[aria-checked='true'] {
        border: 1px solid $appcolor;
      }
    }
  }
}

.clinic-info {
  padding: $tb $lr;
  color: $black1;

  @include flex(row);
  align-items: center;
  .avatar {
    width: 22px;
    height: 22px !important;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
  }
  .name {
    @include hiddenText();
    flex: 1;
    font-size: $size0;
    font-weight: 600;
  }
}

.opinion-warp {
  padding: $tb $lr;
  width: 300px;

  .opinion-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .radio-warp {
    margin: 30px 0;
    max-height: 50vh;
    overflow-y: auto;
  }
  .btn {
    margin-bottom: 0;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-echarts" }, [
    _vm.chartsImage.length
      ? _c(
          "div",
          _vm._l(_vm.chartsImage, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("p", [_vm._v(_vm._s(item.title))]),
                _c("van-image", {
                  staticStyle: { width: "100%" },
                  attrs: { fit: "cover", src: item.image },
                }),
              ],
              1
            )
          }),
          0
        )
      : _c(
          "div",
          _vm._l(_vm.chartsList, function (item, index) {
            return _c("lineChart", { key: index, attrs: { option: item } })
          }),
          1
        ),
    false
      ? _c("div", { staticClass: "data-info" }, [
          _c("p", [_vm._v("结果解读：")]),
          _c("div", { staticClass: "data-info-content" }, [
            _vm._v(
              " 根据本次样本检测分析，CRP检测结果阴性，白细胞正常，淋巴细胞正常，粒细胞正常，，提示无感染或感染已恢复 具体判断请结合临床和连续监测的数值，以医生诊断为 准。 "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import services from '@/service';

export default {
  state: {
    mallId: '',
    mallInfo: {}
  },
  getters: {
    mallId: state => state.mallId,
    mallInfo: state => state.mallInfo
  },
  actions: {
    set_mallId({ commit }, value) {
      commit('set_mallId', value);
    },
    async set_mallInfo({ commit }) {
      const { data } = await services.mall.getMallInfo();
      commit('set_mallInfo', data);
    }
  },
  mutations: {
    set_mallId(state, value) {
      state.mallId = value;
    },
    set_mallInfo(state, value) {
      state.mallInfo = value;
      state.mallId = value.userRelId;
    }
  }
};

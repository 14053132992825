var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-form",
    { ref: "formRef" },
    [
      _vm._l(_vm.fieldList, function (item, index) {
        return [
          !item.hide
            ? [
                item.type === "date"
                  ? [
                      _c("field", {
                        key: item.key,
                        attrs: {
                          label: item.label + ":",
                          placeholder: "请选择" + item.label,
                          readonly: "",
                          clickable: "",
                          value: item.value,
                          rules: item.rules,
                          required: item.required,
                        },
                        on: {
                          click: function ($event) {
                            item.show = true
                          },
                        },
                      }),
                      _c(
                        "popup",
                        {
                          key: "popup_" + item.key,
                          attrs: { position: "bottom" },
                          model: {
                            value: item.show,
                            callback: function ($$v) {
                              _vm.$set(item, "show", $$v)
                            },
                            expression: "item.show",
                          },
                        },
                        [
                          _c("datetime-picker", {
                            attrs: {
                              type: "date",
                              title: "请选择" + item.label,
                              "max-date": item.maxDate,
                              "min-date": item.minDate,
                            },
                            on: {
                              confirm: (date) =>
                                _vm.calendarConfirm(date, item),
                            },
                            model: {
                              value: item.popupValue,
                              callback: function ($$v) {
                                _vm.$set(item, "popupValue", $$v)
                              },
                              expression: "item.popupValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : item.type === "select"
                  ? [
                      _c("field", {
                        key: item.key,
                        attrs: {
                          label: item.label + ":",
                          placeholder: "请选择" + item.label,
                          readonly: "",
                          clickable: "",
                          value:
                            item.value && item.value[item.valueKey || "text"],
                          rules: item.rules,
                          required: item.required,
                        },
                        on: {
                          click: function ($event) {
                            item.show = true
                          },
                        },
                      }),
                      _c(
                        "popup",
                        {
                          key: "popup_" + item.key,
                          attrs: { position: "bottom" },
                          model: {
                            value: item.show,
                            callback: function ($$v) {
                              _vm.$set(item, "show", $$v)
                            },
                            expression: "item.show",
                          },
                        },
                        [
                          _c("picker", {
                            attrs: {
                              columns: item.columns,
                              "value-key": item.valueKey || "text",
                            },
                            on: {
                              confirm: (value, index) =>
                                _vm.pickerConfirm(value, index, item),
                              cancel: function ($event) {
                                item.show = false
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : item.type === "radio-group"
                  ? [
                      _c("field", {
                        key: item.key,
                        attrs: {
                          label: item.label + ":",
                          rules: item.rules,
                          required: item.required,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c(
                                    "radio-group",
                                    {
                                      attrs: { direction: "horizontal" },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    },
                                    _vm._l(
                                      item.actions,
                                      function (radioItem, radioIndex) {
                                        return _c(
                                          "radio",
                                          {
                                            key: "radio" + radioIndex,
                                            attrs: { name: radioItem.key },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(radioItem.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  : item.type === "area"
                  ? [
                      _c("field", {
                        key: item.key,
                        attrs: {
                          label: item.label + ":",
                          placeholder: "请选择" + item.label,
                          readonly: "",
                          clickable: "",
                          value: item.value,
                          rules: item.rules,
                          required: item.required,
                        },
                        on: {
                          click: function ($event) {
                            item.show = true
                          },
                        },
                      }),
                      _c(
                        "popup",
                        {
                          key: "popup_" + item.key,
                          attrs: { position: "bottom" },
                          model: {
                            value: item.show,
                            callback: function ($$v) {
                              _vm.$set(item, "show", $$v)
                            },
                            expression: "item.show",
                          },
                        },
                        [
                          _c("Area", {
                            attrs: {
                              title: "请选择" + item.label,
                              value: item.popupValue,
                              "area-list": _vm.areaList,
                            },
                            on: {
                              confirm: (values) => _vm.onConfirm(values, item),
                              cancel: function ($event) {
                                item.show = false
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : item.type === "uploader"
                  ? [
                      _c("field", {
                        key: item.key,
                        attrs: {
                          name: "uploader",
                          label: item.label + ":",
                          rules: [
                            {
                              required: item.required,
                              message: "请上传" + item.label,
                            },
                          ],
                          required: item.required,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("uploader", {
                                    attrs: {
                                      maxCount: item.maxCount || 1,
                                      "after-read": (from) =>
                                        _vm.afterRead(from, item),
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  : [
                      _c("field", {
                        key: index,
                        attrs: {
                          label: item.label + ":",
                          type: item.type,
                          maxLength: item.maxLength,
                          placeholder: "请输入" + item.label,
                          rules: item.rules,
                          required: item.required,
                        },
                        scopedSlots: _vm._u(
                          [
                            item.slotButton
                              ? {
                                  key: "button",
                                  fn: function () {
                                    return [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.slotButton),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ],
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="download" @click="downImg" v-if="!isWeChat && showDownload">下载报告详情</div>
    <div class="public-report-detail" ref="pageRef">
      <tabs v-model="active" swipeable v-if="infoList.length > 1">
        <tab v-for="(item, index) in infoList" :title="'报告 ' + (index + 1)" :key="index">
          <baseInfo :detail="item" />
          <infoTable :tableInfo="item.checkReportInspectionList" :showRange="item.showRange" />
          <dataEcharts :chartsList="item.checkReportHistogramList" :chartsImage="item.chartsImage" />
          <interpretation :tableInfo="item.clinicalResultExplain" />
        </tab>
      </tabs>
      <template v-if="infoList.length === 1 && infoList[active].checkOrderVo">
        <baseInfo :detail="infoList[active]" />
        <infoTable :tableInfo="infoList[active].checkReportInspectionList" :showRange="infoList[active].showRange" />
        <template v-if="[apiModes.DM, apiModes.JY, apiModes.TK].includes(this.mode)">
          <dataEcharts
            :chartsList="infoList[active].checkReportHistogramList"
            :chartsImage="infoList[active].chartsImage"
          />
          <interpretation :tableInfo="infoList[active].clinicalResultExplain" />
        </template>
      </template>
      <div style="height: 50px;"></div>
    </div>
    <span class="add-icon" @click="showCode" v-if="showCodeAuth">
      <i class="iconfont icon-ewm" />
    </span>
    <divider>没有更多了</divider>

    <qrcode v-if="qrcodeModel.show" :show.sync="qrcodeModel.show" :info="qrcodeModel.info" />
  </div>
</template>

<script>
import { Tab, Tabs, Divider } from 'vant';
import services from '@/service';
import { getUserType } from '@/lib/utils';

import baseInfo from '@/components/report/baseInfo';
import infoTable from '@/components/report/infoTable';
import dataEcharts from '@/components/report/dataEcharts';
import interpretation from '@/components/report/interpretation';
import html2canvas from 'html2canvas';
import qrcode from '@/components/qrcode';
import { isWeChat, apiModes } from '@/global';

import KSdataHandle from './KS';
import FKdataHandle from './FK';

export default {
  components: {
    Tab,
    Tabs,
    Divider,
    baseInfo,
    infoTable,
    dataEcharts,
    interpretation,
    qrcode
  },
  data() {
    return {
      isWeChat,
      qrcodeModel: {
        show: false,
        info: {}
      },
      infoList: [],
      active: 0,
      notFoundImg: this.$getImg('无数据@2x'),
      apiModes,
      mode: ''
    };
  },
  computed: {
    curId() {
      return this.infoList[this.active].checkOrderDetailId;
    },
    queryId() {
      return this.$route.query.id;
    },
    queryCode() {
      return this.$route.query.code;
    },
    userType() {
      return getUserType();
    },
    showCodeAuth() {
      return ['clinic', 'admin'].includes(this.userType);
    },
    showDownload() {
      return ['clinic', 'admin'].includes(this.userType);
    }
  },
  methods: {
    showCode() {
      this.qrcodeModel.show = true;
      // const code = `${window.location.origin}/patient-report-detail?id=${this.queryId}`;
      const code = `http://p.zhuoniaoyunyi.com/r/${this.curId}`;
      this.qrcodeModel.info = {
        code,
        text: `${this.infoList[this.active].checkOrderVo.checkCode}.${this.queryId}`
      };
    },
    async queryReports() {
      let infoList = [];
      let res = {};
      if (this.queryCode) {
        res = await services[this.userType].queryMsgReports(this.queryCode);
        if (!res.data) {
          await this.$dialog.alert({
            title: '报告已过期，请直接登录用户端查看报告',
            message: ''
          });
          return this.$router.go(-1);
        }
        infoList = [res.data];
      } else {
        res = await services[this.userType].queryReports(this.queryId);
        infoList = [res.data];
      }

      this.mode = res.data.apiMode;
      if (this.mode === apiModes.DM) {
        console.log('DM');
        infoList.forEach(i => {
          i.checkReportInspectionList = i.checkReportInspectionList.map(i => {
            return {
              ...i,
              range: `${i.rangeLow} ~ ${i.rangeHigh}`
            };
          });
        });
      } else if (this.mode === apiModes.JY) {
        infoList.forEach(i => {
          const dataValue = JSON.parse(i.dataValue);
          i.checkReportInspectionList = dataValue.paras.map(i => {
            console.log(i.RRs.split('-')[0]);
            return {
              ...i,
              paramValue: i.value,
              range: `${i.RRs.split('-')[0]} ~ ${i.RRs.split('-')[1]}`,
              paramName: i.typeName
            };
          });
        });
      } else if (this.mode === apiModes.TK) {
        infoList.forEach(i => {
          const dataValue = JSON.parse(i.dataValue);
          i.checkReportInspectionList = dataValue.inspection.map(i => {
            let flag = '';
            if (i.flag === 'L') {
              flag = 'Down';
            } else if (i.flag === 'H') {
              flag = 'Up';
            }
            return {
              ...i,
              paramValue: i.value,
              range: `${i.valueRef.split('--')[0]} ~ ${i.valueRef.split('--')[1]}`,
              paramName: `${i.item} (${i.longname})`,
              flag
            };
          });
          i.chartsImage = Object.keys(dataValue.histogram).map(o => {
            return {
              title: o,
              image: `data:image/jpeg;base64,${dataValue.histogram[o]}`
            };
          });
        });
      } else if (this.mode === apiModes.KS) {
        infoList.forEach(i => {
          KSdataHandle(i);
        });
      } else if (this.mode === apiModes.FK) {
        infoList.forEach(i => {
          FKdataHandle(i);
        });
      }

      this.infoList = infoList;
    },
    async downImg() {
      // await new Promise(resolve => setTimeout(resolve, 500));
      const width = this.$refs.pageRef.clientWidth;
      const height = this.$refs.pageRef.clientHeight;

      html2canvas(this.$refs.pageRef, { width, height, scale: 2 }).then(canvas => {
        // 转换为图像数据
        const imageData = canvas.toDataURL('image/png');

        // 创建虚拟下载链接
        const downloadLink = document.createElement('a');
        downloadLink.href = imageData;

        // 设置下载链接的文件名
        downloadLink.download = `${this.infoList[this.active].checkOrderVo.checkCode}报告详情.png`;

        // 模拟点击下载链接
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // 移除下载链接
        document.body.removeChild(downloadLink);
      });
    }
  },
  mounted() {
    if (this.queryCode) {
      var navBarWarp = document.querySelector('.nab-bar-warp');
      if (navBarWarp) {
        navBarWarp.style.display = 'none';
      }
    }
    this.queryReports();
  }
};
</script>

<style lang="scss" scoped>
.download {
  text-align: right;
  background: #fff;
  color: $appcolor;
  padding: $tb $lr 0;
  cursor: pointer;
  font-size: $size6;
}
.add-icon {
  position: absolute;
  right: 20px;
  bottom: 60px;
  font-size: 30px;
  padding: 10px;
  background: $appcolor;
  color: #fff;
  box-shadow: $box-shadow1;
  border: 2px solid #fff;
  border-radius: 50%;
}
</style>

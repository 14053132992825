<template>
  <div class="logistics-page">
    <!-- 快递公司和物流单号 -->
    <div class="logistics-header">
      <h2>快递公司：{{ info.expressCompany }}</h2>
      <p>物流单号：{{ info.expressNo }}</p>
    </div>

    <!-- 物流路由信息 -->
    <div class="logistics-timeline">
      <div v-for="(item, index) in logisticsData" :key="index" class="timeline-item">
        <div class="timeline-time">{{ item.time }}</div>
        <div class="timeline-content">{{ item.context }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/service';

export default {
  data() {
    return {
      info: {},
      logisticsData: []
    };
  },
  computed: {
    mallOrderId() {
      return this.$route.query.id;
    }
  },
  methods: {
    async getInfo() {
      const res = await services.mall.mallOrderLogistics(this.mallOrderId);
      this.info = res.data;
      this.logisticsData = JSON.parse(res.data.locus);
    }
  },
  mounted() {
    this.getInfo();
  }
};
</script>

<style lang="scss">
.logistics-page {
  padding: 20px;
  //   margin: 0 auto;
  background: #fff;

  .logistics-header {
    // text-align: center;
    margin-bottom: 30px;

    h2 {
      //   font-size: 24px;
      color: $appcolor;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #666;
    }
  }

  .logistics-timeline {
    position: relative;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 6px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $appcolor;
    }

    .timeline-item {
      position: relative;
      margin-bottom: 20px;
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        left: -21px;
        top: 0px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $appcolor;
        border: 2px solid white;
      }

      .timeline-time {
        font-size: 14px;
        color: #999;
        margin-bottom: 5px;
      }

      .timeline-content {
        font-size: $size4;
        color: #333;
        line-height: 22px;
      }
    }
  }
}
</style>

<template>
  <div class="partner-info">
    <div class="type-warp">
      <span
        :class="[activeTypes === item.value && 'active']"
        v-for="(item, index) in tabs"
        :key="index"
        @click="changeActive(item)"
      >
        {{ item.type }}
      </span>
    </div>
    <div class="input-warp" v-if="activeTypes === 'old'" key="old">
      <van-form ref="formRef">
        <field
          v-model="formInfo.oldPassword"
          name="旧密码"
          label="旧密码："
          placeholder="如未设置过密码，可为空"
          type="number"
          maxLength="11"
          ref="patientPhone"
        />
        <field
          v-model="formInfo.newPassword"
          name="新密码"
          label="新密码："
          placeholder="请输入新密码"
          :rules="[{ required: true, pattern: /^.{6,20}$/, message: '请输入6-20位的密码' }]"
          required
        />
      </van-form>
    </div>

    <div class="input-warp" v-if="activeTypes === 'sms'" key="sms">
      <van-form ref="formRef">
        <!-- <field
          :value="showpPhone"
          name="手机号"
          label="手机号："
          type="number"
          maxLength="11"
          ref="patientPhone"
          disabled
        /> -->
        <field
          v-model="formInfo.mobileCode"
          name="验证码"
          label="验证码："
          placeholder="请输入验证码"
          :rules="[{ required: true, pattern: /^.{6,6}$/, message: '请输入完整的验证码' }]"
          required
          maxLength="6"
        >
          <template #button>
            <span class="sms-tip" @click="getCode">
              {{ countdown > 0 ? `${countdown} 秒后重新获取` : '获取验证码' }}
            </span>
          </template>
        </field>
        <field
          v-model="formInfo.newPassword"
          name="新密码"
          label="新密码："
          placeholder="请输入新密码"
          :rules="[{ required: true, pattern: /^.{6,20}$/, message: '请输入6-20位的密码' }]"
          maxLength="20"
          required
        />
      </van-form>
    </div>

    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { Form as VanForm, Field } from 'vant';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';

export default {
  components: { fixedButton, VanForm, Field },
  data() {
    return {
      tabs: [
        { type: '旧密码方式', value: 'old' },
        { type: '验证码方式', value: 'sms' }
      ],
      activeTypes: 'old',
      formInfo: {
        newPassword: '',
        mobileCode: '',
        oldPassword: ''
      },
      countdown: 0
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['clinicInfo']),
    userType() {
      return getUserType();
    },
    showpPhone() {
      return this.clinicInfo.phone;
    }
  },
  methods: {
    getCode() {
      if (this.countdown > 0) {
        return;
      }
      this.sendVerificationCode();
    },
    async sendVerificationCode() {
      try {
        await services[this.userType].updatePwdCode();
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        this.$toast(error.msg || '获取验证码失败');
      }
    },
    changeActive(item) {
      this.activeTypes = item.value;
    },
    async submit() {
      await this.$refs.formRef.validate();
      const requsetType = {
        old: 'updatePwd',
        sms: 'smsUpdatePwd'
      };
      await services[this.userType][requsetType[this.activeTypes]](this.formInfo);
      this.$toast('设置成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-field__label {
    width: 80px !important;
  }
}

.type-warp {
  @include flex(row);
  justify-content: center;
  color: $gray2;
  font-size: $size6;
  background: #fff;
  padding: 10px 0;
  span {
    padding: 10px;
    transition-duration: 0.3s;
    border-radius: 4px;
    &.active {
      background: lighten($appcolor, 50%);
      color: $appcolor;
      font-weight: 600;
    }
  }
}

.sms-tip {
  text-align: right;
  font-size: $size6;
  color: $appcolor;
}
</style>

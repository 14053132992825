<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="hasNull">
          您不在打卡诊所1公里范围内
        </div>
        <div class="clock-list">
          <div class="clock-item" v-for="(clock, index) in listInfo" :key="index" @click="clinicId = clock.clinicId">
            <div class="clock-content">
              <checkbox :value="clinicId === clock.clinicId" />
              <div class="clock-image">
                <van-image fit="cover" :src="clock.frontPhoto" :alt="clock.clinicName" class="clock-avatar" />
              </div>
              <div class="clock-info">
                <h3 class="clock-name">{{ clock.clinicName }}</h3>
                <p class="clinic-name">{{ showArea(clock) }}</p>
              </div>
              <div class="clock-status">
                <p class="view-details">{{ clock.distance }} m</p>
              </div>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>
    <fixedButton text="打卡" @emitEvent="submit" v-if="listInfo.length" />
  </div>
</template>

<script>
import { PullRefresh, List, Image as VanImage, Checkbox } from 'vant';
import services from '@/service';
import { defaultFlagAll } from '@/enum/index';
import fixedButton from '@/components/fixedButton';
import { getLocation } from '@/lib/utils';

export default {
  components: { PullRefresh, List, VanImage, fixedButton, Checkbox },
  data() {
    return {
      clocks: [],
      pullLoading: false,
      loading: false,
      hasNull: false,
      hasNext: true,
      clinicId: '',
      listInfo: [],
      defaultFlagAll,
      latitude: '23.121157',
      longitude: '113.350061'
    };
  },
  methods: {
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      try {
        const { latitude, longitude } = await getLocation();
        this.latitude = latitude;
        this.longitude = longitude;
        const res = await services.partner.clinicWithinRange({ lat: this.latitude, lng: this.longitude });
        this.listInfo = [...this.listInfo, ...res.rows];
      } catch (e) {
        this.$toast('无法获取您的位置信息');
      }
      if (!this.listInfo.length) {
        this.hasNull = true;
      }

      this.pullLoading = false;
      this.loading = false;
    },
    toclockDetail(id) {
      this.$router.push({
        name: 'partnerclockDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    async submit() {
      if (!this.clinicId) return this.$toast('请选择打卡点');
      await services.partner.salesSignin({
        ...this.queryParams,
        clinicId: this.clinicId,
        lat: this.latitude,
        lng: this.longitude
      });
      this.$toast('打卡成功');
      this.refreshList();
    }
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.clock-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.clock-content {
  display: flex;
  align-items: center;
}

.clock-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin: 0 $lr;
}

.clock-avatar {
  width: 100%;
  height: auto;
}

.clock-info {
  flex: 1;
}

.clock-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 600;
  color: $black1;
  line-height: 22px;
}

.clinic-name {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.clock-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.status-normal {
  background-color: $green; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "order-confirm" }, [
        _c(
          "div",
          { staticClass: "address-item", on: { click: _vm.selectAddr } },
          [
            _c("div", { staticClass: "address-content" }, [
              _c("i", { staticClass: "iconfont icon-shdz" }),
              _vm.addressInfo.addressId
                ? _c("div", { staticClass: "address-info" }, [
                    _c("h3", { staticClass: "address-name" }, [
                      _c("span", [_vm._v(_vm._s(_vm.addressInfo.addressee))]),
                      _c("span", { staticClass: "address-phone" }, [
                        _vm._v(_vm._s(_vm.addressInfo.phone)),
                      ]),
                    ]),
                    _c("p", { staticClass: "address-area" }, [
                      _vm._v(_vm._s(_vm.showArea(_vm.addressInfo))),
                    ]),
                  ])
                : _c("div", { staticClass: "address-info" }, [
                    _c("h3", { staticClass: "address-area" }, [
                      _vm._v("请选择地址"),
                    ]),
                  ]),
              !_vm.hasOrder
                ? _c("div", { staticClass: "address-status" }, [
                    _c(
                      "p",
                      { staticClass: "view-details" },
                      [_c("icon", { attrs: { name: "arrow" } })],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm.orderInfo.sum
          ? _c(
              "div",
              { staticClass: "product-list" },
              [
                _vm._l(_vm.products, function (product) {
                  return _c(
                    "div",
                    { key: product.id, staticClass: "product-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "product-content" },
                        [
                          _c("van-image", {
                            staticClass: "product-image",
                            attrs: {
                              fit: "cover",
                              src: product.goodsIcon,
                              alt: product.name,
                            },
                          }),
                          _c("div", { staticClass: "product-details" }, [
                            _c("h3", { staticClass: "product-name" }, [
                              _vm._v(_vm._s(product.goodsName)),
                            ]),
                            _c("p", { staticClass: "product-description" }, [
                              _vm._v(_vm._s(product.goodsFunction)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "product-foot" },
                              [
                                _c("span", { staticClass: "product-price" }, [
                                  _vm._v("￥" + _vm._s(product.salePrice)),
                                ]),
                                _c("stepper", {
                                  attrs: {
                                    theme: "round",
                                    "button-size": "22",
                                    "disable-input": "",
                                    min: "1",
                                    max: "99",
                                  },
                                  on: { change: _vm.refreshInfo },
                                  model: {
                                    value: product.number,
                                    callback: function ($$v) {
                                      _vm.$set(product, "number", $$v)
                                    },
                                    expression: "product.number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _c("field", {
                  attrs: {
                    name: "订单备注",
                    label: "订单备注：",
                    placeholder: "输入订单备注",
                    type: "textarea",
                    rules: [{ required: true, message: "输入法人名称" }],
                  },
                  model: {
                    value: _vm.orderInfo.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderInfo, "remark", $$v)
                    },
                    expression: "orderInfo.remark",
                  },
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "price-box" }, [
          _c("span", [_vm._v("合计")]),
          _c("span", { staticClass: "price" }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.payAmount)),
          ]),
        ]),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _vm.hasOrder
        ? _c("fixedButton", {
            staticClass: "wx-btn",
            attrs: { text: "微信支付" },
            on: { emitEvent: _vm.wxPay },
          })
        : _c("fixedButton", {
            attrs: { text: "去支付" },
            on: { emitEvent: _vm.createOrder },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="statistics-table">
    <p class="title">{{ showTitle }}</p>
    <infoTable :tableInfo="table" :tableArgs="tableArgs" />
    <div style="height: 20px;" />
  </div>
</template>

<script>
import services from '@/service';
import infoTable from './components/infoTable';

export default {
  name: 'adminStatisticsTable',
  components: {
    infoTable
  },
  data() {
    return {
      showCalendar: false,
      type: {
        getMonthlyAnalysis: [
          { type: '日期分布', value: 'date' },
          { type: '合伙人分布', value: 'partner' },
          { type: '区域分布', value: 'city' },
          { type: '诊所分布', value: 'clinic' },
          { type: '诊所平均检测量', value: 'clinicAvg' },
          { type: '城市平均检测量', value: 'cityAvg' }
        ],
        getWeekAnalysis: [
          { type: '默认分布', value: 'default' },
          { type: '城市分布', value: 'city' }
        ],
        getTimesAnalysis: [
          { type: '周度分布', value: 'week' },
          { type: '月度分布', value: 'month' },
          { type: '年度分布', value: 'year' }
        ]
      },
      table: [],
      tableArgs: []
    };
  },
  computed: {
    showTitle() {
      return `${this.$route.query[this.$route.query.showKey]} ${
        this.type[this.$route.query.queryType].find(i => i.value === this.$route.query.type).type
      } 数据明细`;
    }
  },
  methods: {
    async getMonthlyAnalysis() {
      const res = await services.admin.monthlyAnalysis({
        month: this.$route.query.month,
        type: this.$route.query.type
      });
      this.table = res.data;
      if (this.$route.query.type === 'partner') {
        this.tableArgs = [
          {
            title: '合伙人',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '新诊所量',
            key: 'clinicCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          }
        ];
      } else if (this.$route.query.type === 'city') {
        this.tableArgs = [
          {
            title: '城市',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '新诊所量',
            key: 'clinicCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          }
        ];
      } else if (this.$route.query.type === 'clinic') {
        this.tableArgs = [
          {
            title: '诊所',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          }
        ];
      } else if (this.$route.query.type === 'date') {
        this.tableArgs = [
          {
            title: '日期',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '新诊所量',
            key: 'clinicCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          }
        ];
      } else if (this.$route.query.type === 'clinicAvg') {
        this.tableArgs = [
          {
            title: '诊所',
            key: 'typeValue'
          },
          {
            title: '诊所平均检测量',
            key: 'weightedAvgCheckCount'
          }
        ];
      } else if (this.$route.query.type === 'cityAvg') {
        this.tableArgs = [
          {
            title: '城市',
            key: 'typeValue'
          },
          {
            title: '城市平均检测量',
            key: 'weightedAvgCheckCount'
          }
        ];
      }
    },
    async getTimesAnalysis() {
      const res = await services.admin.timesAnalysis({
        type: this.$route.query.type
      });
      this.table = res.data;
      this.tableArgs = [
        {
          title: '时间',
          key: 'typeValue'
        },
        {
          title: '检测量',
          key: 'checkCount'
        },
        {
          title: '服务单量',
          key: 'servicsCount'
        },
        {
          title: '新诊所量',
          key: 'clinicCount'
        },
        {
          title: '设备签约量',
          key: 'goodsSignCount'
        },
        {
          title: '服务单复购量',
          key: 'reServicsCount'
        }
      ];
    },
    async getWeekAnalysis() {
      const res = await services.admin.weekAnalysis({
        weekNumber: this.$route.query.weekNumber,
        type: this.$route.query.type
      });
      this.table = res.data;
      if (this.$route.query.type === 'default') {
        this.tableArgs = [
          {
            title: '周度',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '新诊所量',
            key: 'clinicCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          },
          {
            title: '服务复购单量',
            key: 'reServicsCount'
          }
        ];
      } else if (this.$route.query.type === 'city') {
        this.tableArgs = [
          {
            title: '城市',
            key: 'typeValue'
          },
          {
            title: '检测量',
            key: 'checkCount'
          },
          {
            title: '服务单量',
            key: 'servicsCount'
          },
          {
            title: '新诊所量',
            key: 'clinicCount'
          },
          {
            title: '设备签约量',
            key: 'goodsSignCount'
          },
          {
            title: '服务复购单量',
            key: 'reServicsCount'
          }
        ];
      }
    }
  },
  async mounted() {
    await this[this.$route.query.queryType]();
  }
};
</script>

<style lang="scss" scoped>
.statistics-table {
  background: #fff;
  .title {
    padding: $tb $lr;
    text-align: center;
  }
}
</style>

<template>
  <div class="scroll-warp" ref="scroll" @scroll="loadMore">
    <template v-if="pageInit">
      <search v-model="queryParams.clinicName" placeholder="请输入诊所名称" @search="refreshList" />
    </template>
    <sticky>
      <tabs v-model="active" swipeable @change="tabChange">
        <tab v-for="(item, index) in tabList" :title="item.title" :key="index">
          <dropdown-menu active-color="#1989fa" v-if="active === 0">
            <dropdown-item v-model="queryParams.dateType" :options="dateType.enums" @change="refreshList" />
          </dropdown-menu>
        </tab>
      </tabs>
    </sticky>
    <div>
      <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
        <clinicBox v-for="(item, index) in listInfo" :key="index" :info="item" :active="active" />
      </pull-refresh>
    </div>
    <divider v-if="listInfo.length && !hasNext">没有更多了</divider>
    <empty v-if="!listInfo.length" description="暂无数据" />
  </div>
</template>

<script>
import { Search, Sticky, PullRefresh, DropdownMenu, DropdownItem, Tab, Tabs, Empty, Divider } from 'vant';
import services from '@/service';
import clinicBox from '@/components/clinicBox';
import { notRequest } from '@/lib/utils';
import { selectDateTypeAll, auditStatusAll } from '@/enum/index';

export default {
  name: 'partnerMyClinic',
  components: { Search, Sticky, DropdownMenu, DropdownItem, PullRefresh, Tab, Tabs, Empty, Divider, clinicBox },
  data() {
    return {
      tabList: [
        {
          title: '我的诊所',
          func: 'getClinicList'
        },
        {
          title: '审核中',
          func: 'getDevelopClinicList'
        },
        {
          title: '待开发',
          func: 'getClinicList'
        }
      ],
      active: 0,
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        clinicName: '',
        dateType: 'today',
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      auditStatus: {},
      dateType: {},
      scrollPosition: 0
    };
  },
  computed: {
    pageInit() {
      return !notRequest();
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.active = Number(this.$route.query.tab);
    }
    this.upgradeRoutePath();
  },
  async mounted() {
    if (!this.pageInit) return;

    const [dateType, auditStatus] = await Promise.all([selectDateTypeAll(), auditStatusAll()]);
    this.dateType = dateType;
    this.auditStatus = auditStatus;

    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }
    this.refreshList();
  },
  methods: {
    loadMore() {
      if (!this.hasNext) return;
      const container = this.$refs.scroll;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 1) {
        this.loadList();
      }
    },
    async upgradeRoutePath() {
      if (Number(this.$route.query.tab) !== this.active) {
        await this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, tab: this.active } });
      }
    },
    tabChange() {
      this.upgradeRoutePath();
      this.refreshList();
    },
    async refreshList() {
      if (!this.pageInit) return;

      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      if (this.loading) return;
      this.loading = true;
      this.queryParams.pageNum++;

      const params = { ...this.queryParams };

      if (this.active === 2) {
        delete params.dateType;
      }

      const res = await services.partner[this.tabList[this.active].func](params);
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDetail(item) {
      const routerType = ['partnerClinicInfo', 'partnerClinicInfoEdit'];
      this.$router.push({
        name: routerType[this.active],
        query: {
          id: item.clinicId,
          hisId: item.hisId
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollPosition = this.$refs.scroll.scrollTop;
    next();
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.scroll.scrollTop = this.scrollPosition;
    });
  }
};
</script>

<style lang="scss" scoped>
.scroll-warp {
  overflow-y: auto;
  height: calc(100% - 46px);
}

.clinic-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.clinic-content {
  display: flex;
  align-items: center;
}

.clinic-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.clinic-avatar {
  width: 100%;
  height: auto;
}

.clinic-info {
  flex: 1;
}

.clinic-name {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 600;
  color: $black1;
  line-height: 22px;
}

.clinic-tip {
  color: $gray2;
  font-size: $size6;
  margin-top: 6px;
}

.clinic-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}
</style>

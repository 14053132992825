var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: { round: "" },
      on: { closed: _vm.closedHandle },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "qrcode-warp" }, [
        _c("p", { staticClass: "qrcode-title" }, [
          _vm._v(_vm._s(_vm.info.text)),
        ]),
        _c("div", [
          _c("canvas", {
            ref: "qrCanvas",
            attrs: { width: "200", height: "200" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-table" }, [
    _c(
      "div",
      { staticClass: "info-table-div" },
      [
        _c("div", [
          _c("div", { staticClass: "info-table-cell" }, [
            _c("div", { staticClass: "td td1 title" }, [_vm._v("项目名称")]),
            _c("div", { staticClass: "td td2 title" }, [_vm._v("检测结果")]),
            _c("div", { staticClass: "td td3 title" }, [_vm._v("单位")]),
            _vm.showRange
              ? _c("div", { staticClass: "td td3 title" }, [_vm._v("参考范围")])
              : _vm._e(),
          ]),
        ]),
        _vm._l(_vm.tableInfo, function (item, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "info-table-cell" }, [
              _c("div", { staticClass: "td td1" }, [
                _vm._v(_vm._s(_vm.showName(item))),
              ]),
              _c("div", { staticClass: "td td2" }, [
                _vm._v(" " + _vm._s(item.paramValue || "-") + " "),
                item.flag === "Up"
                  ? _c("i", { staticClass: "iconfont icon-up" })
                  : _vm._e(),
                item.flag === "Down"
                  ? _c("i", { staticClass: "iconfont icon-down" })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "td td3" }, [_vm._v(_vm._s(item.unit))]),
              _vm.showRange
                ? _c("div", { staticClass: "td td3" }, [
                    item.range.length < 40
                      ? _c("span", [_vm._v(_vm._s(item.range))])
                      : _c(
                          "span",
                          {
                            staticClass: "read",
                            on: {
                              click: function ($event) {
                                return _vm.showDialog(item)
                              },
                            },
                          },
                          [_vm._v("点击查看")]
                        ),
                  ])
                : _vm._e(),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
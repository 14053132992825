var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      false
        ? _c("search", {
            attrs: { placeholder: "请输入设备编号" },
            on: { search: _vm.refreshList },
            model: {
              value: _vm.queryParams.deviceNum,
              callback: function ($$v) {
                _vm.$set(_vm.queryParams, "deviceNum", $$v)
              },
              expression: "queryParams.deviceNum",
            },
          })
        : _vm._e(),
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.listInfo.length == 0 && !_vm.loading
                ? _c("div", {
                    staticStyle: { "text-align": "center", padding: "10vh 0" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "device-list" },
                _vm._l(_vm.listInfo, function (device, index) {
                  return _c("div", { key: index, staticClass: "device-item" }, [
                    _c("div", { staticClass: "device-content" }, [
                      _c(
                        "div",
                        { staticClass: "device-image" },
                        [
                          _c("van-image", {
                            staticClass: "device-avatar",
                            attrs: { fit: "cover", src: device.goodsIcon },
                          }),
                          device.stockWarningFlag === 1
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "device-status-tag status-abnormal",
                                },
                                [_vm._v(" 预警 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "device-info" }, [
                        _c("h3", { staticClass: "device-name" }, [
                          _vm._v(_vm._s(device.goodsName)),
                        ]),
                        _c("p", { staticClass: "clinic-name" }, [
                          _vm._v("库存：" + _vm._s(device.stock)),
                        ]),
                        _c("p", { staticClass: "clinic-name" }, [
                          _vm._v("有效期：" + _vm._s(device.serviceLimitDate)),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
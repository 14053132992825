<template>
  <deviceApplyRecord />
</template>

<script>
import deviceApplyRecord from '@/components/deviceApplyRecord';

export default {
  components: { deviceApplyRecord },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<template>
  <div>
    <search v-model="queryParams.patientName" placeholder="请输入患者姓名" @search="refreshList" />
    <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
      <list
        v-if="listInfo.length"
        v-model="loading"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="clinic-list">
          <div class="clinic-item" v-for="(item, index) in listInfo" :key="index" @click="toDetail(item)">
            <div class="clinic-content">
              <div class="clinic-image">
                <van-image fit="cover" :src="item.frontPhoto" :alt="item.clinicName" class="clinic-avatar" />
              </div>
              <div class="clinic-info">
                <h3 class="clinic-name">{{ item.clinicName }}</h3>
                <p class="clinic-tip">{{ auditStatus.getValueByKey(item.auditStatus) }} {{ item.auditMes }}</p>
              </div>
              <div class="clinic-status">
                <p class="view-details"><icon name="arrow" /></p>
              </div>
            </div>
          </div>
        </div>
      </list>
      <empty v-if="!listInfo.length" description="暂无数据" />
    </pull-refresh>
  </div>
</template>

<script>
import { PullRefresh, List, Icon, Image as VanImage, Empty } from 'vant';
import services from '@/service';
import { notRequest } from '@/lib/utils';
import { auditStatusAll } from '@/enum/index';

export default {
  name: 'adminClinicHis',
  components: { PullRefresh, List, Icon, VanImage, Empty },
  data() {
    return {
      active: 0,
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      auditStatus: {}
    };
  },
  computed: {
    pageInit() {
      return !notRequest();
    },
    noSelf() {
      return this.$route.query.id;
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.active = Number(this.$route.query.tab);
      console.log(this.$route.query.tab);
    }
    this.upgradeRoutePath();
  },
  async mounted() {
    const [auditStatus] = await Promise.all([auditStatusAll()]);
    this.auditStatus = auditStatus;
    console.log(this.auditStatus);

    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }
    this.refreshList();
  },
  methods: {
    async upgradeRoutePath() {
      if (Number(this.$route.query.tab) !== this.active) {
        await this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, tab: this.active } });
      }
    },
    tabChange() {
      this.upgradeRoutePath();
      this.refreshList();
    },
    async refreshList() {
      if (!this.pageInit) return;

      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.admin.clinicList({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDetail(item) {
      this.$router.push({
        name: 'adminClinicInfoEdit',
        query: {
          id: item.clinicId,
          hisId: item.hisId
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.clinic-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.clinic-content {
  display: flex;
  align-items: center;
}

.clinic-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.clinic-avatar {
  width: 100%;
  height: auto;
}

.clinic-info {
  flex: 1;
}

.clinic-name {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 600;
  color: $black1;
  line-height: 22px;
}

.clinic-tip {
  color: $gray2;
  font-size: $size6;
  margin-top: 20px;
}

.clinic-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}
</style>

const mallLogin = () => import(/* webpackChunkName: "mall" */ '@/views/mall/login');
const mallHome = () => import(/* webpackChunkName: "mall" */ '@/views/mall/home');
const mallCategory = () => import(/* webpackChunkName: "mall" */ '@/views/mall/category');
const mallCart = () => import(/* webpackChunkName: "mall" */ '@/views/mall/cart');
const mallProductDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/productDetail');
const mallPersonal = () => import(/* webpackChunkName: "mall" */ '@/views/mall/personal');
const mallAddClinic = () => import(/* webpackChunkName: "mall" */ '@/views/mall/addClinic');
const mallBindDevice = () => import(/* webpackChunkName: "mall" */ '@/views/mall/bindDevice');
const mallDeviceList = () => import(/* webpackChunkName: "mall" */ '@/views/mall/deviceList');
const mallSalesDeviceList = () => import(/* webpackChunkName: "mall" */ '@/views/mall/salesDeviceList');
const mallMyClinic = () => import(/* webpackChunkName: "mall" */ '@/views/mall/myClinic');
const mallMyTeam = () => import(/* webpackChunkName: "mall" */ '@/views/mall/myTeam');
const mallPartnerInfo = () => import(/* webpackChunkName: "mall" */ '@/views/mall/mallInfo');
const mallFinance = () => import(/* webpackChunkName: "mall" */ '@/views/mall/finance');
const mallFinanceDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/financeDetail');
const mallDeviceDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/deviceDetail');
const mallReportDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/reportDetail');
const mallClinicDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicDetail');
const mallClock = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clock');
const mallInfoEdit = () => import(/* webpackChunkName: "mall" */ '@/views/mall/infoEdit');
const mallInfoEditAdd = () => import(/* webpackChunkName: "mall" */ '@/views/mall/infoEditAdd');
const mallInviteEdit = () => import(/* webpackChunkName: "mall" */ '@/views/mall/inviteEdit');
const mallInviteSuccess = () => import(/* webpackChunkName: "mall" */ '@/views/mall/inviteSuccess');
const mallClinicInfo = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicInfo');
const mallClinicInfoEdit = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicInfoEdit');
const mallMyPartner = () => import(/* webpackChunkName: "mall" */ '@/views/mall/myPartner');
const mallClinicOrder = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicOrder');
const mallClinicOrderList = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicOrderList');
const mallBuy = () => import(/* webpackChunkName: "mall" */ '@/views/mall/buy');
const mallOrderConfirm = () => import(/* webpackChunkName: "mall" */ '@/views/mall/orderConfirm');
const mallOrderDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/orderDetail');
const mallMealDetail = () => import(/* webpackChunkName: "mall" */ '@/views/mall/mealDetail');
const mallMyOrder = () => import(/* webpackChunkName: "mall" */ '@/views/mall/myOrder');
const mallMyAddress = () => import(/* webpackChunkName: "mall" */ '@/views/mall/myAddress');
const mallAddressEdit = () => import(/* webpackChunkName: "mall" */ '@/views/mall/addressEdit');
const mallClinicInventory = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicInventory');
const mallClinicPerformance = () => import(/* webpackChunkName: "mall" */ '@/views/mall/clinicPerformance');
const mallStockSales = () => import(/* webpackChunkName: "mall" */ '@/views/mall/stockSales');
const mallStockDeviceSales = () => import(/* webpackChunkName: "mall" */ '@/views/mall/stockDeviceSales');
const mallContractList = () => import(/* webpackChunkName: "mall" */ '@/views/mall/contractList');
const mallSalesStockRecord = () => import(/* webpackChunkName: "mall" */ '@/views/mall/salesStockRecord');
const mallDeviceApplyRecord = () => import(/* webpackChunkName: "mall" */ '@/views/mall/deviceApplyRecord');
const mallMaterialApplication = () => import(/* webpackChunkName: "mall" */ '@/views/mall/materialApplication');
const mallAddContract = () => import(/* webpackChunkName: "mall" */ '@/views/mall/addContract');
const mallPointsDetails = () => import(/* webpackChunkName: "mall" */ '@/views/mall/pointsDetails');
const mallEquityCard = () => import(/* webpackChunkName: "mall" */ '@/views/mall/equityCard');
const mallSearch = () => import(/* webpackChunkName: "mall" */ '@/views/mall/search');
const mallLogistics = () => import(/* webpackChunkName: "mall" */ '@/views/mall/logistics');

export default [
  {
    path: '/mall-login',
    name: 'mallLogin',
    component: mallLogin,
    meta: {
      title: '登录',
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/mall-home',
    name: 'mallHome',
    component: mallHome,
    meta: {
      title: '首页',
      hideBack: true
    }
  },
  {
    path: '/mall-category',
    name: 'mallCategory',
    component: mallCategory,
    meta: {
      title: '分类',
      hideBack: true,
      noAuth: true,
      keeps: ['mallProductDetail']
    }
  },
  {
    path: '/mall-cart',
    name: 'mallCart',
    component: mallCart,
    meta: {
      title: '购物车',
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/mall-product-detail',
    name: 'mallProductDetail',
    component: mallProductDetail,
    meta: {
      title: '商品详情',
      noAuth: true
    }
  },
  {
    path: '/mall-personal',
    name: 'mallPersonal',
    component: mallPersonal,
    meta: {
      title: '我的',
      hideBack: true
    }
  },
  {
    path: '/mall-add-clinic',
    name: 'mallAddClinic',
    component: mallAddClinic,
    meta: {
      title: '添加诊所'
    }
  },
  {
    path: '/mall-bind-device',
    name: 'mallBindDevice',
    component: mallBindDevice,
    meta: {
      title: '绑定设备'
    }
  },
  {
    path: '/mall-my-team',
    name: 'mallMyTeam',
    component: mallMyTeam,
    meta: {
      title: '我的团队',
      hideBack: true
    }
  },
  {
    path: '/mall-partner-info',
    name: 'mallPartnerInfo',
    component: mallPartnerInfo,
    meta: {
      title: '业务员信息'
    }
  },
  {
    path: '/mall-finance',
    name: 'mallFinance',
    component: mallFinance,
    meta: {
      title: '业绩统计'
    }
  },
  {
    path: '/mall-finance-detail',
    name: 'mallFinanceDetail',
    component: mallFinanceDetail,
    meta: {
      title: '业绩详情'
    }
  },
  {
    path: '/mall-device-List',
    name: 'mallDeviceList',
    component: mallDeviceList,
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/mall-sales-device-List',
    name: 'mallSalesDeviceList',
    component: mallSalesDeviceList,
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/mall-device-detail',
    name: 'mallDeviceDetail',
    component: mallDeviceDetail,
    meta: {
      title: '设备详情'
    }
  },
  {
    path: '/mall-my-clinic',
    name: 'mallMyClinic',
    component: mallMyClinic,
    meta: {
      title: '我的诊所',
      keeps: ['mallClinicInfo']
    }
  },
  {
    path: '/mall-report-detail',
    name: 'mallReportDetail',
    component: mallReportDetail,
    meta: {
      title: '报告详情'
    }
  },
  {
    path: '/mall-clinic-detail',
    name: 'mallClinicDetail',
    component: mallClinicDetail,
    meta: {
      title: '诊所详情'
    }
  },
  {
    path: '/mall-clock',
    name: 'mallClock',
    component: mallClock,
    meta: {
      title: '打卡'
    }
  },
  {
    path: '/mall-info-edit',
    name: 'mallInfoEdit',
    component: mallInfoEdit,
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/mall-info-edit-add',
    name: 'mallInfoEditAdd',
    component: mallInfoEditAdd,
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/mall-invite-edit',
    name: 'mallInviteEdit',
    component: mallInviteEdit,
    meta: {
      title: `诚邀您加入${process.env.VUE_APP_TITLE}`,
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/mall-invite-success',
    name: 'mallInviteSuccess',
    component: mallInviteSuccess,
    meta: {
      title: '提交成功',
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/mall-clinic-info',
    name: 'mallClinicInfo',
    component: mallClinicInfo,
    meta: {
      title: '诊所信息'
    }
  },
  {
    path: '/mall-clinic-info-edit',
    name: 'mallClinicInfoEdit',
    component: mallClinicInfoEdit,
    meta: {
      title: '诊所信息'
    }
  },
  {
    path: '/mall-my-partner',
    name: 'mallMyPartner',
    component: mallMyPartner,
    meta: {
      title: '诊所业务员'
    }
  },
  {
    path: '/mall-clinic-order',
    name: 'mallClinicOrder',
    component: mallClinicOrder,
    meta: {
      title: '诊所订单'
    }
  },
  {
    path: '/mall-clinic-order-list',
    name: 'mallClinicOrderList',
    component: mallClinicOrderList,
    meta: {
      title: '待出库'
    }
  },
  {
    path: '/mall-buy',
    name: 'mallBuy',
    component: mallBuy,
    meta: {
      title: '服务购买'
    }
  },
  {
    path: '/mall-order-confirm',
    name: 'mallOrderConfirm',
    component: mallOrderConfirm,
    meta: {
      title: '确认订单',
      keeps: ['mallMyAddress']
    }
  },
  {
    path: '/mall-order-detail',
    name: 'mallOrderDetail',
    component: mallOrderDetail,
    meta: {
      title: '订单详情',
      keeps: ['mallMyAddress']
    }
  },
  {
    path: '/mall-meal-detail',
    name: 'mallMealDetail',
    component: mallMealDetail,
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/mall-my-order',
    name: 'mallMyOrder',
    component: mallMyOrder,
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/mall-my-address',
    name: 'mallMyAddress',
    component: mallMyAddress,
    meta: {
      title: '我的收货地址',
      keeps: ['mallOrderConfirm']
    }
  },
  {
    path: '/mall-address-edit',
    name: 'mallAddressEdit',
    component: mallAddressEdit,
    meta: {
      title: '编辑地址'
    }
  },
  {
    path: '/mall-clinic-inventory',
    name: 'mallClinicInventory',
    component: mallClinicInventory,
    meta: {
      title: '诊所库存'
    }
  },
  {
    path: '/mall-clinic-performance',
    name: 'mallClinicPerformance',
    component: mallClinicPerformance,
    meta: {
      title: '业绩分析',
      hideBack: true
    }
  },
  {
    path: '/mall-stock-sales',
    name: 'mallStockSales',
    component: mallStockSales,
    meta: {
      title: '业务员库存'
    }
  },
  {
    path: '/mall-sales-stock-record',
    name: 'mallSalesStockRecord',
    component: mallSalesStockRecord,
    meta: {
      title: '库存明细'
    }
  },
  {
    path: '/mall-device-apply-record',
    name: 'mallDeviceApplyRecord',
    component: mallDeviceApplyRecord,
    meta: {
      title: '设备申请记录'
    }
  },
  {
    path: '/mall-material-application',
    name: 'mallMaterialApplication',
    component: mallMaterialApplication,
    meta: {
      title: '申请设备'
    }
  },
  {
    path: '/mall-stock-device-sales',
    name: 'mallStockDeviceSales',
    component: mallStockDeviceSales,
    meta: {
      title: '业务员设备库存'
    }
  },
  {
    path: '/mall-contract-list',
    name: 'mallContractList',
    component: mallContractList,
    meta: {
      title: '合同列表'
    }
  },
  {
    path: '/mall-add-contract',
    name: 'mallAddContract',
    component: mallAddContract,
    meta: {
      title: '新增合同签约'
    }
  },
  {
    path: '/mall-equity-card',
    name: 'mallEquityCard',
    component: mallEquityCard,
    meta: {
      title: '权益卡'
    }
  },
  {
    path: '/mall-search',
    name: 'mallSearch',
    component: mallSearch,
    meta: {
      title: '搜索',
      keeps: ['mallProductDetail']
    }
  },
  {
    path: '/mall-logistics',
    name: 'mallLogistics',
    component: mallLogistics,
    meta: {
      title: '物流详情'
    }
  },
  {
    path: '/mall-points-details',
    name: 'mallPointsDetails',
    component: mallPointsDetails,
    meta: {
      title: '积分明细'
    }
  }
];

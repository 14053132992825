<template>
  <div class="clinic-detail">
    <!-- 顶部轮播图组件 -->
    <swipe :autoplay="3000">
      <swipe-item v-for="(item, index) in images" :key="index">
        <van-image fit="cover" :src="item" class="slide-image" />
      </swipe-item>
    </swipe>

    <p class="clinic-position" @click="openLocation"><i class="iconfont icon-shdz" /> {{ clinicPosition }}</p>

    <!-- 设备详情信息 -->
    <div class="device-info">
      <div class="info-list">
        <field
          v-model="formInfo.phone"
          name="手机号码"
          label="手机号码："
          type="number"
          readonly
          :rules="[{ required: true, message: '请输入手机号码' }]"
        />
        <field
          v-model="formInfo.clinicName"
          name="机构名称"
          label="机构名称："
          readonly
          :rules="[{ required: true, message: '请输入机构名称' }]"
        />
        <field
          v-model="formInfo.corporateName"
          name="法人名称"
          label="法人名称："
          readonly
          :rules="[{ required: true, message: '请输入法人名称' }]"
        />
        <field
          v-model="formInfo.corporateIdCard"
          name="法人身份证"
          label="法人身份证："
          readonly
          type="idcard"
          maxLength="18"
          :rules="[{ required: true, message: '请输入法人身份证' }]"
        />
        <field
          v-model="formInfo.license"
          name="营业执照编号"
          label="营业执照编号："
          readonly
          :rules="[{ required: true, message: '请输入营业执照编号' }]"
        />
        <field
          name="所在区域"
          label="所在区域："
          placeholder="请选择所在区域"
          readonly
          clickable
          :value="area"
          :rules="[{ required: true, message: '请选择所在区域' }]"
        />
        <field
          v-model="formInfo.address"
          name="详细地址"
          label="详细地址："
          readonly
          :rules="[{ required: true, message: '请输入详细地址' }]"
        />
        <field
          name="有效期至"
          label="有效期至："
          placeholder="请选择有效日期"
          readonly
          clickable
          :value="formInfo.validity"
          @click="showCalendar = true"
          :rules="[{ required: true, message: '请选择有效日期' }]"
        />
        <field
          v-model="formInfo.clinicIntro"
          name="机构简介"
          label="机构简介："
          readonly
          type="textarea"
          maxlength="500"
        />

        <field name="uploader" :label="item.label + '：'" v-for="(item, index) in imageList" :key="index">
          <template #input>
            <uploader
              :maxCount="maxCount"
              v-model="item.value"
              :after-read="from => afterRead(from, item)"
              readonly
              :deletable="false"
            />
          </template>
        </field>
      </div>
    </div>

    <div style="height: 100px;" />

    <fixedBottom>
      <div class="btn-warp">
        <div class="btn" @click="toBind">绑定设备</div>
        <div class="btn btn2" @click="performance">业绩</div>
      </div>
    </fixedBottom>
  </div>
</template>

<script>
import { Field, Uploader, Swipe, SwipeItem, Image as VanImage } from 'vant';
import services from '@/service';
import { defaultFlagAll } from '@/enum/index';
import { areaList } from '@vant/area-data';
import fixedBottom from '@/components/fixedBottom';
import { openLocation } from '@/lib/utils';

export default {
  components: {
    Field,
    Uploader,
    Swipe,
    SwipeItem,
    VanImage,
    fixedBottom
  },
  computed: {
    images() {
      const arr = [];
      if (!this.formInfo.clinicId) return arr;
      this.formInfo.frontPhoto && arr.push(this.formInfo.frontPhoto);
      // this.info.goodsImgs && arr.push(...this.info.goodsImgs.split(','));
      return arr;
    },
    curId() {
      return this.$route.query.id;
    },
    clinicPosition() {
      if (!this.area) return '';
      return `${this.area.split('/').join(' ')} ${this.formInfo.address}`;
    }
  },
  data() {
    return {
      formInfo: {},
      area: '',
      info: {},
      imageList: [
        {
          label: '门店照片',
          key: 'frontPhoto',
          value: []
        },
        {
          label: '营业执照',
          key: 'businessPhoto',
          value: []
        },
        {
          label: '许可证',
          key: 'licencePhoto',
          value: []
        },
        {
          label: '身份证-国徽面',
          key: 'idCardBadge',
          value: []
        },
        {
          label: '身份证-人像面',
          key: 'idCardFigure',
          value: []
        }
      ],
      maxCount: 1,
      defaultFlagAll
    };
  },
  mounted() {
    this.getClinicInfo();
  },
  methods: {
    openLocation() {
      openLocation({
        latitude: this.formInfo.lat,
        longitude: this.formInfo.lng,
        name: this.formInfo.clinicName,
        address: this.clinicPosition
      });
    },
    performance() {
      this.$router.push({
        name: 'partnerFinance',
        query: {
          clinicId: this.formInfo.clinicId
        }
      });
    },
    toBind() {
      this.$router.push({
        name: 'partnerBindDevice',
        query: {
          clinicId: this.formInfo.clinicId
        }
      });
    },
    async getClinicInfo() {
      const res = await services.partner.getClinicInfo(this.curId);
      this.formInfo = { ...res.data };

      if (this.formInfo.district) {
        this.area = `${this.formInfo.province}/${this.formInfo.city}/${this.formInfo.district}`;

        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === this.formInfo.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }

      this.imageList.forEach(i => {
        if (res.data[i.key]) {
          i.value = [{ url: res.data[i.key] }];
        }
      });
    },
    async getDetail() {
      if (!this.curId) return;
      const res = await services.partner.getClinicInfo(this.curId);
      this.info = res.data;
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.clinic-detail {
  .slide-image {
    width: 100%;
  }

  .status-normal {
    color: green;
  }

  .status-abnormal {
    color: red;
  }

  .device-name {
    margin: $tb $lr;
    font-size: $size1;
    font-weight: 600;
    color: $black1;
  }

  .clinic-position {
    background: #fff;
    padding: $tb $lr;
    font-size: $size4;
    margin-bottom: $tb;
  }

  .btn-warp {
    width: 100%;
    @include flex(row);
    justify-content: space-between;
    // background: #fff;
    border-bottom: 1px solid $bordercolor;
    .btn {
      width: 48% !important;
      flex: none;
    }
    .btn2 {
      background: $btnbg3;
      &:active {
        background: darken($appcolor3, 10%);
      }
    }
  }
}
</style>

<template>
  <div class="cart-page">
    <!-- 购物车列表 -->
    <div class="cart-list">
      <div v-for="(item, index) in cartItems" :key="item.goodsId" class="cart-item">
        <div class="item-img">
          <van-image :src="item.goodsIcon" fit="cover" />
        </div>
        <div class="item-info">
          <p class="item-name">{{ item.goodsName }}</p>
          <p class="item-price">¥{{ item.salePrice }}</p>
        </div>
        <div class="item-quantity">
          <stepper
            v-model="item.number"
            theme="round"
            button-size="22"
            disable-input
            min="0"
            max="99"
            @change="refreshInfo(item, index)"
          />
          <!-- <van-icon name="minus" size="16px" @click="decreaseQuantity(item)" />
          <span class="quantity">{{ item.number }}</span>
          <van-icon name="plus" size="16px" @click="increaseQuantity(item)" /> -->
        </div>
      </div>
    </div>

    <van-empty
      v-if="!cartItems.length"
      class="custom-image"
      image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
      description="购物车暂无商品"
      image-size="80"
    />

    <!-- 底部悬浮结算栏 -->
    <div class="settle-bar" v-if="cartItems.length">
      <span class="total-price"
        >合计：
        <span class="price">¥{{ calcInfo.payAmount }}</span>
      </span>
      <van-button type="primary" size="small" @click="handleSettle" round>去结算</van-button>
    </div>

    <tabbar :curIndex="2" />
  </div>
</template>

<script>
import tabbar from '../components/tabbar';
import { Image as VanImage, Icon, Button, Empty, Stepper } from 'vant';
import services from '@/service';
import { wxAuthTo, notRequest } from '@/lib/utils';

export default {
  components: {
    tabbar,
    Stepper,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Empty.name]: Empty
  },
  data() {
    return {
      cartItems: [], // 购物车列表
      calcInfo: {}
    };
  },
  computed: {
    pageInit() {
      return !notRequest();
    },
    // 计算总价
    totalPrice() {
      return this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    }
  },
  async created() {
    if (!this.pageInit) return;

    await this.loadCartData();
  },
  methods: {
    async refreshInfo(item, index) {
      if (item.number === 0) {
        await this.deleteMall(item.mallCartId);
        this.cartItems.splice(index, 1);
      } else {
        await this.updataMall(item);
      }
      this.calculatePrice();
    },
    async deleteMall(id) {
      await services.mall.deleteMall(id);
    },
    async updataMall(product) {
      const params = {
        goodsId: product.goodsId,
        number: product.number,
        goodsName: product.name,
        salePrice: product.salePrice,
        goodsIcon: product.goodsIcon
      };
      await services.mall.addMallCart(params);
    },
    async calculatePrice() {
      if (!this.cartItems.length) {
        return;
      }
      this.$store.dispatch('addLoading');

      const params = {
        // addressCityName: this.addressInfo.city,
        // addressDetail: this.addressInfo.address,
        // addressMobile: this.addressInfo.phone || this.clinicInfo.phone,
        // addressName: this.addressInfo.addressee,
        // addressProvinceName: this.addressInfo.province,
        // addressRegionName: this.addressInfo.district,
        clinicId: 1,
        clinicName: '演示诊所',
        consumptionPoints: 0,
        membershipPoints: 0,
        mallOrderDetailAoList: this.cartItems.map(i => {
          return {
            goodsId: i.goodsId,
            number: i.number
          };
        })
      };
      const res = await services.mall.calculatePrice(params);
      this.calcInfo = res.data;
      // this.cartItems = res.data.mallOrderDetailAoList;
      this.$store.dispatch('subLoading');
    },
    // 加载购物车数据
    async loadCartData() {
      const data = await services.mall.mallCart();
      this.cartItems = data.rows;
      this.calculatePrice();
    },
    // 减少数量
    decreaseQuantity(item) {
      if (item.number > 1) {
        item.number--;
      } else {
        this.cartItems = this.cartItems.filter(i => i.id !== item.id);
      }
    },
    // 增加数量
    increaseQuantity(item) {
      item.number++;
    },
    // 处理结算
    handleSettle() {
      console.log('结算商品:', this.cartItems);
      const arr = this.cartItems
        .filter(i => i.number > 0)
        .map(i => {
          return {
            goodsId: i.goodsId,
            number: i.number,
            mallCartId: i.mallCartId
          };
        });

      wxAuthTo({
        routerName: 'mallOrderConfirm',
        query: {
          products: arr
        }
      });

      // 这里可以调用结算接口或跳转到结算页面
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-page {
  padding-bottom: 150px; // 留出底部悬浮栏的位置

  .cart-list {
    padding: 16px;

    .cart-item {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 12px;
      box-shadow: $box-shadow1;

      .item-img {
        width: 80px;
        height: 80px;
        margin-right: 12px;

        .van-image {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      .item-info {
        flex: 1;

        .item-name {
          font-size: 14px;
          color: $gray1;
          margin-bottom: 8px;
          @include hiddenText();
        }

        .item-price {
          font-size: 14px;
          color: $red1;
          font-weight: 500;
        }
      }

      .item-quantity {
        display: flex;
        align-items: center;

        .quantity {
          margin: 0 12px;
          font-size: 14px;
          color: $gray1;
        }

        .van-icon {
          cursor: pointer;
        }
      }
    }
  }

  .settle-bar {
    margin: 0 20px;
    border-radius: $radius;
    position: fixed;
    bottom: calc(80px + env(safe-area-inset-bottom));
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $tb $lr;
    box-shadow: $box-shadow1;

    .total-price {
      font-size: $size6;
      color: $gray1;
      font-weight: 500;

      .price {
        color: $red1;
        font-size: $size1;
        font-weight: 600;
      }
    }

    .van-button {
      width: 100px;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "pull-refresh",
    {
      on: { refresh: _vm.refreshList },
      model: {
        value: _vm.pullLoading,
        callback: function ($$v) {
          _vm.pullLoading = $$v
        },
        expression: "pullLoading",
      },
    },
    [
      _c(
        "list",
        {
          attrs: {
            finished: !_vm.hasNext,
            "finished-text": "没有更多了",
            "immediate-check": false,
          },
          on: { load: _vm.loadList },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _vm.listInfo.length == 0 && !_vm.loading
            ? _c("div", {
                staticStyle: { "text-align": "center", padding: "10vh 0" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "order-list" },
            _vm._l(_vm.listInfo, function (order, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "order-item",
                  on: {
                    click: function ($event) {
                      return _vm.toDeatil(order)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "order-content" },
                    [
                      _c("div", { staticClass: "order-header" }, [
                        _c("p", { staticClass: "order-time" }, [
                          _vm._v(_vm._s(order.createTime)),
                        ]),
                        _c("span", { staticClass: "order-status-tag" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.serviceOrderState.getValueByKey(
                                  order.status
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm._l(
                        order.orderDetailVoList,
                        function (product, pIndex) {
                          return _c(
                            "div",
                            { key: pIndex, staticClass: "order-product" },
                            [
                              _c(
                                "div",
                                { staticClass: "order-image" },
                                [
                                  _c("van-image", {
                                    staticClass: "order-avatar",
                                    attrs: {
                                      fit: "cover",
                                      src: product.goodsIcon,
                                      alt: product.goodsName,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "order-info" }, [
                                _c("h3", { staticClass: "order-name" }, [
                                  _vm._v(_vm._s(product.goodsName)),
                                ]),
                                _c("p", { staticClass: "order-description" }, [
                                  _vm._v(_vm._s(product.description)),
                                ]),
                              ]),
                              _c("p", { staticClass: "order-price" }, [
                                _vm._v("￥" + _vm._s(product.totalAmount)),
                              ]),
                            ]
                          )
                        }
                      ),
                      _c("div", { staticClass: "order-summary" }, [
                        _c("p"),
                        _c("p", [
                          _vm._v(" 合计："),
                          _c("span", { staticClass: "total-price" }, [
                            _vm._v("￥" + _vm._s(order.payAmount)),
                          ]),
                        ]),
                      ]),
                      order.status === "0"
                        ? _c("div", { staticClass: "panel-foot" }, [
                            _c("div", { staticClass: "panel-left" }),
                            _c("div", { staticClass: "panel-right" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "pay-btn",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toDeatil(order)
                                    },
                                  },
                                },
                                [_vm._v("去支付")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <mallSearch />
    <div class="category-page">
      <!-- 左边分类栏目 -->
      <div class="category-sidebar">
        <div
          v-for="(category, index) in categories"
          :key="category.drugsCategoryId"
          class="category-item"
          :class="{ active: activeCategoryId === category.drugsCategoryId }"
          @click="scrollToCategory(category.drugsCategoryId, index)"
        >
          {{ category.name }}
        </div>
      </div>

      <!-- 右边商品列表 -->

      <div class="product-list" ref="scroll" @scroll="loadMore">
        <div class="two-categories-warp">
          <span
            :class="[activeTwoCategoryId === item.drugsCategoryId ? 'active' : '', 'two-item']"
            v-for="item in twoCategories"
            :key="item.drugsCategoryId"
            @click="changeTwoCategory(item.drugsCategoryId)"
            >{{ item.name }}</span
          >
        </div>
        <div class="product-category-section">
          <pull-refresh v-model="pullLoading" @refresh="refreshList">
            <div v-for="product in listInfo" :key="product.id" class="product-item" @click="toDeatil(product.drugsId)">
              <div class="product-img">
                <van-image :src="product.drugsImage.split(',')[0]" fit="cover" />
              </div>
              <div class="product-info">
                <p class="product-name">{{ product.name }}</p>
                <p class="product-points">
                  <pointPrice :product="product" />
                </p>
              </div>
              <div class="add-to-cart" @click.stop="addToCart(product)">
                <!-- <van-icon name="add" /> -->
                <van-icon name="add" size="22" color="#00bdd4" />
              </div>
            </div>
          </pull-refresh>
        </div>
        <divider v-if="listInfo.length && !hasNext">没有更多了</divider>

        <empty v-if="!listInfo.length" description="暂无数据" />
      </div>

      <tabbar :curIndex="1" />
    </div>
  </div>
</template>

<script>
import pointPrice from '@/components/pointPrice';
import mallSearch from '@/components/mallSearch';

import tabbar from '../components/tabbar';
import { Icon, Image as VanImage, PullRefresh, Empty, Divider } from 'vant';
import services from '@/service';

export default {
  name: 'mallCategory',
  components: {
    pointPrice,
    mallSearch,
    tabbar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    PullRefresh,
    Empty,
    Divider
  },
  data() {
    return {
      categories: [], // 分类数据
      activeCategoryId: null, // 当前激活的分类ID
      twoCategories: [],
      activeTwoCategoryId: null,
      listInfo: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      }
    };
  },
  async created() {
    await this.loadData();
    this.activeCategoryId = this.categories[0]?.drugsCategoryId; // 默认激活第一个分类
    this.categoryDrugs();
  },
  methods: {
    realPrice(goods) {
      return (goods.price - goods.memberDeduction).toFixed(2);
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.categoryDrugs();
      }, 100);
    },
    loadMore() {
      if (!this.hasNext) return;
      const container = this.$refs.scroll;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 1) {
        this.categoryDrugs();
      }
    },
    async loadData() {
      // 模拟从接口获取分类和商品数据
      // const data = await services.mall.getCategoryData();
      const res = await services.mall.clinicCategory();
      // console.log(clinicCategory);
      this.categories = res.data;
      this.twoCategories = res.data[1].children;
    },
    async categoryDrugs() {
      this.queryParams.pageNum++;

      const res = await services.mall.categoryDrugs(
        this.activeTwoCategoryId || this.activeCategoryId,
        this.queryParams
      );
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;

      this.total = res.total;
    },
    changeTwoCategory(id) {
      this.activeTwoCategoryId = id;
      this.refreshList();
    },
    // 点击分类滚动到对应商品区域
    scrollToCategory(categoryId, i) {
      this.twoCategories = this.categories[i].children;
      this.activeCategoryId = categoryId;
      this.activeTwoCategoryId = null;

      this.refreshList();
    },
    // 滚动时更新激活的分类
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      for (let i = this.categories.length - 1; i >= 0; i--) {
        const ref = this.$refs[`category-${this.categories[i].id}`]?.[0];
        if (ref && scrollTop >= ref.offsetTop - 50) {
          this.activeCategoryId = this.categories[i].id;
          break;
        }
      }
    },
    // 加入购物车
    async addToCart(product) {
      console.log('加入购物车:', product);

      const params = {
        goodsId: product.drugsId,
        number: 1,
        goodsName: product.name,
        salePrice: product.price,
        goodsIcon: product.drugsImage,
        addOrDeduct: 1
      };
      await services.mall.addMallCart(params);
      this.$toast('加入购物车成功');
    },
    toDeatil(id) {
      this.$router.push({
        name: 'mallProductDetail',
        query: {
          id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.category-page {
  display: flex;
  // $tabbarHeight失效
  height: calc(100vh - env(safe-area-inset-bottom) - 50px - 55px);
  background: #fff;

  //   padding-bottom: env(safe-area-inset-bottom);

  // .tabbar-box {
  //   display: flex;
  //   align-items: center;
  //   height: $tabbarHeight;

  .category-sidebar {
    width: 100px;
    background: #fff;
    overflow-y: auto;
    box-shadow: $box-shadow1;

    .category-item {
      padding: 16px;
      font-size: 14px;
      color: $gray1;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $appcolor;
        font-weight: 500;
        background: #f0f8ff;
      }
    }
  }

  .product-list {
    flex: 1;
    overflow-y: auto;
    padding: 16px;

    .product-category-section {
      margin-bottom: 20px;

      .category-title {
        font-size: 16px;
        font-weight: 500;
        color: $appcolor;
        // margin-bottom: 12px;
      }
      .product-item:not(:last-child) {
        border-bottom: 1px solid $bordercolor;
      }

      .product-item {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 12px 0;
        // border-radius: 8px;
        // margin-bottom: 12px;
        // box-shadow: $box-shadow1;

        .product-img {
          width: 80px;
          height: 80px;
          margin-right: 12px;

          .van-image {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }

        .product-info {
          flex: 1;

          .product-name {
            font-size: 14px;
            color: $gray1;
            margin-bottom: 8px;
            @include hiddenText();
          }

          .product-points {
            font-size: 12px;
            color: $gray2;

            .points {
              font-size: 12px;
              color: $gray1;
            }

            .price {
              font-size: $size5;
              color: $red1;
              font-weight: 500;
            }
          }
        }

        .add-to-cart {
          cursor: pointer;
        }
      }
    }

    .two-categories-warp {
      .two-item {
        display: inline-block;
        background: $gray4;
        color: $gray2;
        margin: 0 6px 6px 0;
        padding: 4px 6px;
        border-radius: 10px;
        font-size: $size7;
        &.active {
          background-color: $appcolor;
          color: #fff;
        }
      }
    }
  }
}
</style>

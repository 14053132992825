<template>
  <div>
    <search v-model="queryParams.keywords" placeholder="请输入搜索关键词" @search="refreshList" />

    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div v-for="product in listInfo" :key="product.id" class="product-item" @click="toDeatil(product.drugsId)">
          <div class="product-img">
            <van-image :src="product.drugsImage.split(',')[0]" fit="cover" />
          </div>
          <div class="product-info">
            <p class="product-name">{{ product.name }}</p>
            <p class="product-points">
              <pointPrice :product="product" />
            </p>
          </div>
          <div class="add-to-cart" @click.stop="addToCart(product)">
            <!-- <van-icon name="add" /> -->
            <van-icon name="add" size="22" color="#00bdd4" />
          </div>
        </div>
      </list>
    </pull-refresh>
  </div>
</template>

<script>
import pointPrice from '@/components/pointPrice';

import { Search, PullRefresh, List, Icon, Image as VanImage } from 'vant';
import services from '@/service';

import { userPointsTypeAll, userPointsFlowChannelAll } from '@/enum/index';

export default {
  name: 'mallSearch',
  components: { pointPrice, Search, PullRefresh, List, [Icon.name]: Icon, [VanImage.name]: VanImage },
  data() {
    return {
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        keywords: ''
      },
      listInfo: [],
      userPointsTypeAll,
      userPointsFlowChannelAll
    };
  },
  methods: {
    realPrice(goods) {
      return (goods.price - goods.memberDeduction).toFixed(2);
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.mall.queryClinicDrugs(1, { ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    // 加入购物车
    async addToCart(product) {
      console.log('加入购物车:', product);

      const params = {
        goodsId: product.drugsId,
        number: 1,
        goodsName: product.name,
        salePrice: product.price,
        goodsIcon: product.drugsImage,
        addOrDeduct: 1
      };
      await services.mall.addMallCart(params);
      this.$toast('加入购物车成功');
    },
    toDeatil(id) {
      this.$router.push({
        name: 'mallProductDetail',
        query: {
          id
        }
      });
    }
    // withdrawal(id) {}
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.product-item:not(:last-child) {
  border-bottom: 1px solid $bordercolor;
}

.product-item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px $lr;
  // border-radius: 8px;
  // margin-bottom: 12px;
  // box-shadow: $box-shadow1;

  .product-img {
    width: 80px;
    height: 80px;
    margin-right: 12px;

    .van-image {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .product-info {
    flex: 1;

    .product-name {
      font-size: 14px;
      color: $gray1;
      margin-bottom: 8px;
      @include hiddenText();
    }

    .product-points {
      font-size: 12px;
      color: $gray2;

      .points {
        font-size: 12px;
        color: $gray1;
      }

      .price {
        font-size: $size5;
        color: $red1;
        font-weight: 500;
      }
    }
  }

  .add-to-cart {
    cursor: pointer;
  }
}
</style>

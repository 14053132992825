var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "scroll", staticClass: "scroll-warp", on: { scroll: _vm.loadMore } },
    [
      _c(
        "sticky",
        [
          _vm.initEnum
            ? _c("searchFilter", {
                attrs: { searchForm: _vm.searchForm, immediately: "" },
                on: { emitSearch: _vm.emitSearch },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "other-warp" },
            [
              _c(
                "checkbox",
                {
                  on: { change: _vm.refreshList },
                  model: {
                    value: _vm.queryParams.notTest,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "notTest", $$v)
                    },
                    expression: "queryParams.notTest",
                  },
                },
                [_vm._v("不看演示诊所")]
              ),
              _c(
                "checkbox",
                {
                  on: { change: _vm.refreshList },
                  model: {
                    value: _vm.queryParams.histogramAnomaly,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "histogramAnomaly", $$v)
                    },
                    expression: "queryParams.histogramAnomaly",
                  },
                },
                [_vm._v("查看异常报告")]
              ),
              false && _vm.listInfo.length
                ? _c("p", { staticClass: "other-text" }, [
                    _vm._v(" 总单量：" + _vm._s(_vm.total) + "，已完成单量："),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.todayFinish)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "pull-refresh",
            {
              on: { refresh: _vm.refreshList },
              model: {
                value: _vm.pullLoading,
                callback: function ($$v) {
                  _vm.pullLoading = $$v
                },
                expression: "pullLoading",
              },
            },
            _vm._l(_vm.listInfo, function (item, index) {
              return _c("reportIBox", { key: index, attrs: { info: item } })
            }),
            1
          ),
        ],
        1
      ),
      _vm.listInfo.length && !_vm.hasNext
        ? _c("divider", [_vm._v("没有更多了")])
        : _vm._e(),
      !_vm.listInfo.length
        ? _c("empty", { attrs: { description: "暂无数据" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
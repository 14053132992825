<template>
  <div class="report-box">
    <div class="report-info report-header">
      <span class="clinic-name">{{ info.clinicName }}</span>
      <span class="doc-name"></span>
    </div>
    <div class="report-info-list">
      <p>{{ info.goodsName }} {{ info.receiveMobile }}</p>
      <p>
        {{ info.addressProvinceName }} {{ info.addressCityName }} {{ info.addressRegionName }} {{ info.addressDetail }}
      </p>
      <p>诊所库存：{{ info.stock }}</p>
      <p>有效期至：{{ info.serviceLimitDate }}</p>
    </div>
    <div class="report-info report-foot">
      <span class="clinic-name">{{ info.createTime }}</span>
      <span class="report-time">
        <span class="info-btn" @click="fieldPrompShow(info)">入库</span>
      </span>
    </div>

    <fieldPrompt
      title="出库"
      v-if="fieldPromptModel.show"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      @emit="fieldPromptEmit"
    />
  </div>
</template>

<script>
import services from '@/service';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';
import fieldPrompt from '@/components/fieldPrompt';
import dayjs from 'dayjs';

export default {
  components: { fieldPrompt },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {}
      },
      colorEnum: {
        检测中: '#808084',
        查看报告: '#24d270'
      }
    };
  },
  computed: {
    ...mapGetters(['checkOrderState']),
    userType() {
      return getUserType();
    },
    showDelete() {
      return this.userType === 'clinic';
    },
    showClinicName() {
      return this.userType === 'admin';
    }
  },
  methods: {
    async fieldPrompShow(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
      this.fieldPromptModel.fieldForm = {
        curNumber: {
          label: '数量'
        },
        serviceLimitTime: {
          label: '服务有效期天数'
        }
      };
    },
    async fieldPromptEmit(e) {
      if (e.curNumber === '' || e.serviceLimitTime === '') return this.$notify('请输入完整');
      const params = {
        clinicId: e.clinicId,
        clinicName: e.clinicName,
        clinicStockId: e.clinicStockId,
        curNumber: Number(e.curNumber),
        serviceLimitTime: Number(e.serviceLimitTime)
      };

      await services.admin.clinicStockAddInventory(params);
      this.fieldPromptModel.show = false;

      // 数据操作
      this.info.stock = this.info.stock += params.curNumber;
      const date = dayjs(this.info.serviceLimitDate); // 创建指定日期
      const newDate = date.add(params.serviceLimitTime, 'day'); // 加 1 天
      this.info.serviceLimitDate = newDate.format('YYYY-MM-DD');
      this.$toast('操作成功');
    },
    hasDetail(item) {
      return ['2', '4', '6'].includes(item.status);
    },
    toDetail(item) {
      this.$router.push({
        path: `/${this.userType}-device-record-add?id=${item.deviceRecordId}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.report-box {
  background: #fff;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  font-size: $size2;
  line-height: 26px;
  color: $black1;
  // height: 300px;

  .report-header {
    font-size: $size6;
    margin-bottom: $tb;
    .clinic-name {
      background: $btnbg;
      color: #fff;
      padding: 2px $lr;
      border-radius: $radius 0 $radius 0;
      @include hiddenText();
    }
    .doc-name {
      color: $gray2;
      padding: 0 $lr;
      position: relative;
      top: 2px;
    }
  }
  .report-name {
    font-size: $size1;
    font-weight: 600;
    padding: $tb $lr;
    color: $gray2;
  }
  .report-code {
    font-size: $size6;
    padding: 0 $lr;
    color: $gray2;
    text-align: right;
  }
  .report-info {
    @include flexCenter(row);
    justify-content: space-between;

    .report-time {
      .clinic-name {
        flex: 1;
        @include hiddenText();
        font-size: $size6;
      }
    }
  }

  .report-info-list {
    padding: 0 $lr 6px;
    font-size: $size5;
  }

  .report-project {
    padding: 0 $lr $tb;

    .report-read {
      font-size: $size4;
      color: $appcolor;
      font-weight: 500;
    }
  }

  .report-foot {
    border-top: 1px solid $bordercolor;
    font-size: $size6;
    color: $gray2;
    padding: 4px $lr;
    .patient-name {
    }

    .info-btn {
      border: 1px solid $blue1;
      color: $blue1;
      margin-left: $lr;
      border-radius: 4px;
      padding: 4px 10px;
    }
  }
}
</style>

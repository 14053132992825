var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "partner-info" },
    [
      _c(
        "div",
        { staticClass: "type-warp" },
        _vm._l(_vm.tabs, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              class: [_vm.activeTypes === item.value && "active"],
              on: {
                click: function ($event) {
                  return _vm.changeActive(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.type) + " ")]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "input-warp" },
        [
          _c(
            "van-form",
            { ref: "formRef" },
            [
              _c("field", {
                attrs: {
                  value: _vm.formInfo.name,
                  name: "收款人",
                  label: "收款人",
                  placeholder: "请选择收款人",
                  required: "",
                  readonly: "",
                  clickable: "",
                  rules: [{ required: true, message: "请选择收款人" }],
                },
                on: {
                  click: function ($event) {
                    _vm.pickerShow = true
                  },
                },
              }),
              _c(
                "popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.pickerShow,
                    callback: function ($$v) {
                      _vm.pickerShow = $$v
                    },
                    expression: "pickerShow",
                  },
                },
                [
                  _c("picker", {
                    attrs: { columns: _vm.listInfo, "value-key": "text" },
                    on: {
                      confirm: _vm.pickerConfirm,
                      cancel: function ($event) {
                        _vm.pickerShow = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("fixedButton", {
        attrs: { text: "保存" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
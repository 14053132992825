import request from '@/lib/request';

let uuid = sessionStorage.getItem('log_uuid');
if (!uuid) {
  uuid = getUuid();
  sessionStorage.setItem('log_uuid', uuid);
}

function getUuid() {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

export function handleLog({ operateName, otherParams = undefined }) {
  if (operateName) return;
  return request.post('/v2-api/admin/page/collection', {
    uuid,
    method: 'POST',
    operateName,
    source: 'web-view打开wps文档',
    ...(otherParams || {})
  });
}

export function routerLog(params) {
  if (params) return;
  return request.post('/v2-api/admin/page/collection', {
    ...params,
    uuid,
    method: 'POST',
    source: '路由跳转frontend-web-view'
  });
}

<template>
  <div class="home-page">
    <h1 class="title">爱检康</h1>
    <div class="home-warp">
      <div class="list-type" v-for="(item, index) in list" :key="index" @click="toPage(item)">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: 'i检康',
          url: '/patient-login'
        },
        {
          name: 'i检医',
          url: '/clinic-login'
        },
        {
          name: 'i检云',
          url: '/partner-login'
        },
        {
          name: '后台管理',
          url: '/admin-login'
        }
      ]
    };
  },
  methods: {
    toPage(e) {
      this.$router.push(e.url);
    }
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-top: 20vh;
}
.title {
  font-weight: 600;
  margin: 40px 0;
  text-align: center;
}
.home-warp {
  display: flex;
  padding: 0 $lr;
  align-items: center;
  justify-content: space-between;
  .list-type {
    width: 80px;
    height: 100px;
    box-shadow: $box-shadow1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius;
  }
}
</style>

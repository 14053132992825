var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: { round: "" },
      on: { closed: _vm.closedHandle },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "opinion-warp" },
        [
          _c("p", { staticClass: "opinion-title" }, [_vm._v("意见反馈")]),
          _c("Field", {
            attrs: {
              label: "",
              placeholder: "请留下您的宝贵意见，帮助我们为您提供更好的服务。",
              type: "textarea",
              maxLength: "500",
              rows: "6",
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c("div", { staticClass: "btn", on: { click: _vm.opinionSubmit } }, [
            _vm._v("提交"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="search-filter" @click="popupShow = true">
      <div class="search-left">
        <span class="search-tip">检索条件：</span>
        <span class="search-tag" v-if="!showTags.length">全部</span>
        <span class="search-tag" v-for="(item, index) in showTags" :key="index">
          {{ item.label }}：{{ item.value }}
        </span>
      </div>
      <Icon name="filter-o" />
    </div>
    <popup v-model="popupShow" position="bottom" :style="{ width: '100%' }" round closeable>
      <p class="search-title">高级检索</p>
      <div class="filter-content">
        <van-form ref="formRef">
          <template v-for="(item, key) in searchForm">
            <template v-if="item.component === 'select'">
              <field
                :key="'select_' + key"
                readonly
                clickable
                :value="formModel[`${key}${filterLable}`]"
                :label="item.label + '：'"
                @click="pickerHandle(key, true)"
                :placeholder="`请选择${item.label}`"
                type="textarea"
                autosize
                clearable
              >
              </field>
              <popup :key="'popup_' + key" v-model="pickerObj[`${key}Picker`]" position="bottom">
                <picker
                  :columns="item.options"
                  @cancel="pickerHandle(key, false)"
                  @confirm="(value, index) => pickerConfirm(value, index, key)"
                  value-key="value"
                />
              </popup>
            </template>
            <template v-else-if="item.component === 'date-picker'">
              <field
                :key="'date-picker_' + key"
                readonly
                clickable
                :value="(formModel[`${key}`] || []).join(' - ')"
                :label="item.label + '：'"
                @click="pickerHandle(key, true)"
                :placeholder="`请选择${item.label}`"
                clearable
              >
                <template #button v-if="(formModel[key] || []).length">
                  <Icon @click.stop="resetDate(key)" name="clear" color="#c8c9cc" />
                </template>
              </field>
              <calendar
                :key="'popup_' + key"
                v-model="pickerObj[`${key}Picker`]"
                type="range"
                @confirm="value => datePickerConfirm(value, key)"
                :min-date="minDate"
                :max-date="maxDate"
                allow-same-day
                color="#04d1ea"
              />
            </template>
            <template v-else-if="item.component === 'area'">
              <field
                :key="'area_' + key"
                readonly
                clickable
                :value="(formModel[`${key}`] || []).join(' / ')"
                :label="item.label + '：'"
                @click="pickerHandle(key, true)"
                :placeholder="`请选择${item.label}`"
                clearable
              >
                <template #button v-if="(formModel[key] || []).length">
                  <Icon @click.stop="resetDate(key)" name="clear" color="#c8c9cc" />
                </template>
              </field>
              <popup :key="'popup_' + key" v-model="pickerObj[`${key}Picker`]" position="bottom">
                <Area
                  :value="formModel[`${key}${filterLable}`]"
                  :area-list="areaList"
                  @confirm="value => areaConfirm(value, key)"
                  @cancel="showArea = false"
                  :columns-placeholder="['请选择', '请选择', '请选择']"
                />
              </popup>
            </template>
            <field
              v-else
              :key="'field_' + key"
              v-model="formModel[key]"
              :label="item.label + '：'"
              :placeholder="`请输入${item.label}`"
              clearable
              maxlength="30"
            >
            </field>
          </template>
        </van-form>
        <div style="height: 100px;"></div>
        <div class="btn-warp">
          <div class="btn-warp-item">
            <div class="btn btn1" @click="resetSearch">重 置</div>
            <div class="btn btn2" @click="searchSubmit">查 询</div>
          </div>
          <!-- <div class="btn" @click="searchSubmit">查 询</div> -->
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { Popup, Form as VanForm, Field, Calendar, Icon, Area } from 'vant';
import Picker from '@/components/picker';
import dayjs from 'dayjs';
import { areaList } from '@vant/area-data';

import { getRangeByDate } from '@/lib/utils';

export default {
  components: {
    Popup,
    VanForm,
    Field,
    Calendar,
    Icon,
    Picker,
    Area
  },
  props: {
    searchForm: {
      type: Object,
      required: true
    },
    immediately: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      areaList,
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(),
      filterLable: '_filterLable',
      showPicker: false,
      pickerObj: {},
      formModel: {},
      formInfo: {
        name: ''
      },
      popupShow: false,
      qrcodeModel: {
        show: false,
        info: {}
      },
      infoList: [],
      active: 0,
      notFoundImg: this.$getImg('无数据@2x'),
      showTags: []
    };
  },
  computed: {
    queryId() {
      return this.$route.query.id;
    }
  },
  methods: {
    resetDate(key) {
      this.formModel[key] = [];
    },
    pickerHandle(name, type) {
      this.$set(this.pickerObj, `${name}Picker`, type);
    },
    datePickerConfirm(value, key) {
      this.formModel[key] = [dayjs(value[0]).format('YYYY-MM-DD'), dayjs(value[1]).format('YYYY-MM-DD')];
      this.pickerHandle(key, false);
    },
    areaConfirm(value, key) {
      this.formModel[key] = value.filter(item => item && item.name).map(item => item.name);
      this.pickerHandle(key, false);
    },
    pickerConfirm(value, index, key) {
      this.formModel[key] = value.key;
      this.formModel[`${key}${this.filterLable}`] = value.value;
      this.pickerHandle(key, false);
    },
    initFrom() {
      const keys = Object.keys(this.searchForm);
      const obj = {};
      keys.forEach(key => {
        obj[key] = this.searchForm[key].defaultValue || '';
        if (this.searchForm[key].component === 'select') {
          const item = this.searchForm[key].options.find(item => item.key === obj[key]);
          obj[`${key}${this.filterLable}`] = item ? item.value : '';
        }
      });
      this.formModel = Object.assign({}, this.formModel, obj);
      if (this.immediately) {
        this.searchSubmit();
      }
    },
    searchSubmit() {
      let subForm = this.removeFilterLabels({ ...this.formModel });

      for (const i in subForm) {
        if (this.searchForm[i] && this.searchForm[i].component === 'date-picker') {
          subForm = Object.assign(subForm, getRangeByDate(subForm[i], this.searchForm[i].dateKey || {}));
          delete subForm[i];
        } else if (this.searchForm[i] && this.searchForm[i].component === 'area') {
          subForm.province = subForm[i][0];
          subForm.city = subForm[i][1];
          subForm.district = subForm[i][2];
          delete subForm[i];
        }
      }
      delete subForm.dateType;
      this.getTags();
      this.popupShow = false;
      this.$emit('emitSearch', subForm);
    },
    resetSearch() {
      this.initFrom();
    },
    removeFilterLabels(obj) {
      return Object.keys(obj).reduce((result, key) => {
        if (!key.endsWith(this.filterLable)) {
          result[key] = obj[key];
        }
        return result;
      }, {});
    },
    getTags() {
      const arr = [];
      const keys = Object.keys(this.searchForm);
      keys.forEach(i => {
        if (this.searchForm[i].component === 'select' && this.formModel[`${i}${this.filterLable}`]) {
          arr.push({
            label: this.searchForm[i].label,
            value: this.formModel[`${i}${this.filterLable}`],
            key: i
          });
        } else if (this.searchForm[i].component === 'date-picker' && this.formModel[i].length) {
          arr.push({
            label: this.searchForm[i].label,
            value: this.formModel[i].join(' - '),
            key: i
          });
        } else if (this.searchForm[i].component === 'area' && this.formModel[i].length) {
          arr.push({
            label: this.searchForm[i].label,
            value: this.formModel[i].join(' / '),
            key: i
          });
        } else if (this.searchForm[i].component === 'input' && this.formModel[i]) {
          arr.push({
            label: this.searchForm[i].label,
            value: this.formModel[i],
            key: i
          });
        }
      });
      this.showTags = arr;
    }
  },
  mounted() {
    this.initFrom();
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  background: #fff;
  @include flex(row);
  justify-content: space-between;
  padding: $tb $lr 6px;

  .search-left {
    font-size: 12px;
    flex: 1;
    .search-tag {
      margin-right: $lr;
      background: $appcolor;
      border-radius: 6px;
      color: #fff;
      padding: 4px 6px;
      display: inline-block;
      margin-bottom: 4px;
      white-space: pre-wrap; /* 保留空白序列，但会正常换行 */
      word-wrap: break-word; /* 在单词的末尾处进行换行 */
      word-break: break-all; /* 允许在单词内换行 */
      line-height: 16px;
    }
  }
  i {
    font-size: 20px;
  }
}

.search-title {
  text-align: center;
  color: $gray1;
  font-size: $size6;
  padding: $tb $lr 0;
}

.van-popup--right {
  height: 100%;
}
.filter-content {
  padding: $tb 0;

  ::v-deep {
    .van-field__label {
      width: 110px !important;
    }
  }

  .btn-warp {
    width: 100%;
    position: absolute;
    bottom: $tb;

    .btn-warp-item {
      width: 100%;
      @include flex(row);
      justify-content: space-between;
      border-bottom: 1px solid $bordercolor;
      .btn1 {
        width: 30% !important;
        flex: none;
        background: $gray3;
        margin: 0 $lr;
        &:active {
          background: darken($gray3, 10%);
        }
      }
      .btn2 {
        margin-right: $lr;
        width: 70% !important;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="detection">
      <p class="line-title">患者信息</p>
      <div class="detection-form">
        <van-form ref="formRef">
          <field
            v-model="formInfo.patientPhone"
            name="患者手机"
            label="患者手机："
            placeholder="请输入患者手机"
            type="number"
            maxLength="11"
            @input="getPatientRelByPhone"
            ref="patientPhone"
          />
          <field
            v-model="formInfo.patientName"
            name="患者姓名"
            label="患者姓名："
            placeholder="请输入患者姓名"
            :rules="[{ required: true, message: '请输入患者姓名' }]"
            required
          />
          <field
            v-model="formInfo[formInfo.ageType]"
            name="年龄"
            label="年龄："
            placeholder="请输入年龄"
            type="number"
            :rules="[{ required: true, pattern: /^([1-9]\d*)$/, message: '请输入整数年龄' }]"
            maxLength="3"
            required
          >
            <template #button>
              <radio-group v-model="formInfo.ageType" direction="horizontal" @change="changeAgeType">
                <radio name="age">岁</radio>
                <radio name="ageMonth">月</radio>
              </radio-group>
            </template>
          </field>
          <field
            name="radio"
            label="性别："
            required
            placeholder="请选择性别"
            :rules="[{ required: true, message: '请选择性别' }]"
          >
            <template #input>
              <radio-group v-model="formInfo.gender" direction="horizontal">
                <radio name="0">男</radio>
                <radio name="1">女</radio>
              </radio-group>
            </template>
          </field>
          <field v-model="formInfo.height" name="身高" label="身高：" placeholder="请输入身高，单位CM" type="number" />
          <field v-model="formInfo.weight" name="体重" label="体重：" placeholder="请输入体重，单位KG" type="number" />
        </van-form>
      </div>
      <div style="height: 10px;" />
      <p class="line-title">检测项目</p>
      <div class="project-form">
        <tabs v-model="active" swipeable @change="getProjects" v-if="clinicInfo.clinicId === 1">
          <tab v-for="(item, index) in tabList" :title="item.title" :key="index"> </tab>
        </tabs>

        <checkbox-group v-model="projectSelect">
          <checkbox
            :disabled="item.stock <= 0"
            :name="item.goodsMealId"
            shape="square"
            v-for="(item, index) in projectList"
            :key="index"
            class="group-checkbox"
          >
            <div class="group-checkbox-content">
              <span class="goods-name">{{ item.goodsName }}</span>
              <span class="stock" :style="{ color: dateColor(item.stockAlarmLevel) }">余：{{ item.stock }}</span>
            </div>
            <p class="goods-foot-warp" :style="{ color: dateColor(item.timeAlarmLevel) }">
              <span class="goods-date">有效期：{{ item.serviceLimitDate }}</span>
              <!-- 项目价格 B端支付显示 -->
              <span class="goods-price" v-if="item.paymentMode === paymentModeMap.patient"
                ><span>￥</span>{{ item.salePrice }}</span
              >
            </p>
            <div
              class="device-warp"
              v-if="projectSelect.includes(item.goodsMealId) && deviceListComp(item.goodsSettingVoList).length > 1"
            >
              <radio-group v-model="item.deviceNum">
                <cell-group>
                  <cell
                    v-for="(dItem, dIndex) in deviceListComp(item.goodsSettingVoList)"
                    :key="'d_' + dIndex"
                    :title="dItem.deviceNum"
                    @click.stop="item.deviceNum = dItem.deviceNum"
                  >
                    <template #right-icon>
                      <radio :name="dItem.deviceName || dItem.deviceNum" />
                    </template>
                  </cell>
                </cell-group>
              </radio-group>
            </div>
          </checkbox>
        </checkbox-group>
      </div>
    </div>
    <action-sheet
      v-model="actionShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="selectPatient"
    />

    <qrcode v-if="qrcodeModel.show" :show.sync="qrcodeModel.show" :info="qrcodeModel.info" />

    <div style="height:200px"></div>
    <!-- <div class="btn" @click="submit">提交申请</div> -->
    <fixedButton text="提交申请" @emitEvent="submit" />

    <!-- <tabbar :curIndex="1" /> -->
  </div>
</template>

<script>
import {
  Form as VanForm,
  Field,
  Area,
  CellGroup,
  Cell,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  ActionSheet,
  Tab,
  Tabs
} from 'vant';
import services from '@/service';
import { mapGetters } from 'vuex';
import fixedButton from '@/components/fixedButton';
import qrcode from '@/components/qrcode';
import { paymentModeMap } from '@/global';

export default {
  components: {
    VanForm,
    Field,
    [Area.name]: Area,
    CellGroup,
    Cell,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    fixedButton,
    ActionSheet,
    Tab,
    Tabs,
    qrcode
  },
  data() {
    return {
      active: 0,
      tabList: [],
      actionShow: false,
      actions: [],
      showArea: false,
      showCalendar: false,
      formInfo: {
        patientName: '',
        gender: '',
        height: '',
        weight: '',
        patientPhone: '',
        ageType: 'age',
        age: '',
        ageMonth: ''
      },
      projectSelect: [],
      projectList: [],
      qrcodeModel: {
        show: false,
        info: {}
      }
    };
  },
  computed: {
    ...mapGetters(['clinicInfo', 'paymentMode']),
    deviceNum() {
      return this.$route.query.deviceNum || '';
    },
    paymentModeMap() {
      return paymentModeMap;
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    showCode(info) {
      this.qrcodeModel.show = true;
      // const code = `${window.location.origin}/patient-report-detail?id=${this.queryId}`;
      const code = `http://p.aitesthealth.com/weChatToMini/patientReportPay/${info.checkOrderId}`;
      this.qrcodeModel.info = {
        code,
        text: `${info.patientName}`
      };
    },
    changeAgeType() {
      this.formInfo.age = '';
      this.formInfo.ageMonth = '';
    },
    async getPatientRelByPhone() {
      if (this.formInfo.patientPhone.length === 11) {
        const res = await services.clinic.getPatientRelByPhone(this.formInfo.patientPhone);
        if (res.data.length) {
          this.actions = res.data;
          this.actionShow = true;
        }
        this.$refs.patientPhone.blur();
      }
    },
    selectPatient(e) {
      this.formInfo.patientName = e.name;
      if (e.age) {
        this.formInfo.ageType = 'age';
      } else if (e.ageMonth) {
        this.formInfo.ageType = 'ageMonth';
      }
      console.log(this.formInfo.age);
      this.formInfo.gender = e.gender;
      this.formInfo.height = e.height;
      this.formInfo.weight = e.weight;

      this.$nextTick(() => {
        this.formInfo.age = e.age;
        this.formInfo.ageMonth = e.ageMonth;
      });

      this.$forceUpdate();
    },
    deviceListComp(goodsSettingVoList) {
      const arr = [];
      goodsSettingVoList.forEach(element => {
        element.clinicDeviceList.forEach(i => {
          arr.push(i);
        });
      });
      return arr;
    },
    dateColor(type) {
      return {
        0: '#808084',
        1: '#ffb811',
        2: '#fe271b'
      }[type];
    },
    reset() {
      this.formInfo = {
        patientName: '',
        gender: '',
        height: '',
        weight: '',
        patientPhone: '',
        ageType: 'age',
        age: '',
        ageMonth: ''
      };

      if (this.paymentMode === '2') {
        this.tabList = [
          {
            title: '患者支付',
            value: '2'
          },
          {
            title: '诊所支付',
            value: '1'
          }
        ];
      } else {
        this.tabList = [
          {
            title: '诊所支付',
            value: '1'
          },
          {
            title: '患者支付',
            value: '2'
          }
        ];
      }
      this.getProjects();
    },
    async getProjects() {
      this.projectSelect = [];
      const res = await services.clinic.listProjects(this.clinicInfo.clinicId, this.tabList[this.active].value);
      this.projectList = res.rows.map(i => {
        return {
          ...i,
          deviceNum: i.goodsSettingVoList[0].clinicDeviceList[0].deviceNum
        };
      });
    },
    async submit() {
      await this.$refs.formRef.validate();

      if (!this.formInfo.patientName.trim()) {
        return this.$toast('请输入患者姓名');
      }
      if (!this.formInfo[this.formInfo.ageType] || this.formInfo[this.formInfo.ageType] == 0) {
        return this.$toast('请输入患者年龄');
      }
      if (!this.formInfo.gender) {
        return this.$toast('请选择性别');
      }
      if (this.projectSelect.length === 0) {
        return this.$toast('请选择检测项目');
      }

      const params = {
        checkOrderDetailList: this.projectSelect.map(item => {
          // 兼容扫码检测
          const deviceNum = this.deviceNum
            ? this.deviceNum
            : this.projectList.find(i => i.goodsMealId === item).deviceNum;
          return {
            goodsMealId: item,
            number: 1,
            deviceNum
          };
        }),
        clinicId: this.clinicInfo.clinicId,
        clinicName: this.clinicInfo.clinicName,
        paymentMode: this.tabList[this.active].value,
        ...this.formInfo
      };
      const res = await services.clinic.createCheckOrder(params);
      this.reset();

      if (res.data.paymentMode === this.paymentModeMap.patient) {
        this.showCode(res.data);
      } else {
        this.$toast.success('申请成功');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-cell__title {
    width: 90px !important;
    flex: none;
  }
}

.detection {
  .line-title {
    margin: $tb $lr;
  }
  .detection-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }

  .project-form {
    margin: $tb $lr;
    padding: 0 $lr;
    background: #fff;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
    .goods-name {
      font-size: $size3;
    }
  }

  .group-checkbox {
    border-bottom: 1px solid $bordercolor;
    padding: $tb 0;
    ::v-deep {
      .van-checkbox__label {
        flex: 1;
      }
    }
    .group-checkbox-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $gray1;
      .stock {
        font-size: $size6;
        color: $gray2;
        flex-shrink: 0;
      }
    }

    .goods-foot-warp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
      font-size: $size6;
      .goods-date {
        color: $gray2;
      }
      .goods-price {
        color: $red1;

        span {
          font-size: 10px;
        }
      }
    }
  }

  .device-warp {
    margin: $tb 0 0;
    ::v-deep {
      [class*='van-hairline']::after {
        border: none;
      }
      .van-radio-group {
        padding: 0 $lr;
        background-color: $bordercolor;
        border-radius: $radius;
      }
      .van-cell {
        border: none;
        background-color: $bordercolor;
      }
      .van-cell__title {
        flex: 1;
      }
      .van-cell {
        padding: 4px 0;
        color: $gray1;
      }
    }
  }
}

.btn {
  margin: $tb $lr;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "pull-refresh",
        {
          attrs: { disabled: !_vm.pageInit },
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c("div", { staticClass: "home-page" }, [
            _c("div", { staticClass: "ach-panel" }, [
              _c("p", { staticClass: "line-title" }, [_vm._v("业绩")]),
              _c("div", { staticClass: "ach-warp" }, [
                _c("div", { staticClass: "ach-header" }, [
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("当日")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.todaySalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("七日")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.cwSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("本月")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.cmSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("今年")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.cySalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "ach-content" }, [
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("上月")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.lmSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("前三月")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.ltmSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toLink("partnerDeviceList")
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "ach-title" }, [_vm._v("设备数")]),
                      _c(
                        "p",
                        { staticClass: "ach-num" },
                        [
                          _c("countTo", {
                            attrs: {
                              separator: "",
                              startVal: 0,
                              endVal: _vm.scoreInfo.deviceAmount,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toLink("partnerMyClinic")
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "ach-title" }, [_vm._v("诊所数")]),
                      _c(
                        "p",
                        { staticClass: "ach-num" },
                        [
                          _c("countTo", {
                            attrs: {
                              separator: "",
                              startVal: 0,
                              endVal: _vm.scoreInfo.clinicAmount,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            !_vm.isPartnerC
              ? _c("div", { staticClass: "func-panel" }, [
                  _c("p", { staticClass: "line-title" }, [
                    _vm._v("工具与服务"),
                  ]),
                  _c("div", { staticClass: "func-warp" }, [
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerClock")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-dk2" }),
                        _c("span", [_vm._v("去打卡")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerAddClinic")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-zs" }),
                        _c("span", [_vm._v("添加诊所")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerBindDevice")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-bdsb" }),
                        _c("span", [_vm._v("绑定设备")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerDeviceList")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-jb" }),
                        _c("span", [_vm._v("解绑设备")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerMaterialApplication")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-wz" }),
                        _c("span", [_vm._v("申请设备")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "func-item",
                        on: {
                          click: function ($event) {
                            return _vm.toLink("partnerClinicOrderList")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-dck" }),
                        _c("span", [_vm._v("待出库")]),
                      ]
                    ),
                    _vm.isPartner
                      ? _c(
                          "div",
                          {
                            staticClass: "func-item",
                            on: {
                              click: function ($event) {
                                return _vm.toLink("invite")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-yq" }),
                            _c("span", [_vm._v("邀请业务员")]),
                          ]
                        )
                      : _vm._e(),
                    _vm.isPartner
                      ? _c(
                          "div",
                          {
                            staticClass: "func-item",
                            on: {
                              click: function ($event) {
                                return _vm.toLink("partnerInfoEditAdd")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-tjywy" }),
                            _c("span", [_vm._v("添加业务员")]),
                          ]
                        )
                      : _vm._e(),
                    false
                      ? _c(
                          "div",
                          {
                            staticClass: "func-item",
                            on: {
                              click: function ($event) {
                                return _vm.toLink("partnerBuy")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-cg" }),
                            _c("span", [_vm._v("我要备货")]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "clinic-panel" }, [
              _c("p", { staticClass: "line-title" }, [
                _vm._v("前七日诊所排行榜"),
              ]),
              _c(
                "div",
                { staticClass: "clinic-warp" },
                [
                  _vm._l(_vm.listInfo, function (item, index) {
                    return _c("clinicBox", {
                      key: index,
                      attrs: { info: item },
                    })
                  }),
                  !_vm.listInfo.length
                    ? _c("empty", { attrs: { description: "暂无数据" } })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "span",
                { staticClass: "read-more", on: { click: _vm.clinicToMore } },
                [_vm._v("更多"), _c("icon", { attrs: { name: "arrow" } })],
                1
              ),
            ]),
            _c("div", { staticClass: "partner-panel" }, [
              _c("p", { staticClass: "line-title" }, [
                _vm._v("前七日个人业绩排行榜"),
              ]),
              _c(
                "div",
                { staticClass: "horizontal-scroll" },
                [
                  _c(
                    "div",
                    { staticClass: "partner-warp" },
                    _vm._l(_vm.rankSalesList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "partner-item",
                          on: {
                            click: function ($event) {
                              return _vm.toPartnerInfo(item.salesId)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "partner-name" }, [
                            _vm._v(_vm._s(item.salesName)),
                          ]),
                          _c("div", { staticClass: "partner-salse" }, [
                            _c("span"),
                            _vm._v(_vm._s(item.cwSalseAmount)),
                          ]),
                          item.stockWarningFlag === 1
                            ? _c("i", { staticClass: "iconfont icon-yujing" })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  !_vm.rankSalesList.length
                    ? _c("empty", { attrs: { description: "暂无数据" } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "read-more", on: { click: _vm.partnerToMore } },
                [_vm._v("更多"), _c("icon", { attrs: { name: "arrow" } })],
                1
              ),
            ]),
          ]),
          _c("div", { staticStyle: { height: "100px" } }),
        ]
      ),
      _c("tabbar", { attrs: { curIndex: 0 } }),
      _vm.qrcodeModel.show
        ? _c("qrcode", {
            attrs: { show: _vm.qrcodeModel.show, info: _vm.qrcodeModel.info },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.qrcodeModel, "show", $event)
              },
            },
          })
        : _vm._e(),
      _c(
        "overlay",
        {
          attrs: {
            show: _vm.showLinkTip,
            "class-name": "link-overlay need-hide",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "overlay-wrap",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("p", { staticClass: "tip" }, [
                _vm._v("点击右上角 "),
                _c("span", { staticClass: "tip-round" }, [_vm._v("●●●")]),
                _vm._v(" 转发给朋友~"),
              ]),
              _c(
                "button",
                {
                  staticClass: "clear-btn",
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.showLinkTip = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
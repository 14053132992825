<template>
  <div class="nab-bar-warp" v-if="showBack" :style="{ width: $store.getters.screenWidth + 'px' }">
    <nav-bar :title="title" :left-arrow="showBack" @click-left="onClickLeft" fixed placeholder />
  </div>
</template>

<script>
import { NavBar } from 'vant';
import { isMiniProgram } from '@/global';

export default {
  components: {
    NavBar
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }
  },
  mounted() {},
  computed: {
    title() {
      return this.$route.meta.title || process.env.VUE_APP_TITLE;
    },
    showBack() {
      if (isMiniProgram) {
        return false;
      }
      return !this.$route.meta.hideBack;
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">
::v-deep {
  .van-nav-bar .van-icon {
    color: $appcolor;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "partner-info" }, [
    _c("div", { staticClass: "ach-warp" }, [
      _vm.partnerInfo.id
        ? _c(
            "div",
            { staticClass: "personal-info" },
            [
              _c("van-image", {
                staticClass: "avatar",
                attrs: {
                  fit: "cover",
                  src: _vm.partnerInfo.avatar || _vm.avatar,
                  alt: _vm.partnerInfo.name,
                },
              }),
              _c("p", { staticClass: "personal-name" }, [
                _vm._v(_vm._s(_vm.partnerInfo.name)),
              ]),
              _c("div", { staticClass: "personal-phone" }, [
                _c(
                  "p",
                  {
                    staticClass: "personal-tel",
                    on: {
                      click: function ($event) {
                        return _vm.dialPhoneNumber(_vm.partnerInfo.phone)
                      },
                    },
                  },
                  [
                    _c("icon", { attrs: { name: "phone" } }),
                    _vm._v(" " + _vm._s(_vm.partnerInfo.phone) + " "),
                  ],
                  1
                ),
                _c("p", { staticClass: "personal-age" }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.genderType.getValueByKey(_vm.partnerInfo.gender)
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.partnerInfo.age) + "岁")]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/lib/request';
import { userTypeAll } from '@/enum/index';
const userType = userTypeAll.getKeyByValue('诊所端');

export function sendSms(mobile) {
  return request.get(`/auth/userlogin/sms/${mobile}/${userType}`);
}

export function smsLogin(params) {
  return request.post(`/auth/userlogin/smslogin`, { ...params, userType });
}

export function checkOrderList(params) {
  return request.get('/clinic/checkOrder/list', { params });
}

export function mealListService(params) {
  return request.get('/clinic/meal/listService', { params });
}

export function calculatePrice(params) {
  return request.post('/clinic/serviceOrder/calculatePrice', params);
}

export function createServiceOrder(params) {
  return request.post('/clinic/serviceOrder/createServiceOrder', params);
}

export function mealDetail(id) {
  return request.get(`/clinic/meal/${id}`);
}

export function wxPay(params) {
  return request.post(`/clinic/serviceOrder/wxPay`, params);
}

export function orderList(params) {
  return request.get(`/clinic/serviceOrder/list`, { params });
}

export function deviceList(params) {
  return request.get(`/clinic/clinic/device/rel/list`, { params });
}

export function listProjects(clinicId, paymentMode = '1') {
  return request.get(`/clinic/meal/listProjects/${clinicId}/${paymentMode}`);
}

export function createCheckOrder(params) {
  return request.post(`/clinic/checkOrder/createCheckOrder`, params);
}

export function createFeedback(params) {
  return request.post(`/clinic/feedback`, params);
}

export function getClinicInfo(params) {
  return request.get(`/clinic/clinic/getInfo`, params);
}

export function editClinicInfo(params) {
  return request.put(`/clinic/clinic/edit`, params);
}

export function getDeviceInfo(clinicDeviceRelId) {
  return request.get(`/clinic/clinic/device/rel/${clinicDeviceRelId}`);
}

export function getDoctorList(params) {
  return request.get(`/clinic/doctor/list`, { params });
}

export function getAddressList(params) {
  return request.get(`/clinic/address/list`, { params });
}

export function getClinicScore() {
  return request.get(`/clinic/index/score`);
}

export function getClinicDeviceStock(clinicId) {
  return request.get(`/clinic/clinic/stock/getClinicDeviceStock/${clinicId}`);
}

export function addDoctor(params) {
  return request.post(`/clinic/doctor`, params);
}

export function addAddress(params) {
  return request.post(`/clinic/address`, params);
}

export function editAddress(params) {
  return request.put(`/clinic/address`, params);
}

export function getAddress(id) {
  return request.get(`/clinic/address/${id}`);
}

export function deleteAddress(id) {
  return request.delete(`/clinic/address/${id}`);
}

export function deleteCheckOrder(id) {
  return request.delete(`/clinic/checkOrder/cancel/${id}`);
}

export function getSales() {
  return request.get(`/clinic/clinic/getSales`);
}

export function getServiceOrder(serviceOrderId) {
  return request.get(`/clinic/serviceOrder/${serviceOrderId}`);
}

export function queryReports(checkOrderDetailId) {
  return request.get(`/clinic/checkReport/queryReports/${checkOrderDetailId}`);
}

export function queryMsgReports(msgCode) {
  return request.get(`/clinic/checkReport/queryMsgReports/${msgCode}`);
}

export function getBindClinics() {
  return request.get(`/clinic/clinic/getBindClinics`);
}

export function chooseClinic(clinicid) {
  return request.put(`/clinic/clinic/chooseClinic/${clinicid}`);
}

export function getDoctorCurrent() {
  return request.get(`/clinic/doctor/current`);
}

export function getDoctorAvatar(doctorId) {
  return request.get(`/clinic/doctor/avatar/${doctorId}`);
}

export function getPatientRelByPhone(phone) {
  return request.get(`/clinic/patient/getPatientRelByPhone/${phone}`);
}

export function updateDeviceName(params) {
  return request.post(`/clinic/clinic/device/rel/updateDeviceName`, params);
}

export function getClinicStockAlarm(clinicId) {
  return request.get(`/clinic/clinic/stock/getClinicStockAlarm/${clinicId}`);
}

export function updatePwd(params) {
  return request.post(`/clinic/user/updatePwd`, params);
}

export function smsUpdatePwd(params) {
  return request.post(`/clinic/user/smsUpdatePwd`, params);
}

export function updatePwdCode() {
  return request.post(`/clinic/user/updatePwdCode`);
}

export function profitShareWechatVerify() {
  return request.get(`/clinic/profitShare/wechat/verify`);
}

export function updatePayee(doctorId) {
  return request.post(`/clinic/profitShare/updatePayee/${doctorId}`);
}

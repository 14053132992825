var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pageInit
        ? [
            _c("search", {
              attrs: { placeholder: "请输入姓名" },
              on: { search: _vm.refreshList },
              model: {
                value: _vm.queryParams.name,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "name", $$v)
                },
                expression: "queryParams.name",
              },
            }),
            _c(
              "dropdown-menu",
              { attrs: { "active-color": "#1989fa" } },
              [
                _c("dropdown-item", {
                  attrs: { options: _vm.dateType.enums },
                  on: { change: _vm.refreshList },
                  model: {
                    value: _vm.queryParams.dateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "dateType", $$v)
                    },
                    expression: "queryParams.dateType",
                  },
                }),
                _c("dropdown-item", {
                  attrs: { options: _vm.productType.enums },
                  on: { change: _vm.refreshList },
                  model: {
                    value: _vm.queryParams.productType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "productType", $$v)
                    },
                    expression: "queryParams.productType",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _vm.listInfo.length
            ? _c(
                "list",
                {
                  attrs: {
                    finished: !_vm.hasNext,
                    "finished-text": "没有更多了",
                    "immediate-check": false,
                  },
                  on: { load: _vm.loadList },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "team-list" },
                    _vm._l(_vm.listInfo, function (team, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "team-item",
                          on: {
                            click: function ($event) {
                              return _vm.toDetail(team)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "team-content" }, [
                            _c(
                              "div",
                              { staticClass: "team-avatar" },
                              [
                                _c("van-image", {
                                  staticClass: "avatar-image",
                                  attrs: {
                                    fit: "cover",
                                    src: team.avatar || _vm.avatar,
                                    alt: team.name,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "team-details" }, [
                              _c("h3", { staticClass: "team-name" }, [
                                _vm._v(_vm._s(team.name)),
                              ]),
                              ["0", "1"].includes(team.gender)
                                ? _c("p", { staticClass: "team-contact" }, [
                                    _vm._v(_vm._s(_vm.$getGender(team.gender))),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "team-right" }, [
                              _c("h3", { staticClass: "team-sales" }, [
                                _vm._v(" 销量："),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(team.salesQuantity)),
                                ]),
                              ]),
                              _c("p", { staticClass: "team-clinic" }, [
                                _vm._v(" 诊所数："),
                                _c("span", [
                                  _vm._v(_vm._s(team.clinicQuantity)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          !_vm.listInfo.length
            ? _c("empty", { attrs: { description: "暂无成员" } })
            : _vm._e(),
        ],
        1
      ),
      _vm.pageInit && _vm.showAction
        ? _c("Icon", {
            staticClass: "add-icon",
            attrs: { name: "add" },
            on: { click: _vm.toAdd },
          })
        : _vm._e(),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("tabbar", { attrs: { curIndex: 2 } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.notices.length
        ? _c(
            "div",
            { staticClass: "notice-panel" },
            [
              _c(
                "van-notice-bar",
                { attrs: { "left-icon": "volume-o", scrollable: false } },
                [
                  _c(
                    "van-swipe",
                    {
                      staticClass: "notice-swipe",
                      attrs: {
                        vertical: "",
                        autoplay: 5000,
                        touchable: false,
                        "show-indicators": false,
                      },
                    },
                    _vm._l(_vm.notices, function (text, index) {
                      return _c("van-swipe-item", { key: index }, [
                        _vm._v(" " + _vm._s(text) + " "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "ach-header" }, [
        _c("div", [
          _c("p", { staticClass: "ach-title" }, [_vm._v("会员积分")]),
          _c(
            "p",
            { staticClass: "ach-num" },
            [
              _c("countTo", {
                attrs: {
                  separator: "",
                  startVal: 0,
                  endVal: _vm.pointsInfo.memberPoints,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", [
          _c("p", { staticClass: "ach-title" }, [_vm._v("消费积分")]),
          _c(
            "p",
            { staticClass: "ach-num" },
            [
              _c("countTo", {
                attrs: {
                  separator: "",
                  startVal: 0,
                  endVal: _vm.pointsInfo.consumptionPoints,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.listInfo.length == 0 && !_vm.loading
                ? _c("div", {
                    staticStyle: { "text-align": "center", padding: "10vh 0" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "finance-list" },
                _vm._l(_vm.listInfo, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "finance-item" },
                    [
                      _c("div", { staticClass: "finance-content" }, [
                        _c("div", { staticClass: "finance-info" }, [
                          _c("h3", { staticClass: "finance-name" }, [
                            _c(
                              "span",
                              {
                                staticClass: "flow-number",
                                style: {
                                  color:
                                    item.flowType === "-"
                                      ? "#07c160"
                                      : "#fe271b",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.flowType) +
                                    _vm._s(item.points) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "flow-channel" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.userPointsTypeAll.getValueByKey(
                                    item.pointsType
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "finance-tip" }, [
                            _vm._v(_vm._s(item.createTime)),
                          ]),
                        ]),
                        _c("div", { staticClass: "finance-status" }, [
                          _c("p", { staticClass: "view-details" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userPointsFlowChannelAll.getValueByKey(
                                  item.flowChannel
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "product-list" },
        [
          _vm._l(_vm.products, function (product) {
            return _c(
              "div",
              {
                key: product.id,
                staticClass: "product-item",
                on: {
                  click: function ($event) {
                    return _vm.handleItemClick(product)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "product-content" },
                  [
                    _c("van-image", {
                      staticClass: "product-image",
                      attrs: {
                        fit: "cover",
                        src: product.goodsIcon,
                        alt: product.name,
                      },
                    }),
                    _c("div", { staticClass: "product-details" }, [
                      _c("h3", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(product.goodsName)),
                      ]),
                      _c("p", { staticClass: "product-description" }, [
                        _vm._v(_vm._s(product.goodsFunction)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "product-foot",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.changeStep.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "product-price" }, [
                            _vm._v("￥" + _vm._s(product.salePrice)),
                          ]),
                          _c("stepper", {
                            attrs: {
                              theme: "round",
                              "button-size": "22",
                              "disable-input": "",
                              min: "0",
                              max: "99",
                            },
                            model: {
                              value: product.number,
                              callback: function ($$v) {
                                _vm.$set(product, "number", $$v)
                              },
                              expression: "product.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
          !_vm.products.length
            ? _c("empty", {
                attrs: { description: "您还没有绑定设备，请联系业务员操作" },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "submit-bar",
        {
          attrs: { price: _vm.cartTotalPrice, "button-text": "去下单" },
          on: { submit: _vm.onSubmit },
        },
        [
          _c(
            "badge",
            { attrs: { content: _vm.hasCartNum } },
            [
              _c("icon", {
                class: [_vm.hasCartNum ? "active" : "", "cart-icon"],
                attrs: { name: "shopping-cart" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <search v-if="false" v-model="queryParams.deviceNum" placeholder="请输入设备编号" @search="refreshList" />
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="device-list">
          <div class="device-item" v-for="(device, index) in listInfo" :key="index">
            <div class="device-content">
              <div class="device-image">
                <van-image fit="cover" :src="device.goodsIcon" class="device-avatar" />
                <span
                  class="device-status-tag status-normal"
                  v-if="device.stockAlarmLevel === 1 || device.timeAlarmLevel === 1"
                >
                  预警
                </span>
                <span
                  class="device-status-tag status-abnormal"
                  v-if="device.stockAlarmLevel === 2 || device.timeAlarmLevel === 2"
                >
                  预警
                </span>
              </div>
              <div class="device-info">
                <h3 class="device-name">{{ device.goodsName }}</h3>
                <p class="clinic-name">库存：{{ device.stock }}</p>
                <p class="clinic-name">有效期：{{ device.serviceLimitDate }}</p>
              </div>
              <!-- <div class="device-status">
                <p class="view-details">查看详情<icon name="arrow" /></p>
              </div> -->
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>
  </div>
</template>

<script>
import { PullRefresh, List, Image as VanImage, Search } from 'vant';
import services from '@/service';
import { mapGetters } from 'vuex';
import { defaultFlagAll } from '@/enum/index';

export default {
  components: { PullRefresh, List, VanImage, Search },
  data() {
    return {
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        deviceNum: ''
      },
      listInfo: [],
      defaultFlagAll
    };
  },
  computed: {
    ...mapGetters(['clinicId'])
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.clinic.getClinicDeviceStock(this.clinicId);
      this.listInfo = res.data;
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'clinicDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.device-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.device-content {
  display: flex;
  align-items: center;
}

.device-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.device-avatar {
  width: 100%;
  height: auto;
}

.device-info {
  flex: 1;
  line-height: 20px;
}

.device-name {
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.clinic-name {
  color: $gray2;
  font-size: $size5;
  margin-top: 6px;
}

.device-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.device-status-tag {
  position: absolute;
  top: 0;
  right: -30px;
  background-color: #007bff; /* 默认背景色 */
  color: #fff;
  padding: 4px 40px;
  font-size: 12px;
  transform: translateY(0%) rotate(45deg) translateX(10%);
}

.status-normal {
  background-color: $yellow; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-edit" },
    [
      _c(
        "div",
        { staticClass: "info-edit-form" },
        [
          _c(
            "van-form",
            { ref: "formRef" },
            [
              _c("field", {
                attrs: { name: "uploader", label: "头像：" },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c("uploader", {
                          attrs: {
                            maxCount: _vm.maxCount,
                            "after-read": (from) =>
                              _vm.afterReadAvatar(from, "avatar"),
                            readonly: _vm.readonly,
                            deletable: !_vm.readonly,
                          },
                          model: {
                            value: _vm.avatar,
                            callback: function ($$v) {
                              _vm.avatar = $$v
                            },
                            expression: "avatar",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("field", {
                attrs: {
                  name: "手机号",
                  label: "手机号：",
                  placeholder: "请输入手机号",
                  type: "number",
                  maxLength: "11",
                  readonly: _vm.readonly,
                  rules: [{ required: true, message: "请输入手机号" }],
                  required: "",
                },
                model: {
                  value: _vm.formInfo.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "phone", $$v)
                  },
                  expression: "formInfo.phone",
                },
              }),
              _c("field", {
                attrs: {
                  name: "姓名",
                  label: "姓名：",
                  placeholder: "请输入姓名",
                  maxLength: "20",
                  readonly: _vm.readonly,
                  rules: [
                    {
                      message: "请输入正确的姓名",
                      pattern: /^[A-Za-z\u4e00-\u9fa5.]+$/,
                    },
                  ],
                  required: "",
                },
                model: {
                  value: _vm.formInfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "name", $$v)
                  },
                  expression: "formInfo.name",
                },
              }),
              _c("field", {
                attrs: { name: "radio", label: "性别：", required: "" },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c(
                          "radio-group",
                          {
                            attrs: {
                              direction: "horizontal",
                              disabled: _vm.readonly,
                            },
                            model: {
                              value: _vm.formInfo.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInfo, "gender", $$v)
                              },
                              expression: "formInfo.gender",
                            },
                          },
                          _vm._l(_vm.genderType.enums, function (item, index) {
                            return _c(
                              "radio",
                              { key: index, attrs: { name: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("field", {
                attrs: {
                  name: "身份证号码",
                  label: "身份证号码：",
                  type: "idcard",
                  maxLength: "18",
                  placeholder: "请输入身份证号码",
                  readonly: _vm.readonly,
                  rules: [{ required: true, message: "请输入手机号" }],
                  required: "",
                },
                model: {
                  value: _vm.formInfo.idCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "idCard", $$v)
                  },
                  expression: "formInfo.idCard",
                },
              }),
              _c("field", {
                attrs: {
                  name: "所在区域",
                  label: "所在区域：",
                  placeholder: "请选择所在区域",
                  clickable: "",
                  value: _vm.area,
                  readonly: "",
                  rules: [{ required: true, message: "请选择所在区域" }],
                  required: "",
                },
                on: {
                  click: function ($event) {
                    _vm.showArea = true
                  },
                },
              }),
              !_vm.readonly
                ? _c(
                    "popup",
                    {
                      attrs: { position: "bottom" },
                      model: {
                        value: _vm.showArea,
                        callback: function ($$v) {
                          _vm.showArea = $$v
                        },
                        expression: "showArea",
                      },
                    },
                    [
                      _c("Area", {
                        attrs: {
                          value: _vm.areaValue,
                          "area-list": _vm.areaList,
                        },
                        on: {
                          confirm: _vm.onConfirm,
                          cancel: function ($event) {
                            _vm.showArea = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("field", {
                attrs: {
                  name: "详细地址",
                  label: "详细地址：",
                  placeholder: "请输入详细地址",
                  rules: [{ required: true, message: "请输入详细地址" }],
                  readonly: _vm.readonly,
                  required: "",
                },
                model: {
                  value: _vm.formInfo.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "address", $$v)
                  },
                  expression: "formInfo.address",
                },
              }),
              _vm._l(_vm.imageList, function (item, index) {
                return _c("field", {
                  key: index,
                  attrs: {
                    name: "uploader",
                    label: item.label + "：",
                    rules: [{ required: true, message: "请上传" + item.label }],
                    required: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "input",
                        fn: function () {
                          return [
                            _c("uploader", {
                              attrs: {
                                maxCount: _vm.maxCount,
                                "after-read": (from) =>
                                  _vm.afterRead(from, item),
                                readonly: _vm.readonly,
                                deletable: !_vm.readonly,
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "100px" } }),
      !_vm.readonly
        ? _c("fixedButton", {
            attrs: { text: "保存" },
            on: { emitEvent: _vm.submit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBack
    ? _c(
        "div",
        {
          staticClass: "nab-bar-warp",
          style: { width: _vm.$store.getters.screenWidth + "px" },
        },
        [
          _c("nav-bar", {
            attrs: {
              title: _vm.title,
              "left-arrow": _vm.showBack,
              fixed: "",
              placeholder: "",
            },
            on: { "click-left": _vm.onClickLeft },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
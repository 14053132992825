<template>
  <reportDetail />
</template>

<script>
import reportDetail from '@/components/reportDetail';

export default {
  components: { reportDetail },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

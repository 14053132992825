<template>
  <tabbar :curIndex="curIndex" :tabbarList="tabbarList" />
</template>

<script>
import tabbar from '@/components/tabbar';

export default {
  components: { tabbar },
  props: {
    curIndex: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      tabbarList: [
        {
          name: '首页',
          icon: 'sy',
          link: '/partner-home'
        },
        {
          name: '业绩分析',
          icon: 'yjfx',
          link: '/partner-clinic-performance'
        },
        // {
        //   name: '绑定设备',
        //   icon: 'bdsb',
        //   link: '/partner-bind-device'
        // },
        {
          name: '业务员',
          icon: 'wdtd',
          link: '/partner-my-team'
        },
        {
          name: '我的',
          icon: 'wd',
          link: '/partner-personal'
        }
      ]
    };
  }
};
</script>

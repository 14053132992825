var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mallSearch"),
      _c(
        "div",
        { staticClass: "category-page" },
        [
          _c(
            "div",
            { staticClass: "category-sidebar" },
            _vm._l(_vm.categories, function (category, index) {
              return _c(
                "div",
                {
                  key: category.drugsCategoryId,
                  staticClass: "category-item",
                  class: {
                    active: _vm.activeCategoryId === category.drugsCategoryId,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.scrollToCategory(
                        category.drugsCategoryId,
                        index
                      )
                    },
                  },
                },
                [_vm._v(" " + _vm._s(category.name) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              ref: "scroll",
              staticClass: "product-list",
              on: { scroll: _vm.loadMore },
            },
            [
              _c(
                "div",
                { staticClass: "two-categories-warp" },
                _vm._l(_vm.twoCategories, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.drugsCategoryId,
                      class: [
                        _vm.activeTwoCategoryId === item.drugsCategoryId
                          ? "active"
                          : "",
                        "two-item",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.changeTwoCategory(item.drugsCategoryId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "product-category-section" },
                [
                  _c(
                    "pull-refresh",
                    {
                      on: { refresh: _vm.refreshList },
                      model: {
                        value: _vm.pullLoading,
                        callback: function ($$v) {
                          _vm.pullLoading = $$v
                        },
                        expression: "pullLoading",
                      },
                    },
                    _vm._l(_vm.listInfo, function (product) {
                      return _c(
                        "div",
                        {
                          key: product.id,
                          staticClass: "product-item",
                          on: {
                            click: function ($event) {
                              return _vm.toDeatil(product.drugsId)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "product-img" },
                            [
                              _c("van-image", {
                                attrs: {
                                  src: product.drugsImage.split(",")[0],
                                  fit: "cover",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "product-info" }, [
                            _c("p", { staticClass: "product-name" }, [
                              _vm._v(_vm._s(product.name)),
                            ]),
                            _c(
                              "p",
                              { staticClass: "product-points" },
                              [
                                _c("pointPrice", {
                                  attrs: { product: product },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "add-to-cart",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addToCart(product)
                                },
                              },
                            },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: "add",
                                  size: "22",
                                  color: "#00bdd4",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm.listInfo.length && !_vm.hasNext
                ? _c("divider", [_vm._v("没有更多了")])
                : _vm._e(),
              !_vm.listInfo.length
                ? _c("empty", { attrs: { description: "暂无数据" } })
                : _vm._e(),
            ],
            1
          ),
          _c("tabbar", { attrs: { curIndex: 1 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
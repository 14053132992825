import services from '@/service';

export default {
  state: {
    adminId: '',
    adminInfo: {}
  },
  getters: {
    adminId: state => state.adminId,
    adminInfo: state => state.adminInfo
  },
  actions: {
    set_adminId({ commit }, value) {
      commit('set_adminId', value);
    },
    async set_adminInfo({ commit }) {
      const res = await services.admin.getAdminInfo();
      commit('set_adminInfo', res);
    }
  },
  mutations: {
    set_adminId(state, value) {
      state.adminId = value;
    },
    set_adminInfo(state, value) {
      state.adminInfo = value;
      state.adminId = value.user.userId;
    }
  }
};

<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list
        v-if="listInfo.length"
        v-model="loading"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="address-list">
          <div class="address-item" v-for="(item, index) in listInfo" :key="index">
            <div class="address-content" @click="selectAddr(item)">
              <div class="address-info">
                <h3 class="address-name">
                  <span>{{ item.addressee }}</span>
                  <span class="address-phone">{{ item.phone }}</span>
                  <tag round type="primary" v-if="item.defFlag === '1'">默认</tag>
                </h3>
                <p class="address-area">{{ showArea(item) }}</p>
              </div>
              <div class="address-status">
                <p class="view-details">
                  <icon name="edit" @click.stop="toDetail(item.addressId)" />
                  <icon name="delete-o" @click.stop="delAddress(item.addressId, index)" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </list>

      <empty v-if="!listInfo.length" description="请添加地址" />
      <div style="height: 100px" />
    </pull-refresh>
    <fixedButton text="新建地址" @emitEvent="toDetail" />
  </div>
</template>

<script>
import { PullRefresh, List, Icon, Empty, Tag } from 'vant';
import services from '@/service';
import { mapGetters } from 'vuex';
import { defaultFlagAll } from '@/enum/index';
import fixedButton from '@/components/fixedButton';

export default {
  name: 'mallMyAddress',
  components: { PullRefresh, List, Icon, fixedButton, Empty, Tag },
  data() {
    return {
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      defaultFlagAll
    };
  },
  computed: {
    ...mapGetters(['clinicId']),
    isSelect() {
      return this.$route.query.isSelect;
    }
  },
  methods: {
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.mall.getAddressList({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDetail(id) {
      this.$router.push({
        name: 'mallAddressEdit',
        query: {
          id
        }
      });
    },
    delAddress(id, index) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '请确认是否删除该地址？'
        })
        .then(async () => {
          await services.mall.deleteAddress(id);
          this.$toast('删除成功');
          this.listInfo.splice(index, 1);
        });
    },
    selectAddr(item) {
      if (!this.isSelect) return;
      this.$store.dispatch('set_addressInfo', item);
      this.$router.back();
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.address-content {
  display: flex;
  align-items: center;
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.view-details {
  font-size: $size1;
  color: $appcolor;
  i {
    margin-left: 10px;
  }
}
</style>

<template>
  <div>
    <div class="partner-info">
      <div class="ach-panel">
        <div class="ach-warp">
          <div class="personal-info">
            <van-image fit="cover" :src="clinicInfo.frontPhoto || avatar" class="avatar" />
            <div class="personal-right">
              <p class="personal-name">{{ clinicInfo.clinicName }}</p>
              <p class="personal-tel">
                {{ clinicInfo.phone }}
              </p>
            </div>
          </div>
          <div class="ach-content">
            <div>
              <p class="ach-title">当天</p>
              <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.todaySalseAmount" /></p>
            </div>
            <div>
              <p class="ach-title">当月</p>
              <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cmSalseAmount" /></p>
            </div>
            <div>
              <p class="ach-title">上月</p>
              <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.lmSalseAmount" /></p>
            </div>
          </div>
        </div>
      </div>

      <div class="personal-func-list">
        <cell v-for="(item, index) in funcList" :key="index" is-link @click="toLink(item.link)">
          <template #title>
            <span :class="['iconfont', `${item.icon}`]"></span>
            <span class="custom-title">{{ item.label }}</span>
          </template>
          <template #right-icon>
            <div class="right">
              <icon name="arrow" />
            </div>
          </template>
        </cell>
      </div>
    </div>

    <opinion v-show="opinionModel.show" :show.sync="opinionModel.show" :info="opinionModel.info" />
    <qrcode v-if="qrcodeModel.show" :show.sync="qrcodeModel.show" :info="qrcodeModel.info" />
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import opinion from '@/components/opinion';
import qrcode from '@/components/qrcode';
import services from '@/service';

import { Cell, Icon, Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import { openLocation, inviteUrl } from '@/lib/utils';

export default {
  name: 'partnerClinicInfo',
  components: { countTo, opinion, qrcode, Cell, Icon, VanImage },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      opinionModel: {
        show: false,
        info: {}
      },
      qrcodeModel: {
        show: false,
        info: {}
      },
      pullLoading: false,
      scoreInfo: {
        lmSalseAmount: 0,
        cmSalseAmount: 0,
        todaySalseAmount: 0
      },
      searchVal: '',

      clinicInfo: {}
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters(['partnerInfo', 'partnerId']),
    curId() {
      return this.$route.query.id;
    },
    funcList() {
      return [
        {
          label: '诊所信息',
          icon: 'icon-jbxx',
          link: '/partner-clinic-info-edit?id=' + this.curId
        },
        {
          label: '诊所订单',
          icon: 'icon-wddd',
          link: '/partner-clinic-order?id=' + this.curId
        },
        {
          label: '诊所设备',
          icon: 'icon-wdsb',
          link: '/partner-device-list?clinicId=' + this.curId
        },
        {
          label: '诊所业务员',
          icon: 'icon-wdywy',
          link: '/partner-my-partner?id=' + this.curId
        },
        {
          label: '诊所位置',
          icon: 'icon-dw',
          link: 'openLocation'
        },
        {
          label: '诊所库存',
          icon: 'icon-kcgl',
          link: '/partner-clinic-inventory?clinicId=' + this.curId
        },
        {
          label: '绑定设备',
          icon: 'icon-wdsb',
          link: '/partner-bind-device?clinicId=' + this.curId
        }
      ];
    }
  },
  methods: {
    async refresh() {
      await Promise.all([this.getClinicInfo(), this.getClinicScore()]);
    },
    async getClinicInfo() {
      const res = await services.partner.getClinicInfo(this.curId);
      this.clinicInfo = { ...res.data };
    },
    async getClinicScore() {
      const res = await services.partner.getClinicScore(this.curId);
      this.scoreInfo = res.data;
    },
    toLink(path) {
      if (path === 'opinion') {
        this.opinionModel.show = true;
      } else if (path === 'qrcode') {
        this.qrcodeModel.show = true;
        this.qrcodeModel.info = {
          code: inviteUrl('partnerInviteEdit', this.partnerId),
          text: `${process.env.VUE_APP_TITLE} - ${this.partnerInfo.sales.name}`
        };
      } else if (path === 'openLocation') {
        const { lat: latitude, lng: longitude, clinicName: name } = this.clinicInfo;
        const address = `${this.clinicInfo.province} ${this.clinicInfo.city} ${this.clinicInfo.district} ${this.clinicInfo.address}`;
        openLocation({ latitude, longitude, name, address });
      } else {
        path && this.$router.push(path);
      }
    },
    dialPhoneNumber(phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
.partner-info {
  .line-title {
    margin: $tb $lr 0px;
  }
  // padding: $tb $lr;
  .ach-panel {
    .ach-warp {
      margin: $tb $lr;
      box-shadow: $box-shadow1;
      border-radius: $radius;
      overflow: hidden;
      background: #fff;
      color: #fff;

      .personal-info {
        // text-align: center;
        background: $btnbg;
        padding: $tb $lr;
        line-height: 30px;
        display: flex;

        .avatar {
          border-radius: 50%;
          width: 70px;
          height: 70px !important;
          border: 2px solid #fff;
          margin-right: $lr;
          overflow: hidden;
        }

        .personal-right {
          flex: 1;
          .personal-name {
            font-size: $size4;
            font-weight: 600;
            line-height: 22px;
            margin-top: 10px;
          }
          .personal-tel {
            font-size: $size4;
          }
        }
      }

      .ach-content {
        display: flex;
        justify-content: space-around;
        padding: $tb $lr;
        > div {
          flex: 1;
          text-align: center;
          border-right: 2px solid $bordercolor;
          &:last-child {
            border-right: none;
          }
        }
        .ach-title {
          color: $gray2;
          font-size: $size6;
        }
        .ach-num {
          font-size: 16px;
          padding-top: $tb;
          text-align: center;
          color: $black1;
          font-size: $size5;
        }
      }
      // > div {
      //   text-align: center;
      //   .ach-title {
      //     font-size: $size4;
      //     color: $gray2;
      //     margin-bottom: $tb;
      //     font-weight: 600;
      //   }
      //   .ach-num {
      //     font-size: 24px;
      //     font-weight: 600;
      //     color: $black1;
      //   }
      // }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.listInfo.length == 0 && !_vm.loading
                ? _c("div", {
                    staticStyle: { "text-align": "center", padding: "10vh 0" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "device-list" },
                _vm._l(_vm.listInfo, function (device, index) {
                  return _c("div", { key: index, staticClass: "device-item" }, [
                    _c("div", { staticClass: "device-content" }, [
                      _c(
                        "div",
                        { staticClass: "device-image" },
                        [
                          _c("van-image", {
                            staticClass: "device-avatar",
                            attrs: {
                              fit: "cover",
                              src: device.goodsIcon,
                              alt: device.goodsName,
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "device-status-tag",
                              class: _vm.getStatusClass(device.status),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.defaultFlagAll.getValueByKey(
                                      device.status
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "device-info" }, [
                        _c("h3", { staticClass: "device-name" }, [
                          _vm._v(_vm._s(device.deviceNum)),
                        ]),
                        _c("p", { staticClass: "clinic-name" }, [
                          _vm._v(_vm._s(device.goodsName)),
                        ]),
                        _c("p", { staticClass: "clinic-name" }, [
                          _vm._v(_vm._s(device.clinicName || "未绑定")),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "report-info report-foot" }, [
                      _c("span", { staticClass: "patient-name" }, [
                        _vm._v(
                          _vm._s(_vm.salesList.getValueByKey(device.salesId))
                        ),
                      ]),
                      _c("span", { staticClass: "report-time" }, [
                        _vm.showAction
                          ? _c(
                              "span",
                              {
                                staticClass: "foot-btn trajectory-btn",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.fieldPrompShow(device)
                                  },
                                },
                              },
                              [_vm._v("调仓")]
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "foot-btn detail-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.detail(device)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm.fieldPromptModel.show
        ? _c("fieldPrompt", {
            attrs: {
              title: "调仓设备",
              show: _vm.fieldPromptModel.show,
              fieldForm: _vm.fieldPromptModel.fieldForm,
              info: _vm.fieldPromptModel.info,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.fieldPromptModel, "show", $event)
              },
              emit: _vm.fieldPromptEmit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-warp", on: { click: _vm.toSearch } },
    [
      _c(
        "div",
        [
          _c("van-icon", {
            staticStyle: { "margin-right": "4px" },
            attrs: { name: "search", size: "20" },
          }),
          _vm._v(" 输入关键词搜索"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
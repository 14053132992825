const clinicSignPdf = () => import(/* webpackChunkName: "clinic" */ '@/views/public/viewPdf');

const clinicLogin = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/login');
const clinicHome = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/home');
const clinicInfoEdit = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/infoEdit');
const clinicInfoEditPage = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/infoEditPage');
const clinicAddDoctor = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/addDoctor');
const clinicMyDoctor = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/myDoctor');
const clinicMyDevice = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/myDevice');
const clinicMyAddress = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/myAddress');
const clinicMyPartner = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/myPartner');
const clinicAddressEdit = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/addressEdit');
const clinicDeviceDetail = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/deviceDetail');
const clinicMyOrder = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/myOrder');
const clinicDetection = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/detection');
const clinicDetectionManagement = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/detectionManagement');
const clinicBuy = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/buy');
const clinicOrderConfirm = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/orderConfirm');
const clinicOrderDetail = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/orderDetail');
const clinicMealDetail = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/mealDetail');
const clinicPersonal = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/personal');
const clinicReportDetail = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/reportDetail');
const clinicIntention = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/intention');
const clinicInventory = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/inventory');
const clinicSetPassword = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/setPassword');
const clinicSetPayee = () => import(/* webpackChunkName: "clinic" */ '@/views/clinic/setPayee');

export default [
  {
    path: '/clinic-login',
    name: 'clinicLogin',
    component: clinicLogin,
    meta: {
      title: '登录',
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/clinic-home',
    name: 'clinicHome',
    component: clinicHome,
    meta: {
      title: '首页',
      hideBack: true
    }
  },
  {
    path: '/clinic-info-edit',
    name: 'clinicInfoEdit',
    component: clinicInfoEdit,
    meta: {
      title: '我的账号'
    }
  },
  {
    path: '/clinic-info-edit-page',
    name: 'clinicInfoEditPage',
    component: clinicInfoEditPage,
    meta: {
      title: '信息修改'
    }
  },
  {
    path: '/clinic-add-doctor',
    name: 'clinicAddDoctor',
    component: clinicAddDoctor,
    meta: {
      title: '添加人员'
    }
  },
  {
    path: '/clinic-my-doctor',
    name: 'clinicMyDoctor',
    component: clinicMyDoctor,
    meta: {
      title: '我的企业'
    }
  },
  {
    path: '/clinic-my-device',
    name: 'clinicMyDevice',
    component: clinicMyDevice,
    meta: {
      title: '我的设备'
    }
  },
  {
    path: '/clinic-my-address',
    name: 'clinicMyAddress',
    component: clinicMyAddress,
    meta: {
      title: '我的收货地址',
      keeps: ['clinicOrderConfirm']
    }
  },
  {
    path: '/clinic-my-partner',
    name: 'clinicMyPartner',
    component: clinicMyPartner,
    meta: {
      title: '我的业务员'
    }
  },
  {
    path: '/clinic-address-edit',
    name: 'clinicAddressEdit',
    component: clinicAddressEdit,
    meta: {
      title: '编辑地址'
    }
  },
  {
    path: '/clinic-device-detail',
    name: 'clinicDeviceDetail',
    component: clinicDeviceDetail,
    meta: {
      title: '设备详情'
    }
  },
  {
    path: '/clinic-my-order',
    name: 'clinicMyOrder',
    component: clinicMyOrder,
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/clinic-detection',
    name: 'clinicDetection',
    component: clinicDetection,
    meta: {
      title: '申请检测'
    }
  },
  {
    path: '/clinic-detection-management',
    name: 'clinicDetectionManagement',
    component: clinicDetectionManagement,
    meta: {
      title: '检测管理',
      hideBack: true
      // keeps: ['clinicReportDetail']
    }
  },
  {
    path: '/clinic-buy',
    name: 'clinicBuy',
    component: clinicBuy,
    meta: {
      title: '我要采购'
    }
  },
  {
    path: '/clinic-order-confirm',
    name: 'clinicOrderConfirm',
    component: clinicOrderConfirm,
    meta: {
      title: '确认订单',
      keeps: ['clinicMyAddress']
    }
  },
  {
    path: '/clinic-order-detail',
    name: 'clinicOrderDetail',
    component: clinicOrderDetail,
    meta: {
      title: '订单详情',
      keeps: ['clinicMyAddress']
    }
  },
  {
    path: '/clinic-meal-detail',
    name: 'clinicMealDetail',
    component: clinicMealDetail,
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/clinic-personal',
    name: 'clinicPersonal',
    component: clinicPersonal,
    meta: {
      title: '我的',
      hideBack: true
    }
  },
  {
    path: '/clinic-report-detail',
    name: 'clinicReportDetail',
    component: clinicReportDetail,
    meta: {
      title: '报告详情'
    }
  },
  {
    path: '/clinic-sign-pdf',
    name: 'clinicSignPdf',
    component: clinicSignPdf,
    meta: {
      title: '查看签章'
    }
  },
  {
    path: '/clinic-inventory',
    name: 'clinicInventory',
    component: clinicInventory,
    meta: {
      title: '我的库存'
    }
  },
  {
    path: '/clinic-set-password',
    name: 'clinicSetPassword',
    component: clinicSetPassword,
    meta: {
      title: '密码设置'
    }
  },
  {
    path: '/clinic-set-payee',
    name: 'clinicSetPayee',
    component: clinicSetPayee,
    meta: {
      title: '收款人设置'
    }
  },
  {
    path: '/clinic-intention',
    name: 'clinicIntention',
    component: clinicIntention,
    meta: {
      title: '意向登记',
      hideBack: true,
      noAuth: true
    }
  }
];

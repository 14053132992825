var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _vm.listInfo.length
            ? _c(
                "list",
                {
                  attrs: {
                    finished: !_vm.hasNext,
                    "finished-text": "没有更多了",
                    "immediate-check": false,
                  },
                  on: { load: _vm.loadList },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _vm.listInfo.length == 0 && !_vm.loading
                    ? _c("div", {
                        staticStyle: {
                          "text-align": "center",
                          padding: "10vh 0",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "address-list" },
                    _vm._l(_vm.listInfo, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "address-item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "address-content",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAddr(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "address-info" }, [
                                _c(
                                  "h3",
                                  { staticClass: "address-name" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.addressee)),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "address-phone" },
                                      [_vm._v(_vm._s(item.phone))]
                                    ),
                                    item.defFlag === "1"
                                      ? _c(
                                          "tag",
                                          {
                                            attrs: {
                                              round: "",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("默认")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "address-area" }, [
                                  _vm._v(_vm._s(_vm.showArea(item))),
                                ]),
                              ]),
                              _c("div", { staticClass: "address-status" }, [
                                _c(
                                  "p",
                                  { staticClass: "view-details" },
                                  [
                                    _c("icon", {
                                      attrs: { name: "edit" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toDetail(item.addressId)
                                        },
                                      },
                                    }),
                                    _c("icon", {
                                      attrs: { name: "delete-o" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delAddress(
                                            item.addressId,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          !_vm.listInfo.length
            ? _c("empty", { attrs: { description: "请添加地址" } })
            : _vm._e(),
          _c("div", { staticStyle: { height: "100px" } }),
        ],
        1
      ),
      _c("fixedButton", {
        attrs: { text: "新建地址" },
        on: { emitEvent: _vm.toDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
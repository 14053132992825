import { isDev } from '@/global';

export default {
  state: {
    system: 'iOS',
    isExportMode: false,
    screenWidth: ''
  },
  getters: {
    system: state => state.system,
    screenWidth: state => state.screenWidth,
    hidePay: (state, getters) => {
      if (isDev) {
        return false;
      }
      return getters.isApproved && (getters.system || 'iOS').includes('iOS');
    },
    isExportMode: state => state.isExportMode
  },
  actions: {
    initSystemInfo({ commit }, system) {
      commit('setSystemInfo', system);
    },
    setExportMode({ state }) {
      state.isExportMode = true;
    },
    set_screenWidth({ commit }) {
      const app = document.getElementById('app');
      commit('set_screenWidth', app.offsetWidth);
    }
  },
  mutations: {
    setSystemInfo(state, system) {
      state.system = system;
    },
    set_screenWidth(state, value) {
      state.screenWidth = value;
    }
  }
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "partner-info" }, [
    _c("div", { staticClass: "ach-panel" }, [
      _c("div", { staticClass: "ach-warp" }, [
        _c(
          "div",
          { staticClass: "personal-info" },
          [
            _c("van-image", {
              staticClass: "avatar",
              attrs: {
                fit: "cover",
                src: _vm.partnerInfo.avatar || _vm.avatar,
                alt: _vm.partnerInfo.name,
              },
            }),
            _c("div", { staticClass: "personal-right" }, [
              _c("p", { staticClass: "personal-name" }, [
                _vm._v(_vm._s(_vm.partnerInfo.name)),
              ]),
              _c("p", { staticClass: "personal-tel" }, [
                _vm._v(
                  " " + _vm._s(_vm.$getGender(_vm.partnerInfo.gender)) + " "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "ach-content" }, [
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("诊所数")]),
            _c("p", { staticClass: "ach-num" }, [
              _vm._v(_vm._s(_vm.scoreInfo.clinicAmount)),
            ]),
          ]),
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("设备数")]),
            _c("p", { staticClass: "ach-num" }, [
              _vm._v(_vm._s(_vm.scoreInfo.machineAmount)),
            ]),
          ]),
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("打卡数")]),
            _c("p", { staticClass: "ach-num" }, [
              _vm._v(_vm._s(_vm.scoreInfo.signinAmount)),
            ]),
          ]),
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("上月销量")]),
            _c("p", { staticClass: "ach-num" }, [
              _vm._v(_vm._s(_vm.scoreInfo.lmSalseAmount)),
            ]),
          ]),
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("本月销量")]),
            _c("p", { staticClass: "ach-num" }, [
              _vm._v(_vm._s(_vm.scoreInfo.salseAmount)),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "personal-func-list" },
      _vm._l(_vm.funcList, function (item, index) {
        return _c("cell", {
          key: index,
          attrs: { "is-link": "" },
          on: {
            click: function ($event) {
              return _vm.toLink(item.link)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c("span", { class: ["iconfont", `icon-${item.icon}`] }),
                    _c("span", { staticClass: "custom-title" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "right" },
                      [_c("icon", { attrs: { name: "arrow" } })],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-box" }, [
    _c("div", { staticClass: "report-info report-header" }, [
      _c("div", [
        _c("span", { staticClass: "clinic-name" }, [
          _vm._v(" " + _vm._s(_vm.info.patientName) + " "),
        ]),
        _c("span", { staticStyle: { "margin-left": "8px" } }, [
          _vm.$getGender(_vm.info.gender) === "男"
            ? _c("i", {
                staticClass: "iconfont icon-nan",
                staticStyle: { color: "#10aeff" },
              })
            : _vm._e(),
          _vm.$getGender(_vm.info.gender) === "女"
            ? _c("i", {
                staticClass: "iconfont icon-nv",
                staticStyle: { color: "#fb7373" },
              })
            : _vm._e(),
        ]),
      ]),
      _c("span", { staticClass: "doc-name" }, [
        _vm._v(_vm._s(_vm.info.updateTime)),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "report-info report-project",
        on: {
          click: function ($event) {
            return _vm.toDetail(_vm.info)
          },
        },
      },
      [
        _c("span", [_vm._v(" " + _vm._s(_vm.info.goodsName) + " ")]),
        _c(
          "span",
          { staticClass: "report-read" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.checkOrderState.getValueByKey(_vm.info.status)) +
                " "
            ),
            _vm.hasDetail(_vm.info)
              ? _c("icon", { attrs: { name: "arrow" } })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "report-foot" }, [
      _c("div", { staticClass: "foot-line" }, [
        !_vm.showClinicName
          ? _c("span", { staticClass: "patient-name" }, [
              _vm._v("医生：" + _vm._s(_vm.info.createBy)),
            ])
          : _vm._e(),
        _vm.showClinicName
          ? _c("span", { staticClass: "patient-name" }, [
              _vm._v(_vm._s(_vm.info.clinicName)),
            ])
          : _vm._e(),
        _vm.showDelete && _vm.hasDelete(_vm.info)
          ? _c(
              "span",
              {
                staticClass: "delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.deleteCheckOrder(_vm.info)
                  },
                },
              },
              [_vm._v(" 取消检测 ")]
            )
          : _vm._e(),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "foot-line" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }
export default {
  inserted(el, { value: { closeHandle = () => {}, animationTime = 300 } }) {
    el.style.transform = 'translateY(0px)';
    let dragCloseCount = 160;
    if (el.clientHeight < 400) {
      dragCloseCount = el.clientHeight * 0.33;
    }

    let dragBtn = null;
    const childNodes = [el];
    for (let i = 0; i < childNodes.length; i++) {
      const nodeItem = childNodes[i];
      if ((nodeItem.className || '').includes('drag-btn')) {
        dragBtn = nodeItem;
        break;
      }
      if (nodeItem.childNodes) {
        childNodes.push(...nodeItem.childNodes);
      }
    }

    if (!dragBtn) {
      throw new Error('暂无绑定拖拽按钮');
    }

    let startY = -1;
    let moveY = 0;
    let dragDisabled = false;

    dragBtn.ontouchstart = touchstartHandle;
    dragBtn.ontouchmove = touchmoveHandle;
    dragBtn.ontouchend = touchendHandle;

    function touchstartHandle(e) {
      if (dragDisabled) {
        return;
      }
      startY = e.touches[0].clientY;
    }
    function touchmoveHandle(e) {
      if (startY === -1) {
        return;
      }
      if (e.touches[0].clientY > startY) {
        moveY = e.touches[0].clientY - startY;
      } else {
        moveY = 0;
      }
      el.style.transform = `translateY(${moveY}px)`;
    }
    function touchendHandle() {
      dragDisabled = true;
      if (moveY > dragCloseCount) {
        closeHandle();
        setTimeout(() => {
          dragDisabled = false;
          el.style.transform = 'translateY(0px)';
        }, animationTime);
      } else {
        el.style.transition = 'all 0.2s';
        el.style.transform = 'translateY(0px)';
        setTimeout(() => {
          dragDisabled = false;
          el.style.transition = '';
        }, 200);
      }
      startY = -1;
      moveY = 0;
    }
  }
};

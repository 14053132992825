<template>
  <div>
    <search v-model="queryParams.deviceNum" placeholder="请输入设备编号" @search="refreshList" show-action>
      <template #action>
        <i class="iconfont icon-sm" @click="toScan" />
      </template>
    </search>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list
        v-model="loading"
        v-if="listInfo.length"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <listBox v-for="(item, index) in listInfo" :key="index" :info="item" />
      </list>
      <empty v-if="!listInfo.length" description="暂无数据" />
      <div style="height: 60px;"></div>
    </pull-refresh>

    <Icon name="add" class="add-icon" @click="toAdd" />
  </div>
</template>

<script>
import { PullRefresh, List, Search, Empty, Icon } from 'vant';
import services from '@/service';
import listBox from './components/listBox';
import { clinicListAll } from '@/enumAdmin/index';
import { scanQRCode } from '@/lib/utils';

export default {
  name: 'adminCheckOrder',
  components: { PullRefresh, List, listBox, Search, Empty, Icon },
  data() {
    return {
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        clinicId: '',
        deviceNum: ''
      },
      listInfo: [],
      clinicList: {}
    };
  },
  computed: {
    notSelect() {
      return this.$route.query.clinicId;
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }

    if (this.$route.query.clinicId) {
      this.queryParams.clinicId = this.$route.query.clinicId;
    }

    if (this.$route.query.deviceNum) {
      this.queryParams.deviceNum = this.$route.query.deviceNum;
    }

    const [clinicList] = await Promise.all([clinicListAll()]);
    this.clinicList = clinicList;
    this.refreshList();
  },
  methods: {
    toAdd() {
      this.$router.push({
        name: 'adminDeviceRecordAdd'
      });
    },
    async toScan() {
      const deviceNum = await scanQRCode();
      this.queryParams.deviceNum = deviceNum;
      this.refreshList();
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.admin.deviceRecordList({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    unbind(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定解绑？'
        })
        .then(async () => {
          await services.admin.delClinicDevice(item.clinicDeviceRelId);
          this.$toast('操作成功');
          this.refreshList();
        });
    },
    replace(item) {
      this.$router.push({
        name: 'partnerBindDevice',
        query: {
          clinicId: item.clinicId,
          goodsSettingId: item.goodsSettingId,
          clinicDeviceRelId: item.clinicDeviceRelId
        }
      });
    },
    detail(item) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id: item.clinicDeviceRelId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-sm {
  font-size: 38px;
  position: relative;
  top: 6px;
}

.device-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr 0;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.device-content {
  display: flex;
  align-items: center;
}

.device-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.device-avatar {
  width: 100%;
  height: auto;
}

.device-info {
  flex: 1;
  line-height: 20px;
  margin-bottom: $tb;
}

.device-name {
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.clinic-name {
  color: $gray2;
  font-size: $size4;
}

.device-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.device-status-tag {
  position: absolute;
  top: 0;
  right: -30px;
  background-color: #007bff; /* 默认背景色 */
  color: #fff;
  padding: 4px 40px;
  font-size: 12px;
  transform: translateY(0%) rotate(45deg) translateX(10%);
}

.status-normal {
  background-color: $green; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}

.report-foot {
  border-top: 1px solid $bordercolor;
  font-size: $size6;
  color: $gray2;
  padding: $tb $lr;
  padding-right: 0;
  @include flexCenter(row);
  justify-content: space-between;
  .patient-name {
  }

  .foot-btn {
    margin-left: $lr;
    border-radius: 4px;
    padding: 4px 10px;
  }
  .unbind-btn {
    border: 1px solid $red1;
    color: $red1;
  }

  .replace-btn {
    border: 1px solid $blue1;
    color: $blue1;
  }

  .detail-btn {
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}

.add-icon {
  position: absolute;
  right: 20px;
  bottom: 60px;
  font-size: 50px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
}
</style>

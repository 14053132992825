<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div class="doctor-list">
          <div class="doctor-item" v-for="(doctor, index) in listInfo" :key="index">
            <div class="doctor-content">
              <div class="doctor-avatar">
                <van-image fit="cover" :src="doctor.avatar || avatar" :alt="doctor.name" class="avatar-image" />
              </div>
              <div class="doctor-details">
                <h3 class="doctor-name">
                  {{ doctor.name }}
                  <tag type="primary" v-if="doctor.jobType === '1'">医生</tag>
                  <tag type="warning" v-if="doctor.jobType === '2'">护士</tag>
                </h3>
                <!-- <p class="doctor-contact">{{ doctor.phone }}</p> -->
                <p class="doctor-contact">{{ $getGender(doctor.gender) }}</p>
              </div>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>
    <Icon name="add" class="add-icon" @click="toAdd" v-if="clinicId !== 1" />
  </div>
</template>

<script>
import { PullRefresh, List, Icon, Image as VanImage, Tag } from 'vant';
import services from '@/service';
import { mapGetters } from 'vuex';
import { defaultFlagAll } from '@/enum/index';

export default {
  components: { PullRefresh, List, Icon, VanImage, Tag },
  data() {
    return {
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      defaultFlagAll,
      avatar: this.$getImg('avatar')
    };
  },
  computed: {
    ...mapGetters(['clinicId'])
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.clinic.getDoctorList({ ...this.queryParams, clinicId: this.clinicId });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'clinicDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    toAdd() {
      this.$router.push({
        name: 'clinicAddDoctor'
      });
    }
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.doctor-item {
  border-bottom: 1px solid $bordercolor;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  width: 100%;
}

.doctor-content {
  display: flex;
  align-items: center;
}

.doctor-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: $lr;
}

.avatar-image {
  width: 100%;
  height: auto;
}

.doctor-details {
  flex: 1;
}

.doctor-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.doctor-contact {
  font-size: $size4;
  color: $gray2;
}

.add-icon {
  position: absolute;
  right: 20px;
  bottom: 60px;
  font-size: 50px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
}
</style>

<template>
  <pull-refresh v-model="pullLoading" @refresh="refreshList">
    <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
      <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
      <div class="finance-list">
        <div class="finance-item" v-for="(item, index) in listInfo" :key="index" @click="toDetail(item)">
          <div class="finance-content">
            <div class="finance-info">
              <h3 class="finance-name">{{ item.billName }}</h3>
              <!-- <p class="finance-tip">当月业绩：500</p> -->
              <p class="finance-tip">{{ item.billCode }}</p>
            </div>
            <div class="finance-status">
              <p class="view-details">详情</p>
            </div>
          </div>
        </div>
      </div>
    </list>
  </pull-refresh>
</template>

<script>
import { PullRefresh, List } from 'vant';
import services from '@/service';

export default {
  components: { PullRefresh, List },
  data() {
    return {
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: []
    };
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.partner.getSalesBill({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDetail(item) {
      this.$router.push({
        name: 'partnerFinanceDetail',
        query: {
          billId: item.billId
        }
      });
    }
    // withdrawal(id) {}
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.finance-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.finance-content {
  display: flex;
  align-items: center;
}

.finance-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.finance-avatar {
  width: 100%;
  height: auto;
}

.finance-info {
  flex: 1;
}

.finance-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.finance-tip {
  color: $gray2;
  font-size: $size6;
  margin-top: $tb;

  .finance-amount {
    color: $red1;
    font-size: $size3;
    font-weight: 600;
  }
}

.finance-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clinic-detail" },
    [
      _c(
        "swipe",
        { attrs: { autoplay: 3000 } },
        _vm._l(_vm.images, function (item, index) {
          return _c("swipe-item", { key: index }, [
            _c("img", { staticClass: "slide-image", attrs: { src: item } }),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "clinic-info" }, [
        _c("h3", { staticClass: "clinic-name" }, [
          _vm._v(_vm._s(_vm.clinicName)),
        ]),
        _c(
          "div",
          { staticClass: "info-list" },
          [
            _c(
              "cell-group",
              { attrs: { inset: "" } },
              [
                _c("cell", {
                  attrs: { title: "机构名称", value: _vm.organizationName },
                }),
                _c("cell", {
                  attrs: { title: "法人名称", value: _vm.legalPersonName },
                }),
                _c("cell", {
                  attrs: { title: "营业执照", value: _vm.businessLicense },
                }),
                _c("cell", {
                  attrs: { title: "手机号码", value: _vm.phoneNumber },
                }),
                _c("cell", {
                  attrs: { title: "有效期", value: _vm.expirationDate },
                }),
                _c("cell", {
                  attrs: { title: "机构简介", value: _vm.organizationSummary },
                }),
                _c("cell", {
                  attrs: { title: "执照附件", "is-link": "" },
                  on: { click: _vm.viewLicenseImage },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="address-edit">
      <div class="address-edit-form">
        <field
          v-model="formInfo.addressee"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <field
          v-model="formInfo.phone"
          name="电话"
          label="电话"
          placeholder="请输入电话"
          type="number"
          maxLength="11"
          :rules="[{ required: true, message: '请输入电话' }]"
        />
        <field
          name="地区"
          label="地区"
          placeholder="请选择地区"
          readonly
          clickable
          :value="area"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择地区' }]"
        />
        <popup v-model="showArea" position="bottom">
          <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </popup>
        <field
          v-model="formInfo.address"
          name="详细地址"
          label="详细地址"
          placeholder="请输入详细地址"
          type="textarea"
          maxLength="50"
          :rules="[{ required: true, message: '请输入详细地址' }]"
        />
        <field name="switch" label="是否默认">
          <template #input>
            <van-switch v-model="formInfo.defFlag" size="20" />
          </template>
        </field>
        <!-- <field
        name="有效期至"
        label="有效期至："
        placeholder="请选择有效日期"
        readonly
        clickable
        :value="formInfo.validity"
        @click="showCalendar = true"
        :rules="[{ required: true, message: '请选择有效日期' }]"
      /> -->
        <calendar v-model="showCalendar" @confirm="calendarConfirm" />
      </div>
    </div>
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import { validatePhoneNumber } from '@/lib/utils';
import { Field, Popup, Area, Calendar, Switch as VanSwitch } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';

// import tabbar from '../components/tabbar';

export default {
  components: { Field, Popup, Area, Calendar, fixedButton, VanSwitch },
  data() {
    return {
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      areaSelected: [],
      formInfo: {
        addressee: '',
        phone: '',
        address: '',
        defFlag: false
      },
      frontPhoto: [{ url: '' }],
      licensePhoto: [{ url: '' }],
      maxCount: 1
    };
  },
  mounted() {
    this.getAddresInfo();
  },
  computed: {
    isEdit() {
      return this.$route.query.id;
    }
  },
  methods: {
    async getAddresInfo() {
      if (!this.isEdit) return;
      const res = await services.mall.getAddress(this.isEdit);
      this.formInfo.addressee = res.data.addressee;
      this.formInfo.phone = res.data.phone;
      this.formInfo.defFlag = res.data.defFlag === '1' ? true : false;
      this.formInfo.address = res.data.address;

      this.area = `${res.data.province}/${res.data.city}/${res.data.district}`;

      if (res.data.district) {
        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === res.data.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    calendarConfirm(date) {
      this.formInfo.validity = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    async submit() {
      if (!this.formInfo.addressee) {
        return this.$toast('请输入姓名');
      }
      if (!validatePhoneNumber(this.formInfo.phone)) {
        this.$toast('请输入正确的手机号');
        return;
      }
      if (!this.area) {
        return this.$toast('请选择地区');
      }
      if (!this.formInfo.address) {
        return this.$toast('请输入详细地址');
      }

      const areaList = this.area.split('/');

      const params = {
        ...this.formInfo,
        province: areaList[0],
        city: areaList[1],
        district: areaList[2],
        defFlag: this.formInfo.defFlag ? '1' : '0'
      };
      if (this.isEdit) {
        params.addressId = this.isEdit;
        await services.mall.editAddress(params);
        this.$toast('修改成功');
      } else {
        await services.mall.addAddress(params);
        this.$toast('添加成功');
      }
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cell__title {
    width: 70px !important;
    flex: none;
  }
}
.address-edit {
  padding: $tb $lr;
  .address-edit-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

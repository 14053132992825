<template>
  <tabbar :curIndex="curIndex" :tabbarList="tabbarList" />
</template>

<script>
import tabbar from '@/components/tabbar';

export default {
  components: { tabbar },
  props: {
    curIndex: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      tabbarList: [
        {
          name: '首页',
          icon: 'sy',
          link: '/clinic-home'
        },
        // {
        //   name: '申请检测',
        //   icon: 'jc',
        //   link: '/clinic-detection'
        // },
        {
          name: '检测管理',
          icon: 'jcgl',
          link: '/clinic-detection-management'
        },
        // {
        //   name: '我要采购',
        //   icon: 'cg',
        //   link: '/clinic-buy'
        // },
        {
          name: '我的',
          icon: 'wd',
          link: '/clinic-personal'
        }
      ]
    };
  }
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';

import authriozation from './modules/authriozation';
import loading from './modules/loading';
import system from './modules/system';
import routeAlive from './modules/routeAlive';

import clinic from './modules/clinic';
import patient from './modules/patient';
import partner from './modules/partner';
import admin from './modules/admin';
import mall from './modules/mall';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { authriozation, loading, system, routeAlive, clinic, patient, partner, admin, mall }
});

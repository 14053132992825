<template>
  <div class="personal">
    <div class="personal-bg"></div>
    <div class="personal-header">
      <div class="personal-info" @click="toInfo">
        <van-image fit="cover" :src="patientInfo.avatar || avatar" :alt="patientInfo.nickname" class="avatar" />
        <div class="personal-right">
          <p class="personal-name">{{ !pageInit ? '请登录' : patientInfo.nickname || patientInfo.patient.name }}</p>
          <!-- <p class="personal-tel">{{ !pageInit ? '登录后查看更多' : patientInfo.phone }}</p> -->
          <p class="personal-tel">{{ !pageInit ? '登录后查看更多' : $getGender(patientInfo.patient.gender) }}</p>
        </div>
      </div>
      <!-- <div class="personal-num">
        <div class="personal-num-item">
          <p class="num">125</p>
          <p class="type">我的订单</p>
        </div>
        <div class="personal-num-item">
          <p class="num">2</p>
          <p class="type">我的设备</p>
        </div>
      </div> -->
    </div>
    <div class="personal-func-list">
      <cell v-for="(item, index) in funcList" :key="index" is-link @click="toLink(item.link)">
        <template #title>
          <span :class="['iconfont', `${item.icon}`]"></span>
          <span class="custom-title">{{ item.label }}</span>
        </template>
        <template #right-icon>
          <div class="right">
            <icon name="arrow" />
          </div>
        </template>
      </cell>
    </div>
    <div style="height:200px" />

    <opinion v-show="opinionModel.show" :show.sync="opinionModel.show" :info="opinionModel.info" />
    <tabbar :curIndex="1" />
  </div>
</template>

<script>
import { Cell, Icon, Image as VanImage } from 'vant';

import opinion from '@/components/opinion';
import tabbar from '../components/tabbar';
import { mapGetters } from 'vuex';
import { signOut, notRequest, checkLogin } from '@/lib/utils';

export default {
  components: { opinion, tabbar, Cell, Icon, VanImage },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      info: {},
      opinionModel: {
        show: false,
        info: {}
      },
      funcList: [
        {
          label: '基本信息',
          icon: 'icon-jbxx',
          link: '/patient-info-edit'
        },
        {
          label: '意见反馈',
          icon: 'icon-kf',
          link: 'opinion'
        },
        {
          label: '退出登录',
          icon: 'icon-tc',
          link: 'exit'
        }
      ],
      successImg: 'https://mms2.baidu.com/it/u=2864809941,757158551&fm=253&app=138&f=JPEG&fmt=auto&q=75?w=200&h=200'
    };
  },
  computed: {
    ...mapGetters(['patientInfo']),
    pageInit() {
      return !notRequest();
    }
  },
  mounted() {
    if (!this.pageInit) return;

    this.refresh();
  },
  methods: {
    async refresh() {
      await Promise.all([this.$store.dispatch('set_patientInfo')]);
    },
    async toLink(path) {
      await checkLogin();

      if (path === 'opinion') {
        this.opinionModel.show = true;
      } else if (path === 'exit') {
        signOut();
      } else {
        this.$router.push(path);
      }
    },
    async toInfo() {
      await checkLogin();
      this.$router.push('/patient-info-edit');
    }
  }
};
</script>

<style lang="scss" scoped>
.personal {
  position: relative;
  padding-top: 40px;

  .personal-bg {
    background: $btnbg;
    height: 110px;
    border-radius: 0 0 $radius $radius;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .personal-header {
    margin: 0 $lr;
    background: #fff;
    border-radius: $radius;
    color: $black1;
    position: relative;

    .personal-info {
      // text-align: center;
      padding: $tb $lr;
      line-height: 30px;
      display: flex;

      .avatar {
        border-radius: 50%;
        width: 70px;
        height: 70px !important;
        border: 2px solid #fff;
        margin-right: $lr;
        overflow: hidden;
      }
      .personal-right {
        .personal-name {
          font-size: 20px;
          font-weight: 600;
          margin-top: 10px;
        }
        .personal-tel {
          font-size: $size4;
        }
      }
    }

    .personal-num {
      @include flex(row);
      justify-content: space-around;
      font-size: $size4;
      .personal-num-item {
        text-align: center;
        line-height: 26px;
        .num {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>

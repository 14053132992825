var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal" },
    [
      _c("div", { staticClass: "personal-bg" }),
      _c("div", { staticClass: "personal-header" }, [
        _c(
          "div",
          { staticClass: "personal-info", on: { click: _vm.toInfo } },
          [
            _c("van-image", {
              staticClass: "avatar",
              attrs: {
                fit: "cover",
                src: _vm.patientInfo.avatar || _vm.avatar,
                alt: _vm.patientInfo.nickname,
              },
            }),
            _c("div", { staticClass: "personal-right" }, [
              _c("p", { staticClass: "personal-name" }, [
                _vm._v(
                  _vm._s(
                    !_vm.pageInit
                      ? "请登录"
                      : _vm.patientInfo.nickname || _vm.patientInfo.patient.name
                  )
                ),
              ]),
              _c("p", { staticClass: "personal-tel" }, [
                _vm._v(
                  _vm._s(
                    !_vm.pageInit
                      ? "登录后查看更多"
                      : _vm.$getGender(_vm.patientInfo.patient.gender)
                  )
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "personal-func-list" },
        _vm._l(_vm.funcList, function (item, index) {
          return _c("cell", {
            key: index,
            attrs: { "is-link": "" },
            on: {
              click: function ($event) {
                return _vm.toLink(item.link)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { class: ["iconfont", `${item.icon}`] }),
                      _c("span", { staticClass: "custom-title" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "right-icon",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "right" },
                        [_c("icon", { attrs: { name: "arrow" } })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("div", { staticStyle: { height: "200px" } }),
      _c("opinion", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.opinionModel.show,
            expression: "opinionModel.show",
          },
        ],
        attrs: { show: _vm.opinionModel.show, info: _vm.opinionModel.info },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.opinionModel, "show", $event)
          },
        },
      }),
      _c("tabbar", { attrs: { curIndex: 1 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as common from './common';
import * as patient from './patient';
import * as partner from './partner';
import * as clinic from './clinic';
import * as admin from './admin';
import * as logger from './logger';

export default {
  common,
  patient,
  partner,
  clinic,
  admin,
  logger
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.hasNull
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "10vh 0",
                      },
                    },
                    [_vm._v(" 您不在打卡诊所1公里范围内 ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "clock-list" },
                _vm._l(_vm.listInfo, function (clock, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "clock-item",
                      on: {
                        click: function ($event) {
                          _vm.clinicId = clock.clinicId
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "clock-content" },
                        [
                          _c("checkbox", {
                            attrs: { value: _vm.clinicId === clock.clinicId },
                          }),
                          _c(
                            "div",
                            { staticClass: "clock-image" },
                            [
                              _c("van-image", {
                                staticClass: "clock-avatar",
                                attrs: {
                                  fit: "cover",
                                  src: clock.frontPhoto,
                                  alt: clock.clinicName,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "clock-info" }, [
                            _c("h3", { staticClass: "clock-name" }, [
                              _vm._v(_vm._s(clock.clinicName)),
                            ]),
                            _c("p", { staticClass: "clinic-name" }, [
                              _vm._v(_vm._s(_vm.showArea(clock))),
                            ]),
                          ]),
                          _c("div", { staticClass: "clock-status" }, [
                            _c("p", { staticClass: "view-details" }, [
                              _vm._v(_vm._s(clock.distance) + " m"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm.listInfo.length
        ? _c("fixedButton", {
            attrs: { text: "打卡" },
            on: { emitEvent: _vm.submit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
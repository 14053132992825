import Compressor from 'compressorjs';

function compressImageFile(file, maxSizeMB = 9, initialQuality = 0.9, qualityStep = 0.5) {
  return new Promise((resolve, reject) => {
    function compress(currentFile, quality) {
      new Compressor(currentFile, {
        quality: quality,
        success: compressedFile => {
          if (compressedFile.size / 1024 / 1024 <= maxSizeMB || quality <= qualityStep) {
            // 如果压缩后的文件小于等于目标大小或质量已降到步长限制
            resolve(compressedFile);
          } else {
            // 否则继续递归压缩
            compress(currentFile, quality - qualityStep);
          }
        },
        error: err => {
          reject(err);
        }
      });
    }

    const size = file.size / 1024 / 1024;
    console.log(size);
    if (size <= maxSizeMB) {
      // 如果文件本身小于等于目标大小，不需要压缩
      resolve(file);
    } else {
      // 开始递归压缩
      compress(file, initialQuality);
    }
  });
}

export default compressImageFile;

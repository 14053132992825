var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "partner-info" },
    [
      _c(
        "div",
        { staticClass: "type-warp" },
        _vm._l(_vm.tabs, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              class: [_vm.activeTypes === item.value && "active"],
              on: {
                click: function ($event) {
                  return _vm.changeActive(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.type) + " ")]
          )
        }),
        0
      ),
      _vm.activeTypes === "old"
        ? _c(
            "div",
            { key: "old", staticClass: "input-warp" },
            [
              _c(
                "van-form",
                { ref: "formRef" },
                [
                  _c("field", {
                    ref: "patientPhone",
                    attrs: {
                      name: "旧密码",
                      label: "旧密码：",
                      placeholder: "如未设置过密码，可为空",
                      type: "number",
                      maxLength: "11",
                    },
                    model: {
                      value: _vm.formInfo.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "oldPassword", $$v)
                      },
                      expression: "formInfo.oldPassword",
                    },
                  }),
                  _c("field", {
                    attrs: {
                      name: "新密码",
                      label: "新密码：",
                      placeholder: "请输入新密码",
                      rules: [
                        {
                          required: true,
                          pattern: /^.{6,20}$/,
                          message: "请输入6-20位的密码",
                        },
                      ],
                      required: "",
                    },
                    model: {
                      value: _vm.formInfo.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "newPassword", $$v)
                      },
                      expression: "formInfo.newPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTypes === "sms"
        ? _c(
            "div",
            { key: "sms", staticClass: "input-warp" },
            [
              _c(
                "van-form",
                { ref: "formRef" },
                [
                  _c("field", {
                    attrs: {
                      name: "验证码",
                      label: "验证码：",
                      placeholder: "请输入验证码",
                      rules: [
                        {
                          required: true,
                          pattern: /^.{6,6}$/,
                          message: "请输入完整的验证码",
                        },
                      ],
                      required: "",
                      maxLength: "6",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "sms-tip",
                                  on: { click: _vm.getCode },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.countdown > 0
                                          ? `${_vm.countdown} 秒后重新获取`
                                          : "获取验证码"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      379310764
                    ),
                    model: {
                      value: _vm.formInfo.mobileCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "mobileCode", $$v)
                      },
                      expression: "formInfo.mobileCode",
                    },
                  }),
                  _c("field", {
                    attrs: {
                      name: "新密码",
                      label: "新密码：",
                      placeholder: "请输入新密码",
                      rules: [
                        {
                          required: true,
                          pattern: /^.{6,20}$/,
                          message: "请输入6-20位的密码",
                        },
                      ],
                      maxLength: "20",
                      required: "",
                    },
                    model: {
                      value: _vm.formInfo.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "newPassword", $$v)
                      },
                      expression: "formInfo.newPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("fixedButton", {
        attrs: { text: "保存" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
      <div class="home-page">
        <div class="ach-panel">
          <p class="line-title">业绩</p>
          <div class="ach-warp">
            <div class="ach-header">
              <div>
                <p class="ach-title">当日</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.todaySalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">七日</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cwSalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">本月</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cmSalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">今年</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.cySalseAmount" /></p>
              </div>
            </div>
            <div class="ach-content">
              <div>
                <p class="ach-title">上月</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.lmSalseAmount" /></p>
              </div>
              <div>
                <p class="ach-title">前三月</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.ltmSalseAmount" /></p>
              </div>
              <div @click="toLink('partnerDeviceList')">
                <p class="ach-title">设备数</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.deviceAmount" /></p>
              </div>
              <div @click="toLink('partnerMyClinic')">
                <p class="ach-title">诊所数</p>
                <p class="ach-num"><countTo separator="" :startVal="0" :endVal="scoreInfo.clinicAmount" /></p>
              </div>
            </div>
          </div>
        </div>
        <div class="func-panel" v-if="!isPartnerC">
          <p class="line-title">工具与服务</p>
          <div class="func-warp">
            <div class="func-item" @click="toLink('partnerClock')">
              <i class="iconfont icon-dk2" />
              <span>去打卡</span>
            </div>
            <div class="func-item" @click="toLink('partnerAddClinic')">
              <i class="iconfont icon-zs" />
              <span>添加诊所</span>
            </div>
            <div class="func-item" @click="toLink('partnerBindDevice')">
              <i class="iconfont icon-bdsb" />
              <span>绑定设备</span>
            </div>
            <div class="func-item" @click="toLink('partnerDeviceList')">
              <i class="iconfont icon-jb" />
              <span>解绑设备</span>
            </div>
            <div class="func-item" @click="toLink('partnerMaterialApplication')">
              <i class="iconfont icon-wz" />
              <span>申请设备</span>
            </div>
            <div class="func-item" @click="toLink('partnerClinicOrderList')">
              <i class="iconfont icon-dck" />
              <span>待出库</span>
            </div>
            <div class="func-item" v-if="isPartner" @click="toLink('invite')">
              <i class="iconfont icon-yq" />
              <span>邀请业务员</span>
            </div>
            <div class="func-item" v-if="isPartner" @click="toLink('partnerInfoEditAdd')">
              <i class="iconfont icon-tjywy" />
              <span>添加业务员</span>
            </div>
            <div class="func-item" v-if="false" @click="toLink('partnerBuy')">
              <i class="iconfont icon-cg" />
              <span>我要备货</span>
            </div>
          </div>
        </div>
        <div class="clinic-panel">
          <p class="line-title">前七日诊所排行榜</p>
          <div class="clinic-warp">
            <clinicBox v-for="(item, index) in listInfo" :key="index" :info="item" />
            <empty v-if="!listInfo.length" description="暂无数据" />
          </div>
          <span class="read-more" @click="clinicToMore">更多<icon name="arrow"/></span>
        </div>
        <div class="partner-panel">
          <p class="line-title">前七日个人业绩排行榜</p>
          <div class="horizontal-scroll">
            <div class="partner-warp">
              <div
                class="partner-item"
                v-for="(item, index) in rankSalesList"
                :key="index"
                @click="toPartnerInfo(item.salesId)"
              >
                <div class="partner-name">{{ item.salesName }}</div>
                <div class="partner-salse"><span></span>{{ item.cwSalseAmount }}</div>
                <i class="iconfont icon-yujing" v-if="item.stockWarningFlag === 1" />
              </div>
            </div>
            <empty v-if="!rankSalesList.length" description="暂无数据" />
          </div>
          <span class="read-more" @click="partnerToMore">更多<icon name="arrow"/></span>
        </div>
      </div>
      <div style="height: 100px"></div>
    </pull-refresh>

    <!-- <span class="clock-icon"><i class="iconfont icon-dk" @click="toClock"/></span> -->

    <tabbar :curIndex="0" />
    <qrcode v-if="qrcodeModel.show" :show.sync="qrcodeModel.show" :info="qrcodeModel.info" />
    <overlay :show="showLinkTip" class-name="link-overlay need-hide">
      <div class="overlay-wrap" @click.stop>
        <!-- <img src="" alt="" class="invite-img" /> -->
        <p class="tip">点击右上角 <span class="tip-round">●●●</span> 转发给朋友~</p>
        <button class="clear-btn" round @click="showLinkTip = false">我知道了</button>
      </div>
    </overlay>
  </div>
</template>

<script>
import services from '@/service';
import countTo from 'vue-count-to';
import tabbar from '../components/tabbar';
import { PullRefresh, Icon, Overlay, Empty } from 'vant';
import { mapGetters } from 'vuex';
import clinicBox from '@/components/clinicBox';
import qrcode from '@/components/qrcode';
import { sharePage, inviteUrl, notRequest, checkLogin } from '@/lib/utils';

export default {
  components: { countTo, tabbar, PullRefresh, Icon, clinicBox, qrcode, Overlay, Empty },
  data() {
    return {
      showLinkTip: false,
      qrcodeModel: {
        show: false,
        info: {}
      },
      pullLoading: false,
      scoreInfo: {
        todaySalseAmount: 0,
        cwSalseAmount: 0,
        cmSalseAmount: 0,
        cySalseAmount: 0,
        lmSalseAmount: 0,
        ltmSalseAmount: 0,
        deviceAmount: 0,
        clinicAmount: 0
      },
      searchVal: '',
      listInfo: [],
      rankSalesList: []
    };
  },
  mounted() {
    if (!this.pageInit) return;
    this.refreshList();
  },
  computed: {
    ...mapGetters(['clinicId', 'partnerInfo', 'isPartnerC', 'partnerId', 'isPartner']),
    pageInit() {
      return !notRequest();
    }
  },
  methods: {
    async toLink(name, query = {}) {
      await checkLogin();
      if (name === 'qrcode') {
        this.qrcodeModel.show = true;
        this.qrcodeModel.info = {
          code: `${this.partnerInfo.phone}`,
          text: `${process.env.VUE_APP_TITLE} - ${this.partnerInfo.sales.name}`
        };
      } else if (name === 'invite') {
        this.inviteHandle();
      } else {
        this.$router.push({
          name,
          query
        });
      }
    },
    async inviteHandle() {
      console.log('邀请', this.partnerId);
      await sharePage({
        title: process.env.VUE_APP_TITLE,
        desc: `诚邀您加入${process.env.VUE_APP_TITLE}`,
        link: inviteUrl('partnerInviteEdit', this.partnerId),
        partnerId: this.partnerId
      });
      this.showLinkTip = true;
    },
    refreshList() {
      this.pullLoading = true;
      setTimeout(async () => {
        await Promise.all([this.getPartnerScore(), this.getRankClinic(), this.getRankSales()]);
        this.pullLoading = false;
      }, 1000);
    },
    async getPartnerScore() {
      const res = await services.partner.getPartnerScore();
      this.scoreInfo = res.data;
    },
    async getRankClinic() {
      const res = await services.partner.getRankClinic({ pageSize: 3 });
      this.listInfo = res.data.splice(0, 3);
    },
    async getRankSales() {
      const res = await services.partner.getRankSales();
      this.rankSalesList = res.data;
    },
    async routerPush(options) {
      await checkLogin();
      this.$router.push({
        ...options
      });
    },
    clinicToMore() {
      this.routerPush({
        name: 'partnerMyClinic'
      });
    },
    partnerToMore() {
      this.routerPush({
        name: 'partnerMyTeam',
        query: {
          dateType: 'week'
        }
      });
    },
    toPartnerInfo(id) {
      this.routerPush({
        name: 'partnerPartnerInfo',
        query: {
          id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-empty {
    padding: 0;
    .van-empty__image {
      width: 60px;
      height: 60px;
    }
  }
}
.home-page {
  > div:not(:first-child) {
    margin-top: 30px;
  }
  .line-title {
    font-size: $size4;
    margin: $tb $lr 0px;
    font-weight: 500;
  }
  // padding: $tb $lr;
  .ach-panel {
    .ach-warp {
      margin: $tb $lr;
      box-shadow: $box-shadow1;
      border-radius: $radius;
      overflow: hidden;
      background: #fff;
      color: #fff;

      .ach-header {
        background: $btnbg;
        padding: $tb $lr;
        display: flex;
        justify-content: space-around;
        > div {
          flex: 1;
          text-align: center;
        }
        .ach-title {
          font-size: $size4;
        }
        .ach-num {
          font-size: 26px;
          padding-top: $tb;
        }
      }

      .ach-content {
        display: flex;
        justify-content: space-around;
        padding: $tb $lr;
        > div {
          flex: 1;
          text-align: center;
          border-right: 2px solid $bordercolor;
          &:last-child {
            border-right: none;
          }
        }
        .ach-title {
          color: $gray2;
          font-size: $size5;
        }
        .ach-num {
          font-size: 16px;
          padding-top: $tb;
          text-align: center;
          color: $black1;
        }
      }
      // > div {
      //   text-align: center;
      //   .ach-title {
      //     font-size: $size4;
      //     color: $gray2;
      //     margin-bottom: $tb;
      //     font-weight: 600;
      //   }
      //   .ach-num {
      //     font-size: 24px;
      //     font-weight: 600;
      //     color: $black1;
      //   }
      // }
    }
  }

  .clinic-panel {
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .partner-panel {
    position: relative;

    .horizontal-scroll {
      overflow: auto;
      white-space: nowrap;
    }
    .partner-warp {
      display: flex;
      flex-wrap: nowrap;
      padding: 20px $lr $tb;
      overflow-x: scroll;
      .partner-item {
        // width: 100px;
        flex-shrink: 0; /* 防止内容收缩 */
        background: #fff;

        margin-right: $lr;
        border-radius: $radius;
        box-shadow: $box-shadow1;
        position: relative;
        padding: $tb $lr 0;
        font-size: $size5;

        .partner-name {
          font-size: $size3;
          font-weight: 500;
          color: $gray2;
        }

        .partner-salse {
          font-size: $size1;
          font-weight: 500;
          text-align: center;
        }

        > div {
          margin-bottom: $tb;
          span {
            font-size: $size5;
            color: $gray2;
          }
        }

        i {
          position: absolute;
          right: 4px;
          top: -18px;
          font-size: 20px;

          /* background-color: $btnNone; */
          color: transparent; /* 将iconfont的颜色改为透明 */
          -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
          background-image: -webkit-linear-gradient(#ff6801, #ff2c00);
        }
      }
    }
  }

  .read-more {
    position: absolute;
    right: $lr;
    top: 0px;
    font-size: $size5;
    color: $gray2;
  }
}

.clock-icon {
  position: absolute;
  right: 20px;
  bottom: 100px;
  font-size: 30px;
  padding: 10px;
  color: $white1;
  background: #fff;
  box-shadow: $box-shadow1;
  border-radius: 50%;
  i {
    color: transparent;
    -webkit-background-clip: text;
    background-image: $btnbg;
  }
}
.func-warp {
  margin: $tb $lr 0;
  box-shadow: $box-shadow1;
  border-radius: $radius;
  padding: $tb $lr 0;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  .func-item {
    @include flexCenter(column);
    font-size: $size6;
    color: $gray1;
    flex: 0 0 25%;
    margin-bottom: 20px;
    i {
      font-size: 30px;
      margin-bottom: 6px;
    }
  }
  .func-item i {
    color: transparent;
    -webkit-background-clip: text;
    background-image: $btnbg;
  }
}

.link-overlay {
  z-index: 2020;
  background: rgba(0, 0, 0, 0.8);

  .overlay-wrap {
    z-index: 2030;
    color: #fff;
    text-align: right;
    margin-top: 30px;

    .invite-img {
      width: 50.88px;
      height: 50.88px;
      object-fit: contain;
    }

    .tip {
      margin-right: 23px;
      font-size: 16px;
      line-height: 21px;
      .tip-round {
        font-size: 12px;
        position: relative;
        top: -3px;
      }
    }

    .clear-btn {
      padding: $tb $lr;
      background: $appcolor;
      border-radius: $radius;
      color: #fff;
      margin: 20px 80px;
      border: none;
    }
  }
}
</style>

<template>
  <div>
    <!-- 公告栏 -->
    <div class="notice-panel" v-if="notices.length">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe vertical :autoplay="5000" :touchable="false" :show-indicators="false" class="notice-swipe">
          <van-swipe-item v-for="(text, index) in notices" :key="index">
            {{ text }}
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="ach-header">
      <div>
        <p class="ach-title">会员积分</p>
        <p class="ach-num"><countTo separator="" :startVal="0" :endVal="pointsInfo.memberPoints" /></p>
      </div>
      <div>
        <p class="ach-title">消费积分</p>
        <p class="ach-num"><countTo separator="" :startVal="0" :endVal="pointsInfo.consumptionPoints" /></p>
      </div>
    </div>

    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="finance-list">
          <div class="finance-item" v-for="(item, index) in listInfo" :key="index">
            <div class="finance-content">
              <div class="finance-info">
                <h3 class="finance-name">
                  <span class="flow-number" :style="{ color: item.flowType === '-' ? '#07c160' : '#fe271b' }">
                    {{ item.flowType }}{{ item.points }}
                  </span>
                  <span class="flow-channel">{{ userPointsTypeAll.getValueByKey(item.pointsType) }}</span>
                </h3>
                <!-- <p class="finance-tip">当月业绩：500</p> -->
                <p class="finance-tip">{{ item.createTime }}</p>
              </div>
              <div class="finance-status">
                <p class="view-details">{{ userPointsFlowChannelAll.getValueByKey(item.flowChannel) }}</p>
              </div>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>
  </div>
</template>

<script>
import { Swipe, SwipeItem, NoticeBar, PullRefresh, List } from 'vant';
import services from '@/service';
import countTo from 'vue-count-to';

import { userPointsTypeAll, userPointsFlowChannelAll } from '@/enum/index';

export default {
  components: {
    countTo,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NoticeBar.name]: NoticeBar,
    PullRefresh,
    List
  },
  data() {
    return {
      items: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      userPointsTypeAll,
      userPointsFlowChannelAll,
      notices: [],
      pointsInfo: {
        memberPoints: 0,
        consumptionPoints: 0
      }
    };
  },
  methods: {
    async loadPoint() {
      this.pointsInfo = (await services.mall.userPoints()).data;

      if (this.pointsInfo.expiringPointsDate) {
        this.notices.push(`${this.pointsInfo.expiringPoints} 积分将于 ${this.pointsInfo.expiringPointsDate} 失效`);
      }
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
        this.loadPoint();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.mall.userPointsFlowList({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    }
    // withdrawal(id) {}
  },
  mounted() {
    this.refreshList();
  }
};
</script>

<style lang="scss" scoped>
.notice-panel {
  margin: 16px;
  border-radius: $radius;
  overflow: hidden;
  box-shadow: $box-shadow1;

  ::v-deep .van-notice-bar {
    // padding: 10px 16px;

    .notice-swipe {
      height: 16px;
      line-height: 16px;
    }
  }
}

.ach-header {
  background: $btnbg;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  display: flex;
  justify-content: space-around;
  color: #fff;
  > div {
    flex: 1;
    text-align: center;
  }
  .ach-title {
    font-size: $size4;
  }
  .ach-num {
    font-size: 30px;
    padding-top: $tb;
  }
}

.finance-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.finance-content {
  display: flex;
  align-items: center;
}

.finance-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.finance-avatar {
  width: 100%;
  height: auto;
}

.finance-info {
  flex: 1;
}

.finance-name {
  margin-bottom: $tb;
  font-size: 24px;
  color: $black1;

  .flow-number {
    font-weight: 700;
  }

  .flow-channel {
    font-size: $size7;
    margin-right: $tb;
    color: $gray2;
  }
}

.finance-tip {
  color: $gray2;
  font-size: $size6;
  margin-top: $tb;

  .finance-amount {
    color: $red1;
    font-size: $size3;
    font-weight: 600;
  }
}

.finance-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}
</style>

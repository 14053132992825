var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-edit" },
    [
      _c(
        "div",
        { staticClass: "info-edit-form" },
        [
          _c("field", {
            attrs: { name: "创建时间", label: "创建时间：", readonly: "" },
            model: {
              value: _vm.formInfo.createTime,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "createTime", $$v)
              },
              expression: "formInfo.createTime",
            },
          }),
          _c("field", {
            attrs: {
              name: "手机号码",
              label: "手机号码：",
              type: "number",
              placeholder: "请输入手机号码",
              readonly: "",
              rules: [{ required: true, message: "请输入手机号码" }],
            },
            model: {
              value: _vm.formInfo.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "phone", $$v)
              },
              expression: "formInfo.phone",
            },
          }),
          _c("field", {
            attrs: {
              name: "机构名称",
              label: "机构名称：",
              placeholder: "请输入机构名称",
              readonly: "",
              type: "textarea",
              rules: [{ required: true, message: "请输入机构名称" }],
            },
            model: {
              value: _vm.formInfo.clinicName,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "clinicName", $$v)
              },
              expression: "formInfo.clinicName",
            },
          }),
          _c("field", {
            attrs: {
              name: "法人名称",
              label: "法人名称：",
              placeholder: "请输入法人名称",
              readonly: "",
              rules: [{ required: true, message: "请输入法人名称" }],
            },
            model: {
              value: _vm.formInfo.corporateName,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "corporateName", $$v)
              },
              expression: "formInfo.corporateName",
            },
          }),
          _c("field", {
            attrs: {
              name: "法人身份证",
              label: "法人身份证：",
              placeholder: "请输入法人身份证",
              type: "idcard",
              maxLength: "18",
              readonly: "",
              rules: [{ required: true, message: "请输入法人身份证" }],
            },
            model: {
              value: _vm.formInfo.corporateIdCard,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "corporateIdCard", $$v)
              },
              expression: "formInfo.corporateIdCard",
            },
          }),
          _c("field", {
            attrs: {
              name: "营业执照编号",
              label: "营业执照编号：",
              placeholder: "请输入营业执照编号",
              readonly: "",
              type: "textarea",
              rules: [{ required: true, message: "请输入营业执照编号" }],
            },
            model: {
              value: _vm.formInfo.license,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "license", $$v)
              },
              expression: "formInfo.license",
            },
          }),
          _c("field", {
            attrs: {
              name: "所在区域",
              label: "所在区域：",
              placeholder: "请选择所在区域",
              readonly: "",
              clickable: "",
              value: _vm.area,
              rules: [{ required: true, message: "请选择所在区域" }],
            },
          }),
          _c(
            "popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showArea,
                callback: function ($$v) {
                  _vm.showArea = $$v
                },
                expression: "showArea",
              },
            },
            [
              _c("Area", {
                attrs: { value: _vm.areaValue, "area-list": _vm.areaList },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function ($event) {
                    _vm.showArea = false
                  },
                },
              }),
            ],
            1
          ),
          _c("field", {
            attrs: {
              name: "详细地址",
              label: "详细地址：",
              placeholder: "请输入详细地址",
              readonly: "",
              type: "textarea",
              rules: [{ required: true, message: "请输入详细地址" }],
            },
            model: {
              value: _vm.formInfo.address,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "address", $$v)
              },
              expression: "formInfo.address",
            },
          }),
          _c("field", {
            attrs: {
              name: "有效期至",
              label: "有效期至：",
              placeholder: "请选择有效日期",
              readonly: "",
              clickable: "",
              value: _vm.formInfo.validity,
              rules: [{ required: true, message: "请选择有效日期" }],
            },
          }),
          _c("calendar", {
            on: { confirm: _vm.calendarConfirm },
            model: {
              value: _vm.showCalendar,
              callback: function ($$v) {
                _vm.showCalendar = $$v
              },
              expression: "showCalendar",
            },
          }),
          _c("field", {
            attrs: {
              name: "机构简介",
              label: "机构简介：",
              placeholder: "请输入机构简介",
              type: "textarea",
              maxlength: "500",
              readonly: "",
            },
            model: {
              value: _vm.formInfo.clinicIntro,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "clinicIntro", $$v)
              },
              expression: "formInfo.clinicIntro",
            },
          }),
          _vm._l(_vm.imageList, function (item, index) {
            return _c("field", {
              key: index,
              attrs: { name: "uploader", label: item.label + "：" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c("uploader", {
                          attrs: {
                            maxCount: _vm.maxCount,
                            "after-read": (from) => _vm.afterRead(from, item),
                            readonly: "",
                            deletable: false,
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("div", { staticStyle: { height: "100px" } }),
      _c(
        "van-dialog",
        {
          attrs: { title: "不通过原因", "show-cancel-button": "" },
          on: {
            confirm: function ($event) {
              return _vm.sumbitAudit("2")
            },
          },
          model: {
            value: _vm.auditShow,
            callback: function ($$v) {
              _vm.auditShow = $$v
            },
            expression: "auditShow",
          },
        },
        [
          _c("field", {
            attrs: {
              label: "",
              type: "textarea",
              placeholder: "请输入不通过原因",
            },
            model: {
              value: _vm.auditMes,
              callback: function ($$v) {
                _vm.auditMes = $$v
              },
              expression: "auditMes",
            },
          }),
        ],
        1
      ),
      _vm.formInfo.auditStatus === "0"
        ? _c("fixedBottom", [
            _c("div", { staticClass: "btn-warp" }, [
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.changeAudit("1")
                    },
                  },
                },
                [_vm._v("通过")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn btn2",
                  on: {
                    click: function ($event) {
                      return _vm.changeAudit("2")
                    },
                  },
                },
                [_vm._v("不通过")]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "viewer",
              rawName: "v-viewer",
              value: _vm.viewerOptions,
              expression: "viewerOptions",
            },
          ],
          ref: "viewer",
          staticClass: "dialog-right",
        },
        _vm._l(_vm.imagesList, function (item, index) {
          return _c("img", { key: index, attrs: { "data-src": item } })
        }),
        0
      ),
      _c("Icon", {
        staticClass: "read-icon",
        attrs: { name: "browsing-history" },
        on: { click: _vm.handlePictureCardPreview },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
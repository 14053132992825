var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-edit" },
    [
      _c("icon", { staticClass: "icon", attrs: { name: "checked" } }),
      _c("div", [_vm._v("提交成功，审核通过后将有短信发送至您的手机")]),
      _c("div", { staticStyle: { height: "60px" } }),
      _c("div", { staticClass: "btn", on: { click: _vm.close } }, [
        _vm._v("我知道了"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info-edit">
    <div class="info-edit-form">
      <field
        v-model="formInfo.phone"
        name="手机号码"
        label="手机号码："
        type="number"
        placeholder="请输入手机号码"
        disabled
        :rules="[{ required: true, message: '请输入手机号码' }]"
      />
      <field
        v-model="formInfo.clinicName"
        name="机构名称"
        label="机构名称："
        placeholder="请输入机构名称"
        :rules="[{ required: true, message: '请输入机构名称' }]"
      />
      <field
        v-model="formInfo.corporateName"
        name="法人名称"
        label="法人名称："
        placeholder="请输入法人名称"
        :rules="[{ required: true, message: '请输入法人名称' }]"
      />
      <field
        v-model="formInfo.corporateIdCard"
        name="法人身份证"
        label="法人身份证："
        placeholder="请输入法人身份证"
        type="idcard"
        maxLength="18"
        :rules="[{ required: true, message: '请输入法人身份证' }]"
      />
      <field
        v-model="formInfo.license"
        name="营业执照"
        label="营业执照："
        placeholder="请输入营业执照编号"
        :rules="[{ required: true, message: '请输入营业执照编号' }]"
      />
      <field
        name="所在区域"
        label="所在区域："
        placeholder="请选择所在区域"
        readonly
        clickable
        :value="area"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择所在区域' }]"
      />
      <popup v-model="showArea" position="bottom">
        <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </popup>
      <field
        v-model="formInfo.address"
        name="详细地址"
        label="详细地址："
        placeholder="请输入详细地址"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />
      <field
        name="有效期至"
        label="有效期至："
        placeholder="请选择有效日期"
        readonly
        clickable
        :value="formInfo.validity"
        @click="showCalendar = true"
        :rules="[{ required: true, message: '请选择有效日期' }]"
      />
      <calendar v-model="showCalendar" @confirm="calendarConfirm" />
      <field
        v-model="formInfo.clinicIntro"
        name="机构简介"
        label="机构简介："
        placeholder="请输入机构简介"
        type="textarea"
        maxlength="500"
      />

      <field name="uploader" :label="item.label + '：'" v-for="(item, index) in imageList" :key="index">
        <template #input>
          <uploader :maxCount="maxCount" v-model="item.value" :after-read="from => afterRead(from, item)" />
        </template>
      </field>
    </div>

    <div style="height: 100px" />
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import { Field, Uploader, Popup, Area, Calendar } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';

// import tabbar from '../components/tabbar';

export default {
  components: { Field, Uploader, Popup, Area, Calendar, fixedButton },
  data() {
    return {
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {
        clinicName: '',
        corporateName: '',
        license: '',
        address: '',
        validity: '',
        phone: '',
        clinicIntro: ''
      },
      imageList: [
        {
          label: '门店照片',
          key: 'frontPhoto',
          value: []
        },
        {
          label: '营业执照',
          key: 'businessPhoto',
          value: []
        },
        {
          label: '许可证',
          key: 'licencePhoto',
          value: []
        },
        {
          label: '身份证-国徽面',
          key: 'idCardBadge',
          value: []
        },
        {
          label: '身份证-人像面',
          key: 'idCardFigure',
          value: []
        }
      ],
      maxCount: 1
    };
  },
  mounted() {
    this.getClinicInfo();
  },
  methods: {
    async afterRead(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      item.value = [res.data];
    },
    async getClinicInfo() {
      const res = await services.clinic.getClinicInfo();
      this.formInfo = { ...res.data };

      if (this.formInfo.district) {
        this.area = `${this.formInfo.province}/${this.formInfo.city}/${this.formInfo.district}`;

        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === this.formInfo.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }

      this.imageList.forEach(i => {
        if (res.data[i.key]) {
          i.value = [{ url: res.data[i.key] }];
        }
      });
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    calendarConfirm(date) {
      this.formInfo.validity = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    async submit() {
      const params = {
        clinicId: this.formInfo.clinicId,
        phone: this.formInfo.phone,
        clinicName: this.formInfo.clinicName,
        corporateIdCard: this.formInfo.corporateIdCard,
        corporateName: this.formInfo.corporateName,
        license: this.formInfo.license,
        address: this.formInfo.address,
        clinicIntro: this.formInfo.clinicIntro,
        validity: this.formInfo.validity
      };

      if (this.area) {
        const areaList = this.area.split('/');
        params.province = areaList[0];
        params.city = areaList[1];
        params.district = areaList[2];
      }

      this.imageList.forEach(i => {
        if (i.value.length) {
          params[i.key] = i.value[0].url;
        }
      });

      await services.clinic.editClinicInfo(params);
      this.$toast('保存成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  .info-edit-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

import wx from 'weixin-js-sdk';
import services from '@/service';
import reporter from '@/lib/reporter';
import { clientPlatform } from '@/global';

export default new (class Wechat {
  _isReady = false;
  _errMsg = '';
  _readyCb = null;
  _isRefreshing = false;

  get isReady() {
    return this._isReady;
  }

  get errMsg() {
    return this._errMsg;
  }

  get $wx() {
    return wx;
  }

  get readyCb() {
    if (this._readyCb) {
      return () => {
        this._readyCb();
        this._readyCb = null;
      };
    }
    return () => {};
  }

  async refreshSdk(readyCb = null) {
    if (readyCb) {
      this._readyCb = readyCb;
    }
    if (!this._isRefreshing) {
      await this.refreshWechatSdk();
    }
  }

  async init(readyCb = null) {
    if (this.isReady || this.errMsg) {
      if (this.isReady) {
        readyCb && readyCb();
      }
      return;
    }
    if (readyCb) {
      this._readyCb = readyCb;
    }
    if (!this._isRefreshing) {
      await this.refreshWechatSdk();
    }
  }

  async refreshWechatSdk() {
    try {
      this._isRefreshing = true;
      const wechatInfo = await this.getWechatSignature();

      wx.config({
        debug: false,
        appId: wechatInfo.appId,
        timestamp: wechatInfo.timestamp,
        nonceStr: wechatInfo.nonceStr,
        signature: wechatInfo.signature,
        openTagList: ['wx-open-launch-weapp'],
        jsApiList: [
          'chooseWXPay',
          'previewImage',
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'closeWindow',
          'scanQRCode',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'getLocation',
          'openLocation'
        ]
      });

      wx.ready(() => {
        console.log('wx.config:ready---');
        this._isReady = true;
        this._errMsg = '';
        this.readyCb();
      });

      wx.error(err => {
        const errMsg = (err && (err.errMsg || err.errmsg)) || '微信sdk注册失败';
        console.log('微信sdk注册失败');
        if (errMsg.includes('config:ok')) {
          this._isReady = true;
          this._errMsg = '';
          this.readyCb();
        } else {
          reporter.log({ message: `微信sdk ${errMsg}` });
          this._isReady = false;
          this._errMsg = errMsg;
        }
      });
    } catch (err) {
      reporter.log({ message: err.stack || err.message });
    } finally {
      this._isRefreshing = false;
    }
  }

  async getWechatSignature() {
    try {
      const params = { signUrl: window.location.href, platform: clientPlatform };
      const { data } = await services.common.getWechatSignature(params);
      if (!data || !data.signature) {
        new Error((data && data.message) || '微信sdk注册失败');
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  }
})();

<template>
  <div>
    <div class="product-list">
      <div class="product-item" v-for="product in products" :key="product.id" @click="handleItemClick(product)">
        <div class="product-content">
          <van-image fit="cover" :src="product.goodsIcon" :alt="product.name" class="product-image" />
          <div class="product-details">
            <h3 class="product-name">{{ product.goodsName }}</h3>
            <p class="product-description">{{ product.goodsFunction }}</p>
            <div class="product-foot" @click.stop="changeStep">
              <span class="product-price">￥{{ product.salePrice }}</span>
              <stepper v-model="product.number" theme="round" button-size="22" disable-input min="0" max="99" />
              <!-- <icon v-else name="add" @click="product.num++" class="add-icon" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <submit-bar :price="cartTotalPrice" button-text="去下单" @submit="onSubmit">
      <badge :content="hasCartNum">
        <icon name="shopping-cart" :class="[hasCartNum ? 'active' : '', 'cart-icon']" />
      </badge>
    </submit-bar>

    <!-- <tabbar :curIndex="3" /> -->
  </div>
</template>

<script>
import services from '@/service';
import { Stepper, SubmitBar, Icon, Badge, Image as VanImage } from 'vant';
import { wxAuthTo } from '@/lib/utils';
import { mapGetters } from 'vuex';

export default {
  components: { Stepper, SubmitBar, Icon, Badge, VanImage },
  data() {
    return {
      products: []
    };
  },
  methods: {
    changeStep() {},
    onSubmit() {
      const arr = this.products
        .filter(i => i.number > 0)
        .map(i => {
          return {
            goodsMealId: i.goodsMealId,
            number: i.number
          };
        });
      if (arr.length === 0) {
        this.$toast('请选择商品');
      } else {
        wxAuthTo({
          routerName: 'partnerOrderConfirm',
          query: {
            products: arr
          }
        });
      }
    },
    async requesMealList() {
      this.$store.dispatch('addLoading');
      const res = await services.partner.mealListService();
      this.products = res.rows.map(i => {
        return {
          ...i,
          number: 0
        };
      });
      this.$store.dispatch('subLoading');
    },
    handleItemClick(product) {
      this.$router.push({
        name: 'partnerMealDetail',
        query: { id: product.goodsMealId }
      });
    }
  },
  computed: {
    ...mapGetters(['clinicId']),
    hasCartNum() {
      return this.products.reduce((sum, product) => sum + product.number, 0);
    },
    cartTotalPrice() {
      return this.products.reduce((sum, product) => sum + product.number * product.salePrice, 0) * 100;
    }
  },
  mounted() {
    this.requesMealList();
  }
};
</script>

<style lang="scss" scoped>
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-item {
  border-radius: 5px;
  margin: $tb $lr 0;
  width: 100%;
  box-shadow: $box-shadow1;
  background: #fff;
  overflow: hidden;
}

.product-content {
  display: flex;
  align-items: center;
}

.product-image {
  width: 100px;
  height: 100px !important;
  border-radius: 5px 0 0 5px;
  margin-right: $lr;
}

.product-details {
  flex: 1;
  .product-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $lr;
  }
}

.product-name {
  color: $black1;
  margin-bottom: $tb;
  font-size: $size2;
}

.product-description {
  color: $gray2;
  margin-bottom: $tb;
  font-size: $size4;
  @include hiddenText();
}

.product-price {
  font-weight: 600;
  color: $black1;
}

.van-submit-bar {
  // bottom: $tabbarHeight;
}
.cart-icon {
  font-size: 24px;
  &.active {
    color: $appcolor;
  }
}
</style>

<template>
  <div class="info-edit">
    <div class="info-edit-form">
      <field name="uploader" label="头像：">
        <template #input>
          <uploader :maxCount="maxCount" v-model="avatar" :after-read="from => afterReadAvatar(from, 'avatar')" />
        </template>
      </field>
      <field
        v-model="formInfo.phone"
        name="手机号"
        label="手机号："
        placeholder="请输入手机号"
        type="number"
        maxLength="11"
        disabled
      />
      <field v-model="formInfo.name" name="姓名" label="姓名：" placeholder="请输入姓名" maxLength="20" />
      <field name="radio" label="性别：">
        <template #input>
          <radio-group v-model="formInfo.gender" direction="horizontal">
            <radio :name="item.key" v-for="(item, index) in genderType.enums" :key="index">{{ item.value }}</radio>
          </radio-group>
        </template>
      </field>
      <field
        v-model="formInfo.idCard"
        name="身份证号码"
        label="身份证号码："
        type="idcard"
        maxLength="18"
        placeholder="请输入身份证号码"
      />
      <field
        name="所在区域"
        label="所在区域："
        placeholder="请选择所在区域"
        readonly
        clickable
        :value="area"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择所在区域' }]"
      />
      <popup v-model="showArea" position="bottom">
        <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </popup>
      <field
        v-model="formInfo.address"
        name="详细地址"
        label="详细地址："
        placeholder="请输入详细地址"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />
      <field name="uploader" :label="item.label + '：'" v-for="(item, index) in imageList" :key="index">
        <template #input>
          <uploader :maxCount="maxCount" v-model="item.value" :after-read="from => afterRead(from, item)" />
        </template>
      </field>
    </div>

    <div style="height: 100px" />
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import { Field, Uploader, Popup, RadioGroup, Radio, Area } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';
import { genderType } from '@/enum';

// import tabbar from '../components/tabbar';

export default {
  components: { Field, Uploader, Popup, RadioGroup, Radio, Area, fixedButton },
  data() {
    return {
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {},
      avatar: [],
      imageList: [
        {
          label: '身份证-国徽面',
          key: 'idCardBadge',
          value: []
        },
        {
          label: '身份证-人像面',
          key: 'idCardFigure',
          value: []
        }
      ],
      maxCount: 1,
      genderType
    };
  },
  mounted() {
    this.getPartnerInfo();
  },
  methods: {
    async afterRead(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      item.value = [res.data];
    },
    async afterReadAvatar(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      this[item] = [res.data];
    },
    async getPartnerInfo() {
      const res = await services.partner.getPartnerInfo();
      this.formInfo = { ...res.data, ...res.data.sales };

      if (this.formInfo.district) {
        this.area = `${this.formInfo.province}/${this.formInfo.city}/${this.formInfo.district}`;

        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === this.formInfo.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }

      this.imageList.forEach(i => {
        if (res.data.sales[i.key]) {
          i.value = [{ url: res.data.sales[i.key] }];
        }
      });

      if (this.formInfo.avatar) {
        this.avatar = [{ url: this.formInfo.avatar }];
      }
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    calendarConfirm(date) {
      this.formInfo.validity = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    async submit() {
      const params = {
        phone: this.formInfo.phone,
        name: this.formInfo.name,
        gender: this.formInfo.gender,
        idCard: this.formInfo.idCard,
        address: this.formInfo.address,
        parentId: this.formInfo.parentId,
        salesType: this.formInfo.salesType,
        levelId: this.formInfo.levelId,
        id: this.formInfo.id
      };

      if (this.area) {
        const areaList = this.area.split('/');
        params.province = areaList[0];
        params.city = areaList[1];
        params.district = areaList[2];
      }

      this.imageList.forEach(i => {
        if (i.value.length) {
          params[i.key] = i.value[0].url;
        }
      });

      if (this.avatar.length) {
        await services.partner.changeAvatar({
          avatar: this.avatar[0].url
        });
      }

      await services.partner.editSalesInfo(params);
      this.$toast('保存成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  .info-edit-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

<template>
  <Popup v-model="visible" round :close-on-click-overlay="false">
    <div class="opinion-warp">
      <p class="opinion-title">消息提醒</p>
      <div class="opinion-content">
        <p>
          <span class="alarm-tag" v-if="info.stockAlarmLevel === 1" style="background: #ffb811;">库</span>
          <span class="alarm-tag" v-if="info.stockAlarmLevel === 2" style="background: #fe271b;">库</span>
          {{ info.stockAlarmContent }}
        </p>
        <p>
          <span class="alarm-tag" v-if="info.timeAlarmLevel === 1" style="background: #ffb811;">效</span>
          <span class="alarm-tag" v-if="info.timeAlarmLevel === 2" style="background: #fe271b;">效</span>
          {{ info.timeAlarmContent }}
        </p>
      </div>
      <div class="btn" @click="closedHandle">
        已知晓
        <span class="countdown" v-if="countdown">
          ( <span>{{ countdown }}</span> )
        </span>
      </div>
    </div>
  </Popup>
</template>

<script>
import { Popup } from 'vant';
import services from '@/service';
import { getUserType } from '@/lib/utils';

export default {
  components: { Popup },
  data() {
    return {
      value: '',
      curValue: [],
      countdown: 5,
      timer: null
    };
  },
  props: {
    show: {
      type: Boolean,
      defautl: false
    },
    info: {
      type: Object,
      required: true,
      defautl: () => {
        return {};
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  },
  methods: {
    closedHandle() {
      if (this.countdown) return;
      this.visible = false;
    },
    async opinionSubmit() {
      if (!this.value.trim()) return;
      const params = {
        feedbackMsg: this.value,
        feedbackType: 'suggestion'
      };
      await services[getUserType()].createFeedback(params);
      this.value = '';
      this.$toast.success('提交成功');
      this.visible = false;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown--;
      if (!this.countdown) {
        clearInterval(this.timer);
      }
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.opinion-warp {
  padding: $tb $lr;
  width: 300px;
  .opinion-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .opinion-content {
    padding: 40px 0;
    font-size: $size5;

    .alarm-tag {
      font-size: 12px;
      border-radius: 50%;
      color: #fff;
      background: $red1;
      padding: 4px;
      display: inline-block;
      margin-right: 4px;
    }
  }
  .btn {
    margin-bottom: 0;
    .countdown {
      margin-left: 10px;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clinic-info", on: { click: _vm.selectClinic } },
        [
          _c("van-image", {
            staticClass: "avatar",
            attrs: {
              fit: "cover",
              src: _vm.clinicInfo.frontPhoto || _vm.avatar,
            },
          }),
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.clinicInfo.clinicName)),
          ]),
          _vm.canChange
            ? _c(
                "span",
                { staticClass: "read-more" },
                [_c("icon", { attrs: { name: "arrow" } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Popup",
        {
          attrs: { round: "" },
          on: {
            closed: function ($event) {
              _vm.visible = false
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "opinion-warp" }, [
            _c("p", { staticClass: "opinion-title" }, [_vm._v("选择诊所")]),
            _c(
              "div",
              { staticClass: "radio-warp" },
              [
                _c(
                  "radio-group",
                  {
                    model: {
                      value: _vm.clinicId,
                      callback: function ($$v) {
                        _vm.clinicId = $$v
                      },
                      expression: "clinicId",
                    },
                  },
                  _vm._l(_vm.clinicList, function (item, index) {
                    return _c(
                      "radio",
                      { key: index, attrs: { name: item.clinicId } },
                      [_vm._v(" " + _vm._s(item.clinicName) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "btn", on: { click: _vm.submit } }, [
              _vm._v("确认"),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="intention">
    <div class="login-bg"></div>
    <div class="header">
      <p class="header-login">检验检测仪器</p>
      <p class="header-title">免费投放</p>
    </div>
    <div class="intention-form">
      <van-form ref="formRef">
        <field
          v-model="formInfo.name"
          name="姓名"
          label="姓名："
          placeholder="请输入姓名"
          maxLength="20"
          required
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <field
          v-model="formInfo.phone"
          name="手机号"
          label="手机号："
          placeholder="请输入手机号"
          type="number"
          maxLength="11"
          required
          :rules="[{ required: true, message: '请输入正确的手机号', pattern: phoneReg }]"
        />
        <field
          name="所在区域"
          label="所在区域："
          placeholder="请选择所在区域"
          readonly
          clickable
          :value="area"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择所在区域' }]"
          required
        />
        <popup v-model="showArea" position="bottom">
          <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </popup>
        <field
          v-model="formInfo.address"
          name="详细地址"
          label="详细地址："
          placeholder="请输入详细地址"
          :rules="[{ required: true, message: '请输入详细地址' }]"
          type="textarea"
          required
        />

        <!-- <field name="radio" label="性别：">
        <template #input>
          <radio-group v-model="formInfo.gender" direction="horizontal">
            <radio :name="item.key" v-for="(item, index) in genderType.enums" :key="index">{{ item.value }}</radio>
          </radio-group>
        </template>
      </field> -->
        <field v-model="formInfo.remark" name="备注" label="备注：" placeholder="输入备注" type="textarea" />
      </van-form>
    </div>
    <p class="introduction">
      爱检康生物科技(广州)有限公司是一家专注于为基层医疗机构提供优质诊疗项目和服务的供应商，致力于为基层医疗机构检验检测一站式解决方案。
    </p>
    <div style="height: 100px" />
    <fixedButton text="提交意向登记" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import { Form as VanForm, Field, Popup, Area } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';
import { genderType } from '@/enum';
import { phoneReg, wxToLogin } from '@/lib/utils';

// import tabbar from '../components/tabbar';

export default {
  components: { VanForm, Field, Popup, Area, fixedButton },
  data() {
    return {
      phoneReg,
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {
        name: '',
        phone: '',
        address: '',
        remark: '',
        businessChannel: 'ijk'
      },
      avatar: [],
      maxCount: 1,
      genderType
    };
  },
  mounted() {
    // this.getPartnerInfo();
    this.pageInit();
  },
  methods: {
    pageInit() {
      const { type, tel, province, city, district, address, name } = this.$route.query;
      if (type) {
        this.formInfo.businessChannel = type;
        this.formInfo.phone = tel;
        this.formInfo.address = address;
        this.formInfo.name = name;

        if (district) {
          this.area = `${province}/${city}/${district}`;

          let areaValue = '';
          for (const key in areaList.county_list) {
            if (areaList.county_list[key] === district) {
              areaValue = key;
            }
          }
          this.areaValue = areaValue;
        }

        this.$forceUpdate();
      }
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    async submit() {
      await this.$refs.formRef.validate();

      const params = {
        phone: this.formInfo.phone,
        name: this.formInfo.name,
        address: this.formInfo.address,
        remark: this.formInfo.remark,
        dataChannel: 'clinic',
        businessChannel: this.formInfo.businessChannel
      };

      console.log(this.formInfo.name);
      if (this.area) {
        const areaList = this.area.split('/');
        params.province = areaList[0];
        params.city = areaList[1];
        params.district = areaList[2];
      }

      await services.common.intentionRecord(params);
      this.$toast('提交成功');
      setTimeout(() => {
        wxToLogin('reLaunch');
      }, 1500);
    }
  }
};
</script>

<style lang="scss" scoped>
.intention {
  .header {
    padding: 0 $tb;
    margin: 40px 0;
    z-index: 20;
    position: relative;
    color: #fff;
    .header-login {
      font-size: 30px;
    }
    p {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
  .intention-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
  .introduction {
    color: $gray2;
    font-size: $size6;
    padding: $tb $lr;
    line-height: 20px;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-management" },
    [
      _c(
        "pull-refresh",
        {
          attrs: { disabled: !_vm.pageInit },
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _vm.listInfo.length
            ? _c(
                "list",
                {
                  attrs: {
                    finished: !_vm.hasNext,
                    "finished-text": "没有更多了",
                    "immediate-check": false,
                  },
                  on: { load: _vm.loadList },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.listInfo, function (item, index) {
                  return _c("reportIBox", { key: index, attrs: { info: item } })
                }),
                1
              )
            : _vm._e(),
          !_vm.listInfo.length
            ? _c("empty", { attrs: { description: "暂无订单" } })
            : _vm._e(),
          _c("div", { staticStyle: { height: "60px" } }),
        ],
        1
      ),
      _c("tabbar", { attrs: { curIndex: 0 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
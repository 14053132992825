var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                { staticClass: "doctor-list" },
                _vm._l(_vm.listInfo, function (doctor, index) {
                  return _c("div", { key: index, staticClass: "doctor-item" }, [
                    _c("div", { staticClass: "doctor-content" }, [
                      _c(
                        "div",
                        { staticClass: "doctor-avatar" },
                        [
                          _c("van-image", {
                            staticClass: "avatar-image",
                            attrs: {
                              fit: "cover",
                              src: doctor.avatar || _vm.avatar,
                              alt: doctor.name,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "doctor-details" }, [
                        _c(
                          "h3",
                          { staticClass: "doctor-name" },
                          [
                            _vm._v(" " + _vm._s(doctor.name) + " "),
                            doctor.jobType === "1"
                              ? _c("tag", { attrs: { type: "primary" } }, [
                                  _vm._v("医生"),
                                ])
                              : _vm._e(),
                            doctor.jobType === "2"
                              ? _c("tag", { attrs: { type: "warning" } }, [
                                  _vm._v("护士"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "doctor-contact" }, [
                          _vm._v(_vm._s(_vm.$getGender(doctor.gender))),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm.clinicId !== 1
        ? _c("Icon", {
            staticClass: "add-icon",
            attrs: { name: "add" },
            on: { click: _vm.toAdd },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <tabbar :curIndex="curIndex" :tabbarList="tabbarList" />
</template>

<script>
import tabbar from '@/components/tabbar';

export default {
  components: { tabbar },
  props: {
    curIndex: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      tabbarList: [
        {
          name: '首页',
          icon: 'sy',
          link: '/mall-home'
        },
        {
          name: '分类',
          icon: 'wz',
          link: '/mall-category'
        },
        {
          name: '购物车',
          icon: 'cg',
          link: '/mall-cart'
        },
        {
          name: '我的',
          icon: 'wd',
          link: '/mall-personal'
        }
      ]
    };
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: { round: "" },
      on: { closed: _vm.closedHandle },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "opinion-warp" },
        [
          _c("p", { staticClass: "opinion-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("Field", {
            attrs: {
              label: "",
              placeholder: _vm.placeholder,
              type: "textarea",
              maxLength: _vm.maxLength,
              rows: _vm.rows,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _c("div", { staticClass: "btn", on: { click: _vm.submit } }, [
            _vm._v(_vm._s(_vm.submitText)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import services from '@/service';

export default {
  state: {
    patientId: '',
    patientInfo: {}
  },
  getters: {
    patientId: state => state.patientId,
    patientInfo: state => state.patientInfo
  },
  actions: {
    set_patientId({ commit }, value) {
      commit('set_patientId', value);
    },
    async set_patientInfo({ commit }) {
      const { data } = await services.patient.getPatientInfo();
      commit('set_patientInfo', data);
    }
  },
  mutations: {
    set_patientId(state, value) {
      state.patientId = value;
    },
    set_patientInfo(state, value) {
      state.patientInfo = value;
      state.patientId = value.userRelId;
    }
  }
};

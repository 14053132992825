var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-box" }, [
    _c("div", { staticClass: "report-info report-header" }, [
      _c("span", { staticClass: "clinic-name" }, [
        _vm._v(_vm._s(_vm.info.deviceNum)),
      ]),
      _c("span", { staticClass: "doc-name" }, [
        _vm._v(_vm._s(_vm.info.createTime)),
      ]),
    ]),
    _c("div", { staticClass: "report-info-list" }, [
      _c("p", [
        _vm._v(
          _vm._s(_vm.info.receiveName) + " " + _vm._s(_vm.info.receiveMobile)
        ),
      ]),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(_vm.info.addressProvinceName) +
            " " +
            _vm._s(_vm.info.addressCityName) +
            " " +
            _vm._s(_vm.info.addressRegionName) +
            " " +
            _vm._s(_vm.info.addressDetail) +
            " "
        ),
      ]),
      _c("p", [_vm._v(_vm._s(_vm.info.remark))]),
    ]),
    _c("div", { staticClass: "report-info report-foot" }, [
      _c("span", { staticClass: "clinic-name" }, [
        _vm._v("由 " + _vm._s(_vm.info.createBy) + " 添加"),
      ]),
      _c("span", { staticClass: "report-time" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
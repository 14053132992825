<template>
  <div class="tabbar" :style="{ width: $store.getters.screenWidth + 'px' }">
    <ul class="tabbar-box">
      <li
        v-for="(item, index) in tabbarList"
        :key="index"
        :class="[{ active: index == curIndex }]"
        @click="changeTab(index, item.link)"
      >
        <i :class="['iconfont', `icon-${item.icon}`]" />
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    curIndex: {
      default: 0,
      type: Number
    },
    tabbarList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {};
  },
  methods: {
    changeTab(index, link) {
      if (this.curIndex == index) return;
      this.$router.replace({
        path: link
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tabbar {
  background-color: $white1;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);

  .tabbar-box {
    display: flex;
    align-items: center;
    height: $tabbarHeight;
    background-color: $white1;
    font-size: 20px;
    color: #9f9f9f;
    font-weight: 500;
    box-shadow: $top-shadow;

    li {
      width: 50%;
      height: $tabbarHeight;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: auto;
      cursor: pointer;

      img {
        width: 22px;
        height: 22px;
        display: block;
        margin: 0 auto;
      }

      span {
        display: block;
        font-size: $size6;
        margin-top: 2px;
      }

      .iconfont {
        font-size: 26px;
      }

      &.active {
        color: transparent;
        -webkit-background-clip: text;
        background-image: $btnbg;
      }
    }
  }
}
</style>

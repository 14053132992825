var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tabbar",
      style: { width: _vm.$store.getters.screenWidth + "px" },
    },
    [
      _c(
        "ul",
        { staticClass: "tabbar-box" },
        _vm._l(_vm.tabbarList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: [{ active: index == _vm.curIndex }],
              on: {
                click: function ($event) {
                  return _vm.changeTab(index, item.link)
                },
              },
            },
            [
              _c("i", { class: ["iconfont", `icon-${item.icon}`] }),
              _c("span", [_vm._v(_vm._s(item.name))]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
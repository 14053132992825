<template>
  <div class="partner-info">
    <div class="ach-panel">
      <div class="ach-warp">
        <div class="personal-info">
          <van-image fit="cover" :src="partnerInfo.avatar || avatar" :alt="partnerInfo.name" class="avatar" />
          <div class="personal-right">
            <p class="personal-name">{{ partnerInfo.name }}</p>
            <!-- <p class="personal-tel" @click="dialPhoneNumber(partnerInfo.phone)">
              <icon name="phone" /> {{ partnerInfo.phone }}
            </p> -->
            <p class="personal-tel">
              {{ $getGender(partnerInfo.gender) }}
            </p>
          </div>
        </div>
        <div class="ach-content">
          <div>
            <p class="ach-title">诊所数</p>
            <p class="ach-num">{{ scoreInfo.clinicAmount }}</p>
          </div>
          <div>
            <p class="ach-title">设备数</p>
            <p class="ach-num">{{ scoreInfo.machineAmount }}</p>
          </div>
          <div>
            <p class="ach-title">打卡数</p>
            <p class="ach-num">{{ scoreInfo.signinAmount }}</p>
          </div>
          <div>
            <p class="ach-title">上月销量</p>
            <p class="ach-num">{{ scoreInfo.lmSalseAmount }}</p>
          </div>
          <div>
            <p class="ach-title">本月销量</p>
            <p class="ach-num">{{ scoreInfo.salseAmount }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="personal-func-list">
      <cell v-for="(item, index) in funcList" :key="index" is-link @click="toLink(item.link)">
        <template #title>
          <span :class="['iconfont', `icon-${item.icon}`]"></span>
          <span class="custom-title">{{ item.label }}</span>
        </template>
        <template #right-icon>
          <div class="right">
            <icon name="arrow" />
          </div>
        </template>
      </cell>
    </div>
  </div>
</template>

<script>
import services from '@/service';

import { Cell, Icon, Image as VanImage } from 'vant';

export default {
  components: { Cell, Icon, VanImage },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      partnerInfo: {},
      scoreInfo: {
        clinicAmount: 0,
        machineAmount: 0,
        signinAmount: 0,
        lmSalseAmount: 0,
        salseAmount: 0,
        withdrawalAmount: 0
      },
      searchVal: '',
      funcList: [
        {
          label: '基本信息',
          icon: 'jbxx',
          link: '/partner-info-edit-add'
        },
        {
          label: 'Ta的诊所',
          icon: 'wdzs',
          link: '/partner-my-clinic'
        },
        {
          label: 'Ta的设备',
          icon: 'wdsb',
          link: '/partner-device-list'
        }
      ]
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    salesId() {
      return this.$route.query.id;
    }
  },
  methods: {
    async refresh() {
      await Promise.all([this.getSalesInfo(), this.getOverview()]);
    },
    async getOverview() {
      const res = await services.partner.getOverview({
        salesId: this.salesId
      });
      this.scoreInfo = res.data;
    },
    async getSalesInfo() {
      const res = await services.partner.getSalesInfo(this.salesId);
      this.partnerInfo = res.data;
    },
    toLink(path) {
      this.$router.push({
        path,
        query: {
          id: this.salesId
        }
      });
    },
    dialPhoneNumber(phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
.partner-info {
  .line-title {
    margin: $tb $lr 0px;
  }
  // padding: $tb $lr;
  .ach-panel {
    .ach-warp {
      margin: $tb $lr;
      box-shadow: $box-shadow1;
      border-radius: $radius;
      overflow: hidden;
      background: #fff;
      color: #fff;

      .personal-info {
        // text-align: center;
        background: $btnbg;
        padding: $tb $lr;
        line-height: 30px;
        display: flex;

        .avatar {
          border-radius: 50%;
          width: 70px;
          height: 70px;
          border: 2px solid #fff;
          margin-right: $lr;
          overflow: hidden;
        }
        .personal-right {
          .personal-name {
            font-size: 20px;
            font-weight: 600;
            margin-top: 10px;
          }
          .personal-tel {
            font-size: $size4;
          }
        }
      }

      .ach-content {
        display: flex;
        justify-content: space-around;
        padding: $tb $lr;
        > div {
          flex: 1;
          text-align: center;
          border-right: 2px solid $bordercolor;
          &:last-child {
            border-right: none;
          }
        }
        .ach-title {
          color: $gray2;
          font-size: $size5;
        }
        .ach-num {
          font-size: 16px;
          padding-top: $tb;
          text-align: center;
          color: $black1;
        }
      }
      // > div {
      //   text-align: center;
      //   .ach-title {
      //     font-size: $size4;
      //     color: $gray2;
      //     margin-bottom: $tb;
      //     font-weight: 600;
      //   }
      //   .ach-num {
      //     font-size: 24px;
      //     font-weight: 600;
      //     color: $black1;
      //   }
      // }
    }
  }
}
</style>

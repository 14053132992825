<template>
  <div class="info-edit">
    <p class="top-tip">先上传<span> 营业执照 </span>和<span> 身份证 </span>，系统将自动识别并回填信息</p>
    <div class="info-edit-form">
      <fieldForm ref="formRef" :fields="fields" />
    </div>

    <div style="height: 150px" />
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import fieldForm from '@/components/fieldForm';
import { paymentModeAll } from '@/enum';
// import { businessReg } from '@/lib/utils';

export default {
  components: { fixedButton, fieldForm },
  data() {
    return {
      paymentModeAll,
      fields: [
        {
          label: '支付模式',
          key: 'paymentMode',
          type: 'radio-group',
          rules: [],
          required: true,
          value: '1',
          actions: paymentModeAll.enums.map(i => {
            return {
              name: i.value,
              key: i.key
            };
          })
        },
        {
          label: '手机号码',
          key: 'phone',
          type: 'number',
          maxLength: '11',
          rules: [{ required: true, message: '请输入手机号码' }],
          required: true,
          value: ''
        },
        {
          label: '所在区域',
          key: 'area',
          type: 'area',
          rules: [{ required: true, message: '请选择所在区域' }],
          required: true,
          show: false,
          popupValue: '',
          value: ''
        },
        {
          label: '营业执照',
          key: 'businessPhoto',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          ocrType: 'business_license',
          ocrKey: {
            unitName: 'clinicName',
            legalPerson: 'corporateName',
            code: 'license',
            businessScope: 'clinicIntro',
            address: 'address'
          }
        },
        {
          label: '法人身份证人像',
          key: 'idCardFigure',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          ocrType: 'idcard_front',
          ocrKey: {
            idCard: 'corporateIdCard'
          }
        },
        {
          label: '法人身份证国徽',
          key: 'idCardBadge',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: []
        },
        {
          label: '门店照片',
          key: 'frontPhoto',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: []
        },
        {
          label: '许可证',
          key: 'licencePhoto',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: []
        },
        {
          label: '机构名称',
          key: 'clinicName',
          type: 'textarea',
          rules: [{ message: '请输入正确的机构名称', pattern: /^[（）()\u4e00-\u9fa5]+$/ }],
          required: true,
          value: ''
        },
        {
          label: '法人姓名',
          key: 'corporateName',
          type: 'text',
          rules: [{ message: '请输入正确的法人姓名', pattern: /^[A-Za-z\u4e00-\u9fa5.]+$/ }],
          required: true,
          value: ''
        },
        {
          label: '法人身份证',
          key: 'corporateIdCard',
          type: 'idcard',
          rules: [{ required: true, message: '请输入法人身份证' }],
          required: true,
          value: ''
        },
        {
          label: '详细地址',
          key: 'address',
          type: 'textarea',
          maxLength: '500',
          rules: [{ required: true, message: '请输入详细地址' }],
          required: true,
          value: ''
        },
        {
          label: '营业执照编号',
          key: 'license',
          type: 'text',
          rules: [{ required: true, message: '请输入营业执照编号' }],
          required: true,
          value: ''
        },
        {
          label: '营业执照有效期',
          key: 'validity',
          type: 'date',
          rules: [{ required: false, message: '请选择有效日期' }],
          required: false,
          show: false,
          maxDate: new Date(2099, 12, 31),
          minDate: new Date(),
          popupValue: new Date(),
          value: ''
        },
        {
          label: '机构简介',
          key: 'clinicIntro',
          type: 'textarea',
          maxLength: '500',
          rules: [],
          required: false,
          value: ''
        }
      ]
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {},

    async submit() {
      const params = await this.$refs.formRef.validate();
      await services.partner.clinicAdd({ ...params, profitSharing: '0' });
      this.$toast('提交成功，等待审核');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  ::v-deep {
    .van-field__label {
      width: 92px !important;
    }
  }
  .info-edit-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

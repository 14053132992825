<template>
  <div class="info-table">
    <div class="info-table-div">
      <sticky>
        <div>
          <div class="info-table-cell">
            <div class="td td1 title" v-for="(item, index) in tableArgs" :key="index">{{ item.title }}</div>
          </div>
        </div>
      </sticky>
      <div v-for="(item, index) in tableInfo" :key="index">
        <div class="info-table-cell">
          <div class="td td2" v-for="(item2, index2) in tableArgs" :key="index2">{{ item[item2.key] || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Sticky } from 'vant';

export default {
  components: { Sticky },
  data() {
    return {};
  },
  props: {
    tableInfo: {
      type: Array,
      default: function() {
        return [];
      }
    },
    tableArgs: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$borderColor: #e0edff;
.info-table {
  background: #ffffff;
  // padding: $tb 0;
  .info-table-div {
    border: 1px solid $borderColor;
    border-right: 0;
    border-bottom: 0;
    // margin: 0 $lr;
  }
  .info-table-cell {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: $size6;
    color: #330e0e;
    text-align: center;
  }
  .td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    line-height: 18px;
  }

  .td1 {
    flex: 1;
    background: #f3f9ff;
  }
  .td2 {
    flex: 1;
  }
  .td3 {
    width: 20%;
  }

  .title {
    text-align: center;
    background: #f3f9ff;
    font-weight: 600;
    font-size: $size6;
  }
  .info-table-content {
    display: inline-block;
  }
  .info-table-edit {
    font-size: 14px;
    font-weight: 400;
    color: #3f6efc;
    margin-left: 10px;
  }

  .down-icon {
    color: red;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
  }
  .down-icon {
    color: blue;
    font-size: 14px;
    font-weight: 500;
  }
  .icon-up {
    color: red;
    font-size: 16px;
  }
  .icon-down {
    color: #439eff;
    font-size: 16px;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statistics-table" },
    [
      _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.showTitle))]),
      _c("infoTable", {
        attrs: { tableInfo: _vm.table, tableArgs: _vm.tableArgs },
      }),
      _c("div", { staticStyle: { height: "20px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-table" }, [
    _c(
      "div",
      { staticClass: "info-table-div" },
      _vm._l(_vm.panelList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "info-table-item",
            style: { width: item.width },
            on: {
              click: function ($event) {
                return _vm.toLink(item)
              },
            },
          },
          [
            _c("div", { staticClass: "info-table-title" }, [
              _vm._v(_vm._s(item.label)),
            ]),
            _c("div", { staticClass: "info-table-content" }, [
              _c("div", { staticClass: "info-table-num" }, [
                _vm._v(_vm._s(_vm.judgmentType(_vm.info, item.key).num)),
              ]),
              _c("div", {
                staticClass: "info-table-calc-num",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.judgmentType(_vm.info, item.key).calcNum
                  ),
                },
              }),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="bind-device">
    <div class="bind-device-form">
      <field
        readonly
        clickable
        name="picker"
        :value="formInfo.f1.text"
        label="选择诊所"
        placeholder="点击选择诊所"
        @click="clinicPicker = true"
        :disabled="disabledSelect"
      />
      <popup v-model="clinicPicker" position="bottom" v-if="!disabledSelect">
        <picker :columns="clinicList" @confirm="clinicConfirm" @cancel="clinicPicker = false" value-key="text" />
      </popup>
      <field
        readonly
        clickable
        name="picker"
        :value="formInfo.f2.text"
        label="选择设备"
        placeholder="点击选择设备"
        @click="devicePicker = true"
        :disabled="disabledSelect"
      />
      <popup v-model="devicePicker" position="bottom" v-if="!disabledSelect">
        <picker
          show-toolbar
          :columns="deviceList"
          @confirm="deviceConfirm"
          @cancel="devicePicker = false"
          value-key="text"
        />
      </popup>
      <field
        v-model="formInfo.deviceNum"
        name="设备编号"
        label="设备编号："
        placeholder="请输入设备编号"
        maxLength="16"
        :rules="[{ required: true, message: '请输入营业执照编号' }]"
      />
      <i class="iconfont icon-sm" @click="toScan" />
    </div>
    <div class="submit-btn" @click="submit">{{ bindText }}</div>
  </div>
</template>

<script>
import { Field, Popup, Area } from 'vant';
import services from '@/service';
import { scanQRCode } from '@/lib/utils';
import Picker from '@/components/picker';

export default {
  components: { Field, Popup, [Area.name]: Area, Picker },
  data() {
    return {
      showArea: false,
      showCalendar: false,
      clinicList: [],
      deviceList: [],
      clinicPicker: false,
      devicePicker: false,
      formInfo: {
        f1: {},
        f2: {},
        deviceNum: ''
      },
      maxCount: 1
    };
  },
  async mounted() {
    await Promise.all([this.getBindClinicList(), this.getDeviceList()]);
    this.defaultHandle();
  },
  computed: {
    clinicDeviceRelId() {
      return this.$route.query.clinicDeviceRelId;
    },
    clinicId() {
      return this.$route.query.clinicId;
    },
    goodsSettingId() {
      return this.$route.query.goodsSettingId;
    },
    disabledSelect() {
      return !!this.goodsSettingId;
    },
    isChange() {
      return this.clinicDeviceRelId;
    },
    bindText() {
      return this.isChange ? '更换绑定' : '绑定设备';
    }
  },
  methods: {
    async toScan() {
      const deviceNum = await scanQRCode();
      this.formInfo.deviceNum = deviceNum;
    },
    refresh() {
      this.formInfo = {
        f1: {},
        f2: {},
        deviceNum: ''
      };
    },
    defaultHandle() {
      if (this.clinicId) {
        this.formInfo.f1 = this.clinicList.find(i => i.clinicId === Number(this.clinicId)) || {};
      }
      if (this.goodsSettingId) {
        this.formInfo.f2 = this.deviceList.find(i => i.goodsSettingId === Number(this.goodsSettingId)) || {};
      } else {
        this.formInfo.f2 = this.deviceList[0];
      }
    },
    async getBindClinicList() {
      const res = await services.partner.getBindClinicList();
      this.clinicList = res.data.map(i => {
        return { ...i, text: i.clinicName };
      });
    },
    async getDeviceList() {
      const res = await services.partner.getDeviceList();
      this.deviceList = res.rows.map(i => {
        return { ...i, text: i.goodsName };
      });
    },

    clinicConfirm(e) {
      this.formInfo.f1 = e;
      this.clinicPicker = false;
    },
    deviceConfirm(e) {
      this.formInfo.f2 = e;
      this.devicePicker = false;
    },
    async submit() {
      if (!this.formInfo.f1.clinicId) {
        return this.$toast('请选择诊所');
      } else if (!this.formInfo.f2.goodsSettingId) {
        return this.$toast('请选择设备');
      } else if (!this.formInfo.deviceNum) {
        return this.$toast('请输入设备编号');
      }
      const params = {
        clinicId: this.formInfo.f1.clinicId,
        clinicName: this.clinicList.find(i => i.clinicId === this.formInfo.f1.clinicId).clinicName,
        goodsSettingId: this.formInfo.f2.goodsSettingId,
        deviceNum: this.formInfo.deviceNum
      };
      if (this.isChange) {
        params.clinicDeviceRelId = this.clinicDeviceRelId;
        await services.partner.changeClinicDevices(params);
      } else {
        await services.partner.bindClinicDevice(params);
      }
      this.$toast('绑定成功');
      setTimeout(() => {
        this.refresh();
        this.$router.go(-1);
      }, 1500);
    }
  }
};
</script>

<style lang="scss" scoped>
.bind-device {
  .bind-device-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
    position: relative;

    .icon-sm {
      position: absolute;
      right: 18px;
      bottom: 10px;
      font-size: 26px;
    }
  }
  .submit-btn {
    margin: $tb $lr;
    @include flexCenter();
    // width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: $btnbg;
    color: #fff;
    margin-bottom: $edge;
    margin-top: 20px;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pdf-box" }, [
    _c("div", { attrs: { id: "pdf-content" } }),
    !_vm.isWeChat
      ? _c(
          "div",
          {
            staticClass: "print-icon",
            attrs: { name: "down" },
            on: { click: _vm.toPrint },
          },
          [_c("i", { staticClass: "iconfont icon-xzbg" })]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "operation-page" }, [
    _c("p", [_vm._v("爱检康 操作手册")]),
    _c(
      "div",
      { staticClass: "operation" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "operation-item" }, [
          _c("div", { staticClass: "item-left" }, [
            _vm._v("第" + _vm._s(item + 1) + "步"),
          ]),
          _vm._m(0, true),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-right" }, [
      _c("p", [_vm._v("a啊实打实大苏打阿斯顿")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
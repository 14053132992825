<template>
  <div>
    <div class="order-confirm">
      <div class="address-item" @click="selectAddr">
        <div class="address-content">
          <i class="iconfont icon-shdz" />
          <div class="address-info" v-if="addressInfo.addressId">
            <h3 class="address-name">
              <span>{{ addressInfo.addressee }}</span>
              <span class="address-phone">{{ addressInfo.phone }}</span>
            </h3>
            <p class="address-area">{{ showArea(addressInfo) }}</p>
          </div>
          <div class="address-info" v-else>
            <h3 class="address-area">请选择地址</h3>
          </div>
          <div class="address-status" v-if="!hasOrder">
            <p class="view-details"><icon name="arrow" /></p>
          </div>
        </div>
      </div>
      <div class="product-list" v-if="orderInfo.sum">
        <div class="product-item" v-for="product in products" :key="product.id">
          <div class="product-content">
            <van-image fit="cover" :src="product.goodsIcon" :alt="product.name" class="product-image" />
            <div class="product-details">
              <h3 class="product-name">{{ product.goodsName }}</h3>
              <p class="product-description">{{ product.goodsFunction }}</p>
              <div class="product-foot">
                <span class="product-price">￥{{ product.salePrice }}</span>
                <stepper
                  v-model="product.number"
                  theme="round"
                  button-size="22"
                  disable-input
                  min="1"
                  max="99"
                  @change="refreshInfo"
                />
              </div>
            </div>
          </div>
        </div>

        <field
          v-model="orderInfo.remark"
          name="订单备注"
          label="订单备注："
          placeholder="输入订单备注"
          type="textarea"
          :rules="[{ required: true, message: '输入法人名称' }]"
        />
      </div>
      <div class="price-box">
        <span>合计</span>
        <span class="price">￥{{ orderInfo.payAmount }}</span>
      </div>
    </div>
    <div style="height: 100px"></div>
    <!-- <fixedButton text="微信支付" v-if="hasOrder" @emitEvent="wxPay" class="wx" /> -->
    <fixedButton text="下单" @emitEvent="createOrder" />

    <!-- <div class="btn" @click="createOrder">去支付</div>
    <div class="btn" @click="wxPay" v-if="orderCode">微信支付</div> -->
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { Stepper, Image as VanImage, Icon, Field } from 'vant';
import { mapGetters } from 'vuex';
import { isWeChat } from '@/global';
import pay from '@/lib/pay';

export default {
  components: { fixedButton, Stepper, VanImage, Icon, Field },
  data() {
    return {
      address: {},
      products: [],
      orderInfo: {},
      orderCode: ''
    };
  },
  computed: {
    ...mapGetters(['openId', 'clinicInfo', 'addressInfo']),
    hasCartNum() {
      return this.products.reduce((sum, product) => sum + product.number, 0);
    },
    cartTotalPrice() {
      return this.products.reduce((sum, product) => sum + product.number * product.salePrice, 0) * 100;
    },
    code() {
      return this.$route.query.code;
    },
    hasOrder() {
      return this.orderCode;
    }
  },
  methods: {
    async getOpenId() {
      if (isWeChat && !this.openId && this.code) {
        const res = await services.common.getOpenId({ authorizeCode: this.code });
        this.$store.dispatch('set_openId', res.data.openId);
      }
    },
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    onSubmit() {},
    selectAddr() {
      if (this.hasOrder) return;
      this.$router.push({
        name: 'partnerMyAddress',
        query: {
          isSelect: true
        }
      });
    },
    async createOrder() {
      this.$store.dispatch('addLoading');
      const params = {
        addressCityName: this.addressInfo.city,
        addressDetail: this.addressInfo.address,
        addressMobile: this.addressInfo.phone,
        addressName: this.addressInfo.addressee,
        addressProvinceName: this.addressInfo.province,
        addressRegionName: this.addressInfo.district,
        // clinicId: this.clinicInfo.clinicId,
        // clinicName: this.clinicInfo.clinicName,
        remark: this.orderInfo.remark,
        orderDetailVoList: this.products.map(i => {
          return {
            goodsMealId: i.goodsMealId,
            number: i.number
          };
        })
      };
      const orderRes = await services.partner.createServiceOrder(params);
      this.orderCode = orderRes.data.orderCode;
      this.$store.dispatch('subLoading');
      this.$toast('下单成功');
      setTimeout(() => {
        this.$router.replace({
          name: 'partnerMyOrder'
        });
      }, 1000);
      // this.wxPay();
    },
    async wxPay() {
      this.$store.dispatch('addLoading');
      const res = await services.partner.wxPay({
        orderCode: this.orderCode,
        tradeType: 'JSAPI',
        openId: this.openId
      });
      this.$store.dispatch('subLoading');

      await pay(res.data);
      this.$toast('支付成功');
      setTimeout(() => {
        this.$router.replace({
          name: 'partnerMyOrder'
        });
      }, 1000);
    },
    async calculatePrice() {
      if (!this.products.length) return;
      this.$store.dispatch('addLoading');

      const params = {
        addressCityName: this.addressInfo.city,
        addressDetail: this.addressInfo.address,
        addressMobile: this.addressInfo.phone || this.clinicInfo.phone,
        addressName: this.addressInfo.addressee,
        addressProvinceName: this.addressInfo.province,
        addressRegionName: this.addressInfo.district,
        // clinicId: this.clinicInfo.clinicId,
        // clinicName: this.clinicInfo.clinicName,
        orderDetailVoList: this.products.map(i => {
          return {
            goodsMealId: i.goodsMealId,
            number: i.number
          };
        })
      };
      const res = await services.partner.calculatePrice(params);
      this.orderInfo = res.data;
      this.products = res.data.orderDetailVoList;
      this.$store.dispatch('subLoading');
    },
    refreshInfo() {
      this.calculatePrice();
    }
  },
  mounted() {
    console.log('window.location.href', window.location.href);
    this.getOpenId();
    const products = JSON.parse(window.atob(this.$route.query.state) || []).products;
    this.products = products;
    this.calculatePrice();
  }
};
</script>

<style lang="scss" scoped>
.order-confirm {
  padding: $tb $lr;
}

.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  // margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.view-details {
  font-size: $size1;
  color: $appcolor;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  overflow: hidden;
}

.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  padding: $tb $lr;
  font-size: $size3;
  color: $gray1;
  text-align: right;
  .price {
    color: $red1;
    font-weight: 600;
    margin-left: $lr;
  }
}
.product-item {
  flex: 1;
  border-bottom: 1px solid $bordercolor;
  padding: $tb 0;
  margin: 0 $lr;
  &:last-child {
    border: none;
  }
}

.product-content {
  display: flex;
  align-items: center;
}

.product-image {
  width: 100px;
  height: 100px;
  border-radius: 5px 0 0 5px;
  margin-right: $lr;
}

.product-details {
  flex: 1;
  .product-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: $lr;
  }
}

.product-name {
  color: $black1;
  margin-bottom: $tb;
  font-size: $size2;
}

.product-description {
  color: $gray2;
  margin-bottom: $tb;
  font-size: $size4;
  @include hiddenText();
}

.product-price {
  font-weight: 600;
  color: $black1;
}

.van-submit-bar {
  bottom: $tabbarHeight;
}
.cart-icon {
  font-size: 24px;
  &.active {
    color: $appcolor;
  }
}

.btn {
  margin: $tb $lr;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-box" },
    [
      _c("div", { staticClass: "report-info report-header" }, [
        _c("span", { staticClass: "clinic-name" }, [
          _vm._v(_vm._s(_vm.info.clinicName)),
        ]),
        _c("span", { staticClass: "doc-name" }),
      ]),
      _c("div", { staticClass: "report-info-list" }, [
        _c("p", [
          _vm._v(
            _vm._s(_vm.info.goodsName) + " " + _vm._s(_vm.info.receiveMobile)
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.info.addressProvinceName) +
              " " +
              _vm._s(_vm.info.addressCityName) +
              " " +
              _vm._s(_vm.info.addressRegionName) +
              " " +
              _vm._s(_vm.info.addressDetail) +
              " "
          ),
        ]),
        _c("p", [_vm._v("诊所库存：" + _vm._s(_vm.info.stock))]),
        _c("p", [_vm._v("有效期至：" + _vm._s(_vm.info.serviceLimitDate))]),
      ]),
      _c("div", { staticClass: "report-info report-foot" }, [
        _c("span", { staticClass: "clinic-name" }, [
          _vm._v(_vm._s(_vm.info.createTime)),
        ]),
        _c("span", { staticClass: "report-time" }, [
          _c(
            "span",
            {
              staticClass: "info-btn",
              on: {
                click: function ($event) {
                  return _vm.fieldPrompShow(_vm.info)
                },
              },
            },
            [_vm._v("入库")]
          ),
        ]),
      ]),
      _vm.fieldPromptModel.show
        ? _c("fieldPrompt", {
            attrs: {
              title: "出库",
              show: _vm.fieldPromptModel.show,
              fieldForm: _vm.fieldPromptModel.fieldForm,
              info: _vm.fieldPromptModel.info,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.fieldPromptModel, "show", $event)
              },
              emit: _vm.fieldPromptEmit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import dayjs from 'dayjs';

export default {
  install: () => {
    // 日期格式化
    Vue.filter('dateFilter', (val, formatString = 'YYYY-MM-DD HH:mm:ss') => {
      if (!val || val === '0') {
        return '-';
      }
      let valStr = String(val);
      if (valStr.length === 10) {
        valStr = `${valStr}000`;
      }
      if (!isNaN(valStr)) {
        valStr = Number(valStr);
      }
      return dayjs(valStr).format(formatString);
    });

    // 电话格式化
    Vue.filter('phoneFilter', (val, formatString = '****') => {
      if ((val || '').length !== 11) {
        return val;
      }
      return val.slice(0, 3) + formatString + val.slice(-4);
    });
  }
};

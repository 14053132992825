import { render, staticRenderFns } from "./index.vue?vue&type=template&id=21d4f927&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=21d4f927&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d4f927",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/ajk-web-view_r9Iq/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21d4f927')) {
      api.createRecord('21d4f927', component.options)
    } else {
      api.reload('21d4f927', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=21d4f927&scoped=true", function () {
      api.rerender('21d4f927', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/bindDevice/index.vue"
export default component.exports
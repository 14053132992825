var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.pageSlotKey, attrs: { id: "app" } },
    [
      _c("navbar"),
      !_vm.$store.getters.isExportMode
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.getters.loadingShow,
                  expression: "$store.getters.loadingShow",
                },
              ],
              staticClass: "loading-content",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "Loading",
                {
                  staticClass: "loading-el",
                  attrs: { size: "40", color: "#fff", vertical: "" },
                },
                [_c("span", [_vm._v("正在加载中...")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "keep-alive",
        { attrs: { include: _vm.keepAliveViewsTemp } },
        [_c("router-view")],
        1
      ),
      _vm.showToLogin
        ? _c(
            "div",
            { staticClass: "global-to-login", on: { click: _vm.toLogon } },
            [_vm._v("去登录")]
          )
        : _vm._e(),
      _vm.currentConfig.miniQrCode
        ? _c("div", { staticClass: "root-left" }, [
            _c("div", { staticClass: "qrcode-warp" }, [
              _c("img", { attrs: { src: _vm.currentConfig.miniQrCode } }),
            ]),
            _c("div", { staticClass: "ba", on: { click: _vm.toBa } }, [
              _c("img", { attrs: { src: _vm.$getImg("ba") } }),
              _c("span", [_vm._v("粤ICP备2024187782号")]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-page" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("爱检康")]),
    _c(
      "div",
      { staticClass: "home-warp" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "list-type",
            on: {
              click: function ($event) {
                return _vm.toPage(item)
              },
            },
          },
          [_vm._v(_vm._s(item.name))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info-edit">
    <icon name="checked" class="icon" />
    <div>提交成功，审核通过后将有短信发送至您的手机</div>
    <div style="height: 60px" />
    <div class="btn" @click="close">我知道了</div>
  </div>
</template>

<script>
import { Icon } from 'vant';
import wechat from '@/wechat';

export default {
  components: { Icon },
  data() {
    return {};
  },
  computed: {
    salesId() {
      return this.$route.query.id;
    },
    readonly() {
      return !!this.salesId;
    }
  },
  mounted() {},
  methods: {
    close() {
      wechat.$wx.closeWindow();
      wechat.$wx.miniProgram.reLaunch({ url: '/pages/h5Jump/index' });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  text-align: center;
  .icon {
    font-size: 70px;
    color: $green;
    margin: 40px 0 40px;
  }
  .btn {
    margin: 0 $lr;
  }
  .info-edit-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

<template>
  <div class="info-edit">
    <div class="info-edit-form">
      <field v-model="formInfo.createTime" name="创建时间" label="创建时间：" readonly />
      <field v-model="formInfo.jobType" name="人员类型" label="人员类型" placeholder="人员类型" readonly>
        <template #input v-if="formInfo.jobType">
          <tag :style="{ background: jobType.enums.find(i => i.key === formInfo.jobType).color }">
            {{ jobType.getValueByKey(formInfo.jobType) }}
          </tag>
        </template>
      </field>
      <field
        v-model="formInfo.phone"
        name="手机号码"
        label="手机号码："
        type="number"
        placeholder="请输入手机号码"
        readonly
        :rules="[{ required: true, message: '请输入手机号码' }]"
      />
      <field
        v-model="formInfo.name"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        readonly
        :rules="[{ required: true, message: '请输入法人名称' }]"
      />
      <field
        v-model="formInfo.idCard"
        name="身份证"
        label="身份证："
        placeholder="请输入身份证"
        type="idcard"
        maxLength="18"
        readonly
        :rules="[{ required: true, message: '请输入法人身份证' }]"
      />
      <field
        name="所在区域"
        label="所在区域："
        placeholder="请选择所在区域"
        readonly
        clickable
        :value="area"
        :rules="[{ required: true, message: '请选择所在区域' }]"
      />
      <popup v-model="showArea" position="bottom">
        <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </popup>
      <field
        v-model="formInfo.address"
        name="详细地址"
        label="详细地址："
        placeholder="请输入详细地址"
        readonly
        type="textarea"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />

      <field name="uploader" :label="item.label + '：'" v-for="(item, index) in imageList" :key="index">
        <template #input>
          <uploader
            :maxCount="maxCount"
            v-model="item.value"
            :after-read="from => afterRead(from, item)"
            readonly
            :deletable="false"
          />
        </template>
      </field>
    </div>

    <div style="height: 100px" />

    <van-dialog v-model="auditShow" title="不通过原因" show-cancel-button @confirm="sumbitAudit('2')">
      <field v-model="auditMes" label="" type="textarea" placeholder="请输入不通过原因" />
    </van-dialog>

    <fixedBottom v-if="formInfo.auditStatus === '0'">
      <div class="btn-warp">
        <div class="btn" @click="changeDoctorAudit('1')">通过</div>
        <div class="btn btn2" @click="changeDoctorAudit('2')">不通过</div>
      </div>
    </fixedBottom>

    <div class="dialog-right" ref="viewer" v-viewer="viewerOptions">
      <img v-for="(item, index) in imagesList" :key="index" :data-src="item" />
    </div>

    <Icon name="browsing-history" class="read-icon" @click="handlePictureCardPreview" />
  </div>
</template>

<script>
import services from '@/service';
import { Field, Uploader, Popup, Area, Icon, Tag } from 'vant';
import { areaList } from '@vant/area-data';
import fixedBottom from '@/components/fixedBottom';
import { viewerOptions, viewerExtensions } from '@/global';
// import tabbar from '../components/tabbar';
import { jobType } from '@/enum/index';

export default {
  name: 'adminClinicHisDetail',
  components: { Field, Uploader, Popup, Area, Icon, Tag, fixedBottom },
  data() {
    return {
      jobType,
      auditShow: false,
      auditMes: '',
      viewerOptions,
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {
        clinicName: '',
        corporateName: '',
        license: '',
        address: '',
        validity: '',
        phone: '',
        clinicIntro: ''
      },
      imageList: [
        {
          label: '身份证-人像面',
          key: 'idCardFigure',
          value: []
        },
        {
          label: '身份证-国徽面',
          key: 'idCardBadge',
          value: []
        },
        {
          label: '执业证书',
          key: 'zyCertificate',
          value: []
        },
        {
          label: '资格证书',
          key: 'zgCertification',
          value: []
        }
      ],
      maxCount: 1
    };
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    imagesList() {
      return Object.values(this.formInfo).filter(
        value =>
          typeof value === 'string' && viewerExtensions.some(extension => value.toLocaleLowerCase().endsWith(extension))
      );
    },
    curId() {
      return this.$route.query.id;
    },
    hisId() {
      return this.$route.query.hisId;
    },
    canEdit() {
      return this.hisId;
    }
  },
  methods: {
    handlePictureCardPreview() {
      this.$refs.viewer.$viewer.show();
    },
    async afterRead(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      item.value = [res.data];
    },
    async getDetail() {
      let res = [];
      if (this.hisId) {
        res = await services.admin.getDoctorHisInfo(this.hisId);
      } else {
        res = await services.admin.getDoctorHisInfo(this.curId);
      }
      this.formInfo = { ...res.data };

      if (this.formInfo.district) {
        this.area = `${this.formInfo.province}/${this.formInfo.city}/${this.formInfo.district}`;

        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === this.formInfo.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }

      this.imageList.forEach(i => {
        if (res.data[i.key]) {
          i.value = [{ url: res.data[i.key] }];
        }
      });
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    calendarConfirm(date) {
      this.formInfo.validity = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    async submit() {
      const params = {
        clinicId: this.formInfo.clinicId,
        phone: this.formInfo.phone,
        clinicName: this.formInfo.clinicName,
        corporateIdCard: this.formInfo.corporateIdCard,
        corporateName: this.formInfo.corporateName,
        license: this.formInfo.license,
        address: this.formInfo.address,
        clinicIntro: this.formInfo.clinicIntro,
        validity: this.formInfo.validity
      };

      if (this.area) {
        const areaList = this.area.split('/');
        params.province = areaList[0];
        params.city = areaList[1];
        params.district = areaList[2];
      }

      if (this.hisId) {
        params.hisId = this.hisId;
      }

      this.imageList.forEach(i => {
        if (i.value.length) {
          params[i.key] = i.value[0].url;
        }
      });

      if (this.formInfo.auditStatus === '0') {
        await services.admin.clinicDevelopingEdit(params);
      } else if (this.formInfo.auditStatus === '2') {
        await services.admin.clinicAdd(params);
      } else {
        await services.admin.clinicAdd(params);
      }
      this.$toast('保存成功');
      this.$router.go(-1);
    },
    async changeDoctorAudit(auditStatus) {
      if (auditStatus === '2') {
        this.auditShow = true;
      } else {
        this.sumbitAudit(auditStatus);
      }
    },
    async sumbitAudit(auditStatus) {
      await services.admin.changeDoctorAudit({
        auditStatus,
        auditMes: auditStatus === '2' ? this.auditMes : '',
        hisId: this.hisId
      });
      this.$toast('操作成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  .info-edit-form {
    // margin: $tb $lr;
    // box-shadow: $box-shadow1;
    // border-radius: $radius;
    overflow: hidden;
  }
}

.btn-warp {
  width: 100%;
  @include flex(row);
  justify-content: space-between;
  // background: #fff;
  border-bottom: 1px solid $bordercolor;
  .btn {
    width: 48% !important;
    flex: none;
  }
  .btn2 {
    background: $btnbg3;
    &:active {
      background: darken($appcolor3, 10%);
    }
  }
}

.read-icon {
  position: absolute;
  right: 20px;
  bottom: 120px;
  font-size: 50px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-edit" },
    [
      _c(
        "div",
        { staticClass: "info-edit-form" },
        [
          _c("field", {
            attrs: { name: "uploader", label: "头像：" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function () {
                  return [
                    _c("uploader", {
                      attrs: {
                        maxCount: _vm.maxCount,
                        "after-read": (from) => _vm.afterRead(from, "avatar"),
                      },
                      model: {
                        value: _vm.avatar,
                        callback: function ($$v) {
                          _vm.avatar = $$v
                        },
                        expression: "avatar",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("field", {
            attrs: {
              name: "手机号",
              label: "手机号：",
              placeholder: "请输入手机号",
              type: "number",
              maxLength: "11",
              disabled: "",
            },
            model: {
              value: _vm.formInfo.phone,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "phone", $$v)
              },
              expression: "formInfo.phone",
            },
          }),
          _c("field", {
            attrs: { name: "radio", label: "性别：" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function () {
                  return [
                    _c(
                      "radio-group",
                      {
                        attrs: { direction: "horizontal" },
                        model: {
                          value: _vm.formInfo.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInfo, "gender", $$v)
                          },
                          expression: "formInfo.gender",
                        },
                      },
                      _vm._l(_vm.genderType.enums, function (item, index) {
                        return _c(
                          "radio",
                          { key: index, attrs: { name: item.key } },
                          [_vm._v(_vm._s(item.value))]
                        )
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("field", {
            attrs: {
              name: "身高",
              label: "身高：",
              placeholder: "请输入身高，单位CM",
              type: "number",
            },
            model: {
              value: _vm.formInfo.height,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "height", $$v)
              },
              expression: "formInfo.height",
            },
          }),
          _c("field", {
            attrs: {
              name: "体重",
              label: "体重：",
              placeholder: "请输入体重，单位KG",
              type: "number",
            },
            model: {
              value: _vm.formInfo.weight,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "weight", $$v)
              },
              expression: "formInfo.weight",
            },
          }),
          _c("field", {
            attrs: {
              name: "身份证号码",
              label: "身份证号码：",
              type: "idcard",
              maxLength: "18",
              placeholder: "请输入身份证号码",
            },
            model: {
              value: _vm.formInfo.idCard,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "idCard", $$v)
              },
              expression: "formInfo.idCard",
            },
          }),
          _c("field", {
            attrs: {
              name: "所在区域",
              label: "所在区域：",
              placeholder: "请选择所在区域",
              readonly: "",
              clickable: "",
              value: _vm.area,
              rules: [{ required: true, message: "请选择所在区域" }],
            },
            on: {
              click: function ($event) {
                _vm.showArea = true
              },
            },
          }),
          _c(
            "popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showArea,
                callback: function ($$v) {
                  _vm.showArea = $$v
                },
                expression: "showArea",
              },
            },
            [
              _c("Area", {
                attrs: { value: _vm.areaValue, "area-list": _vm.areaList },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function ($event) {
                    _vm.showArea = false
                  },
                },
              }),
            ],
            1
          ),
          _c("field", {
            attrs: {
              name: "详细地址",
              label: "详细地址：",
              placeholder: "请输入详细地址",
              rules: [{ required: true, message: "请输入详细地址" }],
            },
            model: {
              value: _vm.formInfo.address,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "address", $$v)
              },
              expression: "formInfo.address",
            },
          }),
        ],
        1
      ),
      _c("fixedButton", {
        attrs: { text: "保存" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
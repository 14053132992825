<template>
  <Popup v-model="visible" round @closed="closedHandle">
    <div class="qrcode-warp">
      <p class="qrcode-title">{{ info.text }}</p>
      <div>
        <canvas ref="qrCanvas" width="200" height="200"></canvas>
      </div>
    </div>
  </Popup>
</template>

<script>
import { Popup } from 'vant';
import QRCode from 'qrcode';

export default {
  components: { Popup },
  data() {
    return {
      value: '',
      curValue: []
    };
  },
  props: {
    show: {
      type: Boolean,
      defautl: false
    },
    info: {
      type: Object,
      required: true,
      defautl: () => {
        return {};
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  },
  methods: {
    closedHandle() {
      this.visible = false;
    },
    async generateQRCode() {
      const canvas = this.$refs.qrCanvas;
      const qrData = this.info.code;

      try {
        await QRCode.toCanvas(canvas, qrData, {
          width: 200,
          color: {
            dark: '#fff', // 透明底
            light: '#0000000' // 白色
          }
        });
        // this.addLongPressListener(canvas);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    },
    addLongPressListener(canvas) {
      canvas.addEventListener('touchstart', this.handleTouchStart);
      canvas.addEventListener('touchend', this.handleTouchEnd);
    },
    handleTouchStart(event) {
      event.preventDefault();
      this.longPressTimeout = setTimeout(() => {
        this.saveQRCodeImage();
      }, 1000); // 长按触发时间设定为1秒
    },
    handleTouchEnd() {
      clearTimeout(this.longPressTimeout);
    },
    async saveQRCodeImage() {
      const canvas = this.$refs.qrCanvas;
      const imageURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'qrcode.png';
      link.click();
    }
  },
  mounted() {
    this.generateQRCode();
  }
};
</script>

<style lang="scss" scoped>
.qrcode-warp {
  padding: $tb $lr;
  background: $btnbg;
  @include flex();
  align-items: center;
  color: #fff;

  .qrcode-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin: $tb 0;
  }
  .btn {
    margin-bottom: 0;
  }
}
</style>

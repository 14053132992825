var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["fixed-button", _vm.hasTabbar && "has-tabbar"],
      style: { width: _vm.$store.getters.screenWidth + "px" },
    },
    [
      _c("div", { staticClass: "btn", on: { click: _vm.emitEvent } }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div ref="chartBar" class="chart-bar"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      charts: {}
    };
  },

  props: {
    option: {
      type: Object,
      default() {
        return {};
      }
    },
    filterKey: {
      type: String,
      default: ''
    }
  },

  watch: {
    option: {
      handler: function(val) {
        this.drawLine(val);
      },
      deep: true
    }
  },
  mounted() {
    this.drawLine(this.option);
    window.addEventListener('resize', () => {
      this.charts.resize();
    });
  },

  methods: {
    stretchArray(originalArray, newLength) {
      const originalLength = originalArray.length;
      // 计算每个元素在新数组中的间隔
      const stretchFactor = originalLength / newLength;
      // 初始化新数组
      const stretchedArray = [];
      // 遍历新数组的每个位置
      for (let i = 0; i < newLength; i++) {
        // 计算在原数组中的位置
        const originalIndex = Math.floor(i * stretchFactor);
        // 将原数组的值填充到新数组中
        stretchedArray[i] = originalArray[originalIndex];
      }
      return stretchedArray;
    },
    mapValues(x, val, newVal) {
      const xMin = 0;
      const xMax = val;
      const yMin = 0;
      const yMax = newVal;

      // 线性映射公式
      const y = ((x - xMin) / (xMax - xMin)) * (yMax - yMin) + yMin;

      return y;
    },
    drawLine(options) {
      if (Object.keys(this.charts).length == 0) {
        this.charts = echarts.init(this.$refs.chartBar);
      }

      // /50代表 50一格
      const xValue = {
        WBC: {
          len: 256,
          interval: 256 / (400 / 50) - 1,
          val: 192,
          newVal: 300,
          show: [0, 100, 200, 300]
        },
        RBC: {
          len: 256,
          interval: 256 / (320 / 50) - 1,
          val: 240,
          newVal: 300,
          show: [0, 100, 200, 300]
        },
        PLT: {
          len: 64,
          interval: 64 / (40 / 5) - 1,
          val: 48,
          newVal: 30,
          show: [0, 10, 20, 30]
        }
      };

      const dataValue = options.dataValue.split(',');
      const line = options.line.split(',');

      this.charts.setOption({
        title: {
          text: options.title
          // left: 'center' // 设置标题水平居中
        },
        // tooltip: {
        //   trigger: 'axis'
        // },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: xValue[options.title].interval, // 设置间隔
            formatter: (value, index) => {
              const num = this.mapValues(index, xValue[options.title].val, xValue[options.title].newVal);
              if (xValue[options.title].show.includes(num)) {
                return num;
              } else {
                return '';
              }
            }
          },
          data: new Array(dataValue.length),
          axisLine: {
            show: true,
            lineStyle: {
              color: '#58595A'
            }
          },
          name: 'fL'
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true, // 显示 Y 轴线
            lineStyle: {
              color: '#58595A' // 修改 Y 轴颜色为浅灰色
            }
          },
          max: Math.max(dataValue)
        },
        series: [
          {
            name: 'Line',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: dataValue,
            lineStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 0, 0, 1)' // 渐变色的起始颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 0, 255, 1)' // 渐变色的结束颜色
                }
              ])
            },
            markLine: {
              symbol: 'none',
              lineStyle: {
                type: 'dashed',
                width: 2
              },
              label: {
                show: false // 设置为 false 隐藏数值
              },
              data: line.map(i => {
                return {
                  xAxis: Number(i)
                };
              })
            }
          }
        ]
      });
      this.charts.on('click', 'series', params => {
        params.filterKey = this.filterKey;
        this.$emit('chartFilter', params);
      });
    }
  }
};
</script>
<style scoped>
.chart-bar {
  width: 100%;
  height: 300px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "info-table" }, [
    _c(
      "div",
      { staticClass: "info-table-div" },
      [
        _c("sticky", [
          _c("div", [
            _c(
              "div",
              { staticClass: "info-table-cell" },
              _vm._l(_vm.tableArgs, function (item, index) {
                return _c("div", { key: index, staticClass: "td td1 title" }, [
                  _vm._v(_vm._s(item.title)),
                ])
              }),
              0
            ),
          ]),
        ]),
        _vm._l(_vm.tableInfo, function (item, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              { staticClass: "info-table-cell" },
              _vm._l(_vm.tableArgs, function (item2, index2) {
                return _c("div", { key: index2, staticClass: "td td2" }, [
                  _vm._v(_vm._s(item[item2.key] || "-")),
                ])
              }),
              0
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="$style['layout-content']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {},
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" module>
.layout-content {
  width: 100%;
  height: 100%;
}
</style>

import request from '@/lib/request';
import { userTypeAll } from '@/enum/index';
const userType = userTypeAll.getKeyByValue('患者端');

// 在API文件中模拟数据
export function getMallData() {
  return {
    swiper: [
      {
        id: 1,
        image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        color: '#FFB900', // 对应背景渐变色
        link: '/activity/1'
      },
      {
        id: 2,
        image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        color: '#38C0F3',
        link: '/activity/2'
      },
      {
        id: 3,
        image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        color: '#8BC34A',
        link: '/activity/3'
      }
    ],
    notices: [
      '🎉 喜迎国庆，积分兑换全场9折起',
      '🆕 新用户注册即送500积分',
      '⏰ 每周五积分翻倍活动火热进行中',
      '🎁 积分兑换区上新50+商品'
    ],
    goods: [
      {
        id: 1001,
        cover: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        name: '无线蓝牙降噪耳机',
        points: 4500,
        price: 299,
        tags: ['爆款', '限时8折']
      },
      {
        id: 1002,
        cover: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        name: '智能运动手环',
        points: 2200,
        price: 99,
        tags: ['新品']
      },
      {
        id: 1003,
        cover: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        name: '男士真皮商务鞋',
        points: 6800,
        price: 399,
        tags: ['3C认证']
      },
      {
        id: 1004,
        cover: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
        name: '便携式咖啡机',
        points: 3200,
        price: 199,
        tags: ['每日限量']
      }
    ]
  };
}

export function getCategoryData() {
  return [
    {
      id: 1,
      name: '手机数码',
      products: [
        {
          id: 101,
          name: 'iPhone 14 Pro Max',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 1000,
          price: 8999
        },
        {
          id: 102,
          name: '小米 12 Ultra',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 800,
          price: 5999
        },
        {
          id: 103,
          name: '华为 Mate 50',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 900,
          price: 6999
        }
      ]
    },
    {
      id: 2,
      name: '家用电器',
      products: [
        {
          id: 201,
          name: '智能空调',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 500,
          price: 2999
        },
        {
          id: 202,
          name: '扫地机器人',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 600,
          price: 1999
        },
        {
          id: 203,
          name: '空气净化器',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 400,
          price: 999
        }
      ]
    },
    {
      id: 3,
      name: '服装服饰',
      products: [
        {
          id: 301,
          name: '男士T恤',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 100,
          price: 99
        },
        {
          id: 302,
          name: '女士连衣裙',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 150,
          price: 199
        },
        {
          id: 303,
          name: '运动鞋',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 200,
          price: 299
        }
      ]
    },
    {
      id: 4,
      name: '食品饮料',
      products: [
        {
          id: 401,
          name: '进口红酒',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 300,
          price: 499
        },
        {
          id: 402,
          name: '坚果礼盒',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 200,
          price: 199
        },
        {
          id: 403,
          name: '有机牛奶',
          image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
          points: 50,
          price: 49
        }
      ]
    }
  ];
}

export function getCartData() {
  return [
    {
      id: 1,
      name: 'iPhone 14 Pro Max',
      image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      price: 8999,
      quantity: 1
    },
    {
      id: 2,
      name: '小米 12 Ultra',
      image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      price: 5999,
      quantity: 2
    },
    {
      id: 3,
      name: '华为 Mate 50',
      image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      price: 6999,
      quantity: 1
    },
    {
      id: 4,
      name: '智能空调',
      image: 'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      price: 2999,
      quantity: 1
    }
  ];
}

export function getProductDetail() {
  return {
    id: 1,
    name: 'iPhone 14 Pro Max',
    description: '新一代旗舰手机，性能强劲，拍照更清晰。',
    points: 1000,
    price: 8999,
    images: [
      'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg'
    ],
    detailImages: [
      'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg',
      'https://img14.360buyimg.com/n0/jfs/t1/264430/26/21071/93930/67b42146Fdfaa68b9/3b0f88242c0edec9.jpg'
    ],
    skus: [
      {
        id: 1,
        name: '128GB',
        quantity: 1
      },
      {
        id: 2,
        name: '256GB',
        quantity: 1
      },
      {
        id: 3,
        name: '512GB',
        quantity: 1
      }
    ]
  };
}

export function mallOrderLogistics(mallOrderId) {
  return request.get(`/mall/mallOrderLogistics/${mallOrderId}`);
}

export function userPoints() {
  return request.get(`/mall/userPoints/info`);
}

export function recommend(clinicId) {
  return request.get(`/mall/index/drugs/recommend/${clinicId}`);
}

export function discount(clinicId) {
  return request.get(`/mall/index/drugs/discount/${clinicId}`);
}

export function imported(clinicId) {
  return request.get(`/mall/index/drugs/imported/${clinicId}`);
}

export function clinicCategory(clinicId = 1) {
  return request.get(`/mall/drugs/clinicCategory/${clinicId}`);
}

export function categoryDrugs(categoryId, params) {
  return request.get(`/mall/drugs/categoryDrugs/${categoryId}`, {
    params
  });
}

export function mallCart() {
  return request.get(`/mall/mallCart/list`);
}

export function addMallCart(params) {
  return request.post(`/mall/mallCart`, params);
}

export function mealDetail(drugsId) {
  return request.get(`/mall/drugs/drugsInfo/${drugsId}`);
}

export function userPointsFlowList(params) {
  return request.get(`/mall/userPoints/flowList`, {
    params
  });
}

export function userPointsCardList(params) {
  return request.get(`/mall/userPoints/list`, {
    params
  });
}

export function deleteMall(mallCartIds) {
  return request.delete(`/mall/mallCart/${mallCartIds}`);
}

export function queryClinicDrugs(clinicId = 1, params) {
  return request.get(`/mall/drugs/queryClinicDrugs/${clinicId}`, {
    params
  });
}

export function cancelOrder(mallOrderId) {
  return request.delete(`/mall/mallOrder/${mallOrderId}`);
}

export function sendSms(mobile) {
  return request.get(`/auth/userlogin/sms/${mobile}/${userType}`);
}

export function smsLogin(params) {
  return request.post(`/auth/userlogin/smslogin`, {
    ...params,
    userType
  });
}

export function calculatePrice(params) {
  return request.post('/mall/mallOrder/calculatePrice', params);
}

export function userLogin(params) {
  return request.post('/user/login', params);
}

export function getClinicList(params) {
  return request.get(`/mall/clinic/list`, {
    params
  });
}

export function getDevelopClinicList(params) {
  return request.get(`/mall/clinic/developing`, {
    params
  });
}

export function getBindClinicList(params) {
  return request.get(`/mall/clinic/allClinic`, {
    params
  });
}

export function getDeviceList() {
  return request.get(`/mall/clinic/device/rel/listAllDevices`);
}

export function bindClinicDevice(params) {
  return request.post(`/mall/clinic/device/rel/bindClinicDevice`, params);
}

export function clinicAdd(params) {
  return request.put(`/mall/clinic/add`, params);
}

export function getMyTeam(params) {
  return request.get(`/mall/sales/myTeam`, {
    params
  });
}

export function getSalesInfo(id) {
  return request.get(`/mall/sales/${id}`);
}

export function editSalesInfo(params) {
  return request.put(`/mall/sales`, params);
}

export function addSalesInfo(params) {
  return request.post(`/mall/sales`, params);
}

export function addSalesInvite(params) {
  return request.post(`/mall/sales/invite`, params);
}

export function getMallInfo() {
  return request.get(`/mall/user/getInfo`);
}

export function changeAvatar(params) {
  return request.post(`/mall/user/avatar?avatar=${params.avatar}`);
}

export function getPartnerScore() {
  return request.get(`/mall/index/score`);
}

export function getRankClinic(params) {
  return request.get(`/mall/index/rank/clinic`, {
    params
  });
}

export function getRankSales() {
  return request.get(`/mall/index/rank/sales`);
}

export function salesDeviceList(params) {
  return request.get(`/mall/clinic/device/rel/salesDeviceList`, {
    params
  });
}

export function getClinicInfo(id) {
  return request.get(`/mall/clinic/getInfo/${id}`);
}

export function getDevelopingClinicInfo(hisId) {
  return request.get(`/mall/clinic/developing/${hisId}`);
}

export function delClinicDevice(id) {
  return request.delete(`/mall/clinic/device/rel/delClinicDevice/${id}`);
}

export function changeClinicDevices(params) {
  return request.post(`/mall/clinic/device/rel/changeClinicDevices`, params);
}

export function clinicWithinRange(params) {
  return request.get(`/mall/clinic/withinRange`, {
    params
  });
}

export function salesSignin(params) {
  return request.get(`/mall/clinic/salesSignin`, {
    params
  });
}

export function getOverview(params) {
  return request.get(`/mall/user/overview/`, {
    params
  });
}

export function getSalesBill(params) {
  return request.get(`/mall/bill/sales/list`, {
    params
  });
}

export function getSalesBillDetails(params) {
  return request.get(`/mall/bill/sales/details`, {
    params
  });
}

export function getClinicScore(id) {
  return request.get(`/mall/clinic/score/${id}`);
}

export function getClinicSales(id) {
  return request.get(`/mall/clinic/getSales/${id}`);
}

export function getDeviceInfo(clinicDeviceRelId) {
  return request.get(`/mall/clinic/device/rel/${clinicDeviceRelId}`);
}

export function getOneClinicServiceOrderList(params) {
  return request.get(`/mall/mallOrder/getOneClinicServiceOrderList`, {
    params
  });
}

export function getOneSalesToDoServiceOrders(params) {
  return request.get(`/mall/mallOrder/getOneSalesToDoServiceOrders`, {
    params
  });
}

export function clinicDevelopingEdit(params) {
  return request.post(`/mall/clinic/developing/edit`, params);
}

export function clinicDevelopingAdd(params) {
  return request.post(`/mall/clinic/developing/add`, params);
}

export function mealListService() {
  return request.get('/mall/meal/listService');
}

export function wxPay(params) {
  return request.post(`/mall/mallOrder/wxPay`, params);
}

export function createServiceOrder(params) {
  return request.post('/mall/mallOrder/createMallOrder', params);
}

export function orderList(params) {
  return request.get(`/mall/mallOrder/list`, {
    params
  });
}

export function getServiceOrder(mallOrderId) {
  return request.get(`/mall/mallOrder/${mallOrderId}`);
}

export function orderBindClinic(params) {
  return request.post(`/mall/mallOrder/bindClinic`, params);
}

export function orderBindExport(params) {
  return request.post(
    `/mall/bill/sales/export?billId=${params.billId}`,
    {},
    {
      responseType: 'blob'
    }
  );
}

export function getAddressList(params) {
  return request.get(`/mall/address/list`, {
    params
  });
}
export function addAddress(params) {
  return request.post(`/mall/address`, params);
}

export function editAddress(params) {
  return request.put(`/mall/address`, params);
}

export function getAddress(id) {
  return request.get(`/mall/address/${id}`);
}

export function deleteAddress(id) {
  return request.delete(`/mall/address/${id}`);
}

export function createFeedback(params) {
  return request.post(`/mall/feedback`, params);
}

export function getClinicDeviceStock(clinicId) {
  return request.get(`/mall/clinic/stock/getClinicDeviceStock/${clinicId}`);
}

export function getPerformanceList(params) {
  return request.get(`/mall/performance/list`, {
    params
  });
}

export function reduceOrderInventory(params) {
  return request.post(`/mall/mallOrder/reduceOrderInventory`, params);
}

export function stockSalesList(params) {
  return request.get(`/mall/stockSales/list`, {
    params
  });
}

export function listDeviceStockSales(params) {
  return request.get(`/mall/stockSales/listDeviceStockSales`, {
    params
  });
}

export function transferSalesStock(params) {
  return request.post(`/mall/stockSales/transferSalesStock`, params);
}

export function salesStockRecord(params) {
  return request.get(`/mall/salesStockRecord/list`, {
    params
  });
}

export function addDeviceApplyRecord(params) {
  return request.post(`/mall/deviceApplyRecord`, params);
}

export function deviceApplyRecordList(params) {
  return request.get(`/mall/deviceApplyRecord/list`, {
    params
  });
}

export function getSalesDevices(params) {
  return request.get(`/mall/clinic/device/rel/getSalesDevices`, {
    params
  });
}

export function deviceRecord(params) {
  return request.post(`/mall/deviceRecord`, params);
}

export function performanceListSummary(params) {
  return request.get(`/mall/performance/listSummary`, params);
}

export function contractList(params) {
  return request.get(`/mall/contract/list/clinic`, params);
}

export function contractListClinic(params) {
  return request.get(`/mall/contract/list/clinic/sign`, {
    params
  });
}

export function contractApply(params) {
  return request.post(`/mall/contract/apply`, params);
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openWechat
    ? _c(
        "div",
        { staticClass: "w" },
        [
          _c("div", [
            _c("img", { staticClass: "logo", attrs: { src: _vm.logo } }),
          ]),
          !_vm.isWeChat
            ? _c("p", { staticClass: "tip-btn", on: { click: _vm.reload } }, [
                _vm._v("正在打开微信"),
              ])
            : _vm._e(),
          _vm.isWeChat
            ? _c(
                "wx-open-launch-weapp",
                {
                  attrs: { id: "launch-btn", appid: _vm.appid, path: _vm.path },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      ' <style> .tip-btn { margin-top: 100px; padding: 10px 20px; border-radius: 20px; border:none; background-color: #16d46b; color: #fff; font-size: $size5; display: inline-block; } </style> <button class="tip-btn">打开小程序</button> '
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("p", { staticClass: "tip" }, [_vm._v("如遇无法打开小程序，")]),
          _c("p", { staticClass: "tip" }, [
            _vm._v('可打开微信搜索 "i检康" 小程序进行查看'),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
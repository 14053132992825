export default function(e) {
  const dataValue = JSON.parse(e.dataValue);
  e.checkReportInspectionList = dataValue.map(i => {
    let flag = '';
    if (i.flag === 'L') {
      flag = 'Down';
    } else if (i.flag === 'H') {
      flag = 'Up';
    }
    return {
      ...i,
      paramValue: i.result,
      paramName: i.project_name,
      range: i.reference || '',
      flag
    };
  });
}

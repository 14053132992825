var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "add-doctor" }, [
        _c(
          "div",
          { staticClass: "device-decord-form" },
          [
            _vm.deviceNums.length
              ? _c(
                  "div",
                  { staticClass: "device-decord-tags" },
                  [
                    _vm._v(" 已添加："),
                    _vm._l(_vm.deviceNums, function (item, index) {
                      return _c(
                        "span",
                        { key: item, staticClass: "dev-tag" },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          _c("Icon", {
                            attrs: { name: "cross" },
                            on: {
                              click: function ($event) {
                                return _vm.delDeviceNums(index)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "van-form",
              { ref: "formRef" },
              [
                _c("field", {
                  attrs: {
                    value: _vm.formInfo.f1.text,
                    name: "产品",
                    label: "产品",
                    placeholder: "请选择产品",
                    required: "",
                    readonly: "",
                    clickable: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.settingDevicesShow = true
                    },
                  },
                }),
                !_vm.isEdit
                  ? _c(
                      "popup",
                      {
                        attrs: { position: "bottom" },
                        model: {
                          value: _vm.settingDevicesShow,
                          callback: function ($$v) {
                            _vm.settingDevicesShow = $$v
                          },
                          expression: "settingDevicesShow",
                        },
                      },
                      [
                        _c("picker", {
                          attrs: {
                            columns: _vm.settingDevicesList,
                            "value-key": "text",
                          },
                          on: {
                            confirm: _vm.settingDevicesListConfirm,
                            cancel: function ($event) {
                              _vm.settingDevicesShow = false
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("field", {
                  attrs: {
                    name: "设备编号",
                    label: "设备编号",
                    placeholder: "请扫码添加设备编号",
                    rules: [{ validator: () => _vm.deviceNums.length > 0 }],
                    "right-icon": "scan",
                    required: "",
                    readonly: "",
                    clickable: "",
                  },
                  on: { click: _vm.toScan },
                  model: {
                    value: _vm.formInfo.deviceNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "deviceNum", $$v)
                    },
                    expression: "formInfo.deviceNum",
                  },
                }),
                _c("field", {
                  attrs: { name: "联系人", label: "联系人：", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c(
                            "radio-group",
                            {
                              attrs: {
                                direction: "horizontal",
                                disabled: _vm.isEdit,
                              },
                              model: {
                                value: _vm.formInfo.receiveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInfo, "receiveType", $$v)
                                },
                                expression: "formInfo.receiveType",
                              },
                            },
                            [
                              _c("radio", { attrs: { name: "sales" } }, [
                                _vm._v("业务员"),
                              ]),
                              _c("radio", { attrs: { name: "people" } }, [
                                _vm._v("联系人"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm.formInfo.receiveType === "sales"
                  ? [
                      _c("field", {
                        attrs: {
                          value: _vm.formInfo.f2.text,
                          name: "业务员",
                          label: "业务员",
                          placeholder: "请选择业务员",
                          required: "",
                          readonly: "",
                          clickable: "",
                          rules: [{ required: true }],
                        },
                        on: {
                          click: function ($event) {
                            _vm.salesShow = true
                          },
                        },
                      }),
                      !_vm.isEdit
                        ? _c(
                            "popup",
                            {
                              attrs: { position: "bottom" },
                              model: {
                                value: _vm.salesShow,
                                callback: function ($$v) {
                                  _vm.salesShow = $$v
                                },
                                expression: "salesShow",
                              },
                            },
                            [
                              _c("picker", {
                                attrs: {
                                  columns: _vm.salesList,
                                  "value-key": "text",
                                },
                                on: {
                                  confirm: _vm.salesListConfirm,
                                  cancel: function ($event) {
                                    _vm.salesShow = false
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.formInfo.receiveType === "people"
                  ? [
                      _c("field", {
                        attrs: {
                          name: "联系人姓名",
                          label: "联系人姓名",
                          placeholder: "请输入联系人姓名",
                          type: "text",
                          rules: [{ required: true }],
                          required: "",
                          readonly: _vm.isEdit,
                        },
                        model: {
                          value: _vm.formInfo.receiveName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInfo, "receiveName", $$v)
                          },
                          expression: "formInfo.receiveName",
                        },
                      }),
                    ]
                  : _vm._e(),
                _c("field", {
                  attrs: {
                    name: "电话号码",
                    label: "电话号码",
                    placeholder: "请输入电话号码",
                    type: "number",
                    maxLength: "11",
                    readonly: _vm.isEdit,
                  },
                  model: {
                    value: _vm.formInfo.receiveMobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "receiveMobile", $$v)
                    },
                    expression: "formInfo.receiveMobile",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "地区",
                    label: "地区",
                    placeholder: "请选择地区",
                    readonly: "",
                    clickable: "",
                    value: _vm.area,
                    rules: [{ required: true }],
                    required: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showArea = true
                    },
                  },
                }),
                !_vm.isEdit
                  ? _c(
                      "popup",
                      {
                        attrs: { position: "bottom" },
                        model: {
                          value: _vm.showArea,
                          callback: function ($$v) {
                            _vm.showArea = $$v
                          },
                          expression: "showArea",
                        },
                      },
                      [
                        _c("Area", {
                          attrs: {
                            value: _vm.areaValue,
                            "area-list": _vm.areaList,
                          },
                          on: {
                            confirm: _vm.onConfirm,
                            cancel: function ($event) {
                              _vm.showArea = false
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("field", {
                  attrs: {
                    name: "详细地址",
                    label: "详细地址",
                    placeholder: "请输入详细地址",
                    rules: [{ required: true }],
                    type: "textarea",
                    required: "",
                    readonly: _vm.isEdit,
                  },
                  model: {
                    value: _vm.formInfo.addressDetail,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "addressDetail", $$v)
                    },
                    expression: "formInfo.addressDetail",
                  },
                }),
                _c("field", {
                  attrs: {
                    name: "说明",
                    label: "说明",
                    placeholder: "请输入说明",
                    type: "textarea",
                    readonly: _vm.isEdit,
                  },
                  model: {
                    value: _vm.formInfo.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "remark", $$v)
                    },
                    expression: "formInfo.remark",
                  },
                }),
                _vm._l(_vm.imageList, function (item, index) {
                  return _c("field", {
                    key: index,
                    attrs: { name: "uploader", label: item.label },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c("uploader", {
                                attrs: {
                                  maxCount: _vm.maxCount,
                                  "after-read": (from) =>
                                    _vm.afterRead(from, item),
                                  readonly: _vm.isEdit,
                                  deletable: !_vm.isEdit,
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticStyle: { height: "200px" } }),
      !_vm.isEdit
        ? _c("fixedButton", {
            attrs: { text: "保存" },
            on: { emitEvent: _vm.submit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
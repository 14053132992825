<template>
  <div class="partner-info">
    <div class="ach-warp">
      <div class="personal-info" v-if="partnerInfo.id">
        <van-image fit="cover" :src="partnerInfo.avatar || avatar" :alt="partnerInfo.name" class="avatar" />
        <p class="personal-name">{{ partnerInfo.name }}</p>
        <div class="personal-phone">
          <p class="personal-tel" @click="dialPhoneNumber(partnerInfo.phone)">
            <icon name="phone" /> {{ partnerInfo.phone }}
          </p>
          <p class="personal-age">
            <span> {{ $getGender(partnerInfo.gender) }} </span>
            <span>{{ partnerInfo.age }}岁</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/service';
import { Icon, Image as VanImage } from 'vant';

export default {
  components: { Icon, VanImage },
  data() {
    return {
      opinionModel: {
        show: false,
        info: {}
      },
      pullLoading: false,
      partnerInfo: {},
      avatar: this.$getImg('avatar')
    };
  },
  mounted() {
    this.getSales();
  },
  methods: {
    async getSales() {
      const res = await services.clinic.getSales();
      this.partnerInfo = res.data;
    },
    dialPhoneNumber(phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
.partner-info {
  .line-title {
    margin: $tb $lr 0px;
  }
  // padding: $tb $lr;
  .ach-warp {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
    background: #fff;
    color: #fff;

    .personal-info {
      // text-align: center;
      background: $btnbg;
      padding: $tb $lr;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar {
        border-radius: 50%;
        width: 70px;
        height: 70px !important;
        border: 2px solid #fff;
        overflow: hidden;
      }
      .personal-name {
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
      }
      .personal-info {
      }
      .personal-tel {
        font-size: $size3;
      }

      .personal-age {
        display: flex;
        justify-content: space-around;
        font-size: $size3;
      }
    }

    .ach-content {
      display: flex;
      justify-content: space-around;
      padding: $tb $lr;
      > div {
        flex: 1;
        text-align: center;
        border-right: 2px solid $bordercolor;
        &:last-child {
          border-right: none;
        }
      }
      .ach-title {
        color: $gray2;
        font-size: $size6;
      }
      .ach-num {
        font-size: 16px;
        padding-top: $tb;
        text-align: center;
        color: $black1;
        font-size: $size5;
      }
    }
    // > div {
    //   text-align: center;
    //   .ach-title {
    //     font-size: $size4;
    //     color: $gray2;
    //     margin-bottom: $tb;
    //     font-weight: 600;
    //   }
    //   .ach-num {
    //     font-size: 24px;
    //     font-weight: 600;
    //     color: $black1;
    //   }
    // }
  }
}
</style>

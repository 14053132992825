<template>
  <div class="data-echarts">
    <div v-if="chartsImage.length">
      <div v-for="(item, index) in chartsImage" :key="index">
        <p>{{ item.title }}</p>
        <van-image fit="cover" :src="item.image" style="width: 100%" />
      </div>
    </div>
    <div v-else>
      <lineChart v-for="(item, index) in chartsList" :key="index" :option="item" />
    </div>

    <div class="data-info" v-if="false">
      <p>结果解读：</p>
      <div class="data-info-content">
        根据本次样本检测分析，CRP检测结果阴性，白细胞正常，淋巴细胞正常，粒细胞正常，，提示无感染或感染已恢复
        具体判断请结合临床和连续监测的数值，以医生诊断为 准。
      </div>
    </div>
  </div>
</template>

<script>
import lineChart from '@/components/echarts/lineChart';
import { Image as VanImage } from 'vant';

export default {
  components: { lineChart, VanImage },
  data() {
    return {
      searchVal: '',
      lineChart
    };
  },
  props: {
    chartsList: {
      type: Array
    },
    chartsImage: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.data-echarts {
  margin-top: $tb;
  background: #fff;
  padding: $tb $lr;
}
</style>

<template>
  <div class="info-edit">
    <div class="info-edit-form">
      <field name="uploader" label="头像：">
        <template #input>
          <uploader :maxCount="maxCount" v-model="avatar" :after-read="from => afterRead(from, 'avatar')" />
        </template>
      </field>
      <field
        v-model="formInfo.phone"
        name="手机号"
        label="手机号："
        placeholder="请输入手机号"
        type="number"
        maxLength="11"
        disabled
      />
      <field name="radio" label="性别：">
        <template #input>
          <radio-group v-model="formInfo.gender" direction="horizontal">
            <radio :name="item.key" v-for="(item, index) in genderType.enums" :key="index">{{ item.value }}</radio>
          </radio-group>
        </template>
      </field>

      <field v-model="formInfo.height" name="身高" label="身高：" placeholder="请输入身高，单位CM" type="number" />
      <field v-model="formInfo.weight" name="体重" label="体重：" placeholder="请输入体重，单位KG" type="number" />
      <field
        v-model="formInfo.idCard"
        name="身份证号码"
        label="身份证号码："
        type="idcard"
        maxLength="18"
        placeholder="请输入身份证号码"
      />

      <field
        name="所在区域"
        label="所在区域："
        placeholder="请选择所在区域"
        readonly
        clickable
        :value="area"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择所在区域' }]"
      />
      <popup v-model="showArea" position="bottom">
        <Area :value="areaValue" :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </popup>
      <field
        v-model="formInfo.address"
        name="详细地址"
        label="详细地址："
        placeholder="请输入详细地址"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />
    </div>

    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import { Field, Popup, Area, RadioGroup, Radio, Uploader } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';
import { genderType } from '@/enum';

export default {
  components: { Field, Popup, Area, RadioGroup, Radio, Uploader, fixedButton },
  data() {
    return {
      avatar: [],
      showArea: false,
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {},
      frontPhoto: [{ url: '' }],
      licensePhoto: [{ url: '' }],
      maxCount: 1,
      genderType
    };
  },
  mounted() {
    this.getPatientInfo();
  },
  methods: {
    async afterRead(form, type) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      this[type] = [res.data];
    },
    async getPatientInfo() {
      const res = await services.patient.getPatientInfo();
      this.formInfo = { ...res.data, ...res.data.patient };

      if (res.data.avatar) {
        this.avatar = [{ url: res.data.avatar }];
      }

      if (this.formInfo.district) {
        this.area = `${this.formInfo.province}/${this.formInfo.city}/${this.formInfo.district}`;

        let areaValue = '';
        for (const key in areaList.county_list) {
          if (areaList.county_list[key] === this.formInfo.district) {
            areaValue = key;
          }
        }
        this.areaValue = areaValue;
      }
      // this.frontPhoto = [{ url: res.data.frontPhoto }];
      // this.licensePhoto = [{ url: res.data.licensePhoto }];
    },
    onConfirm(values) {
      this.area = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/');
      this.showArea = false;
    },
    calendarConfirm(date) {
      this.formInfo.validity = `${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    async submit() {
      const params = {
        phone: this.formInfo.phone,
        gender: this.formInfo.gender,
        height: this.formInfo.height,
        weight: this.formInfo.weight,
        address: this.formInfo.address
      };
      if (this.formInfo.idCard) {
        params.idCard = this.formInfo.idCard;
      }
      if (this.avatar.length) {
        params.avatar = this.avatar[0].url;
      }
      if (this.area) {
        const areaList = this.area.split('/');
        params.province = areaList[0];
        params.city = areaList[1];
        params.district = areaList[2];
      }

      if (this.avatar.length) {
        await services.patient.changeAvatar({
          avatar: this.avatar[0].url
        });
      }

      await services.patient.editPatientInfo(params);
      this.$toast('保存成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.info-edit {
  .info-edit-form {
    margin: $tb $lr;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

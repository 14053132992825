<template>
  <div :class="['fixed-button', hasTabbar && 'has-tabbar']" :style="{ width: $store.getters.screenWidth + 'px' }">
    <div @click="emitEvent" class="btn">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '保存'
    },
    hasTabbar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitEvent() {
      this.$emit('emitEvent');
    }
  }
};
</script>

<style scoped lang="scss">
.fixed-button {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: $btnBox;
  padding: $tb $lr calc(#{$tb} + #{env(safe-area-inset-bottom)});
  font-size: $size1;
  box-shadow: $top-shadow;

  &.has-tabbar {
    bottom: calc(#{$tabbarHeight} + #{env(safe-area-inset-bottom)});
    padding: $tb $lr;
  }

  .btn {
    @include flexCenter();
    width: 100%;
    height: $btnheight;
    border-radius: $radius;
    background: $btnbg;
    color: #fff;
    &:active {
      background: darken($appcolor, 10%);
    }
  }

  &.wx {
    .btn {
      background: $wxbg;
    }
  }
}
</style>

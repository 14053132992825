var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("search", {
        attrs: { placeholder: "请输入搜索关键词" },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c(
        "div",
        { staticClass: "wrap" },
        _vm._l(_vm.searchResult, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "box",
              on: {
                "!click": function ($event) {
                  return _vm.toPage(item.path)
                },
              },
            },
            [
              _c(item.component, { tag: "component", staticClass: "item" }),
              _c("div"),
              _c("div", { staticClass: "component-title" }, [
                _vm._v(_vm._s(_vm.getTitle(item))),
              ]),
              _c("div", { staticClass: "component-title path" }, [
                _vm._v(_vm._s(item.path)),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
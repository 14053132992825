var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("search", {
        attrs: { placeholder: "请输入搜索关键词" },
        on: { search: _vm.refreshList },
        model: {
          value: _vm.queryParams.keywords,
          callback: function ($$v) {
            _vm.$set(_vm.queryParams, "keywords", $$v)
          },
          expression: "queryParams.keywords",
        },
      }),
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.listInfo.length == 0 && !_vm.loading
                ? _c("div", {
                    staticStyle: { "text-align": "center", padding: "10vh 0" },
                  })
                : _vm._e(),
              _vm._l(_vm.listInfo, function (product) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    staticClass: "product-item",
                    on: {
                      click: function ($event) {
                        return _vm.toDeatil(product.drugsId)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "product-img" },
                      [
                        _c("van-image", {
                          attrs: {
                            src: product.drugsImage.split(",")[0],
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "product-info" }, [
                      _c("p", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(product.name)),
                      ]),
                      _c(
                        "p",
                        { staticClass: "product-points" },
                        [_c("pointPrice", { attrs: { product: product } })],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "add-to-cart",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addToCart(product)
                          },
                        },
                      },
                      [
                        _c("van-icon", {
                          attrs: { name: "add", size: "22", color: "#00bdd4" },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
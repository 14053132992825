<template>
  <div class="operation-page">
    <p>爱检康 操作手册</p>
    <div class="operation">
      <div class="operation-item" v-for="(item, index) in list" :key="index">
        <div class="item-left">第{{ item + 1 }}步</div>
        <div class="item-right">
          <p>a啊实打实大苏打阿斯顿</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    };
  },
  methods: {},
  computed: {
    userType() {
      return this.$route.query.userType;
    },
    token() {
      return this.$route.query.token;
    }
  },
  async mounted() {}
};
</script>

<style lang="scss" scoped>
.operation-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: #fff;
}
.operation {
  flex: 1;
  height: 0;
  background: #fff;
  position: relative;
  .operation-item {
    @include flex(row);
    .item-left {
      flex: 1;
      border-right: 2px solid $appcolor;
      padding: 0 $lr;
    }
    .item-right {
      flex: 5;
      height: 200px;
      position: relative;
      padding: 0 $lr;
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: $appcolor;
        border-radius: 50%;
        position: absolute;
        left: -6px;
        top: 4px;
      }
    }
  }
}
</style>

import Vue from 'vue';
import filter from './filter';
import directive from './directive';
import globalComponents from './globalComponents';

export default function() {
  const plugins = [filter, directive, globalComponents];

  plugins.forEach(item => Vue.use(item));
}

import services from '@/service';

export default {
  state: {
    partnerId: '',
    partnerInfo: {},
    isPartner: false,
    isPartnerC: false
  },
  getters: {
    partnerId: state => state.partnerId,
    partnerInfo: state => state.partnerInfo,
    isPartner: state => state.isPartner,
    isPartnerC: state => state.partnerInfo.sales && state.partnerInfo.sales.levelId === 3
  },
  actions: {
    set_partnerId({ commit }, value) {
      commit('set_partnerId', value);
    },
    async set_partnerInfo({ commit }) {
      const { data } = await services.partner.getPartnerInfo();
      commit('set_partnerInfo', data);
      commit('set_isPartner', data.sales.salesType === '1');
    }
  },
  mutations: {
    set_partnerId(state, value) {
      state.partnerId = value;
    },
    set_partnerInfo(state, value) {
      state.partnerInfo = value;
      state.partnerId = value.userRelId;
    },
    set_isPartner(state, value) {
      state.isPartner = value;
    }
  }
};

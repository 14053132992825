import services from '@/service';
import { checkOrderStateAll } from '@/enum/index';
import { getUserType } from '@/lib/utils';

export default {
  state: {
    token: '',
    openId: '',
    userInfo: null,
    checkOrderState: null,
    addressInfo: null,
    personalEnterprise: null,
    enterprises: [],
    enterpriseId: '',
    isApproved: false
  },
  getters: {
    isLogin: state => !!state.token && !!state.userInfo,
    token: state => state.token,
    openId: state => state.openId,
    userInfo: state => state.userInfo,
    checkOrderState: state => state.checkOrderState,
    addressInfo: state => {
      return state.addressInfo || JSON.parse(localStorage.getItem('addressInfo') || '{}');
    }
  },
  actions: {
    set_token({ commit }, value) {
      commit('set_token', value);
    },
    set_openId({ commit }, value) {
      const val = ['undefined', 'null'].includes(value) ? '' : value;
      commit('set_openId', val);
    },
    async initUserInfo({ commit }) {
      const userInfo = await services.auth.getUserInfo();
      commit('set_userInfo', userInfo);
    },
    async set_checkOrderState({ commit }) {
      const res = await checkOrderStateAll();
      commit('set_checkOrderState', res);
    },
    async set_addressInfo({ commit }, value) {
      commit('set_addressInfo', value);
    }
  },
  mutations: {
    set_token(state, value) {
      localStorage.setItem(`webview_token_${getUserType()}`, value);
      state.token = value;
    },
    set_openId(state, value) {
      localStorage.setItem(`openId`, value);
      state.openId = value;
    },
    set_userInfo(state, value) {
      state.userInfo = value;
    },

    set_checkOrderState(state, value) {
      state.checkOrderState = value;
    },
    set_addressInfo(state, value) {
      state.addressInfo = value;
      localStorage.setItem('addressInfo', JSON.stringify(value));
    }
  }
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "address-item", on: { click: _vm.selectAddr } },
        [
          _c("div", { staticClass: "address-content" }, [
            _c("i", { staticClass: "iconfont icon-shdz" }),
            _vm.addressInfo.addressId
              ? _c("div", { staticClass: "address-info" }, [
                  _c("h3", { staticClass: "address-name" }, [
                    _c("span", [_vm._v(_vm._s(_vm.addressInfo.addressee))]),
                    _c("span", { staticClass: "address-phone" }, [
                      _vm._v(_vm._s(_vm.addressInfo.phone)),
                    ]),
                  ]),
                  _c("p", { staticClass: "address-area" }, [
                    _vm._v(_vm._s(_vm.showArea(_vm.addressInfo))),
                  ]),
                ])
              : _c("div", { staticClass: "address-info" }, [
                  _c("h3", { staticClass: "address-area" }, [
                    _vm._v("请选择地址"),
                  ]),
                ]),
            _c("div", { staticClass: "address-status" }, [
              _c(
                "p",
                { staticClass: "view-details" },
                [_c("icon", { attrs: { name: "arrow" } })],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "field-page" }, [
        _c(
          "div",
          { staticClass: "field-page-form" },
          [
            _vm.consumables.enums
              ? _c("fieldForm", {
                  ref: "formRef",
                  attrs: { fields: _vm.fields },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticStyle: { height: "200px" } }),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("fixedButton", {
        attrs: { text: "提交" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info-edit">
    <p class="top-tip">先上传<span> 身份证 </span>，系统将自动识别并回填信息</p>
    <div class="field-page">
      <div class="field-page-form">
        <fieldForm ref="formRef" :fields="fields" />
      </div>
      <div style="height: 200px;"></div>
    </div>
    <fixedButton text="提交" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { genderType } from '@/enum';
import fieldForm from '@/components/fieldForm';
import { phoneReg } from '@/lib/utils';

export default {
  components: { fixedButton, fieldForm },
  data() {
    return {
      genderType,
      fields: [
        {
          label: '手机号码',
          key: 'phone',
          type: 'number',
          maxLength: '11',
          rules: [{ required: true, message: '请输入正确的手机号码', pattern: phoneReg }],
          required: true,
          value: ''
        },
        {
          label: '所在区域',
          key: 'area',
          type: 'area',
          rules: [{ required: true, message: '请选择所在区域' }],
          required: true,
          show: false,
          popupValue: '',
          value: ''
        },
        {
          label: '身份证人像',
          key: 'idCardFigure',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          ocrType: 'idcard_front',
          ocrKey: {
            name: 'name',
            idCard: 'idCard',
            address: 'address',
            gender: 'gender'
          },
          isEncrypt: true,
          encryptText: '邀请业务员注册填写资料'
        },
        {
          label: '身份证国徽',
          key: 'idCardBadge',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          isEncrypt: true,
          encryptText: '邀请业务员注册填写资料'
        },
        {
          label: '姓名',
          key: 'name',
          type: 'text',
          rules: [{ message: '请输入正确的姓名', pattern: /^[A-Za-z\u4e00-\u9fa5.]+$/ }],
          required: true,
          value: ''
        },
        {
          label: '性别',
          key: 'gender',
          type: 'radio-group',
          rules: [],
          required: true,
          value: '0',
          actions: genderType.enums.map(i => {
            return {
              name: i.value,
              key: i.key
            };
          })
        },
        {
          label: '身份证号码',
          key: 'idCard',
          type: 'idcard',
          rules: [{ required: true, message: '请输入身份证号码' }],
          required: true,
          value: ''
        },
        {
          label: '详细地址',
          key: 'address',
          type: 'textarea',
          maxLength: '500',
          rules: [{ required: true, message: '请输入详细地址' }],
          required: true,
          value: ''
        },
        {
          label: '头像',
          key: 'avatar',
          type: 'uploader',
          rules: [],
          required: false,
          value: [],
          isEncrypt: true,
          encryptText: '邀请业务员注册填写资料'
        }
      ]
    };
  },
  computed: {
    parentId() {
      return this.$route.query.id;
    }
  },
  methods: {
    async submit() {
      const params = await this.$refs.formRef.validate();

      await services.partner.addSalesInvite({ ...params, salesType: '0', salesCode: 'Y', parentId: this.parentId });
      this.$router.replace({
        name: 'partnerInviteSuccess'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.field-page {
  padding: $tb $lr;
  .field-page-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

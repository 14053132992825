import request from '@/lib/request';
// import { userTypeAll } from '@/enum/index';
// const userType = userTypeAll.getKeyByValue('后台管理');

export function getCode() {
  return request.get(`/code`);
}

export function smsLogin(params) {
  return request.post(`/auth/login`, params);
}

export function getAdminInfo(params) {
  return request.get(`/system/user/getInfo`, params);
}

export function getRouters() {
  return request.get(`/system/menu/getRouters`);
}

export function checkOrderList(params) {
  return request.get('/clinic/checkOrder/list', { params });
}

// 查询诊所审批列表
export function listHis(params) {
  return request.get('/cms/clinic/his/list', { params });
}

export function getClinicHisInfo(id) {
  return request.get(`/cms/clinic/his/${id}`);
}

export function getDoctorHisInfo(id) {
  return request.get(`/cms/doctor/his/${id}`);
}

export function changeAudit(params) {
  return request.put(`/cms/clinic/his/changeAudit`, params);
}

export function changeDoctorAudit(params) {
  return request.put(`/cms/doctor/his/changeAudit`, params);
}

export function listCheckOrder(params) {
  return request.get('/cms/checkOrder/list', { params });
}

export function listClinic(params) {
  return request.get('/cms/clinic/stock/list', { params });
}

export function queryReports(checkOrderDetailId) {
  return request.get(`/cms/checkReport/queryReports/${checkOrderDetailId}`);
}

export function clinicList(params) {
  return request.get('/cms/clinic/list', { params });
}

export function getClinicInfo(id) {
  return request.get(`/cms/clinic/${id}`);
}

export function deviceRecordList(params) {
  return request.get('/cms/deviceRecord/list', { params });
}

export function addDeviceRecord(params) {
  return request.post('/cms/deviceRecord', params);
}

export function deviceRecordInfo(id) {
  return request.get(`/cms/deviceRecord/${id}`);
}

export function monthlyStatistics() {
  return request.get(`/cms/statistics/h5/monthlyStatistics`);
}

export function yearStatistics() {
  return request.get(`/cms/statistics/h5/yearStatistics`);
}

export function dataPanel() {
  return request.get(`/cms/statistics/h5/dataPanel`);
}

export function monthlyAnalysis(params) {
  return request.get(`/cms/statistics/h5/monthlyAnalysis`, { params });
}

export function timesAnalysis(params) {
  return request.get(`/cms/statistics/h5/timesAnalysis`, { params });
}

export function yearWeekSelect() {
  return request.get(`/cms/statistics/h5/yearWeekSelect`);
}

export function weekAnalysis(params) {
  return request.get(`/cms/statistics/h5/weekAnalysis`, { params });
}

export function getPerformanceList(params) {
  return request.get(`/cms/performance/list`, { params });
}

export function doctorListHis(params) {
  return request.get('/cms/doctor/his/list', { params });
}

export function deviceApplyRecordList(params) {
  return request.get(`/cms/deviceApplyRecord/list`, { params });
}

export function approveDevice(params) {
  return request.post(`/cms/deviceApplyRecord/approveDevice`, params);
}

export function getDeviceApplyRecord(deviceApplyRecordId) {
  return request.get(`/cms/deviceApplyRecord/${deviceApplyRecordId}`);
}

export function deliveryDevice(params) {
  return request.post(`/cms/deviceApplyRecord/deliveryDevice`, params);
}

export function clinicStockAddInventory(params) {
  return request.post(`/cms/clinic/stock/addInventory`, params);
}

export function performanceListSummary(params) {
  return request.get(`/cms/performance/listSummary`, { params });
}

<template>
  <div>
    <search v-model="queryParams.deviceNum" placeholder="请输入设备编号" @search="refreshList" show-action>
      <template #action>
        <i class="iconfont icon-sm" @click="toScan" />
      </template>
    </search>
    <dropdown-menu active-color="#1989fa" v-if="!notSelect">
      <dropdown-item v-model="queryParams.clinicId" :options="clinicList.enums" @change="refreshList" />
    </dropdown-menu>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="device-list">
          <div class="device-item" v-for="(device, index) in listInfo" :key="index">
            <div class="device-content">
              <div class="device-image">
                <van-image fit="cover" :src="device.goodsIcon" :alt="device.goodsName" class="device-avatar" />
                <span class="device-status-tag" :class="getStatusClass(device.status)">
                  {{ defaultFlagAll.getValueByKey(device.status) }}
                </span>
              </div>
              <div class="device-info">
                <h3 class="device-name">{{ device.deviceNum }}</h3>
                <p class="clinic-name">{{ device.goodsName }}</p>
                <p class="clinic-name">{{ device.clinicName }}</p>
              </div>
              <!-- <div class="device-status">
              <p class="view-details">查看详情<icon name="arrow" /></p>
              <p class="view-details">解绑<icon name="arrow" /></p>
            </div> -->
            </div>
            <div class="report-info report-foot">
              <span class="patient-name">{{ device.salesName }}</span>
              <span class="report-time">
                <span class="foot-btn unbind-btn" @click.stop="unbind(device)" v-if="showAction">解绑</span>
                <span class="foot-btn replace-btn" @click.stop="replace(device)" v-if="showAction">更换</span>
                <span class="foot-btn detail-btn" @click.stop="detail(device)">查看详情</span>
              </span>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>
  </div>
</template>

<script>
import { PullRefresh, List, Image as VanImage, DropdownMenu, DropdownItem, Search } from 'vant';
import services from '@/service';
import { defaultFlagAll, clinicAll } from '@/enum/index';
import { scanQRCode } from '@/lib/utils';
import { mapGetters } from 'vuex';

export default {
  components: { PullRefresh, List, VanImage, DropdownMenu, DropdownItem, Search },
  data() {
    return {
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        clinicId: '',
        deviceNum: ''
      },
      listInfo: [],
      defaultFlagAll,
      clinicList: {}
    };
  },
  computed: {
    ...mapGetters(['isPartnerC']),
    showAction() {
      return !this.isPartnerC;
    },
    notSelect() {
      return this.$route.query.clinicId;
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.queryParams.salesId = this.$route.query.id;
    }

    if (this.$route.query.clinicId) {
      this.queryParams.clinicId = this.$route.query.clinicId;
    }

    if (this.$route.query.deviceNum) {
      this.queryParams.deviceNum = this.$route.query.deviceNum;
    }

    const [clinicList] = await Promise.all([clinicAll()]);
    this.clinicList = clinicList;
    this.refreshList();
  },
  methods: {
    async toScan() {
      const deviceNum = await scanQRCode();
      this.queryParams.deviceNum = deviceNum;
      this.refreshList();
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.partner.salesDeviceList({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    unbind(item) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定解绑？'
        })
        .then(async () => {
          await services.partner.delClinicDevice(item.clinicDeviceRelId);
          this.$toast('操作成功');
          this.refreshList();
        });
    },
    replace(item) {
      this.$router.push({
        name: 'partnerBindDevice',
        query: {
          clinicId: item.clinicId,
          goodsSettingId: item.goodsSettingId,
          clinicDeviceRelId: item.clinicDeviceRelId
        }
      });
    },
    detail(item) {
      this.$router.push({
        name: 'partnerDeviceDetail',
        query: {
          id: item.clinicDeviceRelId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-sm {
  font-size: 24px;
}

.device-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr 0;
  margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.device-content {
  display: flex;
  align-items: center;
}

.device-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.device-avatar {
  width: 100%;
  height: auto;
}

.device-info {
  flex: 1;
  line-height: 20px;
  margin-bottom: $tb;
}

.device-name {
  font-size: $size2;
  font-weight: 600;
  color: $black1;
}

.clinic-name {
  color: $gray2;
  font-size: $size5;
  margin-top: 2px;
}

.device-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.view-details {
  font-size: $size4;
  color: $appcolor;
}

.device-status-tag {
  position: absolute;
  top: 0;
  right: -30px;
  background-color: #007bff; /* 默认背景色 */
  color: #fff;
  padding: 4px 40px;
  font-size: 12px;
  transform: translateY(0%) rotate(45deg) translateX(10%);
}

.status-normal {
  background-color: $green; /* 正常状态背景色 */
}

.status-abnormal {
  background-color: $red1; /* 异常状态背景色 */
}

.report-foot {
  border-top: 1px solid $bordercolor;
  font-size: $size6;
  color: $gray2;
  padding: $tb 0;
  @include flexCenter(row);
  justify-content: space-between;
  .patient-name {
  }

  .foot-btn {
    margin-left: $lr;
    border-radius: 4px;
    padding: 4px 10px;
  }
  .unbind-btn {
    border: 1px solid $red1;
    color: $red1;
  }

  .replace-btn {
    border: 1px solid $blue1;
    color: $blue1;
  }

  .detail-btn {
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}
</style>

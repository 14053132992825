var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "point-price" }, [
    _c(
      "p",
      [
        _c("span", [
          _c("span", { staticClass: "rmb" }, [_vm._v("￥")]),
          _c("span", { staticClass: "real-price" }, [
            _vm._v(_vm._s(_vm.realPrice)),
          ]),
        ]),
        _vm.showRatio
          ? [
              _vm.memberRatio != 0
                ? _c("span", { staticClass: "points" }, [
                    _vm._v(" +" + _vm._s(_vm.memberRatio) + "%积分"),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        !_vm.showRatio
          ? [
              _vm.product.memberRatio != 0
                ? _c("span", { staticClass: "points" }, [
                    _vm._v(" +" + _vm._s(_vm.pointsDeduction) + "积分"),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm.showOriginPrice
      ? _c("p", { staticClass: "origin-price" }, [
          _vm._v("原价:￥" + _vm._s(_vm.originPrice)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
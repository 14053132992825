var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bind-device" }, [
    _c(
      "div",
      { staticClass: "bind-device-form" },
      [
        _c("field", {
          attrs: {
            readonly: "",
            clickable: "",
            name: "picker",
            value: _vm.formInfo.f1.text,
            label: "选择诊所",
            placeholder: "点击选择诊所",
            disabled: _vm.disabledSelect,
          },
          on: {
            click: function ($event) {
              _vm.clinicPicker = true
            },
          },
        }),
        !_vm.disabledSelect
          ? _c(
              "popup",
              {
                attrs: { position: "bottom" },
                model: {
                  value: _vm.clinicPicker,
                  callback: function ($$v) {
                    _vm.clinicPicker = $$v
                  },
                  expression: "clinicPicker",
                },
              },
              [
                _c("picker", {
                  attrs: { columns: _vm.clinicList, "value-key": "text" },
                  on: {
                    confirm: _vm.clinicConfirm,
                    cancel: function ($event) {
                      _vm.clinicPicker = false
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("field", {
          attrs: {
            readonly: "",
            clickable: "",
            name: "picker",
            value: _vm.formInfo.f2.text,
            label: "选择设备",
            placeholder: "点击选择设备",
            disabled: _vm.disabledSelect,
          },
          on: {
            click: function ($event) {
              _vm.devicePicker = true
            },
          },
        }),
        !_vm.disabledSelect
          ? _c(
              "popup",
              {
                attrs: { position: "bottom" },
                model: {
                  value: _vm.devicePicker,
                  callback: function ($$v) {
                    _vm.devicePicker = $$v
                  },
                  expression: "devicePicker",
                },
              },
              [
                _c("picker", {
                  attrs: {
                    "show-toolbar": "",
                    columns: _vm.deviceList,
                    "value-key": "text",
                  },
                  on: {
                    confirm: _vm.deviceConfirm,
                    cancel: function ($event) {
                      _vm.devicePicker = false
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("field", {
          attrs: {
            name: "设备编号",
            label: "设备编号：",
            placeholder: "请输入设备编号",
            maxLength: "16",
            rules: [{ required: true, message: "请输入营业执照编号" }],
          },
          model: {
            value: _vm.formInfo.deviceNum,
            callback: function ($$v) {
              _vm.$set(_vm.formInfo, "deviceNum", $$v)
            },
            expression: "formInfo.deviceNum",
          },
        }),
        _c("i", { staticClass: "iconfont icon-sm", on: { click: _vm.toScan } }),
      ],
      1
    ),
    _c("div", { staticClass: "submit-btn", on: { click: _vm.submit } }, [
      _vm._v(_vm._s(_vm.bindText)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="partner-info">
    <div class="type-warp">
      <span
        :class="[activeTypes === item.value && 'active']"
        v-for="(item, index) in tabs"
        :key="index"
        @click="changeActive(item)"
      >
        {{ item.type }}
      </span>
    </div>
    <div class="input-warp">
      <van-form ref="formRef">
        <field
          :value="formInfo.name"
          name="收款人"
          label="收款人"
          placeholder="请选择收款人"
          required
          readonly
          clickable
          @click="pickerShow = true"
          :rules="[{ required: true, message: '请选择收款人' }]"
        />
        <popup v-model="pickerShow" position="bottom">
          <picker :columns="listInfo" @confirm="pickerConfirm" @cancel="pickerShow = false" value-key="text" />
        </popup>
      </van-form>
    </div>
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { Form as VanForm, Field, Popup } from 'vant';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';
import Picker from '@/components/picker';

export default {
  components: { fixedButton, VanForm, Field, Picker, Popup },
  data() {
    return {
      pickerShow: false,
      tabs: [{ type: '选择收款人', value: 'old' }],
      activeTypes: 'old',
      formInfo: {},
      queryParams: {
        pageNum: 0,
        pageSize: 100
      },
      listInfo: [],
      countdown: 0
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['clinicInfo', 'clinicId']),
    userType() {
      return getUserType();
    },
    showpPhone() {
      return this.clinicInfo.phone;
    },
    profitSharing() {
      return this.clinicInfo.profitSharing === '1';
    }
  },
  methods: {
    async init() {
      if (!this.profitSharing) {
        this.$dialog
          .alert({
            title: '提示',
            message: '当前诊所无需设置收款人'
          })
          .then(() => {
            this.$router.go(-1);
          });
      } else {
        this.loadDoctorList();
      }
    },
    pickerConfirm(e) {
      this.formInfo = e;
      this.pickerShow = false;
    },
    async loadDoctorList() {
      this.queryParams.pageNum++;
      const res = await services.clinic.getDoctorList({ ...this.queryParams, clinicId: this.clinicId });
      this.listInfo = res.rows.map(i => {
        return { ...i, text: i.name };
      });
    },
    getCode() {
      if (this.countdown > 0) {
        return;
      }
      this.sendVerificationCode();
    },
    async sendVerificationCode() {
      try {
        await services[this.userType].updatePwdCode();
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        this.$toast(error.msg || '获取验证码失败');
      }
    },
    changeActive(item) {
      this.activeTypes = item.value;
    },
    async submit() {
      await this.$refs.formRef.validate();

      await services[this.userType].updatePayee(this.formInfo.id);
      this.$toast('设置成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-field__label {
    width: 80px !important;
  }
}

.type-warp {
  @include flex(row);
  justify-content: center;
  color: $gray2;
  font-size: $size6;
  background: #fff;
  padding: 10px 0;
  span {
    padding: 10px;
    transition-duration: 0.3s;
    border-radius: 4px;
    &.active {
      background: lighten($appcolor, 50%);
      color: $appcolor;
      font-weight: 600;
    }
  }
}

.sms-tip {
  text-align: right;
  font-size: $size6;
  color: $appcolor;
}
</style>

<template>
  <div class="search-warp" @click="toSearch">
    <div><van-icon name="search" size="20" style="margin-right: 4px" /> 输入关键词搜索</div>
  </div>
</template>

<script>
import { Icon } from 'vant';

export default {
  components: { [Icon.name]: Icon },
  props: {
    text: {
      type: String,
      default: '保存'
    },
    hasTabbar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitEvent() {
      this.$emit('emitEvent');
    },

    toSearch() {
      this.$router.push({
        name: 'mallSearch'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.search-warp {
  background-color: transparent;
  // height: 50px;
  box-sizing: border-box;
  padding: 10px;
  height: 55px;

  div {
    background-color: #fff;
    padding: 8px 6px;
    border-radius: 4px;
    // height: 20px;
    color: #c8c9cc;
    @include flex(row);
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
</style>

<template>
  <div class="content">
    <collapse v-model="activeNames">
      <collapse-item v-for="(item, index) in routers" :key="index" :name="index">
        <template #title>
          <div class="func-type">
            {{ item.meta.title }}
          </div>
        </template>
        <div class="func-warp">
          <div
            class="func-item"
            v-for="(subItem, subIndex) in item.children"
            :key="'sub' + subIndex"
            @click="toLink(subItem)"
          >
            <p>{{ subItem.meta.title }}</p>
          </div>
        </div>
      </collapse-item>
    </collapse>

    <tabbar :curIndex="0" />
  </div>
</template>

<script>
import services from '@/service';
import { Collapse, CollapseItem } from 'vant';
import { routerPush, sleep } from '@/lib/utils';
import tabbar from '../components/tabbar';

export default {
  components: {
    Collapse,
    CollapseItem,
    tabbar
  },
  data() {
    return {
      activeNames: [],
      search: '',
      routers: [],
      hasList: ['诊所审批', '医生审批', '诊所库存管理', '检测管理', '设备轨迹记录', '设备申请记录']
    };
  },
  computed: {},
  watch: {
    routers: {
      handler() {
        this.activeNames = this.routers.map((item, index) => index);
      },
      immediate: true
    }
  },
  methods: {
    async toLink(item) {
      await sleep(100);
      routerPush({
        name: `admin${item.name}`
      });
    },
    toMore() {
      routerPush({
        name: 'clinicDetectionManagement'
      });
    },
    filterData(data, filterTitles) {
      return data
        .map(item => {
          return {
            ...item,
            children: (item.children || []).filter(subItem => {
              return filterTitles.includes(subItem.meta.title);
            })
          };
        })
        .filter(item => {
          return (item.children || []).length > 0;
        });
    },
    async getRouters() {
      const res = await services.admin.getRouters();
      this.routers = this.filterData(res.data, this.hasList);
      console.log(this.routers);
    },
    toPage(path) {
      this.$router.push({
        path
      });
    }
  },
  mounted() {
    this.getRouters();
  }
};
</script>

<style lang="scss" scoped>
.content {
  .func-warp {
    @include flex(row);

    .func-item {
      padding: 8px $lr;
      border-radius: 4px;
      margin: 4px 10px 10px 4px;
      border: 1px solid $appcolor;
      color: $appcolor;
      box-shadow: #e6e6e6 0 2px 8px;

      &:active {
        color: #fff;
        background: $appcolor;
      }
    }
  }
}

.tip {
  font-size: $size2;
  text-align: center;
  margin-bottom: $tb;
}
.wrap {
  @include flex(row);
  justify-content: space-around;
  padding: $tb 0;

  .box {
    width: 45%;
    margin-bottom: 15px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    box-shadow: $box-shadow1;

    p {
      font-size: $size3;
      margin-top: 10px;
      color: $gray1;
      text-align: center;
    }

    // 页面
    .item {
      box-sizing: border-box;
      transform: scale(1);
      zoom: 0.5;
      width: 100%;
      height: 700px;
      overflow: hidden;
    }
  }
}
.component-title {
  margin: 10px 0;
  font-size: $size4;
  color: $black1;
  text-align: center;
  &.path {
    color: $gray2;
  }
}
.wrap:after {
  content: ' ';
  width: 45%; //这个宽度和子元素宽度一致
  height: 0;
  display: block;
}
</style>

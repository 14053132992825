<template>
  <div>
    <searchFilter :searchForm="searchForm" @emitSearch="emitSearch" immediately v-if="initEnum" />

    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="panel-list">
          <div class="panel-item" v-for="(item, index) in listInfo" :key="index">
            <div class="panel-content">
              <div class="panel-header">
                <p class="panel-time">{{ item.createTime }}</p>
                <span class="panel-status-tag" :style="{ color: deviceApplyAll.getColorByKey(item.status) }">
                  {{ deviceApplyAll.getValueByKey(item.status) }}
                </span>
              </div>
              <div class="panel-product">
                <p>
                  <span class="out"
                    >设备实发数： <span>{{ item.deviceOutCount }}</span> 台
                  </span>
                  <span class="apply">（申请数：{{ item.deviceApplyCount }}）</span>
                </p>
                <p>
                  <span class="out"
                    >耗材实发数： <span>{{ item.consumablesOutCount }}</span> 份
                  </span>
                  <span class="apply">（申请数：{{ item.consumablesApplyCount }}）</span>
                </p>
                <div class="gray-warp">
                  <p class="gray-info">{{ item.goodsName }}</p>
                  <p class="gray-info">
                    申请理由：<span>{{ item.applyContent }}</span>
                  </p>
                  <p class="gray-info">申请人：{{ item.createBy }}</p>
                  <p class="gray-info" v-if="item.remark">不通过原因：{{ item.remark }}</p>
                  <p class="gray-info" v-else>
                    收货信息：{{ showArea(item) }}
                    <span
                      v-clipboard:copy="copyValue(item)"
                      v-clipboard:success="() => $toast('复制成功')"
                      class="copy"
                    >
                      复制</span
                    >
                  </p>
                </div>
              </div>
              <div class="panel-info panel-foot">
                <span class="panel-left">
                  <span v-if="item.recordUrl" @click="preview(item.recordUrl)"> 申请图片 <icon name="eye-o" /> </span>
                </span>
                <span class="panel-right">
                  <span v-if="showAudit(item)" class="foot-btn unbind-btn" @click.stop="audit(item)">审核</span>
                  <span v-if="showShipment(item)" class="foot-btn replace-btn" @click.stop="shipment(item)">发货</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>

    <fieldPrompt
      title="审核"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      ref="fieldPrompt"
    >
      <div slot="content" class="prompt-content" v-if="fieldPromptModel.info">
        <p>
          设备申请数量：<span style="margin-right: 30px">{{ fieldPromptModel.info.deviceApplyCount }}</span>
        </p>
        <p>
          耗材申请数量：<span style="margin-right: 30px">{{ fieldPromptModel.info.consumablesApplyCount }}</span>
        </p>
      </div>
      <fixedBottom slot="button">
        <div class="btn-warp">
          <div class="btn btn2" @click="changeAudit('1')">不通过</div>
          <div class="btn" @click="changeAudit('2')">通过</div>
        </div>
      </fixedBottom>
    </fieldPrompt>

    <van-dialog
      v-if="fieldPromptModel.info"
      v-model="auditShow"
      title="不通过原因"
      show-cancel-button
      @confirm="sumbitAudit('1')"
    >
      <field v-model="fieldPromptModel.info.remark" label="" type="textarea" placeholder="请输入不通过原因" />
    </van-dialog>
  </div>
</template>

<script>
import services from '@/service';
import { Icon, PullRefresh, List, ImagePreview, Field } from 'vant';
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';
import { deviceApplyAll } from '@/enum/index';
import fieldPrompt from '@/components/fieldPrompt';
import fixedBottom from '@/components/fixedBottom';
import searchFilter from '@/components/searchFilter';
// import { intersection } from 'lodash';

export default {
  components: { Icon, PullRefresh, List, fieldPrompt, fixedBottom, Field, searchFilter },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {}
      },
      auditShow: false,
      orders: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      searchParams: {},
      listInfo: [],
      deviceApplyAll,
      statusDefaultValue: ''
    };
  },
  computed: {
    ...mapGetters(['clinicId', 'adminInfo']),
    searchForm() {
      return {
        status: {
          label: '申请状态',
          component: 'select',
          options: this.deviceApplyAll.enums,
          defaultValue: this.statusDefaultValue
        },
        salesName: {
          label: '申请人',
          component: 'input'
        }
      };
    },
    initEnum() {
      return this.deviceApplyAll.enums;
    }
  },
  created() {
    if (['admin'].includes(getUserType()) && this.adminInfo.roles.includes('cgy')) {
      this.statusDefaultValue = '2';
    }
  },
  async mounted() {
    // this.refreshList();
  },
  methods: {
    emitSearch(params) {
      this.searchParams = params;
      this.refreshList();
    },
    showAudit(item) {
      return ['admin'].includes(getUserType()) && ['0'].includes(item.status);
    },
    showShipment(item) {
      return ['admin'].includes(getUserType()) && ['2'].includes(item.status);
    },
    preview(url) {
      ImagePreview([url]);
    },
    showArea(item) {
      return `${item.receiveName} ${item.receiveMobile} ${item.addressProvinceName} ${item.addressCityName} ${item.addressRegionName} ${item.addressDetail}`;
    },
    copyValue(row) {
      return `收货人：${row.receiveName}\n电话：${row.receiveMobile}\n收货地址：${row.addressProvinceName} ${row.addressCityName} ${row.addressRegionName} ${row.addressDetail}`;
    },
    async changeAudit(auditStatus) {
      if (auditStatus === '1') {
        this.auditShow = true;
      } else {
        this.sumbitAudit(auditStatus);
      }
    },
    async audit(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
      this.fieldPromptModel.fieldForm = {
        deviceOutCount: {
          label: '设备实发数量',
          defaultValue: item.deviceApplyCount
        },
        consumablesOutCount: {
          label: '耗材实发数量',
          defaultValue: item.consumablesApplyCount
        }
      };
    },
    async sumbitAudit(auditStatus) {
      const res = this.$refs.fieldPrompt.getSubmit();
      await services[getUserType()].approveDevice({
        deviceApplyRecordId: res.deviceApplyRecordId,
        goodsSettingId: res.goodsSettingId,
        goodsName: res.goodsName,
        deviceOutCount: res.deviceOutCount,
        consumablesOutCount: res.consumablesOutCount,
        status: auditStatus,
        remark: this.fieldPromptModel.info.remark
      });
      this.$toast('操作成功');
      this.fieldPromptModel.show = false;
      this.refreshList();
    },
    fieldPromptEmit() {},

    shipment(item) {
      this.$router.push({
        name: `${getUserType()}DeviceShipment`,
        query: {
          id: item.deviceApplyRecordId
        }
      });
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services[getUserType()].deviceApplyRecordList({ ...this.queryParams, ...this.searchParams });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
      // this.$store.dispatch('subLoading');
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  overflow: hidden;
  position: relative;
}
.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $tb;
}

.panel-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
  position: relative;
}

.panel-avatar {
  width: 100%;
  height: auto;
}

.panel-info {
  flex: 1;
}

.panel-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
}

.panel-description {
  color: $gray2;
  font-size: $size4;
}

.panel-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.panel-price {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 500;
  color: $gray2;
}

.panel-time {
  font-size: $size5;
  color: $gray2;
}

.panel-status-tag {
  font-size: $size4;
  font-weight: 600;
  // color: $appcolor;
}

.status-pending {
  color: #ffc107; /* 待发货状态背景色 */
}

.status-shipped {
  color: $green; /* 已发货状态背景色 */
}
.panel-product {
  margin-bottom: 10px;
  line-height: 22px;
  font-size: $size6;
  .out {
    span {
      font-size: $size3;
      font-weight: 600;
      color: $red1;
    }
  }
  .apply {
    margin-left: 10px;
    font-size: $size7;
    color: $gray2;
    span {
    }
  }

  .gray-warp {
    line-height: 18px;
    margin-top: 10px;
    .gray-info {
      font-size: $size7;
      color: $gray2;
      .copy {
        color: $blue1;
      }
    }
  }
}

.panel-foot {
  border-top: 1px solid $bordercolor;
  font-size: $size6;
  color: $gray2;
  padding: $tb 0 0;
  @include flexCenter(row);
  justify-content: space-between;
  .panel-left {
    color: $appcolor;
    font-size: $size6;
  }

  .foot-btn {
    margin-left: $lr;
    border-radius: 4px;
    padding: 4px 10px;
  }
  .unbind-btn {
    border: 1px solid $red1;
    color: $red1;
  }

  .replace-btn {
    border: 1px solid $blue1;
    color: $blue1;
  }

  .detail-btn {
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}
.total-price {
  color: $red1;
  font-weight: bold;
}

.prompt-content {
  font-size: $size6;
  line-height: 24px;
  padding: $tb 18px;
  color: $appcolor;
  border-bottom: 1px solid $bordercolor;
}

.btn-warp {
  width: 100%;
  @include flex(row);
  justify-content: space-between;
  .btn {
    width: 48% !important;
    flex: none;
  }
  .btn2 {
    background: $btnbg3;
    &:active {
      background: darken($appcolor3, 10%);
    }
  }
}
</style>

import enumFactory from './enumFactory';
import * as api from './enum';

// 产品列表
export const settingListAll = async () => {
  const res = await api.settingListAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.goodsName,
      key: i.goodsSettingId
    };
  });
  return enumFactory(list);
};

// 时间类型
export const selectDateTypeAll = async () => {
  const res = await api.selectDateTypeAll();
  const list = [
    // { text: '全部销量区间', value: '' },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.info,
        value: i.code
      };
    })
  ];
  return enumFactory(list);
};

// 设备类型
export const goodsSubTypeAll = async () => {
  const res = await api.goodsSubTypeAll();
  const list = [
    {
      text: '全部设备类型',
      value: ''
    },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.info,
        value: i.code
      };
    })
  ];
  return enumFactory(list);
};

// 检测订单状态
export const checkOrderStateAll = async () => {
  const res = await api.checkOrderStateAll();
  const list = [
    {
      value: '全部',
      key: ''
    },
    ...res.data.map(i => {
      return {
        ...i,
        value: i.info,
        key: i.code
      };
    })
  ];
  return enumFactory(list);
};

// 订单状态列表
export const serviceOrderStateAll = async () => {
  const res = await api.serviceOrderStateAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 获取产品列表（业绩详情专用）
export const allGoodsSetting = async params => {
  console.log(params);
  const res = await api.allGoodsSetting(params);
  const list = [
    {
      text: '全部产品',
      value: ''
    },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.goodsSettingName,
        value: i.goodsSettingId
      };
    })
  ];
  return enumFactory(list);
};

// 获取产品列表（业绩详情专用）
export const allSalesList = async params => {
  const res = await api.allSalesList(params);
  console.log(res);
  const list = [
    {
      text: '全部业务员',
      value: ''
    },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.salesName,
        value: i.salesId
      };
    })
  ];
  return enumFactory(list);
};

// 诊所
export const clinicAll = async () => {
  const res = await api.clinicAll();
  const list = [
    {
      text: '全部诊所',
      value: ''
    },
    ...res.data.map(i => {
      return {
        ...i,
        text: i.clinicName,
        value: i.clinicId
      };
    })
  ];
  return enumFactory(list);
};

// 诊所
export const clinicAll2 = async () => {
  const res = await api.clinicAll();
  const list = [
    ...res.data.map(i => {
      return {
        ...i,
        text: i.clinicName,
        value: i.clinicId
      };
    })
  ];
  return enumFactory(list);
};

// 诊所审批状态和医生审批状态
export const auditStatusAll = async () => {
  const res = await api.auditStatusAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 查询所有业务员库存列表
export const stockSalesListAll = async () => {
  const res = await api.stockSalesListAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: `${i.salesMan} ( ${i.stock} )`,
      key: i.salesStockId
    };
  });
  return enumFactory(list);
};

export const teamAllSalesAll = async () => {
  const res = await api.teamAllSalesAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: `${i.name}`,
      key: i.id
    };
  });
  return enumFactory(list);
};

// 库存记录类型(操作)
export const stockRecordTypeAll = async () => {
  const res = await api.stockRecordTypeAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 库存记录来源类型
export const stockRecordSrcAll = async () => {
  const res = await api.stockRecordSrcAll();
  const list = res.data.map(i => {
    return {
      ...i,
      value: i.info,
      key: i.code
    };
  });
  return enumFactory(list);
};

// 耗材列表
export const consumablesAll = async () => {
  const res = await api.consumablesAll();
  const list = res.rows.map(i => {
    return {
      ...i,
      value: i.goodsName,
      key: i.goodsSettingId
    };
  });
  return enumFactory(list);
};

// 可签约合同列表
export const contractClinicListAll = async () => {
  const res = await api.contractClinicListAll();
  const list = [
    ...res.rows.map(i => {
      return {
        ...i,
        value: i.contractId
      };
    })
  ];
  console.log(list);

  return enumFactory(list);
};

// 套餐类型
export const packageTypeAll = enumFactory([
  {
    value: '服务',
    key: 'FW'
  },
  {
    value: '项目',
    key: 'XM'
  }
]);

// 产品类型
export const goodsTypeAll = enumFactory([
  {
    value: '设备',
    key: 'SB'
  },
  {
    value: '耗材',
    key: 'HC'
  }
]);

export const salesTypeAll = enumFactory([
  {
    value: '业务员',
    key: '0'
  },
  {
    value: '合伙人',
    key: '1'
  }
]);

// 出库状态
export const outFlagAll = enumFactory([
  {
    value: '是',
    key: '1'
  },
  {
    value: '否',
    key: '0'
  }
]);

// 产品、套餐、设备状态
export const defaultFlagAll = enumFactory([
  {
    value: '正常',
    key: '0'
  },
  {
    value: '停用',
    key: '1'
  }
]);

// 产品、套餐、设备状态
export const userTypeAll = enumFactory([
  {
    value: '诊所端',
    key: '1'
  },
  {
    value: '合伙人端',
    key: '2'
  },
  {
    value: '患者端',
    key: '3'
  },
  {
    value: '后台管理',
    key: '0'
  }
]);

// 性别
export const genderType = enumFactory([
  {
    value: '男',
    key: '0'
  },
  {
    value: '女',
    key: '1'
  }
]);

// 性别
export const jobType = enumFactory([
  {
    value: '医生',
    key: '1',
    color: '#1989fa'
  },
  {
    value: '护士',
    key: '2',
    color: '#ff976a'
  }
]);

// 设备申请记录状态
export const deviceApplyAll = enumFactory([
  {
    value: '待审批',
    key: '0',
    color: '#ffb811'
  },
  {
    value: '审批不通过',
    key: '1',
    color: '#fe271b'
  },
  {
    value: '审批通过',
    key: '2',
    color: '#1cd66c'
  },
  {
    value: '已发货',
    key: '4',
    color: '#1b86fe'
  }
]);

export const paymentModeAll = enumFactory([
  {
    value: '诊所支付',
    key: '1'
  },
  {
    value: '患者支付',
    key: '2'
  }
]);

export const profitSharingAll = enumFactory([
  {
    value: '不分账',
    key: '0'
  },
  {
    value: '分账',
    key: '1'
  }
]);

export const payStatusAll = enumFactory([
  {
    code: '0',
    info: '待支付'
  },
  {
    code: '1',
    info: '已支付'
  },
  {
    code: '2',
    info: '支付失败'
  },
  {
    code: '4',
    info: '已退款'
  },
  {
    code: '6',
    info: '退款中'
  }
]);

export const userPointsFlowChannelAll = enumFactory([
  {
    value: '系统录入',
    key: '1'
  },
  {
    value: '订单消费',
    key: '2'
  },
  {
    value: '订单取消',
    key: '3'
  },
  {
    value: '过期',
    key: '4'
  }
]);

export const userPointsStatusAll = enumFactory([
  {
    value: '待审批',
    key: '0'
  },
  {
    value: '未开始',
    key: '1'
  },
  {
    value: '使用中',
    key: '2'
  },
  {
    value: '已使用',
    key: '3'
  },
  {
    value: '已过期',
    key: '4'
  }
]);

export const userPointsTypeAll = enumFactory([
  {
    value: '会员积分',
    key: 'member'
  },
  {
    value: '消费积分',
    key: 'consumption'
  }
]);

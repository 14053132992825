<template>
  <div class="points-mall">
    <mallSearch />
    <!-- 轮播图区域 -->
    <div class="swiper-wrapper">
      <van-swipe :autoplay="3000" @change="onSwipeChange" class="mall-swipe">
        <van-swipe-item v-for="(item, index) in swiperList" :key="index" @click="toDetail(item.bannerContent)">
          <img class="swiper-img" :src="item.imageUrl" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 公告栏 -->
    <div class="notice-panel" v-if="notices.length">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe vertical :autoplay="5000" :touchable="false" :show-indicators="false" class="notice-swipe">
          <van-swipe-item v-for="(text, index) in notices" :key="index">
            {{ text }}
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>

    <!-- 功能入口 -->
    <!-- <div class="func-panel">
      <div class="func-scroll">
        <div v-for="item in funcList" :key="item.id" class="func-item">
          <van-icon :name="item.icon" size="24px" />
          <span class="func-text">{{ item.text }}</span>
        </div>
      </div>
    </div> -->
    <div class="ach-header" @click="toPointsDetail">
      <div>
        <p class="ach-title">总积分</p>
        <p class="ach-num"><countTo separator="" :startVal="0" :endVal="totalPoints" /></p>
      </div>
      <!-- <div>
        <p class="ach-title">积分失效时间</p>
        <p class="ach-num"><countTo separator="" :startVal="0" :endVal="pointsInfo.lmSalseAmount" /></p>
      </div> -->
    </div>

    <!-- 限时折扣 -->
    <div class="discount-panel">
      <tabs v-model="active">
        <tab v-for="(item, index) in tabList" :key="index" :title="item.name" />
      </tabs>
      <!-- <div class="panel-header">
        <h3>热门</h3>
        <span class="more" @click="toMore">查看更多<van-icon name="arrow" /></span>
      </div> -->
      <div class="goods-grid">
        <div v-for="goods in discountGoods" :key="goods.id" class="goods-item" @click="toDetail(goods.drugsId)">
          <div class="goods-img">
            <van-image :src="goods.drugsImage.split(',')[0]" class="goods-cover" fit="cover" />
          </div>
          <p class="goods-title">{{ goods.name }}</p>
          <p class="goods-points">
            <span class="points"></span>
            <pointPrice :product="goods" />
          </p>
        </div>
      </div>
    </div>

    <tabbar :curIndex="0" />
  </div>
</template>

<script>
import pointPrice from '@/components/pointPrice';
import mallSearch from '@/components/mallSearch';
import tabbar from '../components/tabbar';
import { Swipe, SwipeItem, NoticeBar, Image as VanImage, Icon, Tab, Tabs } from 'vant';
import services from '@/service';
import countTo from 'vue-count-to';
import { routerPush, notRequest } from '@/lib/utils';

export default {
  components: {
    pointPrice,
    mallSearch,
    tabbar,
    countTo,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NoticeBar.name]: NoticeBar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    Tab,
    Tabs
  },
  data() {
    return {
      swiperList: [],
      currentSwipe: 0,
      notices: [],
      pointsInfo: {
        memberPoints: 0,
        consumptionPoints: 0
      },
      funcList: [
        { id: 1, icon: 'apps-o', text: '分类' },
        { id: 2, icon: 'discount', text: '折扣' },
        { id: 3, icon: 'orders-o', text: '订单' },
        { id: 4, icon: 'gold-coin', text: '积分' },
        { id: 5, icon: 'gift-o', text: '福利' }
      ],
      discountGoods: [],
      userPoints: {},
      bannerList: [],
      active: 0,
      tabList: [
        {
          name: '热门专区',
          key: 'recommend'
        },
        {
          name: '折扣专区',
          key: 'discount'
        },
        {
          name: '进口药专区',
          key: 'imported'
        }
      ]
    };
  },
  watch: {
    active: {
      handler() {
        this.loadGoods();
      },
      immediate: true
    }
  },
  computed: {
    pageInit() {
      return !notRequest();
    },
    totalPoints() {
      return Number((this.pointsInfo.memberPoints + this.pointsInfo.consumptionPoints).toFixed(0));
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    toSearch() {
      this.$router.push({
        name: 'mallSearch'
      });
    },
    async getBanner() {
      const res = await services.common.getBanner(4);
      this.swiperList = res.data;
    },
    toMore() {
      this.$router.push({
        name: 'mallCategory'
      });
    },
    toDetail(id) {
      routerPush({
        name: 'mallProductDetail',
        query: {
          id
        }
      });
    },
    toPointsDetail() {
      routerPush({
        name: 'mallPointsDetails'
      });
    },
    onSwipeChange(index) {
      this.currentSwipe = index % 4;
    },
    async loadData() {
      this.getBanner();

      if (!this.pageInit) return;

      this.pointsInfo = (await services.mall.userPoints()).data;

      if (this.pointsInfo.expiringPointsDate) {
        this.notices.push(`${this.pointsInfo.expiringPoints} 积分将于 ${this.pointsInfo.expiringPointsDate} 失效`);
      }
    },
    async loadGoods() {
      const res = await services.mall[this.tabList[this.active].key](1);
      this.discountGoods = res.data;
    },
    realPrice(goods) {
      return (goods.price - goods.memberDeduction).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
.points-mall {
  padding-bottom: 50px;

  .swiper-wrapper {
    height: 160px;
    position: relative;

    .mall-swipe {
      height: 100%;
      position: relative;
      z-index: 1;

      .swiper-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .notice-panel {
    margin: 16px;
    border-radius: $radius;
    overflow: hidden;
    box-shadow: $box-shadow1;

    ::v-deep .van-notice-bar {
      // padding: 10px 16px;

      .notice-swipe {
        height: 16px;
        line-height: 16px;
      }
    }
  }

  .func-panel {
    padding: $tb $lr;
    background: #fff;
    margin: $tb $lr;
    border-radius: $radius;

    .func-scroll {
      display: flex;
      @include flex(row);
      justify-content: space-around;

      .func-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .func-text {
          font-size: 12px;
          color: $gray1;
          margin-top: 8px;
        }
      }
    }
  }

  .ach-header {
    background: $btnbg;
    padding: 8px $lr;
    margin: $tb $lr;
    border-radius: $radius;
    display: flex;
    justify-content: space-around;
    color: #fff;
    > div {
      flex: 1;
      text-align: center;
    }
    .ach-title {
      font-size: $size6;
    }
    .ach-num {
      font-size: 22px;
      padding-top: 8px;
    }
  }

  .discount-panel {
    margin: 20px 0;

    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      h3 {
        font-size: 18px;
        color: $appcolor;
        font-weight: 700;
      }

      .more {
        font-size: 13px;
        color: $gray2;

        .van-icon {
          margin-left: 4px;
        }
      }
    }

    .goods-grid {
      margin: $tb $lr;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;

      .goods-item {
        background: white;
        border-radius: 8px;
        box-shadow: $box-shadow1;
        overflow: hidden;

        .goods-img {
          height: 100px;
        }

        .goods-cover {
          width: 100%;
        }

        .goods-title {
          font-size: 14px;
          margin: 8px 12px;
          @include hiddenText();
        }

        .goods-points {
          padding: 0 12px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .points {
            font-size: 12px;
            color: $gray1;
          }

          .price {
            font-size: $size5;
            color: $red1;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>

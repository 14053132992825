import store from '@/store';
import router from './index';
import reporter from '@/lib/reporter';
import services from '@/service';
import eventBus from '@/lib/eventBus';
import { getUserType, notRequest, getCurToken } from '@/lib/utils';
import qs from 'qs';

// import { Toast } from 'vant';

let checkLogin = true;
export function initRouterController() {
  router.beforeEach((to, from, next) => {
    const isCheckLogin = checkLogin;
    if (!isCheckLogin) {
      next();
      return;
    }

    if (to.meta.noAuth) {
      checkLogin = false;
    }

    next();
  });

  // 小程序打开用户初始化
  router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.isActivity) {
      next();
      return;
    }

    const isCheckLogin = checkLogin;
    if (!isCheckLogin) {
      next();
      return;
    }

    checkLogin = false;

    const userType = to.name.split(/(?=[A-Z])/)[0];

    const token = localStorage.getItem(`webview_token_${getUserType()}`);
    const openId = localStorage.getItem(`openId`);
    if (!token) {
      if (['clinic', 'patient', 'partner', 'mall'].includes(userType) && !to.name.includes('Login') && !notRequest()) {
        next({ name: `${userType}Login`, query: { redirect_name: to.name, redirect_query: qs.stringify(to.query) } });
      } else {
        next();
      }
      return;
    } else {
      await Promise.all([
        store.dispatch('set_token', token),
        store.dispatch('set_openId', openId),
        store.dispatch(`set_${userType}Info`),
        store.dispatch('set_checkOrderState')
      ]);
      if (to.name.includes('Login')) {
        router.replace(`/${userType}-home`);
      }
    }

    next();
  });

  router.beforeEach((to, from, next) => {
    let changeDocumentTitle = true;
    if (from.name === 'signature' && to.name === 'sign') {
      changeDocumentTitle = false;
    }
    if (changeDocumentTitle) {
      // let documentTitle = process.env.VUE_APP_TITLE;
      let documentTitle = 'ᅟᅠ';
      if (to.meta.title) {
        documentTitle = to.meta.title;
      }
      document.title = documentTitle;
    }
    next();
  });

  // 进入路由前判断是否添加keep-alive缓存
  router.beforeEach((to, from, next) => {
    if (!to.meta || !(to.meta.keeps || []).length) {
      next();
      return;
    }
    let keepAlive = [...store.getters.keepAliveViews];
    const routeIndex = keepAlive.indexOf(to.name);
    if (routeIndex === -1) {
      keepAlive.unshift(to.name);
    } else {
      if (!to.meta.keeps.includes(from.name)) {
        eventBus.$emit('refreshPage');
      }
    }
    keepAlive = keepAlive.slice(0, 10);
    store.dispatch('changeRouteAliveViews', keepAlive);
    next();
  });

  // 离开路由后判断是否需要销毁keep-alive
  router.afterEach((to, from) => {
    if (from.meta.keeps && !from.meta.keeps.includes(to.name)) {
      const keepAlive = [...store.getters.keepAliveViews];
      const index = keepAlive.indexOf(from.name);
      if (index !== -1) {
        keepAlive.splice(index, 1);
        store.dispatch('changeRouteAliveViews', keepAlive);
      }
    }
  });

  const firstTime = Date.now();
  let preTime = firstTime;
  router.afterEach((to, from) => {
    const nowTime = Date.now();
    const duration = nowTime - preTime;
    preTime = nowTime;
    try {
      services.logger.routerLog({
        to: { path: to.path, query: to.query },
        from: from ? { path: from.path, query: from.query } : {},
        firstTime,
        nowTime,
        duration,
        token: getCurToken()
      });
    } catch (err) {
      reporter.log({ message: err.message, code: '路由跳转log接口异常' });
    }
  });
}

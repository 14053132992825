var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.orderInfo.checkCode
    ? _c(
        "div",
        { staticClass: "report-pay" },
        [
          _c("baseInfo", {
            attrs: {
              detail: { checkOrderVo: _vm.orderInfo },
              statusText: "待支付",
            },
          }),
          _c("div", { staticClass: "wx-btn", on: { click: _vm.wxPay } }, [
            _vm._v("微信支付"),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
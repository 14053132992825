<template>
  <div class="report-box">
    <div class="report-info report-header">
      <span class="clinic-name">{{ info.deviceNum }}</span>
      <span class="doc-name">{{ info.createTime }}</span>
    </div>
    <div class="report-info-list">
      <p>{{ info.receiveName }} {{ info.receiveMobile }}</p>
      <p>
        {{ info.addressProvinceName }} {{ info.addressCityName }} {{ info.addressRegionName }} {{ info.addressDetail }}
      </p>
      <p>{{ info.remark }}</p>
    </div>
    <div class="report-info report-foot">
      <span class="clinic-name">由 {{ info.createBy }} 添加</span>
      <span class="report-time">
        <!-- <span class="delete-btn" @click=""></span> -->
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getUserType } from '@/lib/utils';

export default {
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      colorEnum: {
        检测中: '#808084',
        查看报告: '#24d270'
      }
    };
  },
  computed: {
    ...mapGetters(['checkOrderState']),
    userType() {
      return getUserType();
    },
    showDelete() {
      return this.userType === 'clinic';
    },
    showClinicName() {
      return this.userType === 'admin';
    }
  },
  methods: {
    hasDetail(item) {
      return ['2', '4', '6'].includes(item.status);
    },
    toDetail(item) {
      this.$router.push({
        path: `/${this.userType}-device-record-add?id=${item.deviceRecordId}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.report-box {
  background: #fff;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  font-size: $size2;
  line-height: 26px;
  color: $black1;
  // height: 300px;

  .report-header {
    font-size: $size6;
    margin-bottom: $tb;
    .clinic-name {
      background: $btnbg;
      color: #fff;
      padding: 2px $lr;
      border-radius: $radius 0 $radius 0;
    }
    .doc-name {
      color: $gray2;
      padding: 0 $lr;
      position: relative;
      top: 2px;
    }
  }
  .report-name {
    font-size: $size1;
    font-weight: 600;
    padding: $tb $lr;
    color: $gray2;
  }
  .report-code {
    font-size: $size6;
    padding: 0 $lr;
    color: $gray2;
    text-align: right;
  }
  .report-info {
    @include flexCenter(row);
    justify-content: space-between;

    .report-time {
      .clinic-name {
        flex: 1;
        @include hiddenText();
        font-size: $size6;
      }
    }
  }

  .report-info-list {
    padding: 0 $lr 6px;
    font-size: $size5;
  }

  .report-project {
    padding: 0 $lr $tb;

    .report-read {
      font-size: $size4;
      color: $appcolor;
      font-weight: 500;
    }
  }

  .report-foot {
    border-top: 1px solid $bordercolor;
    font-size: $size6;
    color: $gray2;
    padding: 4px $lr;
    .patient-name {
    }

    .delete-btn {
      border: 1px solid $red1;
      color: $red1;
      margin-left: $lr;
      border-radius: 4px;
      padding: 4px 10px;
    }
  }
}
</style>

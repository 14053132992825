var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meal-detail" },
    [
      _vm.drugsImage.length > 0
        ? _c(
            "div",
            { staticClass: "swipe-warp" },
            [
              _c(
                "swipe",
                { attrs: { autoplay: 3000 } },
                _vm._l(_vm.drugsImage, function (item, index) {
                  return _c("swipe-item", { key: index }, [
                    _c("img", {
                      staticClass: "slide-image",
                      attrs: { src: item },
                    }),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "meal-info" }, [
        _c("h3", { staticClass: "meal-name" }, [
          _vm._v(_vm._s(_vm.info.name) + " " + _vm._s(_vm.info.spec)),
        ]),
        _c(
          "h3",
          { staticClass: "meal-name" },
          [_c("pointPrice", { attrs: { product: _vm.info } })],
          1
        ),
        _c(
          "div",
          { staticClass: "product-info" },
          _vm._l(_vm.info.goodsSettingVoList, function (item, index) {
            return _c("div", { key: index, staticClass: "product-item" }, [
              _c(
                "div",
                { staticClass: "product-content" },
                [
                  _c("van-image", {
                    staticClass: "product-image",
                    attrs: {
                      fit: "cover",
                      src: item.goodsIcon,
                      alt: item.goodsName,
                    },
                  }),
                  _c("span", { staticClass: "product-name" }, [
                    _vm._v(_vm._s(item.goodsName)),
                  ]),
                ],
                1
              ),
              _c("span", { staticClass: "product-num" }, [
                _c("span", { staticClass: "num-tip" }, [_vm._v("数量：")]),
                _vm._v(_vm._s(item.mealNum)),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "info-list" },
        [
          _c(
            "cell-group",
            [
              _c("cell", {
                attrs: { title: "厂家", value: _vm.info.manufacturer },
              }),
              _c("cell", { attrs: { title: "产地", value: _vm.info.origin } }),
              _c("cell", {
                attrs: { title: "服用频率", value: _vm.info.dosageFrequency },
              }),
              _c("cell", {
                attrs: { title: "服用方法", value: _vm.info.usageMethod },
              }),
              _c("cell", {
                attrs: { title: "剂量", value: _vm.info.dosageAmount },
              }),
              _c("cell", {
                attrs: { title: "禁忌", value: _vm.info.prohibitedGroups },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "img-list-box" },
        [
          _vm._l(_vm.detailImage, function (item, index) {
            return _c("img", {
              key: index,
              staticClass: "img-item",
              staticStyle: { width: "100%" },
              attrs: { src: item, mode: "widthFix" },
            })
          }),
          _c("div", { staticStyle: { height: "100px" } }),
        ],
        2
      ),
      _c(
        "goods-action",
        [
          _c("goods-action-icon", {
            attrs: { icon: "revoke", text: "返回" },
            on: { click: _vm.goBack },
          }),
          _c("goods-action-icon", {
            attrs: { icon: "cart", text: "购物车" },
            on: { click: _vm.toCart },
          }),
          _c("goods-action-button", {
            attrs: { type: "warning", text: "加入购物车" },
            on: { click: _vm.addToCart },
          }),
          _c("goods-action-button", {
            attrs: { type: "danger", text: "立即购买" },
            on: { click: _vm.goBuy },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <popup v-model="visible" position="bottom" :style="{ width: '100%' }" round closeable>
      <p class="search-title">{{ title }}</p>
      <slot name="content" />
      <div class="filter-content">
        <van-form ref="formRef">
          <template v-for="(item, key) in fieldForm">
            <template v-if="item.component === 'select'">
              <field
                :key="'select_' + key"
                readonly
                clickable
                :value="formModel[`${key}${filterLable}`]"
                :label="item.label + '：'"
                @click="pickerHandle(key, true)"
                :placeholder="`请选择${item.label}`"
                type="textarea"
                autosize
                clearable
              >
              </field>
              <popup :key="'popup_' + key" v-model="pickerObj[`${key}Picker`]" position="bottom">
                <picker
                  :columns="item.options"
                  @cancel="pickerHandle(key, false)"
                  @confirm="(value, index) => pickerConfirm(value, index, key)"
                  value-key="value"
                />
              </popup>
            </template>
            <template v-else-if="item.component === 'date-picker'">
              <field
                :key="'date-picker_' + key"
                readonly
                clickable
                :value="(formModel[`${key}`] || []).join(' - ')"
                :label="item.label + '：'"
                @click="pickerHandle(key, true)"
                :placeholder="`请选择${item.label}`"
                clearable
              >
                <template #button v-if="(formModel[key] || []).length">
                  <Icon @click.stop="resetDate(key)" name="clear" color="#c8c9cc" />
                </template>
              </field>
              <calendar
                :key="'popup_' + key"
                v-model="pickerObj[`${key}Picker`]"
                type="range"
                @confirm="value => datePickerConfirm(value, key)"
                :min-date="minDate"
                :max-date="maxDate"
                allow-same-day
                color="#04d1ea"
              />
            </template>
            <field
              v-else
              :key="'field_' + key"
              v-model="formModel[key]"
              :label="item.label + '：'"
              :placeholder="`请输入${item.label}`"
              clearable
              maxlength="30"
            >
            </field>
          </template>
        </van-form>
        <div style="height: 100px;"></div>
        <slot name="button" v-if="$slots.button" />
        <div class="btn-warp" v-else>
          <div class="btn-warp-item">
            <div class="btn btn2" @click="searchSubmit">确 认</div>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { Popup, Form as VanForm, Field, Calendar, Icon } from 'vant';
import Picker from '@/components/picker';
import dayjs from 'dayjs';

import { getRangeByDate } from '@/lib/utils';

export default {
  components: {
    Popup,
    VanForm,
    Field,
    Calendar,
    Picker,
    Icon
  },
  props: {
    title: {
      type: String,
      defautl: ''
    },
    show: {
      type: Boolean,
      defautl: false
    },
    info: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fieldForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(),
      filterLable: '_filterLable',
      showPicker: false,
      pickerObj: {},
      formModel: {},
      formInfo: {
        name: ''
      },
      popupShow: false
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  },
  watch: {
    show: {
      handler: function(val) {
        val && this.initFrom();
      }
    }
  },
  methods: {
    resetDate(key) {
      this.formModel[key] = [];
    },
    pickerHandle(name, type) {
      console.log(`${name}Picker`, type);
      this.$set(this.pickerObj, `${name}Picker`, type);
    },
    datePickerConfirm(value, key) {
      this.formModel[key] = [dayjs(value[0]).format('YYYY-MM-DD'), dayjs(value[1]).format('YYYY-MM-DD')];
      this.pickerHandle(key, false);
    },
    pickerConfirm(value, index, key) {
      this.formModel[key] = value.key;
      this.formModel[`${key}${this.filterLable}`] = value.value;
      this.pickerHandle(key, false);
    },
    initFrom() {
      const keys = Object.keys(this.fieldForm);
      const obj = {};
      keys.forEach(key => {
        obj[key] = this.fieldForm[key].defaultValue !== undefined ? this.fieldForm[key].defaultValue : '';
        if (this.fieldForm[key].component === 'select') {
          const item = this.fieldForm[key].options.find(item => item.key === obj[key]);
          obj[`${key}${this.filterLable}`] = item ? item.value : '';
        }
      });
      this.formModel = Object.assign({}, this.formModel, obj);
    },
    searchSubmit() {
      let subForm = this.removeFilterLabels({ ...this.formModel });

      for (const i in subForm) {
        if (this.fieldForm[i] && this.fieldForm[i].component === 'date-picker') {
          subForm = Object.assign(subForm, getRangeByDate(subForm[i], this.fieldForm[i].dateKey || {}));
          delete subForm[i];
        }
      }
      delete subForm.dateType;
      const data = { ...this.info, ...subForm };
      this.$emit('emit', data);
      return data;
    },
    removeFilterLabels(obj) {
      return Object.keys(obj).reduce((result, key) => {
        if (!key.endsWith(this.filterLable)) {
          result[key] = obj[key];
        }
        return result;
      }, {});
    },
    getSubmit() {
      return this.searchSubmit();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  background: #fff;
  @include flex(row);
  justify-content: space-between;
  padding: $tb $lr 0;

  .search-left {
    font-size: 12px;
    flex: 1;
    .search-tag {
      margin-right: $lr;
      background: $appcolor;
      border-radius: 6px;
      color: #fff;
      padding: 4px 6px;
      display: inline-block;
      margin-bottom: 4px;
      white-space: pre-wrap; /* 保留空白序列，但会正常换行 */
      word-wrap: break-word; /* 在单词的末尾处进行换行 */
      word-break: break-all; /* 允许在单词内换行 */
      line-height: 16px;
    }
  }
  i {
    font-size: 20px;
  }
}

.search-title {
  text-align: center;
  color: $gray1;
  font-size: $size6;
  padding: $tb $lr 0;
}

.van-popup--right {
  height: 100%;
}
.filter-content {
  padding: $tb 0;

  ::v-deep {
    .van-field__label {
      width: 120px !important;
    }
  }

  .btn-warp {
    width: 100%;
    position: absolute;
    bottom: $tb;

    .btn-warp-item {
      width: 100%;
      @include flex(row);
      justify-content: space-between;
      border-bottom: 1px solid $bordercolor;
      .btn1 {
        width: 30% !important;
        flex: none;
        background: $gray3;
        margin: 0 $lr;
        &:active {
          background: darken($gray3, 10%);
        }
      }
      .btn2 {
        margin: 0 $lr;
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="interpretation">
    <div class="collapse-warp">
      <p class="content-title">
        结果解读：<br />
        <span class="tip">具体判断请结合临床和连续监测的数值，以医生诊断为准。</span>
      </p>
      <collapse v-model="activeNames">
        <collapse-item v-for="(item, index) in tableInfo" :key="index" :name="index">
          <template #title>
            <div class="indicators">{{ item.indicators }}：{{ item.numerical }}</div>
          </template>
          <div class="clinicalTips" v-html="highlightText(item.clinicalTips)" />
        </collapse-item>
      </collapse>
    </div>

    <div class="data-info">
      <p class="content-title">项目意义：</p>
      <div class="data-info-content">
        血常规、CRP是最常见的炎症检测指标，两联检能充分利用各项指标的特点，并形成互补，更准确地判断患者的感染类型。在临床上可以辅助快速辨别感染性疾病的病因，有利于提高诊断正确率，有效鉴别细菌病毒感染，指导合理高效的用药。
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'vant';

export default {
  components: {
    Collapse,
    CollapseItem
  },
  data() {
    return {
      activeNames: []
    };
  },
  props: {
    tableInfo: {
      type: Array,
      default: function() {
        return [
          // {
          //     name: '白细胞（WBC）',
          //     result: '8.82',
          //     unit: '10^9/L',
          //     range: '3.5~9.5'
          //  }
        ];
      }
    }
  },
  watch: {
    tableInfo: {
      handler() {
        this.activeNames = this.tableInfo.map((item, index) => index);
      },
      immediate: true
    }
  },
  methods: {
    highlightText(text) {
      const virusRegex = /病毒感染/g;
      const bacteriaRegex = /细菌感染/g;

      let highlightedText = text.replace(virusRegex, '<span style="color:red">病毒感染</span>');
      highlightedText = highlightedText.replace(bacteriaRegex, '<span style="color:#ff9b00">细菌感染</span>');

      return highlightedText;
    }
  }
};
</script>

<style lang="scss" scoped>
$borderColor: #e0edff;

.interpretation {
  margin-top: $tb;

  .content-title {
    font-weight: 600;
    font-size: $size3;
    color: $gray1;
    padding: $tb $lr;
    line-height: 20px;
    .tip {
      color: $appcolor;
      font-size: 12px;
    }
  }
  .collapse-warp {
    background: #fff;
  }

  .indicators {
    font-size: $size5;
    color: $black1;
  }
  .clinicalTips {
    color: $gray2;
  }
}

.data-info {
  margin-top: $tb;
  background: #fff;
  .data-info-content {
    color: $gray2;
    padding: 0 $lr $tb;
    font-size: $size5;
    line-height: 24px;
    border-radius: $radius;
  }
}
</style>

<template>
  <div class="w" v-if="openWechat">
    <div><img class="logo" :src="logo" /></div>
    <template v-if="!isWeChat">
      <p class="tip-btn" @click="reload">正在打开微信</p>
      <p class="tip">如遇无法打开小程序，</p>
      <p class="tip">可打开微信搜索 "i检康" 小程序进行查看</p>
    </template>
    <wx-open-launch-weapp v-if="isWeChat" id="launch-btn" :appid="appid" :path="path">
      <script type="text/wxtag-template">
        <style>
          .tip-btn {
            margin-top: 100px;
            padding: 10px 20px;
            border-radius: 20px;
            border:none;
            background-color: #16d46b;
            color: #fff;
            font-size: $size2;
            display: inline-block;
            animation: scale-move 2s infinite;
          }

          @keyframes scale-move {
            0% {
              transform: scale(0.9);
            }
            50% {
              transform: scale(1);
            }
            100% {
              transform: scale(0.9);
            }
          }
        </style>
        <button class="tip-btn">点击去支付</button>
      </script>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
import qs from 'qs';
import { isWeChat } from '@/global';

const openWechat = true;

export default {
  data() {
    return {
      isWeChat,
      openWechat,
      logo: this.$getImg('logo'),
      appid: 'wx3e7c4f8dbf42d679'
    };
  },
  computed: {
    path() {
      const query = encodeURIComponent(
        JSON.stringify({
          redirect_name: this.$route.params.page,
          redirect_query: {
            id: this.$route.params.id
          }
        })
      );
      return `pages/login/index?query=${btoa(query)}`;
    }
  },
  methods: {
    // 获取动态urlscheme
    async getWxUrlscheme() {
      const query = encodeURIComponent(
        JSON.stringify({
          redirect_name: this.$route.params.page,
          redirect_query: {
            id: this.$route.params.id
          }
        })
      );

      const appid = 'wx3e7c4f8dbf42d679';
      const path = 'pages/login/index';
      const envVersion = process.env.NODE_ENV !== 'production' ? 'trial' : 'release';

      // 这里要单独拼上 query,否则小程序解析 key 会有问题
      const link = `weixin://dl/business/?appid=${appid}&path=${path}&query=query=${btoa(
        query
      )}&env_version=${envVersion}`;

      console.log(`query=${btoa(query)}`);

      this.toLink(link);
    },
    toLink(url = this.config.UrlScheme) {
      setTimeout(() => {
        window.location.replace(url);
      }, 10);
    },
    reload() {
      window.location.reload();
    },
    pageInit() {
      if (openWechat) {
        this.getWxUrlscheme();
      } else {
        this.$router.replace({
          name: 'patientLogin',
          query: {
            redirect_name: this.$route.params.page,
            redirect_query: qs.stringify({
              id: this.$route.params.id
            })
          }
        });
      }
    }
  },
  mounted() {
    this.pageInit();
  }
};
</script>

<style lang="scss" scoped>
.w {
  text-align: center;
  background: #ffffff;
  height: 100vh;
}

.logo {
  width: 300px;
  height: 310px;
  margin-top: 20%;
}

.tip-btn {
  margin-top: 100px;
  padding: 10px 20px;
  border-radius: 20px;
  background: $wxbg;
  color: #fff;
  font-size: $size5;
  display: inline-block;
  margin-bottom: 20px;
}

.tip {
  font-size: 12px;
  color: $gray2;
  margin-top: 10px;
}
</style>

<template>
  <div>
    <div class="admin-info">
      <div class="ach-panel">
        <div class="ach-warp">
          <div class="personal-info">
            <van-image fit="cover" :src="adminInfo.user.avatar || avatar" class="avatar" />
            <div class="personal-right">
              <p class="personal-name">{{ !pageInit ? '请登录' : adminInfo.user.nickName }}</p>
              <p class="personal-tel">
                {{ !pageInit ? '登录后查看更多' : adminInfo.user.remark }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="personal-func-list">
        <cell
          v-for="(item, index) in funcList.filter(i => !i.show || i.show())"
          :key="index"
          is-link
          @click="toLink(item.link)"
        >
          <template #title>
            <span :class="['iconfont', `${item.icon}`]"></span>
            <span class="custom-title">{{ item.label }}</span>
          </template>
          <template #right-icon>
            <div class="right">
              <icon name="arrow" />
            </div>
          </template>
        </cell>
      </div>
    </div>

    <tabbar :curIndex="3" />
  </div>
</template>

<script>
import tabbar from '../components/tabbar';

import services from '@/service';

import { Cell, Icon, Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import { signOut, inviteUrl } from '@/lib/utils';
import { notRequest, checkLogin } from '@/lib/utils';

// import { adminAuth } from '@/global';

export default {
  components: { tabbar, Cell, Icon, VanImage },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      opinionModel: {
        show: false,
        info: {}
      },
      qrcodeModel: {
        show: false,
        info: {}
      },
      pullLoading: false,
      scoreInfo: {
        clinicAmount: 0,
        machineAmount: 0,
        signinAmount: 0,
        lmSalseAmount: 0,
        salseAmount: 0,
        withdrawalAmount: 0
      },
      searchVal: ''
    };
  },
  mounted() {
    // if (!this.pageInit) return;
    // this.refresh();
  },
  computed: {
    ...mapGetters(['adminInfo', 'adminId']),
    pageInit() {
      return !notRequest();
    },
    funcList() {
      return [
        // {
        //   label: '基本信息',
        //   icon: 'icon-jbxx',
        //   link: '/admin-info-edit'
        // },
        // {
        //   label: '我的订单',
        //   icon: 'icon-wddd',
        //   link: '/admin-my-order'
        // },
        {
          label: '退出登录',
          icon: 'icon-tc',
          link: 'exit'
        }
      ];
    }
  },
  methods: {
    async refresh() {
      await Promise.all([this.$store.dispatch('set_adminInfo'), this.getOverview()]);
    },
    async getOverview() {
      const res = await services.admin.getOverview();
      this.scoreInfo = res.data;
    },
    async toLink(path) {
      await checkLogin();

      if (path === 'opinion') {
        this.opinionModel.show = true;
      } else if (path === 'qrcode') {
        this.qrcodeModel.show = true;
        this.qrcodeModel.info = {
          code: inviteUrl('adminInviteEdit', this.adminId),
          text: `${process.env.VUE_APP_TITLE} - ${this.adminInfo.sales.name}`
        };
      } else if (path === 'exit') {
        signOut();
      } else {
        path && this.$router.push(path);
      }
    },
    dialPhoneNumber(phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-info {
  .line-title {
    margin: $tb $lr 0px;
  }
  // padding: $tb $lr;
  .ach-panel {
    .ach-warp {
      margin: $tb $lr;
      box-shadow: $box-shadow1;
      border-radius: $radius;
      overflow: hidden;
      background: #fff;
      color: #fff;

      .personal-info {
        // text-align: center;
        background: $btnbg;
        padding: $tb $lr;
        line-height: 30px;
        display: flex;

        .avatar {
          border-radius: 50%;
          width: 70px;
          height: 70px !important;
          border: 2px solid #fff;
          margin-right: $lr;
          overflow: hidden;
        }
        .personal-right {
          .personal-name {
            font-size: 20px;
            font-weight: 600;
            margin-top: 10px;
          }
          .personal-tel {
            font-size: $size4;
          }
        }
      }

      .ach-content {
        display: flex;
        justify-content: space-around;
        padding: $tb $lr;
        > div {
          flex: 1;
          text-align: center;
          border-right: 2px solid $bordercolor;
          &:last-child {
            border-right: none;
          }
        }
        .ach-title {
          color: $gray2;
          font-size: $size6;
        }
        .ach-num {
          font-size: 16px;
          padding-top: $tb;
          text-align: center;
          color: $black1;
          font-size: $size5;
        }
      }
      // > div {
      //   text-align: center;
      //   .ach-title {
      //     font-size: $size4;
      //     color: $gray2;
      //     margin-bottom: $tb;
      //     font-weight: 600;
      //   }
      //   .ach-num {
      //     font-size: 24px;
      //     font-weight: 600;
      //     color: $black1;
      //   }
      // }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "interpretation" }, [
    _c(
      "div",
      { staticClass: "collapse-warp" },
      [
        _vm._m(0),
        _c(
          "collapse",
          {
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          _vm._l(_vm.tableInfo, function (item, index) {
            return _c(
              "collapse-item",
              {
                key: index,
                attrs: { name: index },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "indicators" }, [
                            _vm._v(
                              _vm._s(item.indicators) +
                                "：" +
                                _vm._s(item.numerical)
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("div", {
                  staticClass: "clinicalTips",
                  domProps: {
                    innerHTML: _vm._s(_vm.highlightText(item.clinicalTips)),
                  },
                }),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "content-title" }, [
      _vm._v(" 结果解读："),
      _c("br"),
      _c("span", { staticClass: "tip" }, [
        _vm._v("具体判断请结合临床和连续监测的数值，以医生诊断为准。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data-info" }, [
      _c("p", { staticClass: "content-title" }, [_vm._v("项目意义：")]),
      _c("div", { staticClass: "data-info-content" }, [
        _vm._v(
          " 血常规、CRP是最常见的炎症检测指标，两联检能充分利用各项指标的特点，并形成互补，更准确地判断患者的感染类型。在临床上可以辅助快速辨别感染性疾病的病因，有利于提高诊断正确率，有效鉴别细菌病毒感染，指导合理高效的用药。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
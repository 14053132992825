<template>
  <div class="report-pay" v-if="orderInfo.checkCode">
    <baseInfo :detail="{ checkOrderVo: orderInfo }" statusText="待支付" />
    <div class="wx-btn" @click="wxPay">微信支付</div>

    <!-- <div class="btn" @click="createOrder">去支付</div>
    <div class="btn" @click="wxPay" v-if="orderCode">微信支付</div> -->
  </div>
</template>

<script>
import services from '@/service';
import baseInfo from '@/components/report/baseInfo';

// import { Stepper, Image as VanImage, Icon, Field } from 'vant';
import { mapGetters } from 'vuex';
import { isWeChat, isMiniProgram } from '@/global';
import wechat from '@/wechat';
import pay from '@/lib/pay';
import { wxAuthTo } from '@/lib/utils';
import { payStatusAll } from '@/enum';

export default {
  components: { baseInfo },
  data() {
    return {
      address: {},
      products: [],
      timer: null,
      toCheck: false,
      orderInfo: {},
      orderCode: '',
      serviceOrderId: '',
      checkOrderId: '',
      payStatusAll
    };
  },
  computed: {
    ...mapGetters(['openId', 'patientInfo']),
    hasCartNum() {
      return this.products.reduce((sum, product) => sum + product.number, 0);
    },
    cartTotalPrice() {
      return this.products.reduce((sum, product) => sum + product.number * product.salePrice, 0) * 100;
    },
    code() {
      return this.$route.query.code;
    },
    hasOrder() {
      return this.orderCode;
    },
    state() {
      return this.$route.query.state;
    }
  },
  watch: {
    $route: {
      async handler() {
        if (this.$route.query.state) {
          const state = JSON.parse(window.atob(this.$route.query.state));

          this.checkOrderId = state.checkOrderId;
          await this.getOpenId();
          await this.getOrderDetail();
        } else {
          this.pageInit();
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async toBindMobile() {
      const params = {
        checkOrderId: this.checkOrderId,
        patientPhone: this.patientInfo.phone
      };
      await services.patient.bindMobile(params);
    },
    pageInit() {
      wxAuthTo({
        routerName: 'patientReportPay',
        query: {
          checkOrderId: this.$route.query.id
        }
      });
    },
    async getOpenId() {
      if (isWeChat && !this.openId && this.code) {
        const res = await services.common.getOpenId({ authorizeCode: this.code });
        this.$store.dispatch('set_openId', res.data.openId);
      }
    },
    async wxPay() {
      if (isMiniProgram) {
        const data = {
          orderCode: this.orderCode,
          tradeType: 'JSAPI'
        };
        wechat.$wx.miniProgram.navigateTo({ url: `/pages/miniH5Pay/index?data=${JSON.stringify(data)}` });
        if (!this.toCheck) {
          this.checkOrderStatus();
        }
      } else {
        this.$store.dispatch('addLoading');
        const res = await services.patient.wxPay({
          orderCode: this.orderCode,
          tradeType: 'JSAPI',
          openId: this.openId
        });
        this.$store.dispatch('subLoading');

        await pay(res.data);
        this.$toast.success('支付成功');
        setTimeout(() => {
          this.$router.replace({
            name: 'patientHome'
          });
        }, 1000);
      }
    },
    async getOrderDetail() {
      const { data } = await services.patient.getOrder(this.checkOrderId);
      if (data.payStatus !== '0') {
        this.$toast('非待支付状态');
        return this.$router.replace({
          name: 'patientHome'
        });
      }
      if (!data.patientPhone) {
        await this.toBindMobile();
      }
      this.orderInfo = data;
      this.orderCode = data.checkCode;
      await this.wxPay();
    },
    checkOrderStatus() {
      this.toCheck = true;
      this.timer = setInterval(this.pollOrderStatus, 1000);
    },
    async pollOrderStatus() {
      const { data } = await services.patient.getOrder(this.checkOrderId);
      if (!['0'].includes(data.payStatus)) {
        clearInterval(this.timer);
        this.$toast.success('支付成功');
        this.$router.replace({
          name: 'patientHome'
        });
      }
    }
  },
  async mounted() {}
};
</script>

<style lang="scss" scoped>
.report-pay {
  background: #fff;
  padding-bottom: $tb;
}
.wx-btn {
  width: 90%;
  margin: 0 auto;
}
.order-confirm {
  padding: $tb $lr;
}

.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  // margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.view-details {
  font-size: $size1;
  color: $appcolor;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  overflow: hidden;
}

.price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  padding: $tb $lr;
  font-size: $size3;
  color: $gray1;
  text-align: right;
  .price {
    color: $red1;
    font-weight: 600;
    margin-left: $lr;
  }
}
.product-item {
  flex: 1;
  border-bottom: 1px solid $bordercolor;
  padding: $tb 0;
  margin: 0 $lr;
  &:last-child {
    border: none;
  }
}

.product-content {
  display: flex;
  align-items: center;
}

.product-image {
  width: 100px;
  height: 100px;
  border-radius: 5px 0 0 5px;
  margin-right: $lr;
}

.product-details {
  flex: 1;
  .product-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: $lr;
  }
}

.product-name {
  color: $black1;
  margin-bottom: $tb;
  font-size: $size2;
}

.product-description {
  color: $gray2;
  margin-bottom: $tb;
  font-size: $size4;
  @include hiddenText();
}

.product-price {
  font-weight: 600;
  color: $black1;
}

.van-submit-bar {
  bottom: $tabbarHeight;
}
.cart-icon {
  font-size: 24px;
  &.active {
    color: $appcolor;
  }
}

.btn {
  margin: $tb $lr;
}
</style>

const partnerLogin = () => import(/* webpackChunkName: "partner" */ '@/views/partner/login');
const partnerHome = () => import(/* webpackChunkName: "partner" */ '@/views/partner/home');
const partnerPersonal = () => import(/* webpackChunkName: "partner" */ '@/views/partner/personal');
const partnerAddClinic = () => import(/* webpackChunkName: "partner" */ '@/views/partner/addClinic');
const partnerBindDevice = () => import(/* webpackChunkName: "partner" */ '@/views/partner/bindDevice');
const partnerDeviceList = () => import(/* webpackChunkName: "partner" */ '@/views/partner/deviceList');
const partnerSalesDeviceList = () => import(/* webpackChunkName: "partner" */ '@/views/partner/salesDeviceList');
const partnerMyClinic = () => import(/* webpackChunkName: "partner" */ '@/views/partner/myClinic');
const partnerMyTeam = () => import(/* webpackChunkName: "partner" */ '@/views/partner/myTeam');
const partnerPartnerInfo = () => import(/* webpackChunkName: "partner" */ '@/views/partner/partnerInfo');
const partnerFinance = () => import(/* webpackChunkName: "partner" */ '@/views/partner/finance');
const partnerFinanceDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/financeDetail');
const partnerDeviceDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/deviceDetail');
const partnerReportDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/reportDetail');
const partnerClinicDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicDetail');
const partnerClock = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clock');
const partnerInfoEdit = () => import(/* webpackChunkName: "partner" */ '@/views/partner/infoEdit');
const partnerInfoEditAdd = () => import(/* webpackChunkName: "partner" */ '@/views/partner/infoEditAdd');
const partnerInviteEdit = () => import(/* webpackChunkName: "partner" */ '@/views/partner/inviteEdit');
const partnerInviteSuccess = () => import(/* webpackChunkName: "partner" */ '@/views/partner/inviteSuccess');
const partnerClinicInfo = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicInfo');
const partnerClinicInfoEdit = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicInfoEdit');
const partnerMyPartner = () => import(/* webpackChunkName: "partner" */ '@/views/partner/myPartner');
const partnerClinicOrder = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicOrder');
const partnerClinicOrderList = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicOrderList');
const partnerBuy = () => import(/* webpackChunkName: "partner" */ '@/views/partner/buy');
const partnerOrderConfirm = () => import(/* webpackChunkName: "partner" */ '@/views/partner/orderConfirm');
const partnerOrderDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/orderDetail');
const partnerMealDetail = () => import(/* webpackChunkName: "partner" */ '@/views/partner/mealDetail');
const partnerMyOrder = () => import(/* webpackChunkName: "partner" */ '@/views/partner/myOrder');
const partnerMyAddress = () => import(/* webpackChunkName: "partner" */ '@/views/partner/myAddress');
const partnerAddressEdit = () => import(/* webpackChunkName: "partner" */ '@/views/partner/addressEdit');
const partnerClinicInventory = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicInventory');
const partnerClinicPerformance = () => import(/* webpackChunkName: "partner" */ '@/views/partner/clinicPerformance');
const partnerStockSales = () => import(/* webpackChunkName: "partner" */ '@/views/partner/stockSales');
const partnerStockDeviceSales = () => import(/* webpackChunkName: "partner" */ '@/views/partner/stockDeviceSales');
const partnerSalesStockRecord = () => import(/* webpackChunkName: "partner" */ '@/views/partner/salesStockRecord');
const partnerDeviceApplyRecord = () => import(/* webpackChunkName: "partner" */ '@/views/partner/deviceApplyRecord');
const partnerMaterialApplication = () =>
  import(/* webpackChunkName: "partner" */ '@/views/partner/materialApplication');

export default [
  {
    path: '/partner-login',
    name: 'partnerLogin',
    component: partnerLogin,
    meta: { title: '登录', hideBack: true, noAuth: true }
  },
  {
    path: '/partner-home',
    name: 'partnerHome',
    component: partnerHome,
    meta: { title: '首页', hideBack: true }
  },
  {
    path: '/partner-personal',
    name: 'partnerPersonal',
    component: partnerPersonal,
    meta: {
      title: '我的',
      hideBack: true,
      keeps: ['subjectAnalyse', 'subjectLawerdetail']
    }
  },
  {
    path: '/partner-add-clinic',
    name: 'partnerAddClinic',
    component: partnerAddClinic,
    meta: {
      title: '添加诊所'
    }
  },
  {
    path: '/partner-bind-device',
    name: 'partnerBindDevice',
    component: partnerBindDevice,
    meta: {
      title: '绑定设备'
    }
  },
  {
    path: '/partner-my-team',
    name: 'partnerMyTeam',
    component: partnerMyTeam,
    meta: {
      title: '我的团队',
      hideBack: true
    }
  },
  {
    path: '/partner-partner-info',
    name: 'partnerPartnerInfo',
    component: partnerPartnerInfo,
    meta: {
      title: '业务员信息'
    }
  },
  {
    path: '/partner-finance',
    name: 'partnerFinance',
    component: partnerFinance,
    meta: {
      title: '业绩统计'
    }
  },
  {
    path: '/partner-finance-detail',
    name: 'partnerFinanceDetail',
    component: partnerFinanceDetail,
    meta: {
      title: '业绩详情'
    }
  },
  {
    path: '/partner-device-List',
    name: 'partnerDeviceList',
    component: partnerDeviceList,
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/partner-sales-device-List',
    name: 'partnerSalesDeviceList',
    component: partnerSalesDeviceList,
    meta: {
      title: '设备列表'
    }
  },
  {
    path: '/partner-device-detail',
    name: 'partnerDeviceDetail',
    component: partnerDeviceDetail,
    meta: {
      title: '设备详情'
    }
  },
  {
    path: '/partner-my-clinic',
    name: 'partnerMyClinic',
    component: partnerMyClinic,
    meta: {
      title: '我的诊所',
      keeps: ['partnerClinicInfo']
    }
  },
  {
    path: '/partner-report-detail',
    name: 'partnerReportDetail',
    component: partnerReportDetail,
    meta: {
      title: '报告详情'
    }
  },
  {
    path: '/partner-clinic-detail',
    name: 'partnerClinicDetail',
    component: partnerClinicDetail,
    meta: {
      title: '诊所详情'
    }
  },
  {
    path: '/partner-clock',
    name: 'partnerClock',
    component: partnerClock,
    meta: {
      title: '打卡'
    }
  },
  {
    path: '/partner-info-edit',
    name: 'partnerInfoEdit',
    component: partnerInfoEdit,
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/partner-info-edit-add',
    name: 'partnerInfoEditAdd',
    component: partnerInfoEditAdd,
    meta: {
      title: '基本信息'
    }
  },
  {
    path: '/partner-invite-edit',
    name: 'partnerInviteEdit',
    component: partnerInviteEdit,
    meta: {
      title: `诚邀您加入${process.env.VUE_APP_TITLE}`,
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/partner-invite-success',
    name: 'partnerInviteSuccess',
    component: partnerInviteSuccess,
    meta: {
      title: '提交成功',
      hideBack: true,
      noAuth: true
    }
  },
  {
    path: '/partner-clinic-info',
    name: 'partnerClinicInfo',
    component: partnerClinicInfo,
    meta: {
      title: '诊所信息'
    }
  },
  {
    path: '/partner-clinic-info-edit',
    name: 'partnerClinicInfoEdit',
    component: partnerClinicInfoEdit,
    meta: {
      title: '诊所信息'
    }
  },
  {
    path: '/partner-my-partner',
    name: 'partnerMyPartner',
    component: partnerMyPartner,
    meta: {
      title: '诊所业务员'
    }
  },
  {
    path: '/partner-clinic-order',
    name: 'partnerClinicOrder',
    component: partnerClinicOrder,
    meta: {
      title: '诊所订单'
    }
  },
  {
    path: '/partner-clinic-order-list',
    name: 'partnerClinicOrderList',
    component: partnerClinicOrderList,
    meta: {
      title: '待出库'
    }
  },
  {
    path: '/partner-buy',
    name: 'partnerBuy',
    component: partnerBuy,
    meta: {
      title: '服务购买'
    }
  },
  {
    path: '/partner-order-confirm',
    name: 'partnerOrderConfirm',
    component: partnerOrderConfirm,
    meta: {
      title: '确认订单',
      keeps: ['partnerMyAddress']
    }
  },
  {
    path: '/partner-order-detail',
    name: 'partnerOrderDetail',
    component: partnerOrderDetail,
    meta: {
      title: '订单详情',
      keeps: ['partnerMyAddress']
    }
  },
  {
    path: '/partner-meal-detail',
    name: 'partnerMealDetail',
    component: partnerMealDetail,
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/partner-my-order',
    name: 'partnerMyOrder',
    component: partnerMyOrder,
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/partner-my-address',
    name: 'partnerMyAddress',
    component: partnerMyAddress,
    meta: {
      title: '我的收货地址',
      keeps: ['partnerOrderConfirm']
    }
  },
  {
    path: '/partner-address-edit',
    name: 'partnerAddressEdit',
    component: partnerAddressEdit,
    meta: {
      title: '编辑地址'
    }
  },
  {
    path: '/partner-clinic-inventory',
    name: 'partnerClinicInventory',
    component: partnerClinicInventory,
    meta: {
      title: '诊所库存'
    }
  },
  {
    path: '/partner-clinic-performance',
    name: 'partnerClinicPerformance',
    component: partnerClinicPerformance,
    meta: {
      title: '业绩分析',
      hideBack: true
    }
  },
  {
    path: '/partner-stock-sales',
    name: 'partnerStockSales',
    component: partnerStockSales,
    meta: {
      title: '业务员库存'
    }
  },
  {
    path: '/partner-sales-stock-record',
    name: 'partnerSalesStockRecord',
    component: partnerSalesStockRecord,
    meta: {
      title: '库存明细'
    }
  },
  {
    path: '/partner-device-apply-record',
    name: 'partnerDeviceApplyRecord',
    component: partnerDeviceApplyRecord,
    meta: {
      title: '设备申请记录'
    }
  },
  {
    path: '/partner-material-application',
    name: 'partnerMaterialApplication',
    component: partnerMaterialApplication,
    meta: {
      title: '申请设备'
    }
  },
  {
    path: '/partner-stock-device-sales',
    name: 'partnerStockDeviceSales',
    component: partnerStockDeviceSales,
    meta: {
      title: '业务员设备库存'
    }
  }
];

<template>
  <div class="pdf-box">
    <div id="pdf-content" />
    <!-- <van-image id="print-img" width="100%" :src="imgUrl" />
    <div v-print="'#print-img'">打印</div> -->
    <div name="down" class="print-icon" @click="toPrint" v-if="!isWeChat"><i class="iconfont icon-xzbg" /></div>
  </div>
</template>
<script>
import { isDev, isWeChat } from '@/global';
import print from 'vue-print-nb';
import Pdfh5 from 'pdfh5';
import 'pdfh5/css/pdfh5.css';
import services from '@/service';

export default {
  components: {},
  directives: {
    print
  },
  data() {
    return {
      isWeChat,
      imgUrl: '',
      currentPage: 0,
      pdfSrc: '',
      pdf: null
    };
  },
  computed: {
    fileSrc() {
      return this.$route.query.src;
    }
  },
  mounted() {
    this.pdf = new Pdfh5('#pdf-content', {
      pdfurl: isDev ? 'http://localhost:8080/test.pdf' : this.fileSrc
    });
    // this.getFileBase64();
  },
  methods: {
    loadPdfHandler() {},
    async getFileBase64() {
      const res = await services.common.getFileBase64({
        // fileUrl: 'https://ajk-base.oss-cn-guangzhou.aliyuncs.com/20240510094648A898.pdf'
        fileUrl: 'https://h5.zhuoniaoyunyi.com/logo.png'
      });
      this.imgUrl = `data:image/png;base64,${res.data}`;
      console.log(this.imgUrl);
    },
    toPrint() {
      window.open(this.fileSrc);
    }
  }
};
</script>

<style lang="scss" scoped>
.pdf-box {
  width: 100%;
  height: 100%;
}
/* 去掉页眉页脚 */
@page {
  size: auto;
  margin: 0mm;
}

.print-icon {
  cursor: pointer;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: 80px;
  bottom: 66px;
  font-size: 34px;
  padding: 8px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
  transition-duration: 0.3s;
  &:hover {
    background-color: $appcolor;
    color: #fff;
  }
}
</style>

<template>
  <div>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
        <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
        <div class="order-list">
          <div class="order-item" v-for="(order, index) in listInfo" :key="index">
            <div class="order-content">
              <div class="order-header">
                <p class="order-time">{{ order.clinicName }}</p>
                <span class="order-status-tag">
                  {{ serviceOrderState.getValueByKey(order.status) }}
                </span>
              </div>
              <div v-for="(product, pIndex) in order.orderDetailVoList" :key="pIndex" class="order-product">
                <div class="order-image">
                  <van-image fit="cover" :src="product.goodsIcon" :alt="product.goodsName" class="order-avatar" />
                </div>
                <div class="order-info">
                  <h3 class="order-name">{{ product.goodsName }}</h3>
                  <p class="order-description">{{ product.description }}</p>
                </div>
                <div class="order-info-right">
                  <p class="order-price">￥{{ product.totalAmount }}</p>
                  <div class="pay-btn" v-if="showFoot(product)" @click.stop="shipping(product)">发货</div>
                </div>
              </div>
              <div class="order-summary">
                <p class="create-time">{{ order.createTime }}</p>
                <p>
                  合计：<span class="total-price">￥{{ order.payAmount }}</span>
                </p>
              </div>
              <!-- <div class="panel-foot" v-if="showFoot(order)">
                <div class="panel-left"></div>
                <div class="panel-right">
                  <div class="pay-btn" @click.stop="shipping(order)">发货</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </list>
    </pull-refresh>

    <fieldPrompt
      title="发货"
      v-if="fieldPromptModel.show"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      @emit="shippingEmit"
    />
  </div>
</template>

<script>
import services from '@/service';
import { PullRefresh, List, Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import { wxAuthTo } from '@/lib/utils';

import { serviceOrderStateAll, stockSalesListAll } from '@/enum/index';

import fieldPrompt from '@/components/fieldPrompt';

export default {
  components: { PullRefresh, List, VanImage, fieldPrompt },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {}
      },
      orders: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      serviceOrderState: {},
      stockSalesList: {}
    };
  },
  computed: {
    ...mapGetters(['clinicId']),
    curId() {
      return this.$route.query.id;
    }
  },
  async mounted() {
    const [serviceOrderState, stockSalesList] = await Promise.all([serviceOrderStateAll(), stockSalesListAll()]);
    this.serviceOrderState = serviceOrderState;
    this.stockSalesList = stockSalesList;
    console.log(stockSalesList);
    this.refreshList();
  },
  methods: {
    async shippingEmit(e) {
      if (!e.salesStockId) return this.$notify('请选择业务员');
      const params = {
        inventoryType: '2',
        salesStockId: e.salesStockId,
        serviceOrderDetailId: e.serviceOrderDetailId,
        serviceOrderId: e.serviceOrderId
      };

      await services.partner.reduceOrderInventory(params);
      this.fieldPromptModel.show = false;
      this.refreshList();
    },
    showFoot(item) {
      return item.status === '1';
    },
    toDeatil(item) {
      wxAuthTo({
        routerName: 'clinicOrderDetail',
        query: {
          orderCode: item.orderCode,
          serviceOrderId: item.serviceOrderId
        }
      });
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.partner.getOneClinicServiceOrderList({ ...this.queryParams, clinicId: this.curId });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
      // this.$store.dispatch('subLoading');
    },
    async shipping(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
      this.fieldPromptModel.fieldForm = {
        salesStockId: {
          label: '业务员',
          component: 'select',
          options: this.stockSalesList.enums
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.order-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  overflow: hidden;
  position: relative;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $tb;
  padding-bottom: $tb;
  border-bottom: 1px solid $bordercolor;
}

.order-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
  position: relative;
}

.order-avatar {
  width: 100%;
  height: auto;
}

.order-info {
  flex: 1;
}

.order-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
}

.order-description {
  color: $gray2;
  font-size: $size4;
}

.order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.order-info-right {
  text-align: right;
}

.order-price {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 500;
  color: $gray2;
}

.order-time {
  font-size: $size4;
  color: $gray2;
  line-height: 20px;
  flex: 1;
}

.order-status-tag {
  font-size: $size4;
  font-weight: 500;
  color: $appcolor;
}

.status-pending {
  color: #ffc107; /* 待发货状态背景色 */
}

.status-shipped {
  color: $green; /* 已发货状态背景色 */
}

.order-product {
  display: flex;
  margin-bottom: 10px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .create-time {
    color: $gray2;
  }
}

.panel-foot {
  border-top: 1px solid $bordercolor;
  @include flexCenter(row);
  justify-content: space-between;
  padding-top: $tb;
  margin-top: $tb;
}

.total-price {
  color: $red1;
  font-weight: bold;
}

.pay-btn {
  font-size: $size6;
  margin-left: $lr;
  border-radius: 4px;
  padding: 6px 10px;
  border: 1px solid $appcolor;
  color: $appcolor;
  &:active {
    background-color: $appcolor;
    color: #fff;
  }
}
</style>

<template>
  <div ref="chartBar" class="chart-bar"></div>
</template>

<script>
import * as echarts from 'echarts';
import { echartsPieColor } from '@/global';

export default {
  data() {
    return {
      charts: {}
    };
  },

  props: {
    option: {
      type: Object,
      default() {
        return {};
      }
    },
    filterKey: {
      type: String,
      default: ''
    },
    lastStart: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    option: {
      handler: function(val) {
        this.drawLine(val);
      },
      deep: true
    }
  },
  mounted() {
    this.drawLine(this.option);
    window.addEventListener('resize', () => {
      this.charts.resize();
    });
  },

  methods: {
    mapValues(x, val, newVal) {
      const xMin = 0;
      const xMax = val;
      const yMin = 0;
      const yMax = newVal;

      // 线性映射公式
      const y = ((x - xMin) / (xMax - xMin)) * (yMax - yMin) + yMin;

      return y;
    },
    drawLine(options) {
      this.charts.clear && this.charts.clear();
      if (Object.keys(this.charts).length == 0) {
        this.charts = echarts.init(this.$refs.chartBar);
      }

      const dataLength = options.dataset?.source.length || 0;

      let dataZoom = [];

      if (dataLength > 6) {
        if (this.lastStart) {
          dataZoom = [
            {
              type: 'slider',
              start: 100 * ((dataLength - 7) / dataLength),
              end: 100,
              filterMode: 'filter',
              showDetail: false
            }
          ];
        } else {
          dataZoom = [
            {
              type: 'slider',
              startValue: 0, // 第1项
              endValue: 6, // 第11项
              filterMode: 'filter',
              showDetail: false
            }
          ];
        }
      }

      const params = {
        legend: {
          selected: {
            检测量: true
          }
        },
        tooltip: {},
        color: echartsPieColor,
        dataset: options.dataset,
        xAxis: {
          type: 'category'
        },
        yAxis: {},
        grid: { x: '12%', y: '20%', width: '80%', height: '50%' },
        dataZoom,
        series: new Array(options.dataset.source[0].length - 1).fill().map(() => {
          return { type: 'bar' };
        })
      };
      if (options.dataset?.source.length > 10) {
        params.xAxis.axisLabel = {
          interval: 'auto',
          rotate: 45, //倾斜角度设置,是什么时针未测
          margin: 8 //距离上部的间距
        };
      } else {
        params.xAxis.axisLabel = {};
      }

      this.charts.setOption(params);
      this.charts.on('click', 'series', params => {
        params.filterKey = this.filterKey;
        this.$emit('chartFilter', params);
      });
    }
  }
};
</script>
<style scoped>
.chart-bar {
  width: 100%;
  height: 300px;
}
</style>

<template>
  <div class="point-price">
    <p>
      <span>
        <span class="rmb">￥</span>
        <span class="real-price">{{ realPrice }}</span>
      </span>
      <template v-if="showRatio">
        <span class="points" v-if="memberRatio != 0"> +{{ memberRatio }}%积分</span>
      </template>
      <template v-if="!showRatio">
        <span class="points" v-if="product.memberRatio != 0"> +{{ pointsDeduction }}积分</span>
      </template>
    </p>
    <p class="origin-price" v-if="showOriginPrice">原价:￥{{ originPrice }}</p>
  </div>

  <!-- <p>
    <span class="points" v-if="memberRatio != 0"> 积分{{ memberRatio }}% + </span><span>￥{{ realPrice }}</span>
  </p> -->
</template>

<script>
export default {
  data() {
    return {
      showRatio: true
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    realPrice() {
      let price = (this.product.price - this.product.price * this.product.memberRatio).toFixed(2);
      if (price === '0.00') {
        price = 0;
      }
      return price;
    },
    pointsDeduction() {
      return (this.product.price * this.product.memberRatio).toFixed(0);
    },
    originPrice() {
      return this.product.price.toFixed(2);
    },
    showOriginPrice() {
      return this.realPrice !== this.originPrice;
    },
    memberRatio() {
      return (this.product.memberRatio * 100).toFixed(0);
    }
  },
  async mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.point-price {
  .rmb {
    font-size: 10px;
    color: $red1;
  }
  .real-price {
    font-size: $size1;
    color: $red1;
  }

  .points {
    font-size: 12px;
    color: $gray1;
  }

  .origin-price {
    font-size: 12px;
    color: $gray2;
    margin-top: 6px;
  }
}
</style>

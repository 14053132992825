<template>
  <div class="meal-detail">
    <!-- 顶部轮播图组件 -->
    <div class="swipe-warp" v-if="images.length > 0">
      <swipe :autoplay="3000">
        <swipe-item v-for="(item, index) in images" :key="index">
          <img :src="item" class="slide-image" />
        </swipe-item>
      </swipe>
    </div>

    <div class="meal-info">
      <h3 class="meal-name">{{ info.goodsName }}</h3>
      <div class="product-info">
        <div class="product-item" v-for="(item, index) in info.goodsSettingVoList" :key="index">
          <div class="product-content">
            <van-image fit="cover" :src="item.goodsIcon" :alt="item.goodsName" class="product-image" />
            <span class="product-name">{{ item.goodsName }}</span>
          </div>
          <span class="product-num"><span class="num-tip">数量：</span>{{ item.mealNum }}</span>
        </div>
      </div>
    </div>

    <div class="info-list">
      <cell-group>
        <cell title="分类" :value="packageTypeAll.getValueByKey(info.goodsType)" />
        <cell title="规格" :value="info.goodsSpec" />
        <cell title="单位" :value="info.goodsUnit" />
        <cell title="用途" :value="info.goodsFunction" />
        <cell title="描述" :value="info.goodsDesc" />
      </cell-group>
    </div>

    <div class="img-list-box">
      <img
        class="img-item"
        v-for="(item, index) in images"
        :key="index"
        :src="item"
        mode="widthFix"
        style="width:100%;"
      />
    </div>

    <goods-action>
      <goods-action-icon icon="revoke" text="返回" @click="goBack" />
      <goods-action-icon icon="chat-o" text="客服" @click="goTel" />
      <goods-action-button type="danger" text="立即购买" @click="goBuy" />
    </goods-action>
  </div>
</template>

<script>
import {
  Swipe,
  SwipeItem,
  Cell,
  CellGroup,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Image as VanImage
} from 'vant';
import services from '@/service';

import { packageTypeAll } from '@/enum/index';
import { wxAuthTo } from '@/lib/utils';

export default {
  components: {
    Swipe,
    SwipeItem,
    Cell,
    CellGroup,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    VanImage
  },
  data() {
    return {
      info: {},
      packageTypeAll
    };
  },
  computed: {
    images() {
      const arr = [];
      if (!this.info.goodsMealId) return arr;
      this.info.goodsIcon && arr.push(this.info.goodsIcon);
      this.info.goodsImgs && arr.push(...this.info.goodsImgs.split(','));
      return arr;
    },
    curId() {
      return this.$route.query.id;
    }
  },
  methods: {
    viewLicenseImage() {
      // 查看执照附件图片的逻辑
    },
    async getDetail() {
      if (!this.curId) return;
      const res = await services.clinic.mealDetail(this.curId);
      this.info = res.data;
    },
    goBack() {
      this.$router.go(-1);
    },
    goTel() {
      // 跳转到客服电话的逻辑
    },
    goBuy() {
      const arr = [
        {
          goodsMealId: this.info.goodsMealId,
          number: 1
        }
      ];

      wxAuthTo({
        routerName: 'clinicOrderConfirm',
        query: {
          products: arr
        }
      });
    }
  },
  mounted() {
    this.getDetail();
  }
};
</script>

<style lang="scss" scoped>
.van-cell__value {
  text-align: left;
}
.van-cell__title {
  width: 100px;
  flex: none;
}
.meal-detail {
  .swipe-warp {
    margin-bottom: $tb;
    .slide-image {
      width: 100%;
    }
  }

  .meal-info {
    background: #fff;
    margin-bottom: $tb;

    .meal-name {
      padding: 20px $lr 10px;
      font-size: $size1;
      font-weight: 600;
      color: $black1;
    }

    .product-info {
      padding: 0 $lr;

      .product-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $tb 0;
        border-bottom: 1px solid $bordercolor;
        font-size: $size2;

        .product-content {
          display: flex;
          align-items: center;

          &:last-child {
            border-bottom: none;
          }
          .product-image {
            width: 60px;
            height: 40px;
            margin-right: $lr;
            border-radius: 4px;
            overflow: hidden;
          }
          .product-name {
            font-weight: 500;
            color: $black1;
          }
        }
        .product-num {
          font-weight: 500;

          .num-tip {
            color: $gray2;
            font-size: $size6;
          }
        }
      }
    }

    .info-list {
      margin: $tb 0;

      .cell__value {
        color: $gray2;
      }
    }
  }

  .img-list-box {
    font-size: 0;
    margin-top: $edge2;
  }
}
</style>

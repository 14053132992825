var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-page" },
    [
      _c(
        "div",
        { staticClass: "cart-list" },
        _vm._l(_vm.cartItems, function (item, index) {
          return _c("div", { key: item.goodsId, staticClass: "cart-item" }, [
            _c(
              "div",
              { staticClass: "item-img" },
              [
                _c("van-image", {
                  attrs: { src: item.goodsIcon, fit: "cover" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "item-info" }, [
              _c("p", { staticClass: "item-name" }, [
                _vm._v(_vm._s(item.goodsName)),
              ]),
              _c("p", { staticClass: "item-price" }, [
                _vm._v("¥" + _vm._s(item.salePrice)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "item-quantity" },
              [
                _c("stepper", {
                  attrs: {
                    theme: "round",
                    "button-size": "22",
                    "disable-input": "",
                    min: "0",
                    max: "99",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.refreshInfo(item, index)
                    },
                  },
                  model: {
                    value: item.number,
                    callback: function ($$v) {
                      _vm.$set(item, "number", $$v)
                    },
                    expression: "item.number",
                  },
                }),
              ],
              1
            ),
          ])
        }),
        0
      ),
      !_vm.cartItems.length
        ? _c("van-empty", {
            staticClass: "custom-image",
            attrs: {
              image: "https://img01.yzcdn.cn/vant/custom-empty-image.png",
              description: "购物车暂无商品",
              "image-size": "80",
            },
          })
        : _vm._e(),
      _vm.cartItems.length
        ? _c(
            "div",
            { staticClass: "settle-bar" },
            [
              _c("span", { staticClass: "total-price" }, [
                _vm._v("合计： "),
                _c("span", { staticClass: "price" }, [
                  _vm._v("¥" + _vm._s(_vm.calcInfo.payAmount)),
                ]),
              ]),
              _c(
                "van-button",
                {
                  attrs: { type: "primary", size: "small", round: "" },
                  on: { click: _vm.handleSettle },
                },
                [_vm._v("去结算")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("tabbar", { attrs: { curIndex: 2 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
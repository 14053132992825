<template>
  <Popup v-model="visible" round @closed="closedHandle">
    <div class="opinion-warp">
      <p class="opinion-title">意见反馈</p>
      <Field
        v-model="value"
        label=""
        placeholder="请留下您的宝贵意见，帮助我们为您提供更好的服务。"
        type="textarea"
        maxLength="500"
        rows="6"
      />
      <div class="btn" @click="opinionSubmit">提交</div>
    </div>
  </Popup>
</template>

<script>
import { Popup, Field } from 'vant';
import services from '@/service';
import { getUserType } from '@/lib/utils';

export default {
  components: { Popup, Field },
  data() {
    return {
      value: '',
      curValue: []
    };
  },
  props: {
    show: {
      type: Boolean,
      defautl: false
    },
    info: {
      type: Object,
      required: true,
      defautl: () => {
        return {};
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    }
  },
  methods: {
    closedHandle() {
      this.visible = false;
    },
    async opinionSubmit() {
      if (!this.value.trim()) return;
      const params = {
        feedbackMsg: this.value,
        feedbackType: 'suggestion'
      };
      await services[getUserType()].createFeedback(params);
      this.value = '';
      this.$toast.success('提交成功');
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.opinion-warp {
  padding: $tb $lr;
  width: 300px;
  .opinion-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .btn {
    margin-bottom: 0;
  }
}
</style>

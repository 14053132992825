var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "points-mall" },
    [
      _c("mallSearch"),
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "mall-swipe",
              attrs: { autoplay: 3000 },
              on: { change: _vm.onSwipeChange },
            },
            _vm._l(_vm.swiperList, function (item, index) {
              return _c(
                "van-swipe-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item.bannerContent)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "swiper-img",
                    attrs: { src: item.imageUrl },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.notices.length
        ? _c(
            "div",
            { staticClass: "notice-panel" },
            [
              _c(
                "van-notice-bar",
                { attrs: { "left-icon": "volume-o", scrollable: false } },
                [
                  _c(
                    "van-swipe",
                    {
                      staticClass: "notice-swipe",
                      attrs: {
                        vertical: "",
                        autoplay: 5000,
                        touchable: false,
                        "show-indicators": false,
                      },
                    },
                    _vm._l(_vm.notices, function (text, index) {
                      return _c("van-swipe-item", { key: index }, [
                        _vm._v(" " + _vm._s(text) + " "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ach-header", on: { click: _vm.toPointsDetail } },
        [
          _c("div", [
            _c("p", { staticClass: "ach-title" }, [_vm._v("总积分")]),
            _c(
              "p",
              { staticClass: "ach-num" },
              [
                _c("countTo", {
                  attrs: {
                    separator: "",
                    startVal: 0,
                    endVal: _vm.totalPoints,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "discount-panel" },
        [
          _c(
            "tabs",
            {
              model: {
                value: _vm.active,
                callback: function ($$v) {
                  _vm.active = $$v
                },
                expression: "active",
              },
            },
            _vm._l(_vm.tabList, function (item, index) {
              return _c("tab", { key: index, attrs: { title: item.name } })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "goods-grid" },
            _vm._l(_vm.discountGoods, function (goods) {
              return _c(
                "div",
                {
                  key: goods.id,
                  staticClass: "goods-item",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(goods.drugsId)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "goods-img" },
                    [
                      _c("van-image", {
                        staticClass: "goods-cover",
                        attrs: {
                          src: goods.drugsImage.split(",")[0],
                          fit: "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "goods-title" }, [
                    _vm._v(_vm._s(goods.name)),
                  ]),
                  _c(
                    "p",
                    { staticClass: "goods-points" },
                    [
                      _c("span", { staticClass: "points" }),
                      _c("pointPrice", { attrs: { product: goods } }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("tabbar", { attrs: { curIndex: 0 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import reporter from '@/lib/reporter';
import router from '@/router';
import { Dialog } from 'vant';
import store from '@/store';
import wechat from '@/wechat';
import { isDev, isWeChat, isMiniProgram, wechatAppid, appConfig, userClient } from '@/global';
import services from '@/service';
import dayjs from 'dayjs';
import { clearCache } from '@/enum/enum';

export const phoneReg = /^1[3456789]\d{9}$/;

export const businessReg = /^[a-zA-Z0-9]{10,20}$/;

export function validatePhoneNumber(phone) {
  return phoneReg.test(phone);
}

export function blobToUint8Array(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', e => {
      try {
        const result = e.target.result;
        if (!result) {
          throw new Error('pdf解析失败');
        }
        resolve(result);
      } catch (err) {
        reject(err.message);
      }
    });
    reader.readAsArrayBuffer(blob);
  });
}

export function formatBlobToEext(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('loadend', e => {
      try {
        const result = e.target.result;
        if (!result) {
          throw new Error('解析失败');
        }
        resolve(JSON.parse(result));
      } catch (err) {
        resolve();
      }
    });
    reader.readAsText(blob);
  });
}

export function arrayToTree(sNodes, options = {}) {
  let i, l;
  const key = options.key || 'id';
  const parentKey = options.parentKey || 'parentId';
  const childKey = options.childKey || 'children';
  if (!key || key == '' || !sNodes) return [];

  const arr = JSON.parse(JSON.stringify(sNodes));

  if (arr instanceof Array) {
    const r = [];
    const tmpMap = [];
    for (i = 0, l = arr.length; i < l; i++) {
      tmpMap[arr[i][key]] = arr[i];
    }
    for (i = 0, l = arr.length; i < l; i++) {
      if (tmpMap[arr[i][parentKey]] && arr[i][key] != arr[i][parentKey]) {
        if (!tmpMap[arr[i][parentKey]][childKey]) tmpMap[arr[i][parentKey]][childKey] = [];

        tmpMap[arr[i][parentKey]][childKey].push(arr[i]);
      } else {
        r.push(arr[i]);
      }
    }
    return r;
  } else {
    return [arr];
  }
}

export function treeToArray(tree, options = {}) {
  const { level = 0, fullParentId = '' } = options;
  const arr = [];

  tree.forEach(item => {
    arr.push({ ...item, level, fullParentId });
    if ((item.children || []).length > 0) {
      const childFullParentId = fullParentId ? [...fullParentId.split('/'), item.id].join('/') : item.id;
      arr.push(...treeToArray(item.children, { level: level + 1, fullParentId: childFullParentId }));
    }
  });

  return arr;
}

export function formatMoney(value) {
  var param = {};
  var k = 10000,
    sizes = ['', '万', '亿', '万亿'],
    i;
  if (value < k) {
    param.value = value;
    param.unit = '';
  } else {
    i = Math.floor(Math.log(value) / Math.log(k));

    param.value = (value / Math.pow(k, i)).toFixed(2);
    param.unit = sizes[i];
  }
  return param;
}

export function getFilterKeywords({ searchKeywords = [], filterArr = [] }) {
  const words = [...searchKeywords];

  const textSearchItem = filterArr.find(item => item.key === 'textSearch');
  if (textSearchItem) {
    (textSearchItem.value || []).forEach(item => {
      const text = item.value.trim();
      if (text) {
        words.push(text);
      }
    });
  }

  return words;
}

export function formatKeywordByRegexp(title, words = [], replaceVal = '') {
  if (!title || words.length < 1) {
    return title;
  }
  const originTitle = title;

  try {
    title = title.replace(/<\/?[a-zA-Z]+(\s+[a-zA-Z]+=".*")*>/g, '');
    title = title.replace(
      new RegExp('(' + words.join('|') + ')', 'g'),
      replaceVal || '<span style="color: #f86359">$1</span>'
    );
  } catch (err) {
    title = '';
    reporter.log({
      message: `高亮转换失败，title：${originTitle}，搜索词：${JSON.stringify(words)}`
    });
  }

  return title || originTitle;
}

export function getUserType() {
  return window.location.pathname.split('/')[1].split('-')[0];
}

export function getCurToken() {
  return localStorage.getItem(`webview_token_${getUserType()}`);
}

export function inviteUrl(routerName, id) {
  return `${window.location.origin}/${camelToKebab(routerName)}?id=${id}`;
}

// export function setPageTypeToken() {
//   getUserType();
// }

export function signOut() {
  Dialog.confirm({
    title: '提示',
    message: '请确认是否退出登录？'
  }).then(async () => {
    await services.common.logout();
    resetToLogin();
  });
}

export function resetToLogin() {
  store.dispatch('set_token', '');
  store.dispatch('set_openId', '');
  clearCache();
  wxToLogin('reLaunch');
}

// 获取定位时需要重新注册下，否则会失败
export function getLocation() {
  return new Promise((resolve, reject) => {
    wechat.refreshSdk(() => {
      wechat.$wx.getLocation({
        type: 'gcj02',
        success: function(res) {
          resolve(res);
        },
        fail: function(res) {
          reject(res);
        }
      });
    });
  });
}

export function openLocation(options) {
  wechat.$wx.openLocation({
    ...options,
    scale: 14
  });
}

export function sharePage({ title, desc, link, partnerId }) {
  if (isMiniProgram) {
    wechat.$wx.miniProgram.navigateTo({ url: `/pages/sharePage/index?partnerId=${partnerId}` });
  } else {
    return new Promise(async resolve => {
      await wechat.$wx.updateAppMessageShareData({ title, desc, link, imgUrl: 'https://img01.yzcdn.cn/vant/logo.png' });
      await wechat.$wx.updateTimelineShareData({ title, desc, link, imgUrl: 'https://img01.yzcdn.cn/vant/logo.png' });
      resolve();
    });
  }
}

export function extractAfterFirstComma(str) {
  const index = str.indexOf(',');
  if (index !== -1) {
    return str.substring(index + 1);
  }
  return str; // 如果没有逗号，则返回原始字符串
}

export function scanQRCode() {
  return new Promise((resolve, reject) => {
    wechat.$wx.scanQRCode({
      needResult: 1,
      success: res => {
        resolve(extractAfterFirstComma(res.resultStr));
      },
      error: function(res) {
        reject(res);
      }
    });
  });
}

export function camelToKebab(camelCase) {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function wxAuthTo({ routerName, query }) {
  const openId = store.getters.openId;
  const queryState = window.btoa(JSON.stringify(query));
  if (!isMiniProgram && isWeChat && !isDev && !openId) {
    const redirectUri = `${process.env.VUE_APP_REDIRECT_URI}/${camelToKebab(routerName)}`;
    const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatAppid}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=snsapi_base&state=${queryState}&connect_redirect=1#wechat_redirect`;
    window.location.href = href;
  } else {
    router.push({
      name: routerName,
      query: {
        state: queryState
      }
    });
  }
}

export async function checkLogin() {
  return new Promise(async resolve => {
    const token = getCurToken();
    if (!token) {
      wxToLogin();
    } else {
      resolve();
    }
  });
}

export function checkTokenTo(cb) {
  const token = getCurToken();
  if (!token && isMiniProgram) {
    wxToLogin();
  } else {
    cb();
  }
}

export function routerPush(options) {
  checkTokenTo(() => {
    router.push({ ...options });
  });
}

export function notRequest() {
  if (!getCurToken() && isMiniProgram) {
    return true;
  }
  return false;
}

// reLaunch/navigateTo
export function wxToLogin(type = 'navigateTo') {
  if (isMiniProgram) {
    wechat.$wx.miniProgram[type]({ url: '/pages/login/index' });
  } else {
    router.replace({
      path: `/${getUserType()}-login`
    });
  }
}

export async function sleep(ms = 500) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

// 日期格式处理
export function getRangeByDate(date, options = {}) {
  const { beginKey = 'beginTime', endKey = 'endTime' } = options;
  const isArr = Array.isArray(date);
  if (!date || (isArr && date.length !== 2)) {
    return {};
  }

  let startDate = '';
  let endDate = '';

  if (isArr) {
    startDate = dayjs(date[0]).format('YYYY-MM-DD');
    endDate = dayjs(date[1]).format('YYYY-MM-DD');
  } else {
    startDate = dayjs(date).format('YYYY-MM-DD');
    endDate = startDate;
  }

  return { [beginKey]: startDate, [endKey]: endDate };
}

export function getCurrentConfig() {
  const type = getUserType();
  if (userClient.includes(type)) {
    return appConfig[type];
  } else {
    return {};
  }
}

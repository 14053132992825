var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "admin-info" }, [
        _c("div", { staticClass: "ach-panel" }, [
          _c("div", { staticClass: "ach-warp" }, [
            _c(
              "div",
              { staticClass: "personal-info" },
              [
                _c("van-image", {
                  staticClass: "avatar",
                  attrs: {
                    fit: "cover",
                    src: _vm.adminInfo.user.avatar || _vm.avatar,
                  },
                }),
                _c("div", { staticClass: "personal-right" }, [
                  _c("p", { staticClass: "personal-name" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.pageInit ? "请登录" : _vm.adminInfo.user.nickName
                      )
                    ),
                  ]),
                  _c("p", { staticClass: "personal-tel" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.pageInit
                            ? "登录后查看更多"
                            : _vm.adminInfo.user.remark
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "personal-func-list" },
          _vm._l(
            _vm.funcList.filter((i) => !i.show || i.show()),
            function (item, index) {
              return _c("cell", {
                key: index,
                attrs: { "is-link": "" },
                on: {
                  click: function ($event) {
                    return _vm.toLink(item.link)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", { class: ["iconfont", `${item.icon}`] }),
                          _c("span", { staticClass: "custom-title" }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right-icon",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "right" },
                            [_c("icon", { attrs: { name: "arrow" } })],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }
          ),
          1
        ),
      ]),
      _c("tabbar", { attrs: { curIndex: 3 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
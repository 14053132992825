var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "intention" },
    [
      _c("div", { staticClass: "login-bg" }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "intention-form" },
        [
          _c(
            "van-form",
            { ref: "formRef" },
            [
              _c("field", {
                attrs: {
                  name: "姓名",
                  label: "姓名：",
                  placeholder: "请输入姓名",
                  maxLength: "20",
                  required: "",
                  rules: [{ required: true, message: "请输入姓名" }],
                },
                model: {
                  value: _vm.formInfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "name", $$v)
                  },
                  expression: "formInfo.name",
                },
              }),
              _c("field", {
                attrs: {
                  name: "手机号",
                  label: "手机号：",
                  placeholder: "请输入手机号",
                  type: "number",
                  maxLength: "11",
                  required: "",
                  rules: [
                    {
                      required: true,
                      message: "请输入正确的手机号",
                      pattern: _vm.phoneReg,
                    },
                  ],
                },
                model: {
                  value: _vm.formInfo.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "phone", $$v)
                  },
                  expression: "formInfo.phone",
                },
              }),
              _c("field", {
                attrs: {
                  name: "所在区域",
                  label: "所在区域：",
                  placeholder: "请选择所在区域",
                  readonly: "",
                  clickable: "",
                  value: _vm.area,
                  rules: [{ required: true, message: "请选择所在区域" }],
                  required: "",
                },
                on: {
                  click: function ($event) {
                    _vm.showArea = true
                  },
                },
              }),
              _c(
                "popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showArea,
                    callback: function ($$v) {
                      _vm.showArea = $$v
                    },
                    expression: "showArea",
                  },
                },
                [
                  _c("Area", {
                    attrs: { value: _vm.areaValue, "area-list": _vm.areaList },
                    on: {
                      confirm: _vm.onConfirm,
                      cancel: function ($event) {
                        _vm.showArea = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c("field", {
                attrs: {
                  name: "详细地址",
                  label: "详细地址：",
                  placeholder: "请输入详细地址",
                  rules: [{ required: true, message: "请输入详细地址" }],
                  type: "textarea",
                  required: "",
                },
                model: {
                  value: _vm.formInfo.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "address", $$v)
                  },
                  expression: "formInfo.address",
                },
              }),
              _c("field", {
                attrs: {
                  name: "备注",
                  label: "备注：",
                  placeholder: "输入备注",
                  type: "textarea",
                },
                model: {
                  value: _vm.formInfo.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInfo, "remark", $$v)
                  },
                  expression: "formInfo.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "introduction" }, [
        _vm._v(
          " 爱检康生物科技(广州)有限公司是一家专注于为基层医疗机构提供优质诊疗项目和服务的供应商，致力于为基层医疗机构检验检测一站式解决方案。 "
        ),
      ]),
      _c("div", { staticStyle: { height: "100px" } }),
      _c("fixedButton", {
        attrs: { text: "提交意向登记" },
        on: { emitEvent: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("p", { staticClass: "header-login" }, [_vm._v("检验检测仪器")]),
      _c("p", { staticClass: "header-title" }, [_vm._v("免费投放")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
const adminLogin = () => import(/* webpackChunkName: "admin" */ '@/views/admin/login');
const adminHome = () => import(/* webpackChunkName: "admin" */ '@/views/admin/home');
const adminClinicHis = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinicHis');
const adminClinic = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinic');
const adminStatistics = () => import(/* webpackChunkName: "admin" */ '@/views/admin/statistics');
const adminStatisticsTable = () => import(/* webpackChunkName: "admin" */ '@/views/admin/statistics/table.vue');
const adminPersonal = () => import(/* webpackChunkName: "admin" */ '@/views/admin/personal');
const adminClinicHisDetail = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinicHisDetail');
const adminCheckOrder = () => import(/* webpackChunkName: "admin" */ '@/views/admin/checkOrder');
const adminReportDetail = () => import(/* webpackChunkName: "admin" */ '@/views/admin/reportDetail');
const adminClinicList = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinicList');
const adminClinicInfoEdit = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinicInfoEdit');
const adminDeviceRecord = () => import(/* webpackChunkName: "admin" */ '@/views/admin/deviceRecord');
const adminDeviceRecordAdd = () => import(/* webpackChunkName: "admin" */ '@/views/admin/deviceRecord/add');
const adminClinicPerformance = () => import(/* webpackChunkName: "admin" */ '@/views/admin/clinicPerformance');

const adminDoctorHis = () => import(/* webpackChunkName: "admin" */ '@/views/admin/doctorHis');
const adminDoctorHisDetail = () => import(/* webpackChunkName: "admin" */ '@/views/admin/doctorHisDetail');
const adminDeviceApplyRecord = () => import(/* webpackChunkName: "admin" */ '@/views/admin/deviceApplyRecord');
const adminDeviceShipment = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/deviceApplyRecord/shipment.vue');

export default [
  {
    path: '/admin-login',
    name: 'adminLogin',
    component: adminLogin,
    meta: {
      title: '登录',
      noAuth: true,
      hideBack: true
    }
  },
  {
    path: '/admin-home',
    name: 'adminHome',
    component: adminHome,
    meta: {
      title: '功能模块',
      hideBack: true
    }
  },
  {
    path: '/admin-clinic',
    name: 'adminClinic',
    component: adminClinic,
    meta: {
      title: '诊所库存管理'
    }
  },
  {
    path: '/admin-clinic-his',
    name: 'adminClinicHis',
    component: adminClinicHis,
    meta: {
      title: '诊所审批',
      keeps: ['adminClinicHisDetail']
    }
  },
  {
    path: '/admin-statistics',
    name: 'adminStatistics',
    component: adminStatistics,
    meta: {
      title: '统计',
      hideBack: true,
      keeps: ['adminStatisticsTable']
    }
  },
  {
    path: '/admin-statistics-table',
    name: 'adminStatisticsTable',
    component: adminStatisticsTable,
    meta: {
      title: '数据明细'
    }
  },
  {
    path: '/admin-Personal',
    name: 'adminPersonal',
    component: adminPersonal,
    meta: {
      title: '我的',
      hideBack: true
    }
  },
  {
    path: '/admin-clinic-his-detail',
    name: 'adminClinicHisDetail',
    component: adminClinicHisDetail,
    meta: {
      title: '诊所审批信息'
    }
  },
  {
    path: '/admin-check-order',
    name: 'adminCheckOrder',
    component: adminCheckOrder,
    meta: {
      title: '检测管理',
      keeps: ['adminReportDetail']
    }
  },
  {
    path: '/admin-report-detail',
    name: 'adminReportDetail',
    component: adminReportDetail,
    meta: {
      title: '报告详情'
    }
  },
  {
    path: '/admin-clinic-list',
    name: 'adminClinicList',
    component: adminClinicList,
    meta: {
      title: '诊所管理'
    }
  },
  {
    path: '/admin-clinic-info-edit',
    name: 'adminClinicInfoEdit',
    component: adminClinicInfoEdit,
    meta: {
      title: '诊所信息'
    }
  },
  {
    path: '/admin-device-record',
    name: 'adminDeviceRecord',
    component: adminDeviceRecord,
    meta: {
      title: '设备轨迹记录'
    }
  },
  {
    path: '/admin-device-record-add',
    name: 'adminDeviceRecordAdd',
    component: adminDeviceRecordAdd,
    meta: {
      title: '设备轨迹'
    }
  },
  {
    path: '/admin-clinic-performance',
    name: 'adminClinicPerformance',
    component: adminClinicPerformance,
    meta: {
      title: '业绩分析',
      hideBack: true
    }
  },
  {
    path: '/admin-his',
    name: 'adminHis',
    component: adminDoctorHis,
    meta: {
      title: '医生审批',
      keeps: ['adminDoctorHisDetail']
    }
  },
  {
    path: '/admin-doctor-his-detail',
    name: 'adminDoctorHisDetail',
    component: adminDoctorHisDetail,
    meta: {
      title: '审批信息'
    }
  },
  {
    path: '/admin-device-apply-record',
    name: 'adminDeviceApplyRecord',
    component: adminDeviceApplyRecord,
    meta: {
      title: '设备申请记录'
    }
  },
  {
    path: '/admin-device-shipment',
    name: 'adminDeviceShipment',
    component: adminDeviceShipment,
    meta: {
      title: '发货'
    }
  }
];

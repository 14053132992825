var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.initEnum
        ? _c("searchFilter", {
            attrs: { searchForm: _vm.searchForm, immediately: "" },
            on: { emitSearch: _vm.emitSearch },
          })
        : _vm._e(),
      _c(
        "pull-refresh",
        {
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c(
            "list",
            {
              attrs: {
                finished: !_vm.hasNext,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.loadList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.listInfo.length == 0 && !_vm.loading
                ? _c("div", {
                    staticStyle: { "text-align": "center", padding: "10vh 0" },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "panel-list" },
                _vm._l(_vm.listInfo, function (item, index) {
                  return _c("div", { key: index, staticClass: "panel-item" }, [
                    _c("div", { staticClass: "panel-content" }, [
                      _c("div", { staticClass: "panel-header" }, [
                        _c("p", { staticClass: "panel-time" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "panel-status-tag",
                            style: {
                              color: _vm.deviceApplyAll.getColorByKey(
                                item.status
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.deviceApplyAll.getValueByKey(item.status)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "panel-product" }, [
                        _c("p", [
                          _c("span", { staticClass: "out" }, [
                            _vm._v("设备实发数： "),
                            _c("span", [_vm._v(_vm._s(item.deviceOutCount))]),
                            _vm._v(" 台 "),
                          ]),
                          _c("span", { staticClass: "apply" }, [
                            _vm._v(
                              "（申请数：" +
                                _vm._s(item.deviceApplyCount) +
                                "）"
                            ),
                          ]),
                        ]),
                        _c("p", [
                          _c("span", { staticClass: "out" }, [
                            _vm._v("耗材实发数： "),
                            _c("span", [
                              _vm._v(_vm._s(item.consumablesOutCount)),
                            ]),
                            _vm._v(" 份 "),
                          ]),
                          _c("span", { staticClass: "apply" }, [
                            _vm._v(
                              "（申请数：" +
                                _vm._s(item.consumablesApplyCount) +
                                "）"
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "gray-warp" }, [
                          _c("p", { staticClass: "gray-info" }, [
                            _vm._v(_vm._s(item.goodsName)),
                          ]),
                          _c("p", { staticClass: "gray-info" }, [
                            _vm._v(" 申请理由："),
                            _c("span", [_vm._v(_vm._s(item.applyContent))]),
                          ]),
                          _c("p", { staticClass: "gray-info" }, [
                            _vm._v("申请人：" + _vm._s(item.createBy)),
                          ]),
                          item.remark
                            ? _c("p", { staticClass: "gray-info" }, [
                                _vm._v("不通过原因：" + _vm._s(item.remark)),
                              ])
                            : _c("p", { staticClass: "gray-info" }, [
                                _vm._v(
                                  " 收货信息：" +
                                    _vm._s(_vm.showArea(item)) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: _vm.copyValue(item),
                                        expression: "copyValue(item)",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: () => _vm.$toast("复制成功"),
                                        expression: "() => $toast('复制成功')",
                                        arg: "success",
                                      },
                                    ],
                                    staticClass: "copy",
                                  },
                                  [_vm._v(" 复制")]
                                ),
                              ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "panel-info panel-foot" }, [
                        _c("span", { staticClass: "panel-left" }, [
                          item.recordUrl
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.preview(item.recordUrl)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" 申请图片 "),
                                  _c("icon", { attrs: { name: "eye-o" } }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c("span", { staticClass: "panel-right" }, [
                          _vm.showAudit(item)
                            ? _c(
                                "span",
                                {
                                  staticClass: "foot-btn unbind-btn",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.audit(item)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              )
                            : _vm._e(),
                          _vm.showShipment(item)
                            ? _c(
                                "span",
                                {
                                  staticClass: "foot-btn replace-btn",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.shipment(item)
                                    },
                                  },
                                },
                                [_vm._v("发货")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "fieldPrompt",
        {
          ref: "fieldPrompt",
          attrs: {
            title: "审核",
            show: _vm.fieldPromptModel.show,
            fieldForm: _vm.fieldPromptModel.fieldForm,
            info: _vm.fieldPromptModel.info,
          },
          on: {
            "update:show": function ($event) {
              return _vm.$set(_vm.fieldPromptModel, "show", $event)
            },
          },
        },
        [
          _vm.fieldPromptModel.info
            ? _c(
                "div",
                {
                  staticClass: "prompt-content",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c("p", [
                    _vm._v(" 设备申请数量："),
                    _c("span", { staticStyle: { "margin-right": "30px" } }, [
                      _vm._v(
                        _vm._s(_vm.fieldPromptModel.info.deviceApplyCount)
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" 耗材申请数量："),
                    _c("span", { staticStyle: { "margin-right": "30px" } }, [
                      _vm._v(
                        _vm._s(_vm.fieldPromptModel.info.consumablesApplyCount)
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c("fixedBottom", { attrs: { slot: "button" }, slot: "button" }, [
            _c("div", { staticClass: "btn-warp" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn2",
                  on: {
                    click: function ($event) {
                      return _vm.changeAudit("1")
                    },
                  },
                },
                [_vm._v("不通过")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.changeAudit("2")
                    },
                  },
                },
                [_vm._v("通过")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.fieldPromptModel.info
        ? _c(
            "van-dialog",
            {
              attrs: { title: "不通过原因", "show-cancel-button": "" },
              on: {
                confirm: function ($event) {
                  return _vm.sumbitAudit("1")
                },
              },
              model: {
                value: _vm.auditShow,
                callback: function ($$v) {
                  _vm.auditShow = $$v
                },
                expression: "auditShow",
              },
            },
            [
              _c("field", {
                attrs: {
                  label: "",
                  type: "textarea",
                  placeholder: "请输入不通过原因",
                },
                model: {
                  value: _vm.fieldPromptModel.info.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.fieldPromptModel.info, "remark", $$v)
                  },
                  expression: "fieldPromptModel.info.remark",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
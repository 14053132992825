<template>
  <div></div>
</template>

<script>
import qs from 'qs';

// async function test(token, phone, rxid) {
//   let params = {};
//   let url = `/api/base/hospital/cacheHospital/NULL`;
//   let res = await request(url, params, 'get');
//   if (res.code == 1) {
//     let code = res.data.data;

//     if (token) {
//       if (rxid != '' && token) {
//         console.log(1);
//         localStorage.setItem('S_hosCodeWeiXin', '');
//         localStorage.setItem('S_rxid', rxid);
//         // 扫处方进入，验证处方
//         this.$router.replace({
//           path: `/verification`
//         });
//       } else if (code == 'null' || code == '') {
//         localStorage.setItem('S_hosCodeWeiXin', '');
//         sessionStorage.setItem('normal', 'hospital');
//         this.$router.replace({
//           path: `/cooperationList/1`
//         });
//       } else {
//         console.log('3');
//         localStorage.setItem('S_hosCodeWeiXin', code);
//         this.$router.replace({
//           path: `/hospitalHome`
//         });
//       }
//     } else {
//       this.$this.$router.replace({
//         path: `/myOrder`
//       });
//     }
//   } else {
//     console.log('报错');
//   }
// }

export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    //   testtest(){},
  },
  computed: {
    userType() {
      return this.$route.query.userType;
    },
    token() {
      return this.$route.query.token;
    }
  },
  async mounted() {
    if (this.token) {
      // 兼容小程序跳转url的userType为miniInit, 微信授权重定向后userType正常
      localStorage.setItem(`webview_token_${this.userType}`, this.token);

      await Promise.all([
        this.$store.dispatch('set_token', this.token),
        this.$store.dispatch(`set_${this.userType}Info`),
        this.$store.dispatch('set_checkOrderState')
      ]);
    } else {
      localStorage.setItem(`webview_token_${this.userType}`, '');
      this.$store.dispatch('set_token', '');
    }

    const { redirect_name: redirectName = '', redirect_query: redirectQuery = '' } = this.$route.query;

    this.$router.push({
      path: `/${this.userType}-home`
    });
    if (redirectName && this.token) {
      const query = qs.parse(decodeURIComponent(redirectQuery));
      setTimeout(() => {
        this.$router.push({ name: redirectName, query });
      }, 2000);
    }
  }
};
</script>

<template>
  <div class="clinic-detail">
    <!-- 顶部轮播图组件 -->
    <swipe :autoplay="3000">
      <swipe-item v-for="(item, index) in images" :key="index">
        <img :src="item" class="slide-image" />
      </swipe-item>
    </swipe>

    <!-- 诊所地址 -->
    <!-- <div class="clinic-address">{{ clinicAddress }}</div> -->

    <!-- 诊所详情信息 -->
    <div class="clinic-info">
      <h3 class="clinic-name">{{ clinicName }}</h3>
      <div class="info-list">
        <cell-group inset>
          <cell title="机构名称" :value="organizationName" />
          <cell title="法人名称" :value="legalPersonName" />
          <cell title="营业执照" :value="businessLicense" />
          <cell title="手机号码" :value="phoneNumber" />
          <cell title="有效期" :value="expirationDate" />
          <cell title="机构简介" :value="organizationSummary" />
          <cell title="执照附件" is-link @click="viewLicenseImage" />
        </cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Cell, CellGroup } from 'vant';

export default {
  components: {
    Swipe,
    SwipeItem,
    Cell,
    CellGroup
  },
  data() {
    return {
      images: ['https://via.placeholder.com/400x200', 'https://via.placeholder.com/400x200'],
      clinicAddress: '诊所地址', // 诊所地址，模拟数据
      clinicName: '诊所名称', // 诊所名称，模拟数据
      organizationName: '机构名称', // 机构名称，模拟数据
      legalPersonName: '法人名称', // 法人名称，模拟数据
      businessLicense: '营业执照', // 营业执照，模拟数据
      phoneNumber: '手机号码', // 手机号码，模拟数据
      expirationDate: '2024-12-31', // 有效期，模拟数据
      organizationSummary: '机构简介' // 机构简介，模拟数据
    };
  },
  methods: {
    viewLicenseImage() {
      // 查看执照附件图片的逻辑
    }
  }
};
</script>

<style lang="scss" scoped>
.clinic-detail {
  .slide-image {
    width: 100%;
  }
  .clinic-address {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }

  .clinic-info {
    .clinic-name {
      margin: $tb $lr;
      font-size: $size1;
      font-weight: 600;
      color: $black1;
    }

    .info-list {
      .van-cell__value {
        color: $gray2;
      }
    }
  }
}
</style>

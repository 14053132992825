<template>
  <div class="device-detail">
    <!-- 顶部轮播图组件 -->
    <swipe :autoplay="3000">
      <swipe-item v-for="(item, index) in images" :key="index">
        <van-image fit="cover" :src="item" class="slide-image" />
      </swipe-item>
    </swipe>

    <!-- 设备状态 -->
    <cell-group inset>
      <cell title="设备状态">
        <template #right-icon>
          <span :class="getStatusClass(info.status)" class="status-normal">
            {{ defaultFlagAll.getValueByKey(info.status) }}
          </span>
        </template>
      </cell>
    </cell-group>

    <!-- 设备详情信息 -->
    <div class="device-info">
      <h3 class="device-name">{{ info.goodsName }}</h3>
      <div class="info-list">
        <cell-group inset>
          <cell title="设备有效期" :value="info.deviceValidDate" />
          <cell title="设备编号" :value="info.deviceNum" />
          <cell title="设备类型" :value="info.subType" />
          <cell title="设备描述" :value="info.goodsFunction" />
        </cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Cell, CellGroup, Image as VanImage } from 'vant';
import services from '@/service';
import { defaultFlagAll } from '@/enum/index';

export default {
  components: {
    Swipe,
    SwipeItem,
    Cell,
    CellGroup,
    VanImage
  },
  computed: {
    images() {
      const arr = [];
      if (!this.info.clinicDeviceRelId) return arr;
      this.info.goodsIcon && arr.push(this.info.goodsIcon);
      this.info.goodsImgs && arr.push(...this.info.goodsImgs.split(','));
      return arr;
    },
    curId() {
      return this.$route.query.id;
    }
  },
  data() {
    return {
      info: {},
      defaultFlagAll
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      if (!this.curId) return;
      const res = await services.clinic.getDeviceInfo(this.curId);
      this.info = res.data;
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.device-detail {
  .slide-image {
    width: 100%;
  }

  .status-normal {
    color: green;
  }

  .status-abnormal {
    color: red;
  }

  .device-name {
    margin: $tb $lr;
    font-size: $size1;
    font-weight: 600;
    color: $black1;
  }

  .info-list {
    .van-cell__value {
      color: $gray2;
    }
  }
}
</style>

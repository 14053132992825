var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-detail" },
    [
      _c(
        "swipe",
        { attrs: { autoplay: 3000 } },
        _vm._l(_vm.images, function (item, index) {
          return _c(
            "swipe-item",
            { key: index },
            [
              _c("van-image", {
                staticClass: "slide-image",
                attrs: { fit: "cover", src: item },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "cell-group",
        { attrs: { inset: "" } },
        [
          _c("cell", {
            attrs: { title: "设备状态" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "status-normal",
                        class: _vm.getStatusClass(_vm.info.status),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.defaultFlagAll.getValueByKey(_vm.info.status)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "device-info" }, [
        _c("h3", { staticClass: "device-name" }, [
          _vm._v(_vm._s(_vm.info.goodsName)),
        ]),
        _c(
          "div",
          { staticClass: "info-list" },
          [
            _c(
              "cell-group",
              { attrs: { inset: "" } },
              [
                _c("cell", {
                  attrs: {
                    title: "设备有效期",
                    value: _vm.info.deviceValidDate,
                  },
                }),
                _c("cell", {
                  attrs: { title: "设备编号", value: _vm.info.deviceNum },
                }),
                _c("cell", {
                  attrs: { title: "设备类型", value: _vm.info.subType },
                }),
                _c("cell", {
                  attrs: { title: "设备描述", value: _vm.info.goodsFunction },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="finance-detail">
    <dropdown-menu active-color="#1989fa">
      <dropdown-item v-model="queryParams.goodsSettingId" :options="goodsSetting.enums" @change="refreshList" />
      <dropdown-item v-model="queryParams.salesId" :options="allSales.enums" @change="refreshList" />
    </dropdown-menu>

    <div class="finance-item-warp">
      <div class="finance-item" v-for="(item, index) in listInfo" :key="index">
        <div class="finance-item-title">
          <div>
            {{ item.clinicName }}<span class="sales-name">({{ item.salesName }})</span>
          </div>
          <div>{{ item.salesAmount }}</div>
        </div>
        <div class="finance-item-content" v-if="item.goodsSettingDetails.length">
          <div class="finance-item-content-item" v-for="(oItem, oIndex) in item.goodsSettingDetails" :key="oIndex">
            <span>{{ oItem.goodsSettingName }}</span>
            <span>{{ oItem.salesAmount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 100px"></div>
    <Icon name="descending" class="add-icon" @click="exportExcel" />
    <tabbar :curIndex="2" />
  </div>
</template>

<script>
import tabbar from '../components/tabbar';

import { DropdownMenu, DropdownItem, Icon } from 'vant';
import services from '@/service';
import { allGoodsSetting, allSalesList } from '@/enum/index';
import FileSaver from 'file-saver';

export default {
  components: { tabbar, DropdownMenu, DropdownItem, Icon },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        goodsSettingId: '',
        salesId: ''
      },
      listInfo: [],
      goodsSetting: {},
      allSales: {},
      detail: {}
    };
  },
  computed: {
    billId() {
      return this.$route.query.billId;
    }
  },
  async mounted() {
    const params = {
      billId: this.billId
    };
    const [goodsSetting, allSales] = await Promise.all([allGoodsSetting(params), allSalesList(params)]);
    this.goodsSetting = goodsSetting;
    this.allSales = allSales;

    this.refreshList();
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      const res = await services.partner.getSalesBillDetails({ billId: this.billId, ...this.queryParams });
      this.detail = res.data;
      this.listInfo = [...this.listInfo, ...res.data.clinicDetails];
      this.hasNext = true;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'clinicDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    async exportExcel() {
      const result = await services.partner.orderBindExport({ billId: this.billId, ...this.queryParams });
      const blob = new Blob([result]);

      FileSaver.saveAs(blob, `${this.detail.billName}.xlsx`);
    },
    toDetail(item) {
      this.$router.push({
        name: 'partnerPartnerInfo',
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-item-warp {
  padding: $tb $lr;
  .finance-item {
    border-radius: $radius;
    overflow: hidden;
    margin-top: $tb;

    .finance-item-title {
      padding: $tb $lr;
      background: $btnbg;
      color: $white1;
      font-size: $size5;
      display: flex;
      justify-content: space-between;
      .sales-name {
        font-size: $size5;
        margin-left: $lr;
      }
    }

    .finance-item-content {
      padding: $tb $lr;
      background: #fff;
      font-size: $size3;
      line-height: 28px;
      // margin-bottom: $tb;
      border-radius: 0 0 $radius $radius;

      .finance-item-content-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.add-icon {
  position: absolute;
  right: 20px;
  bottom: 100px;
  font-size: 34px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  padding: 8px;
  border-radius: 50%;
  background: #fff;
}
</style>

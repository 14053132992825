var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pageInit
        ? _c("setClinic", {
            ref: "setClinic",
            attrs: { initShow: _vm.initShow },
            on: { refresh: _vm.refreshList },
          })
        : _vm._e(),
      _c(
        "pull-refresh",
        {
          attrs: { disabled: !_vm.pageInit },
          on: { refresh: _vm.refreshList },
          model: {
            value: _vm.pullLoading,
            callback: function ($$v) {
              _vm.pullLoading = $$v
            },
            expression: "pullLoading",
          },
        },
        [
          _c("div", { staticClass: "home-page" }, [
            _c("div", { staticClass: "ach-panel" }, [
              _c("p", { staticClass: "line-title" }, [_vm._v("业绩")]),
              _c("div", { staticClass: "ach-warp" }, [
                _c("div", { staticClass: "ach-header" }, [
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("当日")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.todaySalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("当月")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.cmSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "ach-title" }, [_vm._v("上月")]),
                    _c(
                      "p",
                      { staticClass: "ach-num" },
                      [
                        _c("countTo", {
                          attrs: {
                            separator: "",
                            startVal: 0,
                            endVal: _vm.scoreInfo.lmSalseAmount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "func-warp" }, [
              _c(
                "div",
                {
                  staticClass: "func-item",
                  on: {
                    click: function ($event) {
                      return _vm.toLink("clinicDetection")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-jc" }),
                  _c("span", [_vm._v("申请检测")]),
                ]
              ),
              _vm.clinicId === 1
                ? _c(
                    "div",
                    {
                      staticClass: "func-item",
                      on: {
                        click: function ($event) {
                          return _vm.toLink("scanQRCode")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont icon-sm" }),
                      _c("span", [_vm._v("扫码检测")]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "func-item",
                  on: {
                    click: function ($event) {
                      return _vm.toLink("clinicBuy")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-cg" }),
                  _c("span", [_vm._v("我要采购")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "report-panel" }, [
              _c("p", { staticClass: "line-title" }, [_vm._v("报告")]),
              _c(
                "div",
                { staticClass: "report-warp" },
                [
                  _vm._l(_vm.listInfo, function (item, index) {
                    return _c("reportIBox", {
                      key: index,
                      attrs: { info: item },
                    })
                  }),
                  !_vm.listInfo.length
                    ? _c("empty", { attrs: { description: "暂无数据" } })
                    : _vm._e(),
                ],
                2
              ),
              _vm.listInfo.length
                ? _c(
                    "span",
                    { staticClass: "read-more", on: { click: _vm.toMore } },
                    [_vm._v("更多"), _c("icon", { attrs: { name: "arrow" } })],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "warning-panel" }, [
              _c("p", { staticClass: "line-title" }, [_vm._v("预警")]),
              _c(
                "div",
                { staticClass: "horizontal-scroll" },
                [
                  !_vm.stockList.length
                    ? _c("empty", { attrs: { description: "暂无数据" } })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "warning-warp" },
                    _vm._l(_vm.stockList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "warning-item" },
                        [
                          _c("div", { staticClass: "warning-name" }, [
                            _vm._v(_vm._s(item.goodsName)),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("库存：")]),
                            _vm._v(_vm._s(item.stock)),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("有效期：")]),
                            _vm._v(_vm._s(item.serviceLimitDate)),
                          ]),
                          item.stockAlarmLevel === 1 ||
                          item.timeAlarmLevel === 1
                            ? _c("i", {
                                staticClass: "iconfont icon-yujing",
                                staticStyle: { color: "#ffb811" },
                              })
                            : _vm._e(),
                          item.stockAlarmLevel === 2 ||
                          item.timeAlarmLevel === 2
                            ? _c("i", {
                                staticClass: "iconfont icon-yujing",
                                staticStyle: { color: "#fe271b" },
                              })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticStyle: { height: "100px" } }),
        ]
      ),
      _vm.earlyWarningModel.show
        ? _c("earlyWarning", {
            attrs: {
              show: _vm.earlyWarningModel.show,
              info: _vm.earlyWarningModel.info,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.earlyWarningModel, "show", $event)
              },
            },
          })
        : _vm._e(),
      _c("tabbar", { attrs: { curIndex: 0 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { debounce } from 'lodash';
// import request from '@/lib/request';

class Reporter {
  defaultInfo = {
    productName: 'webview',
    type: 'PAGE'
  };
  storages = [];

  constructor() {
    this.emit = debounce(this.emit.bind(this), 500, { leading: false, trailing: true });
  }

  log(options) {
    const newInfo = { ...this.defaultInfo, msg: options.message || 'message为空', pageUrl: location.href };
    if (options.code) {
      newInfo.code = options.code;
      newInfo.params = options.requestInfo;
    }
    console.error(newInfo);
    if (process.env.NODE_ENV === 'production') {
      this.storages.push(newInfo);
      this.emit();
    }
  }

  async emit() {
    if (this.storages.length) {
      const storages = [...this.storages];
      this.storages = [];
      let errArr = storages.map(item => {
        return new Promise(resolve => {
          try {
            resolve({ ...item, params: JSON.stringify(item.params) });
          } catch (err) {
            resolve({ ...item, params: err.message || 'emit json转义失败' });
          }
        });
      });
      errArr = await Promise.all(errArr);

      try {
        console.log(`report: ${errArr}`);
        // await request.post('/v2-api/admin/exception', errArr);
      } catch (err) {
        console.error(err);
      }
    }
  }
}

export default new Reporter();

<template>
  <Popup v-model="visible" round @closed="visible = false">
    <div class="opinion-warp">
      <p class="opinion-title">选择诊所</p>

      <div class="radio-warp">
        <radio-group v-model="clinicId">
          <radio :name="item.clinicId" v-for="(item, index) in clinicList" :key="index">
            {{ item.clinicName }}
          </radio>
        </radio-group>
      </div>

      <div class="btn" @click="submit">确认</div>
    </div>
  </Popup>
</template>

<script>
import services from '@/service';
import { Popup, RadioGroup, Radio } from 'vant';
import { mapGetters } from 'vuex';

export default {
  components: { Popup, RadioGroup, Radio },
  props: {
    initShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clinicList: [],
      clinicId: '',
      visible: false
    };
  },
  mounted() {
    this.getBindClinics();
  },
  computed: {
    ...mapGetters(['clinicInfo'])
  },
  methods: {
    async submit() {
      if (!this.clinicId) return this.$toast('请选择诊所');
      this.$emit('selected', {
        clinicId: this.clinicId
      });
      this.visible = false;
    },
    async getBindClinics() {
      const res = await services.partner.getBindClinicList();
      this.clinicList = res.data;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-radio-group {
    > div {
      border: 1px solid $bordercolor;
      margin: $tb 0;
      padding: $tb $lr;
      border-radius: $radius;
      transition-duration: 0.2s;
      &[aria-checked='true'] {
        border: 1px solid $appcolor;
      }
    }
  }
}

.opinion-warp {
  padding: $tb $lr;
  width: 300px;

  .opinion-title {
    text-align: center;
    font-size: $size1;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .radio-warp {
    margin: 30px 0;
    max-height: 50vh;
    overflow-y: auto;
  }
  .btn {
    margin-bottom: 0;
  }
}
</style>

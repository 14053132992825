function roundToDecimalPlace(number, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

const projectMap = {
  1: {
    name: '血糖血脂',
    projectItem: {
      result_0: {
        name: 'GLU',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.01, 1);
        }
      },
      result_1: {
        name: 'TG',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.001, 2);
        }
      },
      result_2: {
        name: 'HDL-C',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.001, 2);
        }
      },
      result_3: {
        name: 'TC',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.001, 2);
        }
      },
      result_4: {
        name: 'LDL-C',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.001, 2);
        }
      }
    }
  },
  9: {
    name: '肝功 3 项',
    projectItem: {
      result_0: {
        name: 'ALT',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_1: {
        name: 'AST',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_2: {
        name: 'ALB',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.01, 1);
        }
      }
    }
  },
  3: {
    name: '肾功 3 项',
    projectItem: {
      result_0: {
        name: 'CR',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_1: {
        name: 'UA',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_2: {
        name: 'UREA',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.01, 1);
        }
      },
      result_3: {
        name: 'BUN/CR',
        calc: function(n) {
          return roundToDecimalPlace(n / 100, 1);
        }
      },
      result_4: {
        name: 'UREA/CR',
        calc: function(n) {
          return roundToDecimalPlace(n / 10000, 2);
        }
      }
    }
  },
  7: {
    name: '代谢 3 项',
    projectItem: {
      result_0: {
        name: 'GLU',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.01, 1);
        }
      },
      result_1: {
        name: 'UA',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_2: {
        name: 'TC',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.001, 2);
        }
      }
    }
  },
  6: {
    name: '献血 2 项',
    projectItem: {
      result_0: {
        name: 'ALT',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      },
      result_1: {
        name: 'HB',
        calc: function(n) {
          return roundToDecimalPlace(n * 0.1, 0);
        }
      }
    }
  }
};

export default function(e) {
  const dataValue = JSON.parse(JSON.parse(e.dataValue));
  const projectItem = projectMap[dataValue.apiMode].projectItem;
  e.checkReportInspectionList = Object.keys(projectItem).map(i => {
    return {
      unit: 'mmol/L',
      paramValue: projectItem[i].calc(dataValue[i]),
      paramName: projectItem[i].name
    };
  });
  e.showRange = false;
}

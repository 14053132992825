import Vue from 'vue';
import bottomPopupDrag from './bottomPopupDrag';
import clipboard from './clipboard';

export default {
  install: () => {
    Vue.directive('bottomPopupDrag', bottomPopupDrag);
    Vue.directive('clipboard', clipboard);
  }
};

<template>
  <div class="detection-management">
    <pull-refresh v-model="pullLoading" @refresh="refreshList" :disabled="!pageInit">
      <list
        v-if="listInfo.length"
        v-model="loading"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <reportIBox v-for="(item, index) in listInfo" :key="index" :info="item" />
      </list>
      <empty v-if="!listInfo.length" description="暂无订单" />
      <div style="height: 60px;"></div>
    </pull-refresh>
    <tabbar :curIndex="0" />
  </div>
</template>

<script>
import { PullRefresh, List, Empty } from 'vant';

import tabbar from '../components/tabbar';
import reportIBox from '@/components/reportIBox';
import services from '@/service';
import { mapGetters } from 'vuex';
import { notRequest } from '@/lib/utils';

export default {
  components: { tabbar, reportIBox, PullRefresh, List, Empty },
  data() {
    return {
      searchVal: '',
      tabList: ['全部', '待支付', '检测中', '已完成'],
      listInfo: [],
      pullLoading: false,
      loading: false,
      finished: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      colorEnum: {
        检测中: '#808084',
        查看报告: '#24d270'
      }
    };
  },
  computed: {
    ...mapGetters(['patientId']),
    pageInit() {
      return !notRequest();
    }
  },
  mounted() {
    if (!this.pageInit) return;

    this.refreshList();
  },
  methods: {
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      // if (!this.S_token) {
      //   this.webviewLogin();
      //   return;
      // }
      this.queryParams.pageNum++;

      const res = await services.patient.getHealthList({ ...this.queryParams, patientId: this.patientId });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.detection-management {
}
</style>

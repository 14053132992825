export default {
  state: {
    keepAliveViews: []
  },
  getters: {
    keepAliveViews: state => state.keepAliveViews
  },
  actions: {
    changeRouteAliveViews({ commit }, viewArr) {
      commit('changeRouteViews', viewArr);
    }
  },
  mutations: {
    changeRouteViews(state, viewArr) {
      state.keepAliveViews = viewArr;
    }
  }
};

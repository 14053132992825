<template>
  <div>
    <div class="add-doctor">
      <div class="panel-product">
        <p>
          <span class="out"
            >设备实发数： <span>{{ item.deviceOutCount }}</span> 台
          </span>
          <span class="apply">（申请数：{{ item.deviceApplyCount }}）</span>
        </p>
        <p>
          <span class="out"
            >耗材实发数： <span>{{ item.consumablesOutCount }}</span> 份
          </span>
          <span class="apply">（申请数：{{ item.consumablesApplyCount }}）</span>
        </p>
        <div class="gray-warp">
          <p class="gray-info">{{ item.createTime }}</p>
          <p class="gray-info">{{ item.goodsName }}</p>
          <p class="gray-info">
            申请理由：<span>{{ item.applyContent }}</span>
          </p>
          <p class="gray-info">申请人：{{ item.createBy }}</p>
          <p class="gray-info">
            收货信息：{{ showArea(item) }}
            <span v-clipboard:copy="copyValue(item)" v-clipboard:success="() => $toast('复制成功')" class="copy">
              复制</span
            >
          </p>
        </div>
      </div>

      <div class="device-decord-form">
        <div class="device-decord-tags">
          已添加：<span v-for="(item, index) in deviceNums" :key="item" class="dev-tag">
            {{ item }}
            <Icon name="cross" @click="delDeviceNums(index)" />
          </span>
          <span class="dev-tag"><Icon name="plus" @click="fieldPrompShow({})"/></span>
        </div>
        <van-form ref="formRef">
          <field
            :value="item.goodsName"
            name="产品"
            label="产品"
            placeholder="请选择产品"
            required
            readonly
            clickable
          />
          <!-- <popup v-if="!isEdit" v-model="settingDevicesShow" position="bottom">
            <picker
              :columns="settingDevicesList"
              @confirm="settingDevicesListConfirm"
              @cancel="settingDevicesShow = false"
              value-key="text"
            />
          </popup> -->
          <field
            v-model="formInfo.deviceNum"
            name="设备编号"
            label="设备编号"
            placeholder="请添加设备编号"
            right-icon="scan"
            readonly
            clickable
            @click="toScan"
          />

          <field
            v-model="formInfo.deviceOutCount"
            name="设备发货数量"
            label="设备发货数量"
            placeholder="请输入设备发货数量"
            type="number"
            :readonly="isEdit"
            required
            :rules="[{ required: true, message: '请输入' }]"
          />
          <field
            v-model="formInfo.consumablesOutCount"
            name="耗材发货数量"
            label="耗材发货数量"
            placeholder="请输入耗材发货数量"
            type="number"
            :readonly="isEdit"
            required
            :rules="[{ required: true, message: '请输入' }]"
          />
          <field
            v-model="formInfo.remark"
            name="说明"
            label="说明"
            placeholder="请输入说明"
            type="textarea"
            :readonly="isEdit"
          />
          <field name="uploader" :label="item.label" v-for="(item, index) in imageList" :key="index">
            <template #input>
              <uploader
                :maxCount="maxCount"
                v-model="item.value"
                :after-read="from => afterRead(from, item)"
                :readonly="isEdit"
                :deletable="!isEdit"
              />
            </template>
          </field>
        </van-form>
      </div>
    </div>

    <div style="height: 100px" />
    <fixedButton text="提交" @emitEvent="submit" />

    <fieldPrompt
      title="添加"
      v-if="fieldPromptModel.show"
      :show.sync="fieldPromptModel.show"
      :fieldForm="fieldPromptModel.fieldForm"
      :info="fieldPromptModel.info"
      @emit="fieldPrompEmit"
    />
  </div>
</template>

<script>
import services from '@/service';
import { Icon, Form as VanForm, Field, Uploader } from 'vant';
import { areaList } from '@vant/area-data';
import fixedButton from '@/components/fixedButton';
import { scanQRCode, phoneReg } from '@/lib/utils';
// import tabbar from '../components/tabbar';
import fieldPrompt from '@/components/fieldPrompt';

export default {
  components: { Icon, VanForm, Field, Uploader, fixedButton, fieldPrompt },
  data() {
    return {
      fieldPromptModel: {
        show: false,
        fieldForm: {
          deviceNum: {
            label: '设备编号'
          }
        }
      },
      phoneReg,
      deviceNums: [],
      showCalendar: false,
      areaList,
      area: '',
      areaValue: '',
      formInfo: {
        f1: {},
        f2: {},
        receiveType: 'sales',
        deviceOutCount: '',
        consumablesOutCount: '',
        remark: ''
      },
      imageList: [
        {
          label: '拍照',
          key: 'recordUrl',
          value: []
        }
      ],
      maxCount: 1,
      settingDevices: {},
      settingDevicesList: [],
      settingDevicesShow: false,

      sales: {},
      salesList: [],
      salesShow: false,
      item: {}
    };
  },
  async mounted() {
    // const [settingDevices] = await Promise.all([settingDevicesAll()]);
    // this.settingDevices = settingDevices;
    // this.settingDevicesList = settingDevices.enums.map(i => {
    //   return { ...i, text: i.goodsName };
    // });
    // this.formInfo.f1 = this.settingDevicesList[0];

    this.getDeviceApplyRecord();
  },
  computed: {
    isEdit() {
      return false;
    },
    curId() {
      return this.$route.query.id;
    }
  },
  methods: {
    async fieldPrompShow(item) {
      this.fieldPromptModel.info = item;
      this.fieldPromptModel.show = true;
    },
    fieldPrompEmit(e) {
      if (!e.deviceNum) return;

      const deviceNums = [...new Set([...this.deviceNums, e.deviceNum])];
      this.deviceNums = deviceNums;
      this.fieldPromptModel.show = false;
    },
    showArea(item) {
      return `${item.receiveName} ${item.receiveMobile} ${item.addressProvinceName} ${item.addressCityName} ${item.addressRegionName} ${item.addressDetail}`;
    },
    copyValue(row) {
      return `收货人：${row.receiveName}\n电话：${row.receiveMobile}\n收货地址：${row.addressProvinceName} ${row.addressCityName} ${row.addressRegionName} ${row.addressDetail}`;
    },
    async getDeviceApplyRecord() {
      const res = await services.admin.getDeviceApplyRecord(this.$route.query.id);
      this.item = res.data;
      this.formInfo.deviceOutCount = res.data.deviceOutCount;
      this.formInfo.consumablesOutCount = res.data.consumablesOutCount;
    },
    settingDevicesListConfirm(e) {
      this.formInfo.f1 = e;
      this.settingDevicesShow = false;
    },
    salesListConfirm(e) {
      this.formInfo.f2 = e;
      this.salesShow = false;
    },
    async getInfo() {
      if (!this.isEdit) return;
      const res = await services.admin.deviceRecordInfo(this.curId);
      this.formInfo.deviceNum = res.data.deviceNum;
      this.formInfo.receiveName = res.data.receiveName;
      this.formInfo.receiveMobile = res.data.receiveMobile;
      this.formInfo.addressDetail = res.data.addressDetail;

      this.area = `${res.data.addressProvinceName}/${res.data.addressCityName}/${res.data.addressRegionName}`;

      if (res.data.recordUrl) {
        this.imageList[0].value = [{ url: res.data.recordUrl }];
      }

      if (res.data.goodsSettingId) {
        this.formInfo.f1 = this.settingDevices.enums.find(i => i.goodsSettingId === res.data.goodsSettingId);
      }
      if (res.data.salesId) {
        this.formInfo.f2 = this.sales.enums.find(i => i.salesId === res.data.salesId);
      }
      if (res.data.receiveName) {
        this.formInfo.receiveType = 'people';
      }
    },
    async toScan() {
      if (this.isEdit) return;
      const deviceNum = await scanQRCode();
      const deviceNums = [...new Set([...this.deviceNums, deviceNum])];
      this.deviceNums = deviceNums;
    },
    delDeviceNums(idx) {
      this.deviceNums.splice(idx, 1);
    },
    async afterRead(form, item) {
      const formData = new FormData();
      formData.append('file', form.file);
      const res = await services.common.fileUpload(formData);
      item.value = [res.data];
    },

    async submit() {
      await this.$refs.formRef.validate();

      if (this.deviceNums.length !== Number(this.formInfo.deviceOutCount)) {
        return this.$toast('设备发货数量与设备编号数量不一致');
      }

      const params = {
        ...this.formInfo,
        goodsSettingId: this.item.goodsSettingId,
        goodsName: this.item.goodsName,
        deviceApplyRecordId: this.item.deviceApplyRecordId,
        deviceNums: this.deviceNums
      };

      delete params.f1;
      delete params.f2;

      this.imageList.forEach(i => {
        if (i.value.length) {
          params[i.key] = i.value[0].url;
        }
      });

      await services.admin.deliveryDevice(params);
      this.$toast('提交成功');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-field__label {
    width: 100px !important;
  }
}
.add-doctor {
  padding: $tb $lr;

  .panel-product {
    margin-bottom: 10px;
    line-height: 22px;
    font-size: $size6;
    .out {
      span {
        font-size: $size3;
        font-weight: 600;
        color: $red1;
      }
    }
    .apply {
      margin-left: 10px;
      font-size: $size7;
      color: $gray2;
      span {
      }
    }

    .gray-warp {
      line-height: 18px;
      margin-top: 10px;
      .gray-info {
        font-size: $size7;
        color: $gray2;
        .copy {
          color: $blue1;
        }
      }
    }
  }

  .panel-product {
    background: #fff;
    box-shadow: $box-shadow1;
    border-radius: $radius;
    padding: $tb $lr;
  }

  .device-decord-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;

    .device-decord-tags {
      background: #fff;
      padding: $tb $lr 4px;
      border: 1px solid $bordercolor;
      .dev-tag {
        display: inline-block;
        padding: 4px 6px;
        background: $appcolor;
        color: #fff;
        border-radius: 4px;
        font-size: $size5;
        margin: 0 10px 10px 0;
      }
    }
  }
}
</style>

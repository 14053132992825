import Vue from 'vue';
import Router from 'vue-router';

import patientRoutes from './patientRoutes';
import clinicRoutes from './clinicRoutes';
import partnerRoutes from './partnerRoutes';

import adminRoutes from './adminRoutes';

const publicViewer = () => import(/* webpackChunkName: "viewer" */ '@/views/viewer');
const w = () => import(/* webpackChunkName: "public" */ '@/views/public/w');
const weChatToMini = () => import(/* webpackChunkName: "public" */ '@/views/public/weChatToMini');

// 短信打开
const r = () => import(/* webpackChunkName: "public" */ '@/views/public/r');

const operation = () => import(/* webpackChunkName: "public" */ '@/views/public/operation');

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

import home from '@/views/home';

Vue.use(Router);

export const routes = [
  {
    path: '/',
    redirect: '/home'
    // redirect: '/public-viewer'
    // redirect: '/patient-login'
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { title: '', hideBack: true, noAuth: true }
  },
  {
    path: '/operation',
    name: 'operation',
    component: operation,
    meta: { title: '操作手册', hideBack: true, noAuth: true }
  },
  // 短信链接跳转专用
  {
    path: '/w/:id?',
    name: 'w',
    component: w,
    meta: { title: '', hideBack: true, noAuth: true }
  },
  // 扫码跳转小程序专用
  {
    path: '/weChatToMini/:page/:id',
    name: 'weChatToMini',
    component: weChatToMini,
    meta: { title: '', hideBack: true, noAuth: true }
  },
  // 诊所端报告详情二维码跳转专用
  {
    path: '/r/:id?',
    name: 'r',
    component: r,
    meta: { title: '', hideBack: true, noAuth: true }
  },
  // 小程序带参数跳转webview
  {
    path: '/miniInit',
    name: 'miniInit',
    component: () => import('@/views/public/miniInit'),
    meta: { title: '', hideBack: true, noAuth: true }
  },

  ...patientRoutes,
  ...clinicRoutes,
  ...partnerRoutes,
  ...adminRoutes,
  {
    path: '/public-viewer',
    name: 'publicViewer',
    component: publicViewer,
    meta: { title: 'Viewer', notViewer: true, hideBack: true, noAuth: true }
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-info" }, [
    _c("div", { staticClass: "base-info-status" }, [
      _c("img", { attrs: { src: _vm.successImg } }),
      _c("span", [_vm._v(_vm._s(_vm.statusText || "检测完成"))]),
    ]),
    _c("div", { staticClass: "base-info-name" }, [
      _vm._v(_vm._s(_vm.info.clinicName)),
    ]),
    _c("div", { staticClass: "base-info-id" }, [
      _vm._v("单号：" + _vm._s(_vm.info.checkCode)),
    ]),
    _c("div", { staticClass: "base-info-warp" }, [
      _c("div", { staticClass: "base-info-item" }, [
        _c("span", [_vm._v("姓名：")]),
        _c("span", [_vm._v(_vm._s(_vm.info.patientName))]),
      ]),
      _c("div", { staticClass: "base-info-item" }, [
        _c("span", [_vm._v("性别：")]),
        _c("span", [
          _vm._v(_vm._s(_vm.genderType.getValueByKey(_vm.info.gender))),
        ]),
      ]),
      _c("div", { staticClass: "base-info-item" }, [
        _c("span", [_vm._v("年龄：")]),
        _vm.info.age
          ? _c("span", [_vm._v(_vm._s(_vm.info.age) + " 岁")])
          : _vm.info.ageMonth
          ? _c("span", [_vm._v(_vm._s(_vm.info.ageMonth) + " 月龄")])
          : _c("span", [_vm._v("-")]),
      ]),
      _c("div", { staticClass: "base-info-item" }, [
        _c("span", [_vm._v("身高：")]),
        _c("span", [_vm._v(_vm._s(_vm.info.height || "-") + " cm")]),
      ]),
      _c("div", { staticClass: "base-info-item" }, [
        _c("span", [_vm._v("体重：")]),
        _c("span", [_vm._v(_vm._s(_vm.info.weight || "-") + " kg")]),
      ]),
      _vm.detail.signUrl
        ? _c(
            "div",
            { staticClass: "base-info-item report-warp" },
            [
              _c("span", [_vm._v("签章图片：")]),
              _c(
                "span",
                {
                  staticClass: "sign-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showImg(_vm.detail.signUrl)
                    },
                  },
                },
                [_vm._v("点击查看")]
              ),
              !_vm.isWeChat
                ? [
                    _c("span", { staticClass: "line" }, [_vm._v(" | ")]),
                    _c(
                      "span",
                      {
                        staticClass: "sign-btn",
                        on: {
                          click: function ($event) {
                            return _vm.toPrint(_vm.detail.signUrl)
                          },
                        },
                      },
                      [_vm._v("点击下载")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
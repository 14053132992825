var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "patient-login" }, [
    _c("div", { staticClass: "login-bg" }),
    _vm._m(0),
    _c("div", { staticClass: "input-warp" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fromInfo.mobile,
            expression: "fromInfo.mobile",
          },
        ],
        attrs: { type: "number", maxlength: "11", placeholder: "请输入手机号" },
        domProps: { value: _vm.fromInfo.mobile },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fromInfo, "mobile", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.fromInfo.smsCode,
            expression: "fromInfo.smsCode",
          },
        ],
        attrs: { type: "number", maxlength: "6", placeholder: "请输入验证码" },
        domProps: { value: _vm.fromInfo.smsCode },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.fromInfo, "smsCode", $event.target.value)
          },
        },
      }),
      _c("span", { staticClass: "sms-tip", on: { click: _vm.getCode } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.countdown > 0 ? `${_vm.countdown} 秒后重新获取` : "获取验证码"
            ) +
            " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "login-btn", on: { click: _vm.smsLogin } }, [
      _vm._v("登 录"),
    ]),
    false
      ? _c(
          "div",
          { staticClass: "login-tip" },
          [
            _c(
              "checkbox",
              {
                model: {
                  value: _vm.isAgree,
                  callback: function ($$v) {
                    _vm.isAgree = $$v
                  },
                  expression: "isAgree",
                },
              },
              [_vm._v("已阅读并同意《用户协议》和《隐私政策》")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("p", { staticClass: "header-login" }, [_vm._v("登录")]),
      _c("p", { staticClass: "header-title" }, [_vm._v("爱检康")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <p class="top-tip">先上传<span> 身份证 </span>，系统将自动识别并回填信息</p>
    <div class="add-doctor">
      <div class="add-doctor-form">
        <fieldForm ref="formRef" :fields="fields" :fieldsHandle="fieldsHandle" />
      </div>
      <div style="height: 200px;"></div>
    </div>

    <div style="height: 100px" />
    <fixedButton text="保存" @emitEvent="submit" />
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { mapGetters } from 'vuex';
import fieldForm from '@/components/fieldForm';

export default {
  components: { fixedButton, fieldForm },
  data() {
    return {
      fields: [
        {
          label: '人员类型',
          key: 'jobType',
          type: 'radio-group',
          rules: [],
          required: true,
          value: '1',
          actions: [
            { name: '医生', key: '1' },
            { name: '护士', key: '2' }
          ]
        },
        {
          label: '手机号码',
          key: 'phone',
          type: 'number',
          maxLength: '11',
          rules: [{ required: true, message: '请输入手机号码' }],
          required: true,
          value: ''
        },
        {
          label: '身份证人像',
          key: 'idCardFigure',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          ocrType: 'idcard_front',
          ocrKey: {
            name: 'name',
            idCard: 'idCard',
            address: 'address'
          }
        },
        {
          label: '身份证国徽',
          key: 'idCardBadge',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: []
        },
        {
          label: '姓名',
          key: 'name',
          type: 'text',
          rules: [{ message: '请输入正确的姓名', pattern: /^[A-Za-z\u4e00-\u9fa5.]+$/ }],
          required: true,
          value: ''
        },
        {
          label: '身份证号码',
          key: 'idCard',
          type: 'idcard',
          rules: [{ required: true, message: '请输入身份证号码' }],
          required: true,
          value: ''
        },
        {
          label: '所在区域',
          key: 'area',
          type: 'area',
          rules: [{ required: true, message: '请选择所在区域' }],
          required: true,
          show: false,
          popupValue: '',
          value: ''
        },
        {
          label: '详细地址',
          key: 'address',
          type: 'textarea',
          maxLength: '500',
          rules: [{ required: true, message: '请输入详细地址' }],
          required: true,
          value: ''
        },
        {
          label: '执业证书',
          key: 'zyCertificate',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: []
        },
        {
          label: '资格证书',
          key: 'zgCertification',
          type: 'uploader',
          rules: [{ required: true, message: '请上传图片' }],
          required: true,
          value: [],
          hide: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['clinicId'])
  },
  methods: {
    async submit() {
      const params = await this.$refs.formRef.validate();

      await services.clinic.addDoctor({ ...params, clinicId: this.clinicId });
      this.$toast('保存成功');
      this.$router.go(-1);
    },
    fieldsHandle(fields) {
      const item = fields.find(i => i.key === 'jobType');
      const targetItem = fields.find(i => i.key === 'zgCertification');
      if (item.value !== '1') {
        targetItem.hide = true;
      } else {
        targetItem.hide = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-doctor {
  padding: $tb $lr;
  .add-doctor-form {
    box-shadow: $box-shadow1;
    border-radius: $radius;
    overflow: hidden;
  }
}
</style>

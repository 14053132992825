var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "logistics-page" }, [
    _c("div", { staticClass: "logistics-header" }, [
      _c("h2", [_vm._v("快递公司：" + _vm._s(_vm.info.expressCompany))]),
      _c("p", [_vm._v("物流单号：" + _vm._s(_vm.info.expressNo))]),
    ]),
    _c(
      "div",
      { staticClass: "logistics-timeline" },
      _vm._l(_vm.logisticsData, function (item, index) {
        return _c("div", { key: index, staticClass: "timeline-item" }, [
          _c("div", { staticClass: "timeline-time" }, [
            _vm._v(_vm._s(item.time)),
          ]),
          _c("div", { staticClass: "timeline-content" }, [
            _vm._v(_vm._s(item.context)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
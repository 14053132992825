import request from '@/lib/request';

export function queryReports(checkOrderDetailId) {
  return request.get(`/clinic/checkReport/queryReports/${checkOrderDetailId}`);
}

export function login(params, token = '') {
  const options = {};
  if (token) {
    options.headers = {
      Authorization: token
    };
  }
  return request.post('/user/valid', params, options);
}

export function wechatLogin(code, regUrl = '') {
  const params = {};
  if (regUrl) {
    params.regUrl = regUrl;
  }
  return request.post(`/wechat/login?code=${code}&platform=pay_platform_company&type=official_accounts`, params);
}

export function getUserInfo(token = '') {
  const options = {};
  if (token) {
    options.headers = {
      Authorization: token
    };
  }
  return request.get('/admin/user', options);
}

export function uploadImg(fileBlob) {
  const formData = new FormData();
  formData.append('file', fileBlob);
  return request.post('/api/files/upload', formData);
}

export function userLogin(params) {
  return request.post('/user/login', params);
}

export function fileUpload(params) {
  return request.post('/file/upload', params);
}

export function notAuthFileUpload(params) {
  return request.post('/file/upload/open', params);
}

export function getPublickey() {
  return request.get('/auth/rsa/publickey');
}

export function getWechatSignature(params) {
  return request.get('/auth/wechat/mp/jssdk/sign', {
    params
  });
}

export const getOpenId = params =>
  request.get('/auth/wechat/mp/getOpenId', {
    params
  });

export function logout() {
  return request.delete('/auth/userlogin/logout');
}

export function getWxUrlSchemeInfo(params) {
  return request.post('/auth/wechat/ma/getWxUrlSchemeInfo', params);
}

export function intentionRecord(params) {
  return request.post(`/auth/intention/record`, params);
}

export function getFileBase64(params) {
  return request.get(`/auth/opentool/getFileBase64`, {
    params
  });
}

export function passwordlogin(params) {
  return request.post(`/auth/userlogin/passwordlogin`, params);
}

export function opentoolOcr(params) {
  return request.post(`/auth/opentool/baidu/ocr`, params);
}

export function getBanner(client) {
  return request.get(`/auth/banner/${client}`);
}

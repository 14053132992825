import wechat from '@/wechat';

export default function pay(res) {
  console.log('--进入支付--');
  console.log(JSON.stringify(res));
  return new Promise((resolve, reject) => {
    wechat.$wx.chooseWXPay({
      timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
      package: res.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
      signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: res.paySign, // 支付签名
      success: () => resolve(),
      cancel: () => reject('取消支付'),
      fail: err => {
        err = err.errMsg || '支付异常';
        console.log('支付异常', JSON.stringify(err));
        reject(err);
      }
    });
  });
}

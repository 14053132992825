var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["fixed-button", _vm.hasTabbar && "has-tabbar"],
      style: { width: _vm.$store.getters.screenWidth + "px" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="content">
    <search v-model="search" placeholder="请输入搜索关键词" />

    <!-- <div class="tip">共{{ previewComponents.length }}个主页面</div> -->
    <div class="wrap">
      <div class="box" v-for="(item, index) in searchResult" @click.capture="toPage(item.path)" :key="index">
        <component class="item" :is="item.component" />
        <div></div>
        <div class="component-title">{{ getTitle(item) }}</div>
        <div class="component-title path">{{ item.path }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Search } from 'vant';

import { routes } from '@/router';
const previewComponents = [];
routes.forEach(item => {
  if (!item.meta || !item.meta.title || item.meta.notViewer) return;
  previewComponents.push({
    name: item.path.replace('/', ''),
    component: item.component,
    title: item.meta.title,
    path: item.path
  });
});

export default {
  components: {
    Search
  },
  data() {
    return {
      previewComponents,
      search: ''
    };
  },
  computed: {
    pathPrefixMapping() {
      return {
        patient: '用户端',
        clinic: '诊所端',
        partner: '业务端'
      };
    },
    searchResult() {
      if (this.search) {
        return this.previewComponents.filter(item => this.getTitle(item).indexOf(this.search) > -1);
      } else {
        return this.previewComponents;
      }
    }
  },
  methods: {
    toPage(path) {
      this.$router.push({
        path
      });
    },
    getTitle(item) {
      const prefix = Object.keys(this.pathPrefixMapping).find(prefix => item.path.indexOf(prefix) > -1);

      if (prefix) {
        return `${this.pathPrefixMapping[prefix]} - ${item.title}`;
      } else {
        return item.title;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
}

.tip {
  font-size: $size2;
  text-align: center;
  margin-bottom: $tb;
}
.wrap {
  @include flex(row);
  justify-content: space-around;
  padding: $tb 0;

  .box {
    width: 45%;
    margin-bottom: 15px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    box-shadow: $box-shadow1;

    p {
      font-size: $size3;
      margin-top: 10px;
      color: $gray1;
      text-align: center;
    }

    // 页面
    .item {
      box-sizing: border-box;
      transform: scale(1);
      zoom: 0.5;
      width: 100%;
      height: 700px;
      overflow: hidden;
    }
  }
}
.component-title {
  margin: 10px 0;
  font-size: $size4;
  color: $black1;
  text-align: center;
  &.path {
    color: $gray2;
  }
}
.wrap:after {
  content: ' ';
  width: 45%; //这个宽度和子元素宽度一致
  height: 0;
  display: block;
}
</style>

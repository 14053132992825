<template>
  <div>
    <div class="order-confirm">
      <div class="address-item" @click="selectAddr">
        <div class="address-content">
          <i class="iconfont icon-shdz" />
          <div class="address-info" v-if="addressInfo.addressId">
            <h3 class="address-name">
              <span>{{ addressInfo.addressee }}</span>
              <span class="address-phone">{{ addressInfo.phone }}</span>
            </h3>
            <p class="address-area">{{ showArea(addressInfo) }}</p>
          </div>
          <div class="address-info" v-else>
            <h3 class="address-area">请选择地址</h3>
          </div>
          <div class="address-status" v-if="!hasOrder">
            <p class="view-details"><icon name="arrow" /></p>
          </div>
        </div>
      </div>
      <div class="product-list" v-if="orderInfo.sum">
        <div class="product-item" v-for="(product, index) in products" :key="product.id">
          <div class="product-content">
            <div class="item-img">
              <van-image fit="cover" :src="product.goodsIcon" />
            </div>
            <div class="product-details">
              <h3 class="product-name">{{ product.goodsName }}</h3>
              <p class="product-description">{{ product.goodsFunction }}</p>
              <div class="product-foot">
                <span class="product-price">￥{{ product.salePrice }}</span>
                <stepper
                  v-model="product.number"
                  theme="round"
                  button-size="22"
                  disable-input
                  min="1"
                  max="99"
                  @change="refreshInfo"
                />
              </div>
              <icon class="del-icon" name="delete-o" @click="delHandle(product, index)" color="#f77474" />
            </div>
          </div>
        </div>

        <field
          v-model="orderInfo.remark"
          name="订单备注"
          label="订单备注："
          placeholder="输入订单备注"
          type="textarea"
          :rules="[{ required: true, message: '输入法人名称' }]"
        />
      </div>
      <div class="price-info">
        <div class="price-box">
          <span>实付款</span>
          <span class="price price-total">￥{{ orderInfo.payAmount }}</span>
        </div>
        <div class="price-box">
          <span>订单金额</span>
          <span class="info">￥{{ orderInfo.amount }}</span>
        </div>
        <div class="price-box">
          <span>配送金额</span>
          <span class="info">￥{{ orderInfo.deliveryAmount }}</span>
        </div>
        <div class="price-box">
          <span>会员积分抵扣</span>
          <span class="info">- ￥{{ orderInfo.realDeductionAmount }}</span>
        </div>
        <div class="price-box">
          <span>消费积分抵扣</span>
          <span class="info">
            <van-switch v-if="false" v-model="checked" size="18px" />
            - ￥{{ orderInfo.realConsumptionAmount }}</span
          >
        </div>
      </div>
    </div>
    <div style="height: 100px"></div>
    <fixedButton text="微信支付" v-if="hasOrder" @emitEvent="wxPay" class="wx" />
    <fixedButton text="提交订单" v-else @emitEvent="createOrder" />

    <!-- <div class="btn" @click="createOrder">去支付</div>
    <div class="btn" @click="wxPay" v-if="orderCode">微信支付</div> -->
  </div>
</template>

<script>
import services from '@/service';
import fixedButton from '@/components/fixedButton';
import { Switch as VanSwitch, Stepper, Image as VanImage, Icon, Field } from 'vant';
import { mapGetters } from 'vuex';
import { isWeChat, isMiniProgram } from '@/global';
import wechat from '@/wechat';
import pay from '@/lib/pay';

export default {
  name: 'mallOrderConfirm',
  components: { fixedButton, VanSwitch, Stepper, VanImage, Icon, Field },
  data() {
    return {
      address: {},
      products: [],
      originCart: [],
      timer: null,
      toCheck: false,
      orderInfo: {},
      orderCode: '',
      mallOrderId: '',
      payAmount: null,
      checked: true
    };
  },
  computed: {
    ...mapGetters(['openId', 'clinicInfo', 'addressInfo']),
    hasCartNum() {
      return this.products.reduce((sum, product) => sum + product.number, 0);
    },
    cartTotalPrice() {
      return this.products.reduce((sum, product) => sum + product.number * product.salePrice, 0) * 100;
    },
    code() {
      return this.$route.query.code;
    },
    hasOrder() {
      return this.orderCode && this.payAmount !== 0;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    delHandle(item, index) {
      console.log(item);
      this.$dialog
        .confirm({
          title: '提示',
          message: '请确认是否删除？'
        })
        .then(async () => {
          await this.deleteMall(this.originCart.find(i => i.goodsId === item.goodsId).mallCartId);
          this.products.splice(index, 1);
          this.refreshInfo();
        });
    },
    async deleteMall(id) {
      await services.mall.deleteMall(id);
    },
    async getOpenId() {
      if (isWeChat && !this.openId && this.code) {
        const res = await services.common.getOpenId({ authorizeCode: this.code });
        this.$store.dispatch('set_openId', res.data.openId);
      }
    },
    showArea(item) {
      return `${item.province} ${item.city} ${item.district} ${item.address}`;
    },
    onSubmit() {},
    selectAddr() {
      if (this.hasOrder) return;
      this.$router.push({
        name: 'mallMyAddress',
        query: {
          isSelect: true
        }
      });
    },
    async createOrder() {
      if (!this.addressInfo.addressId) {
        return this.$toast('请选择地址');
      }
      this.$store.dispatch('addLoading');
      const params = {
        addressCityName: this.addressInfo.city,
        addressDetail: this.addressInfo.address,
        addressMobile: this.addressInfo.phone,
        addressName: this.addressInfo.addressee,
        addressProvinceName: this.addressInfo.province,
        addressRegionName: this.addressInfo.district,
        clinicId: 1,
        clinicName: '演示诊所',
        remark: this.orderInfo.remark,
        consumptionPoints: 0,
        membershipPoints: 0,
        mallOrderDetailAoList: this.products
          .filter(i => i.number > 0)
          .map(i => {
            return {
              goodsId: i.goodsId,
              number: i.number
            };
          })
      };
      const orderRes = await services.mall.createServiceOrder(params);
      this.orderCode = orderRes.data.orderCode;
      this.mallOrderId = orderRes.data.mallOrderId;
      this.payAmount = orderRes.data.payAmount;
      this.$store.dispatch('subLoading');

      if (this.payAmount === 0) {
        this.$toast('下单成功');
        setTimeout(() => {
          this.$router.replace({
            name: 'mallMyOrder'
          });
        }, 1000);
      } else {
        this.wxPay();
      }
    },
    async wxPay() {
      if (isMiniProgram) {
        const data = {
          orderCode: this.orderCode,
          tradeType: 'JSAPI'
        };
        wechat.$wx.miniProgram.navigateTo({ url: `/pages/miniH5Pay/index?data=${JSON.stringify(data)}` });
        if (!this.toCheck) {
          this.checkOrderStatus();
        }
      } else {
        this.$store.dispatch('addLoading');
        const res = await services.mall.wxPay({
          orderCode: this.orderCode,
          tradeType: 'JSAPI',
          openId: this.openId
        });
        this.$store.dispatch('subLoading');

        await pay(res.data);
        this.$toast('支付成功');
        setTimeout(() => {
          this.$router.replace({
            name: 'mallMyOrder'
          });
        }, 1000);
      }
    },
    async calculatePrice() {
      if (!this.products.length) return;
      this.$store.dispatch('addLoading');

      const params = {
        addressCityName: this.addressInfo.city,
        addressDetail: this.addressInfo.address,
        addressMobile: this.addressInfo.phone || this.clinicInfo.phone,
        addressName: this.addressInfo.addressee,
        addressProvinceName: this.addressInfo.province,
        addressRegionName: this.addressInfo.district,
        clinicId: 1,
        clinicName: '演示诊所',
        consumptionPoints: 0,
        membershipPoints: 0,
        mallOrderDetailAoList: this.products
          .filter(i => i.number > 0)
          .map(i => {
            return {
              goodsId: i.goodsId,
              number: i.number
            };
          })
      };
      const res = await services.mall.calculatePrice(params);
      this.orderInfo = res.data;
      this.products = res.data.mallOrderDetailAoList;
      this.$store.dispatch('subLoading');
    },
    async getOrderDetail() {
      const res = await services.mall.getServiceOrder(this.mallOrderId);
      this.orderInfo = res.data;
    },
    checkOrderStatus() {
      this.toCheck = true;
      this.timer = setInterval(this.pollOrderStatus, 1000);
    },
    async pollOrderStatus() {
      await this.getOrderDetail();
      if (!['0', '2'].includes(this.orderInfo.status)) {
        clearInterval(this.timer);
        this.$toast('支付成功');
        this.$router.replace({
          name: 'mallMyOrder'
        });
      }
    },
    refreshInfo() {
      this.calculatePrice();
    }
  },
  mounted() {
    console.log('window.location.href', window.location.href);
    console.log('openId:', this.openId);

    this.getOpenId();
    const products = JSON.parse(window.atob(this.$route.query.state) || []).products;
    this.products = products;
    this.originCart = products;
    this.calculatePrice();
  }
};
</script>

<style lang="scss" scoped>
.order-confirm {
  padding: $tb $lr;
}

.address-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  // margin: $tb $lr;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $box-shadow1;
}

.address-content {
  display: flex;
  align-items: center;

  .icon-shdz {
    font-size: 32px;
    margin-right: $lr;
    color: transparent; /* 将iconfont的颜色改为透明 */
    -webkit-background-clip: text; /* 将背景图的绘画仅作用在文字上 */
    background-image: $btnbg;
  }
}

.address-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
}

.address-avatar {
  width: 100%;
  height: auto;
}

.address-info {
  flex: 1;
}

.address-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
  .address-phone {
    font-size: $size4;
    margin: 0 $lr;
  }
}

.address-area {
  color: $gray2;
  font-size: $size4;
  line-height: 20px;
}

.view-details {
  font-size: $size1;
  color: $appcolor;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  overflow: hidden;
}

.price-info {
  box-shadow: $box-shadow1;
  border-radius: 5px;
  background: #fff;
  margin: $tb 0;
  padding: $tb $lr;
  font-size: $size5;
  color: $gray1;

  .price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    > :first-child {
      color: $gray2;
    }
    &:not(:last-child) {
      margin-bottom: $tb;
    }
    .price {
      color: $red1;
      font-weight: 600;
      margin-left: $lr;
    }
  }

  .price-total {
    font-size: $size2;
  }
}

.product-item {
  flex: 1;
  border-bottom: 1px solid $bordercolor;
  padding: $tb 0;
  margin: 0 $lr;
  &:last-child {
    border: none;
  }
}

.product-content {
  display: flex;
  align-items: center;
}

.item-img {
  width: 80px;
  height: 80px;
  margin-right: 12px;

  .van-image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.product-details {
  position: relative;
  flex: 1;
  .product-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: $lr;
  }

  .del-icon {
    position: absolute;
    right: 3px;
    top: 0px;
  }
}

.product-name {
  color: $black1;
  margin-bottom: $tb;
  font-size: $size2;
}

.product-description {
  color: $gray2;
  margin-bottom: $tb;
  font-size: $size4;
  @include hiddenText();
}

.product-price {
  font-weight: 600;
  color: $black1;
}

.van-submit-bar {
  bottom: $tabbarHeight;
}
.cart-icon {
  font-size: 24px;
  &.active {
    color: $appcolor;
  }
}

.btn {
  margin: $tb $lr;
}
</style>

export default {
  state: {
    loadingCount: 0
  },
  getters: {
    loadingShow: state => state.loadingCount > 0
  },
  actions: {
    addLoading({ commit }) {
      commit('addLoading');
    },
    subLoading({ commit }) {
      commit('subLoading');
    }
  },
  mutations: {
    addLoading(state) {
      state.loadingCount = state.loadingCount + 1;
    },
    subLoading(state) {
      const count = state.loadingCount - 1;
      if (count >= 0) {
        state.loadingCount = count;
      }
    }
  }
};

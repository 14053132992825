<template>
  <div>
    <template v-if="pageInit">
      <search v-model="queryParams.name" placeholder="请输入姓名" @search="refreshList" />
      <dropdown-menu active-color="#1989fa">
        <dropdown-item v-model="queryParams.dateType" :options="dateType.enums" @change="refreshList" />
        <dropdown-item v-model="queryParams.productType" :options="productType.enums" @change="refreshList" />
      </dropdown-menu>
    </template>
    <pull-refresh v-model="pullLoading" @refresh="refreshList">
      <list
        v-if="listInfo.length"
        v-model="loading"
        :finished="!hasNext"
        finished-text="没有更多了"
        @load="loadList"
        :immediate-check="false"
      >
        <div class="team-list">
          <div class="team-item" v-for="(team, index) in listInfo" :key="index" @click="toDetail(team)">
            <div class="team-content">
              <div class="team-avatar">
                <van-image fit="cover" :src="team.avatar || avatar" :alt="team.name" class="avatar-image" />
              </div>
              <div class="team-details">
                <h3 class="team-name">{{ team.name }}</h3>
                <!-- <p class="team-contact">{{ team.phone }}</p> -->
                <p class="team-contact" v-if="['0', '1'].includes(team.gender)">{{ $getGender(team.gender) }}</p>
              </div>
              <div class="team-right">
                <h3 class="team-sales">
                  销量：<span style="color: red">{{ team.salesQuantity }}</span>
                </h3>
                <p class="team-clinic">
                  诊所数：<span>{{ team.clinicQuantity }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </list>
      <empty v-if="!listInfo.length" description="暂无成员" />
    </pull-refresh>
    <Icon name="add" class="add-icon" @click="toAdd" v-if="pageInit && showAction" />
    <div style="height: 100px"></div>
    <tabbar :curIndex="2" />
  </div>
</template>

<script>
import tabbar from '../components/tabbar';

import { Search, DropdownMenu, DropdownItem, PullRefresh, List, Icon, Image as VanImage, Empty } from 'vant';
import services from '@/service';
import { selectDateTypeAll, goodsSubTypeAll } from '@/enum/index';
import { notRequest } from '@/lib/utils';
import { mapGetters } from 'vuex';

export default {
  components: { tabbar, Search, DropdownMenu, DropdownItem, PullRefresh, List, Icon, VanImage, Empty },
  data() {
    return {
      avatar: this.$getImg('avatar'),
      devices: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        name: '',
        dateType: 'month',
        productType: '',
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      dateType: {},
      productType: {}
    };
  },
  computed: {
    ...mapGetters(['isPartner', 'isPartnerC']),
    showAction() {
      return this.isPartner && !this.isPartnerC;
    },
    pageInit() {
      return !notRequest();
    }
  },
  async mounted() {
    if (!this.pageInit) return;

    const [dateType, productType] = await Promise.all([selectDateTypeAll(), goodsSubTypeAll()]);
    this.dateType = dateType;
    this.productType = productType;

    if (this.$route.query.dateType) {
      this.queryParams.dateType = this.$route.query.dateType;
    }

    this.refreshList();
  },
  methods: {
    async refreshList() {
      if (!this.pageInit) return;

      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.partner.getMyTeam({ ...this.queryParams });
      this.listInfo = [...this.listInfo, ...res.data];
      // 接口暂没做分页
      this.hasNext = false;
      this.pullLoading = false;
      this.loading = false;
    },
    toDeviceDetail(id) {
      this.$router.push({
        name: 'clinicDeviceDetail',
        query: {
          id
        }
      });
    },
    getStatusClass(status) {
      return {
        'status-normal': status === '0',
        'status-abnormal': status === '1'
      };
    },
    toAdd() {
      this.$router.push({
        name: 'partnerInfoEditAdd'
      });
    },
    toDetail(item) {
      this.$router.push({
        name: 'partnerPartnerInfo',
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.team-item {
  border-bottom: 1px solid $bordercolor;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
}

.team-content {
  display: flex;
  align-items: center;
}

.team-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: $lr;
}

.avatar-image {
  width: 100%;
  height: auto;
}

.team-details {
  flex: 1;
}

.team-right {
  flex: 1;
  text-align: right;
  line-height: 20px;
  font-size: $size2;

  .team-sales {
    margin-bottom: $tb;
  }
  .team-clinic {
    font-size: $size5;
    color: $gray2;
  }
}

.team-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
}

.team-contact {
  font-size: $size4;
  color: $gray2;
}

.add-icon {
  position: absolute;
  right: 20px;
  bottom: 100px;
  font-size: 50px;
  color: $appcolor;
  box-shadow: $box-shadow1;
  border-radius: 50%;
}
</style>

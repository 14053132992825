import request from '@/lib/request';
import { userTypeAll } from '@/enum/index';
const userType = userTypeAll.getKeyByValue('患者端');

export function sendSms(mobile) {
  return request.get(`/auth/userlogin/sms/${mobile}/${userType}`);
}

export function smsLogin(params) {
  return request.post(`/auth/userlogin/smslogin`, { ...params, userType });
}

export function userLogin(params) {
  return request.post('/user/login', params);
}

export function getPatientInfo() {
  return request.get('/patient/user/getInfo');
}

export function editPatientInfo(params) {
  return request.put('/patient/user', params);
}

export function getHealthList(params) {
  return request.get(`/patient/checkOrder/list`, { params });
}

export function changeAvatar(params) {
  return request.post(`/patient/user/avatar?avatar=${params.avatar}`);
}

export function queryReports(checkOrderDetailId) {
  return request.get(`/patient/checkReport/queryReports/${checkOrderDetailId}`);
}

export function queryMsgReports(msgCode) {
  return request.get(`/patient/checkReport/queryMsgReports/${msgCode}`);
}

export function createFeedback(params) {
  return request.post(`/patient/feedback`, params);
}

export function bindMobile(params) {
  return request.post(`/patient/checkOrder/bindMobile`, params);
}

export function getOrder(checkOrderId) {
  return request.get(`/patient/checkOrder/${checkOrderId}`);
}

export function calculatePrice(params) {
  return request.post('/patient/checkOrder/calculatePrice', params);
}

export function wxPay(params) {
  return request.post('/patient/checkOrder/wxPay', params);
}

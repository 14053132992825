<template>
  <div id="app" :key="pageSlotKey">
    <navbar />
    <div class="loading-content" v-if="!$store.getters.isExportMode" v-show="$store.getters.loadingShow" @click.stop>
      <Loading class="loading-el" size="40" color="#fff" vertical>
        <span>正在加载中...</span>
      </Loading>
    </div>
    <keep-alive :include="keepAliveViewsTemp">
      <router-view />
    </keep-alive>
    <div class="global-to-login" v-if="showToLogin" @click="toLogon">去登录</div>
    <div class="root-left" v-if="currentConfig.miniQrCode">
      <div class="qrcode-warp"><img :src="currentConfig.miniQrCode" /></div>
      <div class="ba" @click="toBa">
        <img :src="$getImg('ba')" />
        <span>粤ICP备2024187782号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'vant';
import { mapGetters } from 'vuex';
import { uniqueId } from 'lodash';
import eventBus from '@/lib/eventBus';
import { routes } from '@/router/index';
import navbar from '@/components/navbar';
import { notRequest, wxToLogin } from '@/lib/utils';
import { appConfig, userClient } from '@/global';

const pageKeyPre = 'page_key_';

export default {
  components: { Loading, navbar },
  data() {
    return {
      imgEnum: {},
      pageSlotKey: uniqueId(pageKeyPre)
    };
  },
  computed: {
    ...mapGetters(['keepAliveViews']),
    currentConfig() {
      const type = this.$route.path.split('/')[1].split('-')[0];
      if (userClient.includes(type)) {
        return appConfig[type];
      } else {
        return {};
      }
    },
    keepAliveViewsTemp() {
      const forceArr = routes
        .filter(routeItem => {
          return routeItem.meta && routeItem.meta.forceKeepAlive;
        })
        .map(routeItem => routeItem.name);
      return [...new Set([...forceArr, ...this.keepAliveViews])];
    },
    showToLogin() {
      return notRequest() && !this.$route.meta.noAuth;
    }
  },
  beforeDestroy() {
    eventBus.$off('refreshPage', this.refreshPageHandle);

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setScreenWidth);
    }
  },
  created() {
    eventBus.$on('refreshPage', this.refreshPageHandle);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.setScreenWidth);
      this.setScreenWidth();
    }
  },
  methods: {
    refreshPageHandle() {
      console.log('refreshPageHandle');
      this.pageSlotKey = uniqueId(pageKeyPre);
    },
    toLogon() {
      wxToLogin();
    },
    setScreenWidth() {
      this.$store.dispatch('set_screenWidth');
    },
    toBa() {
      window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery');
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
@import './assets/styles/reset.scss';

#app {
  margin: 0 auto;
  max-width: $maxScreenWidth;
}

@media screen and (max-width: 600px) {
  #app {
    max-width: 100%;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #ebebeb;
  font-size: 16px;
}

body #app {
  background-color: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #172237;
  height: 100%;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .app-skeleton {
    margin: 24px 8px 0;
  }
}

.loading-content {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
  background-color: transparent;
  padding-bottom: 160px;

  .loading-el {
    &.van-loading {
      border-radius: 10px;
      padding: 24px 15px;
      background-color: rgba(0, 0, 0, 0.7);

      .van-loading__text {
        font-size: 14px;
        margin: 30px 0 0;
      }
    }
  }
}

.global-to-login {
  position: fixed;
  left: 50%;
  padding: $tb 30px;
  background: $btnbg;
  font-size: $size4;
  border-radius: 40px;
  color: #fff;
  box-shadow: $box-shadow1;
  bottom: calc(100px + env(safe-area-inset-bottom));
  animation: slow-move 3s infinite;

  @keyframes slow-move {
    0% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(-10px);
    }
    100% {
      transform: translateX(-50%) translateY(0);
    }
  }
}

.root-left {
  position: absolute;
  left: 50%;
  top: 70px;
  margin-left: -380px;
  @include flex();
  align-items: center;
  .qrcode-warp {
    padding: $tb $lr;
    background: #fff;
    border-radius: 4px;
    width: 110px;
    height: 110px;
    img {
      width: 100%;
    }
  }
  .ba {
    @include flex(row);
    align-items: center;
    font-size: 12px;
    color: $gray2;
    margin-top: 10px;
    cursor: pointer;
    img {
      width: 10px;
      margin-right: 4px;
    }
  }
}
@media screen and (max-width: 900px) {
  .root-left {
    display: none;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    {
      attrs: { round: "", "close-on-click-overlay": false },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "opinion-warp" }, [
        _c("p", { staticClass: "opinion-title" }, [_vm._v("消息提醒")]),
        _c("div", { staticClass: "opinion-content" }, [
          _c("p", [
            _vm.info.stockAlarmLevel === 1
              ? _c(
                  "span",
                  {
                    staticClass: "alarm-tag",
                    staticStyle: { background: "#ffb811" },
                  },
                  [_vm._v("库")]
                )
              : _vm._e(),
            _vm.info.stockAlarmLevel === 2
              ? _c(
                  "span",
                  {
                    staticClass: "alarm-tag",
                    staticStyle: { background: "#fe271b" },
                  },
                  [_vm._v("库")]
                )
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.info.stockAlarmContent) + " "),
          ]),
          _c("p", [
            _vm.info.timeAlarmLevel === 1
              ? _c(
                  "span",
                  {
                    staticClass: "alarm-tag",
                    staticStyle: { background: "#ffb811" },
                  },
                  [_vm._v("效")]
                )
              : _vm._e(),
            _vm.info.timeAlarmLevel === 2
              ? _c(
                  "span",
                  {
                    staticClass: "alarm-tag",
                    staticStyle: { background: "#fe271b" },
                  },
                  [_vm._v("效")]
                )
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.info.timeAlarmContent) + " "),
          ]),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.closedHandle } }, [
          _vm._v(" 已知晓 "),
          _vm.countdown
            ? _c("span", { staticClass: "countdown" }, [
                _vm._v(" ( "),
                _c("span", [_vm._v(_vm._s(_vm.countdown))]),
                _vm._v(" ) "),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <pull-refresh v-model="pullLoading" @refresh="refreshList">
    <list v-model="loading" :finished="!hasNext" finished-text="没有更多了" @load="loadList" :immediate-check="false">
      <div style="text-align: center; padding: 10vh 0" v-if="listInfo.length == 0 && !loading"></div>
      <div class="order-list">
        <div class="order-item" v-for="(order, index) in listInfo" :key="index" @click="toDeatil(order)">
          <div class="order-content">
            <div class="order-header">
              <p class="order-time">{{ order.createTime }}</p>
              <span class="order-status-tag">
                {{ serviceOrderState.getValueByKey(order.status) }}
              </span>
            </div>
            <div v-for="(product, pIndex) in order.orderDetailVoList" :key="pIndex" class="order-product">
              <div class="order-image">
                <van-image fit="cover" :src="product.goodsIcon" :alt="product.goodsName" class="order-avatar" />
              </div>
              <div class="order-info">
                <h3 class="order-name">{{ product.goodsName }}</h3>
                <p class="order-description">{{ product.description }}</p>
              </div>
              <p class="order-price">￥{{ product.totalAmount }}</p>
            </div>
            <div class="order-summary">
              <p></p>
              <p>
                合计：<span class="total-price">￥{{ order.payAmount }}</span>
              </p>
            </div>
            <div class="panel-foot" v-if="order.status === '0'">
              <div class="panel-left"></div>
              <div class="panel-right">
                <div class="pay-btn" @click.stop="toDeatil(order)">去支付</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </list>
  </pull-refresh>
</template>

<script>
import services from '@/service';
import { PullRefresh, List, Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import { wxAuthTo } from '@/lib/utils';

import { serviceOrderStateAll } from '@/enum/index';

export default {
  components: { PullRefresh, List, VanImage },
  data() {
    return {
      orders: [],
      pullLoading: false,
      loading: false,
      hasNext: true,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      listInfo: [],
      serviceOrderState: {}
    };
  },
  computed: {
    ...mapGetters(['clinicId'])
  },
  async mounted() {
    this.serviceOrderState = await serviceOrderStateAll();
    this.refreshList();
  },
  methods: {
    toDeatil(item) {
      wxAuthTo({
        routerName: 'clinicOrderDetail',
        query: {
          orderCode: item.orderCode,
          serviceOrderId: item.serviceOrderId
        }
      });
    },
    async refreshList() {
      this.pullLoading = true;
      this.listInfo = [];
      this.queryParams.pageNum = 0;
      this.hasNext = false;
      setTimeout(() => {
        this.loadList();
      }, 500);
    },
    async loadList() {
      this.queryParams.pageNum++;

      const res = await services.clinic.orderList({ ...this.queryParams, clinicId: this.clinicId });
      this.listInfo = [...this.listInfo, ...res.rows];
      this.hasNext = this.listInfo.length < res.total;
      this.pullLoading = false;
      this.loading = false;
      // this.$store.dispatch('subLoading');
    }
  }
};
</script>

<style lang="scss" scoped>
.order-item {
  border-bottom: 1px solid $bordercolor;
  background: #fff;
  padding: $tb $lr;
  margin: $tb $lr;
  border-radius: $radius;
  box-shadow: $box-shadow1;
  overflow: hidden;
  position: relative;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $tb;
}

.order-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: $lr;
  position: relative;
}

.order-avatar {
  width: 100%;
  height: auto;
}

.order-info {
  flex: 1;
}

.order-name {
  margin-bottom: $tb;
  font-size: $size2;
  font-weight: 500;
  color: $black1;
}

.order-description {
  color: $gray2;
  font-size: $size4;
}

.order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.order-price {
  margin-bottom: $tb;
  font-size: $size4;
  font-weight: 500;
  color: $gray2;
}

.order-time {
  font-size: $size5;
  color: $gray2;
}

.order-status-tag {
  font-size: $size4;
  font-weight: 500;
  color: $appcolor;
}

.status-pending {
  color: #ffc107; /* 待发货状态背景色 */
}

.status-shipped {
  color: $green; /* 已发货状态背景色 */
}
.order-product {
  display: flex;
  margin-bottom: 10px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-foot {
  border-top: 1px solid $bordercolor;
  @include flexCenter(row);
  justify-content: space-between;
  padding-top: $tb;
  margin-top: $tb;
  .pay-btn {
    font-size: $size4;
    margin-left: $lr;
    border-radius: 4px;
    padding: 6px 10px;
    border: 1px solid $appcolor;
    color: $appcolor;
  }
}

.total-price {
  color: $red1;
  font-weight: bold;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.routers, function (item, index) {
          return _c(
            "collapse-item",
            {
              key: index,
              attrs: { name: index },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "func-type" }, [
                          _vm._v(" " + _vm._s(item.meta.title) + " "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c(
                "div",
                { staticClass: "func-warp" },
                _vm._l(item.children, function (subItem, subIndex) {
                  return _c(
                    "div",
                    {
                      key: "sub" + subIndex,
                      staticClass: "func-item",
                      on: {
                        click: function ($event) {
                          return _vm.toLink(subItem)
                        },
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(subItem.meta.title))])]
                  )
                }),
                0
              ),
            ]
          )
        }),
        1
      ),
      _c("tabbar", { attrs: { curIndex: 0 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }